import SvgIcon from "@mui/material/SvgIcon";

const Check = (props): JSX.Element => {
	return (
		<SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
			<g id="03 Misc / Check">
				<path
					id="Check@@"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.4486 2.11957C15.344 2.02564 15.2238 1.97867 15.0881 1.97867C14.9523 1.97867 14.8374 2.02564 14.7431 2.11957L7.31546 9.64096C7.22153 9.74559 7.10653 9.7979 6.97048 9.7979C6.83443 9.7979 6.71425 9.74559 6.60996 9.64096L3.30355 6.24079C3.25141 6.18864 3.19658 6.15722 3.13909 6.14652C3.08159 6.13582 3.00587 6.13064 2.91194 6.13098C2.85979 6.13098 2.79193 6.13616 2.70836 6.14652C2.62479 6.15688 2.55693 6.18831 2.50478 6.24079L1.09427 7.54149C1.09427 7.54149 0.925005 7.675 0.925003 7.925C0.925001 8.175 1.09427 8.34026 1.09427 8.34026C2.88415 10.2315 4.6756 12.1247 6.51669 13.9658C6.62132 14.0597 6.73882 14.1067 6.86919 14.1067C6.99956 14.1067 7.11706 14.0597 7.2217 13.9658L16.8431 4.32885C16.9477 4.23492 17 4.11992 17 3.98387C17 3.84782 16.9477 3.72764 16.8431 3.62335L15.4486 2.11907V2.11957Z"
				/>
			</g>
		</SvgIcon>
	);
};

export default Check;
