import { isEqual } from "lodash";
import { createContext, useContext } from "react";
import { useState } from "react";

import { DevelopmentDataConfig } from "../../models";
import { EnvironmentHelper } from "../functions/env";

const dataConfigKey = "dataConfig";
type DataConfig = DevelopmentDataConfig & { overrideToken: string | null };
interface IDataConfig {
	config: DataConfig;
	setConfig: (config: DataConfig) => void;
	resetConfig: () => void;
}

export const defaultConfig: DataConfig = {
	includeFufi: true,
	overrideToken: null,
};

const getIsDefaultConfig = (newConfig: DataConfig) => {
	return isEqual(newConfig, defaultConfig);
};

const loadConfig = (): DataConfig => {
	const configString = EnvironmentHelper.isBrowser()
		? localStorage.getItem(dataConfigKey)
		: null;
	if (configString === null) return defaultConfig;
	return JSON.parse(configString) as DataConfig;
};

const DataConfigContext = createContext<IDataConfig>({} as IDataConfig);

export const useDataConfig = (): IDataConfig => {
	return useContext(DataConfigContext);
};

export const DataConfigContextProvider = ({
	children,
}: {
	children: JSX.Element;
}) => {
	const [config, setConfig] = useState<DataConfig>(loadConfig());

	return (
		<DataConfigContext.Provider
			value={{
				config,
				setConfig: (config: DataConfig) => {
					!getIsDefaultConfig(config)
						? localStorage.setItem(dataConfigKey, JSON.stringify(config))
						: localStorage.removeItem(dataConfigKey);
					setConfig(config);
				},
				resetConfig: () => {
					setConfig(defaultConfig);
					localStorage.removeItem(dataConfigKey);
				},
			}}
		>
			{children}
		</DataConfigContext.Provider>
	);
};
