import { QueryKey } from "@tanstack/react-query";

export interface ICreateQueryKey {
	includeUserIdentifier?: boolean;
	key: unknown[];
}

export type QueryKeyFactory = (props: ICreateQueryKey) => QueryKey;

export const getQueryKeyFactory: (
	userIdentifier: string | null
) => QueryKeyFactory =
	(userIdentifier) =>
	({ includeUserIdentifier = true, key }) => {
		const queryKey = [...key];
		if (includeUserIdentifier) {
			queryKey.unshift(userIdentifier);
		}
		return queryKey;
	};
