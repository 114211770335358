import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import useIsDesktop from "desktop/useIsDesktop";
import { navigate } from "gatsby";

import { BodyTextM, HeadingL } from "../../components/Typography";
import cs from "../../translations/cs.json";
import { useSmartAction } from "../Contract/SmartAction/context";
import ArrowIconRight from "../Icons/ArrowIconRight";
interface CallToActionProps {
	portalVersion?: boolean;
	changeContributionUrl?: string;
	newContribution?: number;
}

const CallToAction = ({
	portalVersion,
	changeContributionUrl,
	newContribution,
}: CallToActionProps) => {
	const isDesktop = useIsDesktop();
	const { setAction } = useSmartAction();
	return (
		<Stack
			sx={{ py: 5, px: 6, backgroundColor: "#fff" }}
			direction={isDesktop ? "row" : "column"}
			spacing={isDesktop ? 0 : 4}
		>
			<Stack spacing={2}>
				<HeadingL sx={{ mb: 0, color: "primary.main" }}>
					{cs.pensionCalculator.callToAction.heading}
				</HeadingL>
				<BodyTextM sx={{ color: "#6D6D6D" }}>
					{cs.pensionCalculator.callToAction.description}
				</BodyTextM>
			</Stack>
			<Button
				variant="contained"
				href={!portalVersion ? "https://www.mojenn.cz/prihlaseni/" : undefined}
				sx={{
					flexShrink: 0,
					alignSelf: isDesktop ? "center" : "flex-start",
					ml: isDesktop ? 6 : 0,
					px: 5,
				}}
				endIcon={<ArrowIconRight sx={{ width: "2.4rem", height: "2.4rem" }} />}
				onClick={() => {
					if (portalVersion && changeContributionUrl !== undefined) {
						setAction({ type: "Fond", context: { newContribution } });
						void navigate(changeContributionUrl);
					}
				}}
			>
				{cs.pensionCalculator.callToAction.button}
			</Button>
		</Stack>
	);
};

export default CallToAction;
