import { compareDesc, isSameYear } from "date-fns";

import { Chart } from "../components/Charts/props";

export enum DateFormats {
	MMyy = "MM/yy",
	Myy = "M/yy",
	MMM_yyyy = "MMM yyyy",
	MMM = "MMM",
}

export function sortByDate<T extends { date: Date }>(data: T[]): T[] {
	return data.sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));
}

export const dateTimeFormat = new Intl.DateTimeFormat("cs-CZ", {
	timeZone: "Europe/Prague",
});

export const getXAxisLabelFormats = (
	data: Chart[],
	isDesktop: boolean
): DateFormats => {
	const firstDate = data[0].data[0].date;
	const lastDate = data[0].data[data[0].data.length - 1].date;
	if (!isSameYear(firstDate, lastDate))
		return isDesktop ? DateFormats.MMM_yyyy : DateFormats.Myy;
	return DateFormats.MMM;
};
