import { StaticImage } from "gatsby-plugin-image";

export default function Logout() {
	return (
		<StaticImage
			src="../images/logout.jpg"
			alt="desk"
			style={{
				position: "absolute",
				zIndex: -1,
				left: "-85px",
				top: "150px",
			}}
		/>
	);
}
