import SvgIcon from "@mui/material/SvgIcon";

const ContactsIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="25" height="18" viewBox="0 0 25 18" {...props}>
			<path d="M23.75 2.6a.5.5 0 0 0-.5.5v12.8a1.1 1.1 0 0 1-1.09 1.1H2.34a1.1 1.1 0 0 1-1.09-1.1V2.1A1.1 1.1 0 0 1 2.34 1h18.68a.5.5 0 0 0 0-1H2.34A2.1 2.1 0 0 0 .25 2.1v13.8A2.1 2.1 0 0 0 2.34 18h19.82a2.1 2.1 0 0 0 2.09-2.1V3.1a.5.5 0 0 0-.5-.5Z" />
			<path d="M12.55 13.2c.21-.17.44-.3.68-.41.34.3.78.5 1.24.55.15 0 .3-.04.44-.13.2-.14.5-.4.7-.57l.95.3a.5.5 0 0 0 .34-.02l.82-.35h.53l1.1.36a.5.5 0 1 0 .3-.95l-1.16-.38a.5.5 0 0 0-.15-.03h-.72a.5.5 0 0 0-.2.04l-.74.32-1.03-.33a.5.5 0 0 0-.48.1c-.14.13-.52.46-.76.63a2.4 2.4 0 0 1-.68-.42c-.14-.11-.56-.45-1.78.49a.5.5 0 1 0 .6.8ZM3.25 12.97a.5.5 0 0 0 .64.54.5.5 0 0 0 .36-.42c.11-.97.43-1.37 1.2-1.53a.32.32 0 0 1 .28.07c.33.24.73.36 1.14.34.4 0 .78-.1 1.11-.3a.33.33 0 0 1 .4-.03 2.11 2.11 0 0 1 .88 1.54.5.5 0 0 0 .98-.17 3.11 3.11 0 0 0-1.26-2.16 1.32 1.32 0 0 0-1.54-.03.97.97 0 0 1-.57.15.78.78 0 0 1-.54-.13 1.28 1.28 0 0 0-1.08-.26 2.44 2.44 0 0 0-2 2.39ZM6.76 9.76c1.2 0 2.1-1.73 2.1-2.86A1.93 1.93 0 0 0 6.76 5a1.93 1.93 0 0 0-2.11 1.9c0 1.13.9 2.86 2.1 2.86Zm0-3.76c.18 0 1.1.04 1.1.9 0 .77-.65 1.86-1.1 1.86-.46 0-1.11-1.1-1.11-1.86 0-.86.92-.9 1.1-.9ZM20.7 5.5a.5.5 0 0 0-.5-.5h-8.46a.5.5 0 1 0 0 1h8.46a.5.5 0 0 0 .5-.5ZM19.11 7.5a.5.5 0 0 0-.5-.5h-6.87a.5.5 0 1 0 0 1h6.87a.5.5 0 0 0 .5-.5Z" />
		</SvgIcon>
	);
};

export default ContactsIcon;
