import cs from "../../../../translations/cs.json";
import InformationList from "../../../InformationList";

const Person = ({ firstName, lastName }) => (
	<InformationList
		information={[
			{
				title: `${cs.global.fullName}:`,
				description: `${firstName} ${lastName}`,
			},
		]}
	/>
);

export default Person;
