import { Paper, PaperProps, Skeleton, Stack } from "@mui/material";
import { isValidElement, ReactElement, ReactNode } from "react";

import HeaderWithDescription, {
	HeaderWithDescriptionProps,
} from "../HeaderWithDescription";
import { HeadingM } from "../Typography";

interface Props extends PaperProps {
	title: string | ReactNode;
	information: (HeaderWithDescriptionProps | ReactElement)[];
	isLoading: boolean;
	icon?: ReactElement | ReactNode;
}

function InformationsList({
	title,
	information,
	sx,
	isLoading,
	icon,
}: Props): JSX.Element {
	return (
		<Paper
			sx={{
				height: "100%",
				p: 4,
				...sx,
			}}
		>
			{icon}
			<HeadingM sx={{ pl: 5, py: 4, backgroundColor: "#F1EDEB" }}>
				{isLoading ? <Skeleton width={200} /> : title}
			</HeadingM>
			<Stack spacing={4}>
				{information.map((singleInformation) => {
					if (isValidElement(singleInformation)) {
						return singleInformation;
					} else {
						const params = isLoading
							? {
									title: singleInformation.title || <Skeleton width={200} />,
									description: <Skeleton width={200} />,
									additionalElements: singleInformation.additionalElements && (
										<Skeleton width={50} />
									),
							  }
							: singleInformation;
						return (
							<HeaderWithDescription
								key={singleInformation.title}
								{...params}
								swipeable={true}
							/>
						);
					}
				})}
			</Stack>
		</Paper>
	);
}

export default InformationsList;
