import { Box, Stack } from "@mui/material";

import { LoggedOutBar } from "../../components/AppBar";
import Attention from "../../components/AttentionBlock/index";
import NewPortalAttention from "../../components/AttentionBlock/NewPortalAttention";
import { InternalButtonLink } from "../../components/Link";
import { BackLink } from "../../components/Link/InternalLink";
import { OverviewLoginType } from "../../components/Overview";
import { SEO } from "../../components/SEO";
import { BodyTextM, HeadingM } from "../../components/Typography";
import cs from "../../translations/cs.json";

export const AppBar = (): JSX.Element => <LoggedOutBar />;

function AuthChoicePage(): JSX.Element {
	return (
		<>
			<OverviewLoginType alt="Přihlášení">
				<Stack sx={{ flexGrow: 1, px: 3 }}>
					<BackLink to="/rozcestnik/" mb={4} />
					<HeadingM>Portál Moje NN</HeadingM>
					{process.env.GATSBY_FEATURE_IS_REGISTRATION_ENABLED === "true" ? (
						<BodyTextM mb={4}>{cs.portalRegistration.allowed}</BodyTextM> && (
							<NewPortalAttention />
						)
					) : (
						<Attention severity="warning">
							{cs.portalRegistration.forbidden}
						</Attention>
					)}
					<Stack
						mt={5}
						direction="row"
						justifyContent="space-between"
						spacing={5}
					>
						<InternalButtonLink
							to="/prihlaseni/"
							variant="outlined"
							sx={{ width: "50%", py: "11px" }}
						>
							<BodyTextM>Přihlášení</BodyTextM>
						</InternalButtonLink>
						{process.env.GATSBY_FEATURE_IS_REGISTRATION_ENABLED === "true" && (
							<InternalButtonLink
								to="/registrace/"
								variant="outlined"
								sx={{ width: "50%", py: "11px" }}
							>
								<BodyTextM>Registrace</BodyTextM>
							</InternalButtonLink>
						)}
					</Stack>
				</Stack>
			</OverviewLoginType>
			<Box
				sx={{
					p: { md: 5 },
					boxShadow: (theme) => ({
						md: theme.shadows[4],
					}),
					backgroundColor: "white.main",
				}}
			>
				<HeadingM withoutScale>Výhody portálu</HeadingM>
				<ul>
					<li>
						<BodyTextM>Všechny smlouvy po ruce</BodyTextM>
					</li>
					<li>
						<BodyTextM>Aktuální stav mých investic</BodyTextM>
					</li>
					<li>
						<BodyTextM>Přehled o platbách a platby pojistného</BodyTextM>
					</li>
					<li>
						<BodyTextM>Roční výpisy a daňové potvrzení</BodyTextM>
					</li>
					<li>
						<BodyTextM>Změna příspěvku na penzijku</BodyTextM>
					</li>
					<li>
						<BodyTextM>Přidělení souhlasu s elektronickou komunikací</BodyTextM>
					</li>
				</ul>
			</Box>
		</>
	);
}

export default AuthChoicePage;

export const Head = (): JSX.Element => <SEO title="Přihlášení/Registrace" />;
