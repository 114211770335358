const BeneficiariesByMail = (): JSX.Element => {
	return (
		<p>
			Je nám líto, ale změna právnické osoby nelze provést přes Portál.
			Chcete-li změnu provést, vyplňte{" "}
			<a
				href="/navrh-na-zmenu-pojistne-smlouvy.pdf"
				target="_blank"
				rel="noreferrer"
			>
				tento
			</a>{" "}
			formulář a pošlete nám ho mailem na{" "}
			<a href="mailto:skenovani@nn.cz">skenovani@nn.cz</a> nebo se obraťte na
			Vašeho poradce.
		</p>
	);
};

export default BeneficiariesByMail;
