import { Stack } from "@mui/material";
import { useLocation } from "@reach/router";
import useIsDesktop from "desktop/useIsDesktop";
import { PageProps } from "gatsby";

import LoginImage from "../../images/LoginImage";
import LogoutImage from "../../images/LogoutImage";

const images = {
	"/bezpecne-odhlaseni/": <LogoutImage />,
};

type ImagePaths = keyof typeof images;

const Layout = ({ children, pageContext }: PageProps): JSX.Element => {
	const isD = useIsDesktop();
	const { pathname } = useLocation();
	const image = images[pathname as ImagePaths] || (
		<LoginImage opacity={pageContext.additionalProps.backgroundOpacity} />
	);

	return (
		<Stack
			sx={{
				position: "relative",
				flexGrow: 1,
				alignItems: { md: "flex-end" },
			}}
		>
			{isD && image}
			<Stack
				spacing={5}
				sx={{
					flexGrow: 1,
					width: { md: "48%" },
					overflowY: "hidden",
					mx: { md: -3, xxs: 0 },
					pb: 3,
					px: { md: 3, xxs: 4 },
				}}
			>
				{children}
			</Stack>
		</Stack>
	);
};

export default Layout;
