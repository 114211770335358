import { graphql, useStaticQuery } from "gatsby";

import Attention from "../../../components/AttentionBlock";
import { SEO } from "../../../components/SEO";
import { BodyTextM } from "../../../components/Typography";
import withProtection from "../../../highOrderComponents/withProtection";

const useLocalTtranslations = () => {
	return useStaticQuery<Queries.CommunicationContractTranslationQuery>(
		graphql`
			query CommunicationContractTranslation {
				translations: file(name: { eq: "cs" }) {
					childTranslationsJson {
						profile {
							communicationContractation {
								header
								content
								blue {
									title
									content
								}
							}
						}
					}
				}
			}
		`
	);
};

export const Title = (): string =>
	useLocalTtranslations().translations.childTranslationsJson.profile
		.communicationContractation.header;

const Info = ({ pageContext }): JSX.Element => {
	const content = useLocalTtranslations();
	const translations =
		content.translations.childTranslationsJson.profile
			.communicationContractation;

	return (
		<>
			<BodyTextM>{translations.content}</BodyTextM>
			<Attention severity="info" title={translations.blue.title}>
				<span
					dangerouslySetInnerHTML={{ __html: translations.blue.content }}
				></span>
			</Attention>
		</>
	);
};

export default withProtection(Info);

export const Head = (): JSX.Element => (
	<SEO title={Title}>
		<meta name="robots" content="noindex" />
	</SEO>
);
