export class EnvironmentHelper {
	static isLocal(): boolean {
		return !process.env.GATSBY_ENVIRONMENT_NAME;
	}

	// Returns local config file name based on what api we want to use
	static getLocalEnvFileName(): string {
		if (process.env.GATSBY_API_ENV === "ACC") return ".env.local.acc";
		return ".env.local";
	}

	static isDev(): boolean {
		return (
			process.env.GATSBY_ENVIRONMENT_NAME === "eng" ||
			process.env.GATSBY_ENVIRONMENT_NAME === "dev"
		);
	}
	static isAcc(): boolean {
		return process.env.GATSBY_ENVIRONMENT_NAME === "acc";
	}
	static isProd(): boolean {
		return process.env.GATSBY_ENVIRONMENT_NAME === "prod";
	}
	static isBrowser(): boolean {
		return typeof window !== "undefined";
	}
	static isBuild(): boolean {
		return typeof window === "undefined";
	}
}
