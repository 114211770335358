/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PickedAnswerRequest } from './PickedAnswerRequest';
import {
    PickedAnswerRequestFromJSON,
    PickedAnswerRequestFromJSONTyped,
    PickedAnswerRequestToJSON,
} from './PickedAnswerRequest';

/**
 * 
 * @export
 * @interface UserAnswersRequest
 */
export interface UserAnswersRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UserAnswersRequest
     */
    rejected: boolean;
    /**
     * 
     * @type {Array<PickedAnswerRequest>}
     * @memberof UserAnswersRequest
     */
    answers: Array<PickedAnswerRequest>;
}

/**
 * Check if a given object implements the UserAnswersRequest interface.
 */
export function instanceOfUserAnswersRequest(value: object): value is UserAnswersRequest {
    if (!('rejected' in value) || value['rejected'] === undefined) return false;
    if (!('answers' in value) || value['answers'] === undefined) return false;
    return true;
}

export function UserAnswersRequestFromJSON(json: any): UserAnswersRequest {
    return UserAnswersRequestFromJSONTyped(json, false);
}

export function UserAnswersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAnswersRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'rejected': json['rejected'],
        'answers': ((json['answers'] as Array<any>).map(PickedAnswerRequestFromJSON)),
    };
}

export function UserAnswersRequestToJSON(value?: UserAnswersRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rejected': value['rejected'],
        'answers': ((value['answers'] as Array<any>).map(PickedAnswerRequestToJSON)),
    };
}

