import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { ErrorMessage, FieldArray, useField, useFormikContext } from "formik";

import { ProductType, StrategyCode } from "../../../../models";
import { Allocation } from "../../../../models";
import { NumberToCZK } from "../../../functions";
import { usePensionDetail } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import { sortedFunds } from "../../../utils";
import FormErrorMessage from "../../Form/FormErrorMessage";
import CheckboxInput from "../../Form/Input/Checkbox";
import Share from "../../Form/Input/Share";
import RatioDistribution from "../../Form/RatioDistribution";
import FundsDistribution from "../../Funds/Distribution";
import { BodyTextM, BodyTextS, HeadingL, HeadingM } from "../../Typography";
import {
	DistributionProps,
	fundsDistributionDefault,
} from "../ChangeSavingsStrategy";
import { errorMap, maxRecommendedAllocations } from "./Form/validation";
interface EditInvestmentStrategyProps extends DistributionProps {
	resources: "existingResources" | "newResources" | undefined;
	contractId: string;
	recommendedStrategy: {
		code: StrategyCode;
		allocation: Allocation[];
	};
}

const EditInvestmentStrategy = ({
	allocation = fundsDistributionDefault,
	resources,
	recommendedStrategy,
	contractId,
}: EditInvestmentStrategyProps): JSX.Element => {
	const sortedAllocation = sortedFunds(allocation);
	const { title, description } = cs.investmentStrategy[resources];
	const { values, errors } = useFormikContext();
	const { data } = usePensionDetail({ id: contractId, type: ProductType.Uf });
	const sumOfRatios: number = values[resources].allocation.reduce(
		(acc, obj) => {
			if (obj.allocation === undefined) return acc;
			else return acc + obj.allocation;
		},
		0
	);

	const [{ value }, _, { setValue }] = useField(
		`${resources}.overrideFundsAllocationRecommendation`
	);

	return (
		<Stack spacing={6}>
			<Stack spacing={5}>
				{title && <HeadingL mb={0}>{title}</HeadingL>}
				{description && <BodyTextM component="p">{description}</BodyTextM>}
				<Stack flexDirection={{ md: "row" }} gap={6}>
					<Stack sx={{ width: { md: "50%" } }} spacing={5}>
						{contractId ? (
							<Stack
								sx={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<HeadingM mb={0}>
									{cs.investmentStrategy.currentAllocation}
								</HeadingM>
								<Box>
									<BodyTextM>
										Mám naspořeno <br />
										{NumberToCZK(data.savedAmount)}
									</BodyTextM>
								</Box>
							</Stack>
						) : (
							<HeadingM mb={0}>
								{cs.investmentStrategy.currentAllocation}
							</HeadingM>
						)}

						<FundsDistribution allocation={sortedAllocation} isFixed={true} />
						{/* TODO FundsDistribution: INFORMACE NESMÍ BÝT PODLE API, ALE MUSÍ BÝT POSTARU, VIZ "Aktuální stav spoření k předešlému pracovnímu dni" NA http://localhost:8000/doplnkove-penzijni-sporeni/?cislo=0229399636*/}
					</Stack>
					<Stack spacing={4} sx={{ width: { md: "50%" } }}>
						<HeadingM sx={{ mb: "unset" }}>
							{cs.investmentStrategy.newAllocation}
						</HeadingM>
						<RatioDistribution
							usedRatio={sumOfRatios}
							shareInputs={
								<>
									<FieldArray
										name={`${resources}.allocation`}
										render={() => {
											return (
												<>
													{sortedFunds(
														maxRecommendedAllocations[recommendedStrategy.code]
													).map((fund, i) =>
														fund.fundCode !== undefined ? (
															<Share
																onChange={() => {
																	if (value) {
																		setValue(false);
																	}
																}}
																key={fund.fundCode}
																label={
																	<>
																		<BodyTextM strong>
																			{
																				cs.investmentStrategy.fundsNames[
																					fund.fundCode
																				]
																			}
																		</BodyTextM>
																		<BodyTextS sx={{ mb: 0 }}>
																			{cs.investmentStrategy.recomendedTo}{" "}
																			{`${fund.allocation} %`}
																		</BodyTextS>
																	</>
																}
																name={`${resources}.allocation.${i}.allocation`}
																errorMessage={
																	<ErrorMessage
																		name={`${resources}.${
																			errorMap[recommendedStrategy.code][
																				fund.fundCode
																			]
																		}`}
																		render={(msg) => (
																			<FormErrorMessage severity={"info"}>
																				{
																					cs.investmentStrategy
																						.outOfRecommendedStrategyIntervalInfo
																				}
																			</FormErrorMessage>
																		)}
																	/>
																}
															/>
														) : (
															<Stack spacing={3} key={i}>
																<Skeleton variant="rectangular" />
																<Stack
																	spacing={3}
																	direction="row"
																	justifyContent="space-between"
																>
																	<Skeleton variant="rectangular" width={200} />
																	<Skeleton variant="rectangular" width={30} />
																</Stack>
															</Stack>
														)
													)}
												</>
											);
										}}
									/>
									<ErrorMessage
										name={`${resources}.allocation`}
										render={(msg) => (
											<FormErrorMessage name={`${resources}.allocation`}>
												{msg}
											</FormErrorMessage>
										)}
									/>
									<ErrorMessage
										name={`${resources}.valuesChanged`}
										render={(msg) => (
											<FormErrorMessage name={`${resources}.valuesChanged`}>
												{msg}
											</FormErrorMessage>
										)}
									/>
								</>
							}
						/>
					</Stack>
				</Stack>
			</Stack>
			{/* if the fond distribution sum is 100%, check also if the fund allocations are in the recommended intervals */}
			{((errors?.[resources] &&
				!errors[resources].allocation &&
				!errors[resources].valuesChanged) ||
				values[resources].overrideFundsAllocationRecommendation) && (
				<Stack spacing={5}>
					<Stack spacing={4}>
						<HeadingM mb={0}>{cs.investmentStrategy.dontOverlook}</HeadingM>
						<BodyTextM>
							{cs.investmentStrategy.outOfRecommendedStrategyDesc}
						</BodyTextM>
					</Stack>
					<Paper sx={{ p: 4 }}>
						{cs.investmentStrategy.acceptTheRisks}
						<CheckboxInput
							name={`${resources}.overrideFundsAllocationRecommendation`}
							label={cs.global.iAgree}
							isRequired
						/>
					</Paper>
				</Stack>
			)}
		</Stack>
	);
};

export default EditInvestmentStrategy;
