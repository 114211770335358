import SvgIcon from "@mui/material/SvgIcon";

const HandMoneyIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="20" height="25" viewBox="0 0 20 25" fill="none" {...props}>
			<path d="m19.616 16.944-.109-.305c-.142-.399-.13-.832.067-1.309a1.963 1.963 0 0 0-.07-1.674l-.462-.886c-.073-.143-.215-.216-.367-.241l1.041-8.04a1.61 1.61 0 0 0-1.595-1.817l-4.985-.006h-.003a1.614 1.614 0 0 0-1.604 1.74l.658 8.094h-1.904a.5.5 0 1 0 0 1l2.377-.005 2.076.288c.45.064.906.068 1.357.015l2.222-.26.301.58c.135.258.148.559 0 .916a2.682 2.682 0 0 0-.05 1.94l.109.306c.167.47.179.992.033 1.471l-.336 1.092c-.1.323-.138.66-.115 1.003l.012.155a.667.667 0 0 1-.387.652l-1.556.71c-.2.09-.415.137-.635.137h-4.953a5.28 5.28 0 0 0-2.004.394l-1.575.643a.5.5 0 0 0 .378.926l1.575-.644a4.282 4.282 0 0 1 1.627-.319h4.952c.364 0 .716-.076 1.05-.227l1.555-.71a1.673 1.673 0 0 0 .97-1.634l-.01-.156c-.017-.216.008-.43.071-.635l.337-1.094a3.37 3.37 0 0 0-.048-2.1Zm-3.64-4.14a4.29 4.29 0 0 1-1.102-.011l-1.68-.23-.67-8.238a.606.606 0 0 1 .162-.464.6.6 0 0 1 .447-.196l4.987.007a.6.6 0 0 1 .457.208.6.6 0 0 1 .147.48l-1.068 8.247-1.68.197Z" />
			<path d="m9.148 10.047.45-.307.19 1.33a.5.5 0 0 0 .99-.14L9.541 2.219a.601.601 0 0 1 .142-.485.602.602 0 0 1 .46-.21l6.01-.024a.5.5 0 0 0-.002-1h-.002l-6.01.023A1.61 1.61 0 0 0 8.55 2.36l.89 6.277-.856.585c-.411.28-.764.653-1.021 1.08l-2.09 3.469a7.934 7.934 0 0 1-3.939 3.298l-1.214.465a.5.5 0 1 0 .358.934l1.214-.465a8.936 8.936 0 0 0 4.437-3.717l2.09-3.468a2.5 2.5 0 0 1 .728-.77ZM16.642 7.017c-.643-.657-1.802-.658-2.447 0-.32.33-.498.768-.498 1.233 0 .465.177.902.5 1.233a1.72 1.72 0 0 0 1.222.517c.456 0 .901-.188 1.222-.517.323-.33.5-.768.5-1.233 0-.465-.177-.903-.5-1.233Zm-.717 1.768a.724.724 0 0 1-1.013 0 .768.768 0 0 1 0-1.07.707.707 0 0 1 1.014 0 .767.767 0 0 1-.001 1.07Z" />
		</SvgIcon>
	);
};

export default HandMoneyIcon;
