import { createContext, FC, useContext, useState } from "react";

interface INavigationTabsContext {
	activeTabId: number | false;
	setActiveTabId: (isFullLayout: number | false) => void;
}

const NavigationTabsContext = createContext<INavigationTabsContext>(undefined);

export default NavigationTabsContext;

export const NavigationTabsProvider: FC = ({ children }) => {
	const [activeTabId, setActiveTabId] = useState<number | false>(0);
	return (
		<NavigationTabsContext.Provider
			value={{
				activeTabId,
				setActiveTabId,
			}}
		>
			{children}
		</NavigationTabsContext.Provider>
	);
};

export function useNavigationTabs(): INavigationTabsContext {
	return useContext(NavigationTabsContext);
}
