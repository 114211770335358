import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { ReactNode } from "react";

import { EnvironmentHelper } from "../functions/env";
import { CustomApiError } from "../types/errors";
import { FetchApiError } from "./PrimaryAPIClient";

interface Props {
	children: ReactNode;
}

const ReactQueryClientProvider = ({ children }: Props): JSX.Element => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				useErrorBoundary(error) {
					if (error instanceof FetchApiError) {
						return (
							error.errorResponse.code !== CustomApiError.StarPensionCallFailed
						);
					}
					return true;
				},
				refetchOnWindowFocus: false,
				retryOnMount: false,
				cacheTime: 1000 * 60 * 60 * 24,
			},
		},
	});

	const persister = createSyncStoragePersister({
		storage: EnvironmentHelper.isBrowser() ? window.localStorage : undefined,
	});

	return (
		<PersistQueryClientProvider
			client={queryClient}
			persistOptions={{ persister }}
		>
			{children}
		</PersistQueryClientProvider>
	);
};

export default ReactQueryClientProvider;
