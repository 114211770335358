import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { NotInMVPButton } from "../../../components/Drawer/NotInMVP";
import InformationList from "../../../components/InformationList";
import InternalLink from "../../../components/Link/InternalLink";
import { SEO } from "../../../components/SEO";
import withProtection from "../../../highOrderComponents/withProtection";
import { useProfile } from "../../../queryHooks";
import cs from "../../../translations/cs.json";

export const Title = "Kontaktní údaje";

const Contact = ({ pageContext }): JSX.Element => {
	const result = useProfile();

	const ai = useAppInsightsContext();
	const handleClick = (changeText) => {
		ai.trackEvent({
			name: `[Change] ${changeText}`,
			properties: {
				type: "not in MVP",
			},
		});
	};

	const { mobile, email } = result.data || {
		mobile: undefined,
		email: undefined,
	};

	return (
		<>
			<InformationList
				isLoading={result.isLoading}
				information={[
					{
						title: "Telefon",
						description: (
							<>
								{mobile ? (
									<InternalLink
										to={`tel:${mobile}`}
										key="dps-phone"
										variant="light"
									>
										{mobile}
									</InternalLink>
								) : (
									"-"
								)}
							</>
						),
					},
					{
						title: "E-mail",
						description: (
							<>
								{email ? (
									<InternalLink
										to={`mailto:${email}`}
										key="dps-phone"
										variant="light"
										sx={{ overflowWrap: "anywhere" }}
									>
										{email}
									</InternalLink>
								) : (
									"-"
								)}
							</>
						),
					},
				]}
			/>
			<NotInMVPButton
				text={cs.profile.person.changeContactInfo}
				onClick={() => {
					handleClick(cs.profile.person.changeContactInfo);
				}}
			/>
		</>
	);
};

export default withProtection(Contact);

export const Head = (): JSX.Element => (
	<SEO title={Title}>
		<meta name="robots" content="noindex" />
	</SEO>
);
