import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { VictoryPie } from "victory";

import { NumberToCZKExtraDigits } from "../../functions";
import ChartLabel from "./Label";
import { PieChartProps } from "./props";

interface Props {
	data: Array<PieChartProps>;
}

const PieChart = ({ data }: Props): JSX.Element => {
	const theme = useTheme();

	return (
		<Box>
			<VictoryPie
				innerRadius={85}
				labels={() => null}
				colorScale={data.map(
					(_, index) =>
						theme.palette.dataVisualisation.quantitative[index + 1].main
				)}
				data={data.map((item) => ({ x: item.label, y: item.data }))}
			/>
			{data.map((item, index) => {
				return (
					<ChartLabel
						key={item.name}
						color={theme.palette.dataVisualisation.quantitative[index + 1].main}
						component="div"
					>
						<Stack direction="row" justifyContent="space-between">
							<span>{item.label}</span>{" "}
							<Box component="span" sx={{ pl: 3 }}>
								{NumberToCZKExtraDigits(item.data, 2)}
							</Box>
						</Stack>
					</ChartLabel>
				);
			})}
		</Box>
	);
};

export default PieChart;
