import { getImage } from "gatsby-plugin-image";

import { FundCode } from "../models";
import { cookieId } from "./components/Drawer/CookieDrawer/CookieIds";
import countries from "./components/Form/countries.json";
import { ICookieConsent } from "./context/CookieContext";
import { EnvironmentHelper } from "./functions/env";
import { imageProps } from "./pages/rozcestnik-zivotko";

export function formatAmount(amount: number): string {
	const rounded = Math.round(amount * 100) / 100;
	const formatted = rounded
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, "\xa0")
		.replace(/\./g, ",");
	return formatted;
}

export const getViewportDimensions = () => {
	const isClient = EnvironmentHelper.isBrowser();
	return {
		width: isClient ? window.innerWidth : 0,
		height: isClient ? window.innerHeight : 0,
	};
};

export const debounce = (fn, ms) => {
	let timer;
	return (_) => {
		clearTimeout(timer);
		timer = setTimeout((_) => {
			timer = null;
			fn.apply(this);
		}, ms);
	};
};

export const sortedFunds = <
	T extends { fundCode: FundCode; allocation: number }
>(
	arr?: T[]
): T[] | null => {
	if (arr === undefined) {
		return null;
	} else {
		return arr
			.reduce((acc: T[], obj: T): T[] => {
				const insertIndex = acc.findIndex(
					(item) => item["fundCode"] > obj["fundCode"]
				);
				if (insertIndex === -1) {
					acc.push(obj);
				} else {
					acc.splice(insertIndex, 0, obj);
				}
				return acc;
			}, [])
			.sort((a: T, b: T): number => {
				if (a.fundCode === FundCode.Grow) {
					return -1;
				}
				if (b.fundCode === FundCode.Grow) {
					return 1;
				}
				if (a.fundCode === FundCode.Bal) {
					return -1;
				}
				if (b.fundCode === FundCode.Bal) {
					return 1;
				}
				if (a.fundCode === FundCode.Con) {
					return -1;
				}
				if (b.fundCode === FundCode.Con) {
					return 1;
				}
				return 0;
			});
	}
};

export function countryCode2CountryName(code: string) {
	const countryName = countries.find(
		(country) => country.country_code_a3 === code
	)?.country_name_cz;

	return code === "CZ" ? "Česká Republika" : countryName;
}

/**
 * create img from url for qr code
 */
export async function downloadImage(imageSrc: string) {
	const image = await fetch(imageSrc);
	const imageBlog = await image.blob();
	const imageURL = URL.createObjectURL(imageBlog);

	const link = document.createElement("a");
	link.href = imageURL;
	link.download = "qr-kod-pro-platbu";
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

/**
 * Komponenta přijme výsledek graphql dotazu [useStaticQuery z gatsby], kterým se získávají data pro obrázky. Vrátí data modifikovaná pomocí getImage() [gatsby-plugin-image], aby se to nemuselo dělat ručně.
 * @param graphqlResult
 */
export const richImageData = (graphqlResult: imageProps) =>
	Object.keys(graphqlResult).reduce(
		(previousValue, currentValue) =>
			Object.assign(previousValue, {
				[currentValue]: {
					...graphqlResult[currentValue],
					...getImage(graphqlResult[currentValue]),
				},
			}),
		{}
	);

export const getAllowedCookiesFromLocalStorage = (): cookieId[] => {
	const cookieConsentJSON = EnvironmentHelper.isBrowser()
		? window.localStorage.getItem("cookieConsent")
		: null;

	if (cookieConsentJSON === null) {
		return [];
	}

	return (JSON.parse(cookieConsentJSON) as ICookieConsent).allowedCookies;
};
