import { Formik } from "formik";
import { useEffect, useState } from "react";
import { object, string } from "yup";

import {
	useChangeContactAddresses,
	useContactAdresses,
	useProfile,
} from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import ChangeAddress from "../../ChangeAddress/ChangeAddress";
import OverviewAddress from "../../ChangeAddress/OverviewAddress";
import { useDrawer } from "../context";
import FormMultiStepDrawer from "../FormMultiStepDrawer";
import {
	mapFetchApiErrorToSubmitStatus,
	SubmitStatus,
	SubmitStatusStep,
} from "../GlobalSteps/SubmitStatusStep";

interface IChangeAddressDrawerForm {
	title: string;
	refetch: boolean;
	setFormWasSend: (set: boolean) => void;
	contractNumber: string;
}

const ChangeAddressDrawerForm = ({
	setFormWasSend,
	refetch,
	title,
	contractNumber,
}: IChangeAddressDrawerForm): JSX.Element => {
	const [comesValidCountryCodeFromApi, setComesValidCountryCodeFromApi] =
		useState(true);

	const { mutateAsync } = useChangeContactAddresses();
	const { data, isSuccess } = useProfile();
	const adressesResult = useContactAdresses();
	const { nextStep } = useDrawer();

	const addressSchema = object({
		addressType: string().required(cs.AddressErrorMessages.address),
		street: string().when("addressType", {
			is: (address) => address === "other" || !comesValidCountryCodeFromApi,
			then: string().required(cs.AddressErrorMessages.street),
		}),
		houseNumber: string()
			.matches(/^[0-9/]*$/, cs.AddressErrorMessages.houseNumberSymbols)
			.max(30)
			.when(["addressType", "comesValidCountryCodeFromApi"], {
				is: (address) => address === "other" || !comesValidCountryCodeFromApi,
				then: string().required(cs.AddressErrorMessages.houseNumber),
				otherwise: string().nullable(),
			}),
		zip: string()
			.matches(/^[0-9]*$/, cs.AddressErrorMessages.zipWithOutSymbols)
			.when("addressType", {
				is: (address) => address === "other" || !comesValidCountryCodeFromApi,
				then: string().required(cs.AddressErrorMessages.zip),
			}),
		town: string().when("addressType", {
			is: (address) => address === "other" || !comesValidCountryCodeFromApi,
			then: string().required(cs.AddressErrorMessages.town),
		}),
		country: string().when("addressType", {
			is: (address) => address === "other" || !comesValidCountryCodeFromApi,
			then: string().required(cs.AddressErrorMessages.country),
		}),
	});

	useEffect(() => {
		if (isSuccess && data?.permanentAddresses[0].country === "") {
			setComesValidCountryCodeFromApi(false);
		}
	}, [data]);

	return (
		<Formik
			initialValues={{
				addressType: "permanent0",
				street: data?.permanentAddresses[0].street,
				houseNumber: data?.permanentAddresses[0].houseNumber,
				zip: data?.permanentAddresses[0].zip,
				town: data?.permanentAddresses[0].town,
				country: data?.permanentAddresses[0].country,
				changeAllAddresses: true,
			}}
			onSubmit={async (values, { setStatus, setSubmitting }) => {
				try {
					const isGlobalChange =
						!adressesResult.isLoading &&
						adressesResult.data?.contactAddresses &&
						adressesResult.data?.contactAddresses.length > 1 &&
						adressesResult.data?.isGlobalContactAddressChangeEnabled;
					setStatus(SubmitStatus.Submitting);
					setSubmitting(true);
					await mutateAsync({
						changeContactAddressesRequest: {
							address: {
								type: "Delivery",
								street: values.street,
								houseNumber: values.houseNumber,
								town: values.town,
								zip: values.zip,
								country: values.country,
							},
							isGlobalChange: isGlobalChange
								? values.changeAllAddresses
								: false,
							contractNumber: isGlobalChange ? null : contractNumber,
						},
					});
					if (refetch) {
						adressesResult.refetch();
					}
					setSubmitting(false);
					if (setFormWasSend) setFormWasSend(true);
					setStatus(SubmitStatus.Success);
				} catch (error) {
					setStatus(mapFetchApiErrorToSubmitStatus(error));
				}
			}}
			validationSchema={addressSchema}
		>
			{({ status, submitForm, validateForm }) => (
				<FormMultiStepDrawer title={cs.global.contactAddress}>
					{{
						component: (
							<ChangeAddress
								contractNumber={contractNumber}
								title={title}
								comesValidCountryCodeFromApi={comesValidCountryCodeFromApi}
								setComesValidCountryCodeFromApi={
									setComesValidCountryCodeFromApi
								}
							/>
						),
						stepConfig: {
							label: cs.global.continue,
						},
						stepName: "ChangeAddress",
					}}
					{{
						component: (
							<OverviewAddress contractNumber={contractNumber} title={title} />
						),
						stepConfig: {
							label: cs.global.confirmChange,
							customFunction: () => {
								validateForm()
									.then(async (errors) => {
										if (Object.keys(errors).length === 0) {
											await submitForm();
											nextStep();
										}
									})
									.catch((err) => {
										throw err;
									});
							},
						},
						stepName: "OverviewAddress",
					}}
					{{
						component: <SubmitStatusStep status={status as SubmitStatus} />,
						stepConfig: {
							label: cs.global.iUnderstand,
						},
						stepName: "SubmitStatusStep",
					}}
				</FormMultiStepDrawer>
			)}
		</Formik>
	);
};
export default ChangeAddressDrawerForm;
