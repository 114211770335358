import Stack from "@mui/material/Stack";
import { useState } from "react";

import { LoggedInBar } from "../../components/AppBar";
import DocumentManager from "../../components/DocumentManager";
import DocumentsError from "../../components/DocumentManager/DocumentsError";
import { SEO } from "../../components/SEO";
import withProtection from "../../highOrderComponents/withProtection";
import { useDocuments } from "../../queryHooks";

export const AppBar = (): JSX.Element => <LoggedInBar />;

const Documents = (): JSX.Element => {
	const result = useDocuments({
		options: {
			onError: (error) => setDocsEndpointErrorCode(error.errorResponse.code),
		},
	});
	const [docsEndpointErrorCode, setDocsEndpointErrorCode] = useState();

	if (result.isLoading) {
		return (
			<Stack
				spacing={7}
				sx={{
					px: 4,
				}}
			>
				<DocumentManager isLoading />
			</Stack>
		);
	}

	if (result.isError) {
		return <DocumentsError errorCode={docsEndpointErrorCode} />;
	}

	return (
		<Stack
			sx={{
				px: 4,
			}}
		>
			<DocumentManager data={result.data} />
		</Stack>
	);
};

export default withProtection(Documents);

export const Head = (): JSX.Element => (
	<SEO title="Dokumenty">
		<meta name="robots" content="noindex" />
	</SEO>
);
