const BeneficiaryChangeInProgress = (): JSX.Element => {
	return (
		<p>
			Momentálně pro Vás zpracováváme předchozí žádost. Než budete moci vytvořit
			novou změnu, vyčkejte na provedení té původní.
		</p>
	);
};

export default BeneficiaryChangeInProgress;
