import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Stack } from "@mui/material";

import AddressBox from "../../../components/AddressBox";
import Attention from "../../../components/AttentionBlock";
import { contractTypeToTitle } from "../../../components/Contract/ContractCard";
import ChangeAddressDrawer from "../../../components/Drawer/ChangeAddressDrawer";
import { NotInMVPButtonLink } from "../../../components/Drawer/NotInMVP";
import { Trigger } from "../../../components/Link";
import { SEO } from "../../../components/SEO";
import { HeadingM } from "../../../components/Typography";
import { dateTimeFormat } from "../../../functions/dates";
import withProtection from "../../../highOrderComponents/withProtection";
import { useContactAdresses, useProfile } from "../../../queryHooks";
import cs from "../../../translations/cs.json";

export const Title = "Moje adresy";

const Address = (): JSX.Element => {
	const profileResult = useProfile();
	const adressesResult = useContactAdresses();

	const ai = useAppInsightsContext();
	const handleClick = (changeText) => {
		ai.trackEvent({
			name: `[Change] ${changeText}`,
			properties: {
				type: "not in MVP",
			},
		});
	};

	return (
		<>
			<HeadingM withoutScale sx={{ mb: 0 }}>
				{cs.global.permanentResidence}
			</HeadingM>
			{profileResult.isLoading && <AddressBox isLoading={true} />}
			{profileResult?.data?.permanentAddresses?.map((item, i) => {
				return (
					<Stack spacing={0} key={i}>
						<AddressBox
							key={i}
							address={item}
							label={cs.profile.person.sameForAllAddressesLabel}
						/>
						<Attention severity="info" variant="transparent">
							<span
								dangerouslySetInnerHTML={{
									__html: cs.profile.person.permanentAddressInfo,
								}}
							></span>
						</Attention>
					</Stack>
				);
			})}
			<HeadingM withoutScale>{cs.global.contactAddress}</HeadingM>
			{adressesResult.isLoading && (
				<>
					<AddressBox
						isLoading={true}
						editAction={
							<Trigger
								sx={{
									ml: { md: "40%", sm: "0" },
								}}
								disabled
							>
								{cs.changeAddress.changeContactAddress}
							</Trigger>
						}
					/>
				</>
			)}
			{adressesResult.data?.contactAddresses?.map((item, i) => {
				let formattedLabel = `${contractTypeToTitle[item.type] ?? ""}`;
				item.firstInsuredPerson?.name &&
					(formattedLabel += ` - ${item.firstInsuredPerson.name}`);
				item.firstInsuredPerson?.birthday &&
					(formattedLabel += ` (${dateTimeFormat.format(
						new Date(item.firstInsuredPerson.birthday)
					)})`);

				return (
					<>
						<AddressBox
							key={`contactAddresses-${i}`}
							address={item.address}
							label={item && formattedLabel}
							editAction={
								process.env.GATSBY_FEATURE_IS_CONTACT_ADDRESS_CHANGE_ENABLED ===
								"true" ? (
									<ChangeAddressDrawer
										type={item.type}
										contractNumber={item.contractNumber}
										title={item.title}
										disabled={item.isContactAddressChangeInProgress}
										refetch={true}
									/>
								) : (
									<NotInMVPButtonLink
										sx={{
											ml: { md: "40%", sm: "0" },
										}}
										text={cs.changeAddress.changeContactAddress}
										disabled={profileResult.isLoading}
										onClick={() =>
											handleClick(cs.profile.person.changeContactAddress)
										}
									/>
								)
							}
						/>
					</>
				);
			})}
		</>
	);
};

export default withProtection(Address);

export const Head = (): JSX.Element => (
	<SEO title={Title}>
		<meta name="robots" content="noindex" />
	</SEO>
);
