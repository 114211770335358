/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IsContractPresentResponse
 */
export interface IsContractPresentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsContractPresentResponse
     */
    isPresent: boolean;
}

/**
 * Check if a given object implements the IsContractPresentResponse interface.
 */
export function instanceOfIsContractPresentResponse(value: object): value is IsContractPresentResponse {
    if (!('isPresent' in value) || value['isPresent'] === undefined) return false;
    return true;
}

export function IsContractPresentResponseFromJSON(json: any): IsContractPresentResponse {
    return IsContractPresentResponseFromJSONTyped(json, false);
}

export function IsContractPresentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsContractPresentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'isPresent': json['isPresent'],
    };
}

export function IsContractPresentResponseToJSON(value?: IsContractPresentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isPresent': value['isPresent'],
    };
}

