/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentFrequency } from './PaymentFrequency';
import {
    PaymentFrequencyFromJSON,
    PaymentFrequencyFromJSONTyped,
    PaymentFrequencyToJSON,
} from './PaymentFrequency';
import type { DeliveryType } from './DeliveryType';
import {
    DeliveryTypeFromJSON,
    DeliveryTypeFromJSONTyped,
    DeliveryTypeToJSON,
} from './DeliveryType';

/**
 * 
 * @export
 * @interface ContractParametersPP
 */
export interface ContractParametersPP {
    /**
     * 
     * @type {string}
     * @memberof ContractParametersPP
     */
    contractNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersPP
     */
    signatureDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersPP
     */
    registrationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersPP
     */
    effectiveDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersPP
     */
    retirementDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractParametersPP
     */
    isTaxOptimum: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContractParametersPP
     */
    monthlyContributionClient: number;
    /**
     * 
     * @type {PaymentFrequency}
     * @memberof ContractParametersPP
     */
    contributionFrequency: PaymentFrequency;
    /**
     * 
     * @type {boolean}
     * @memberof ContractParametersPP
     */
    doesEmployerContribute: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContractParametersPP
     */
    readonly monthlyContributionOthers: number;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ContractParametersPP
     */
    transactionsStatementDelivery: DeliveryType;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ContractParametersPP
     */
    taxStatementDelivery: DeliveryType;
    /**
     * 
     * @type {number}
     * @memberof ContractParametersPP
     */
    readonly pensionPlan?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractParametersPP
     */
    readonly isEarlyRetirement: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersPP
     */
    readonly earlyRetirementDate?: Date | null;
}

/**
 * Check if a given object implements the ContractParametersPP interface.
 */
export function instanceOfContractParametersPP(value: object): value is ContractParametersPP {
    if (!('contractNumber' in value) || value['contractNumber'] === undefined) return false;
    if (!('signatureDate' in value) || value['signatureDate'] === undefined) return false;
    if (!('effectiveDate' in value) || value['effectiveDate'] === undefined) return false;
    if (!('isTaxOptimum' in value) || value['isTaxOptimum'] === undefined) return false;
    if (!('monthlyContributionClient' in value) || value['monthlyContributionClient'] === undefined) return false;
    if (!('contributionFrequency' in value) || value['contributionFrequency'] === undefined) return false;
    if (!('doesEmployerContribute' in value) || value['doesEmployerContribute'] === undefined) return false;
    if (!('monthlyContributionOthers' in value) || value['monthlyContributionOthers'] === undefined) return false;
    if (!('transactionsStatementDelivery' in value) || value['transactionsStatementDelivery'] === undefined) return false;
    if (!('taxStatementDelivery' in value) || value['taxStatementDelivery'] === undefined) return false;
    if (!('isEarlyRetirement' in value) || value['isEarlyRetirement'] === undefined) return false;
    return true;
}

export function ContractParametersPPFromJSON(json: any): ContractParametersPP {
    return ContractParametersPPFromJSONTyped(json, false);
}

export function ContractParametersPPFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractParametersPP {
    if (json == null) {
        return json;
    }
    return {
        
        'contractNumber': json['contractNumber'],
        'signatureDate': (new Date(json['signatureDate'])),
        'registrationDate': json['registrationDate'] == null ? undefined : (new Date(json['registrationDate'])),
        'effectiveDate': (new Date(json['effectiveDate'])),
        'retirementDate': json['retirementDate'] == null ? undefined : (new Date(json['retirementDate'])),
        'isTaxOptimum': json['isTaxOptimum'],
        'monthlyContributionClient': json['monthlyContributionClient'],
        'contributionFrequency': PaymentFrequencyFromJSON(json['contributionFrequency']),
        'doesEmployerContribute': json['doesEmployerContribute'],
        'monthlyContributionOthers': json['monthlyContributionOthers'],
        'transactionsStatementDelivery': DeliveryTypeFromJSON(json['transactionsStatementDelivery']),
        'taxStatementDelivery': DeliveryTypeFromJSON(json['taxStatementDelivery']),
        'pensionPlan': json['pensionPlan'] == null ? undefined : json['pensionPlan'],
        'isEarlyRetirement': json['isEarlyRetirement'],
        'earlyRetirementDate': json['earlyRetirementDate'] == null ? undefined : (new Date(json['earlyRetirementDate'])),
    };
}

export function ContractParametersPPToJSON(value?: Omit<ContractParametersPP, 'monthlyContributionOthers'|'pensionPlan'|'isEarlyRetirement'|'earlyRetirementDate'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractNumber': value['contractNumber'],
        'signatureDate': ((value['signatureDate']).toISOString()),
        'registrationDate': value['registrationDate'] == null ? undefined : ((value['registrationDate'] as any).toISOString()),
        'effectiveDate': ((value['effectiveDate']).toISOString()),
        'retirementDate': value['retirementDate'] == null ? undefined : ((value['retirementDate'] as any).toISOString()),
        'isTaxOptimum': value['isTaxOptimum'],
        'monthlyContributionClient': value['monthlyContributionClient'],
        'contributionFrequency': PaymentFrequencyToJSON(value['contributionFrequency']),
        'doesEmployerContribute': value['doesEmployerContribute'],
        'transactionsStatementDelivery': DeliveryTypeToJSON(value['transactionsStatementDelivery']),
        'taxStatementDelivery': DeliveryTypeToJSON(value['taxStatementDelivery']),
    };
}

