import { boolean, object, string } from "yup";

import { AMLIncomeSource } from "..";

export const AMLQuestionsSchema = object().shape({
	usaResident: boolean().required(),
	greenCardHolder: boolean().required(),
	politicallyExposed: boolean().required(),
	taxResidence: string().required(),
	taxNumber: string().when("taxResidence", {
		is: (residence: string) => residence !== "CZE",
		then: (residence) => residence.required(),
		otherwise: (residence) => residence.notRequired(),
	}),
	employmentType: string().required(),
	businessIncomeId: string().when("employmentType", {
		is: AMLIncomeSource.Business,
		then: (id) => id.required(),
		otherwise: (id) => id.notRequired(),
	}),
	otherIncomeId: string()
		.required()
		.when("employmentType", {
			is: AMLIncomeSource.Other,
			then: (id) => id.required(),
			otherwise: (id) => id.notRequired(),
		}),
	incomeSourceSpecification: string().when(
		["employmentType", "businessIncomeId", "otherIncomeId"],
		{
			is: (
				type: AMLIncomeSource,
				businessIncomeId: string,
				otherIncomeId: string
			) => {
				if (type === AMLIncomeSource.Business && businessIncomeId === "Jiné")
					return true;
				if (type === AMLIncomeSource.Other && otherIncomeId === "Jiné")
					return true;
				return false;
			},
			then: (specification) => specification.required(),
			otherwise: (specification) => specification.notRequired(),
		}
	),
	//AML B Properties
	averageIncome: string().required(),
	propertySources: object()
		.required()
		.test({
			test: (sources) => {
				for (const source in sources) {
					if (sources[source]) return true;
				}
				return false;
			},
			message: "Prosím vyberte alespoň jednu položku",
		}),
	propertySourceSpecification: string().when(["propertySources"], {
		is: (propertySources: Record<string, boolean>) => {
			return propertySources["Jiné"] === true;
		},
		then: (specification) => specification.required(),
		otherwise: (specification) => specification.notRequired(),
	}),
});
