import Box from "@mui/material/Box";

import SingleStepDrawer from "../Drawer/SingleStepDrawer";
import { TriggerButton } from "../Link";
import { BodyTextM, HeadingM } from "../Typography";
import { useDrawer } from "./context";

interface Props {
	title?: string;
	description?: string | JSX.Element;
	bottomButtonAction?: () => void;
	dataTest?: string;
}

const InfoIconDrawer = ({
	title,
	description,
	bottomButtonAction,
	dataTest,
}: Props): JSX.Element => {
	const { hideDrawer } = useDrawer();
	return (
		<SingleStepDrawer dataTest={dataTest}>
			<HeadingM withoutScale>{title}</HeadingM>
			<BodyTextM>{description}</BodyTextM>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					mt: 6,
				}}
			>
				<TriggerButton
					onClick={bottomButtonAction ? bottomButtonAction : hideDrawer}
				>
					Rozumím
				</TriggerButton>
			</Box>
		</SingleStepDrawer>
	);
};

export default InfoIconDrawer;
