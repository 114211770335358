import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const InfoIcon = (props: SvgIconProps): JSX.Element => {
	return (
		<SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
			<path d="M13.333 1.417a.44.44 0 0 0-.583.166.44.44 0 0 0 .167.584c2.5 1.416 4 4.083 4 6.916C16.917 13.417 13.333 17 9 17s-7.917-3.667-7.917-8S4.667 1.083 9 1.083c.5 0 .917.084 1.417.167.25 0 .416-.083.5-.333 0-.25-.084-.417-.334-.5C10.083.333 9.5.25 9 .25A8.749 8.749 0 0 0 .25 9 8.749 8.749 0 0 0 9 17.75 8.749 8.749 0 0 0 17.75 9c0-3.167-1.667-6.083-4.417-7.583Z" />
			<path d="M9 14.25c.25 0 .417-.167.417-.417v-6.5c0-.25-.167-.416-.417-.416-.25 0-.417.166-.417.416v6.5c0 .167.167.417.417.417ZM8.667 5.083s.083.084.166.084h.334c.083 0 .083-.084.166-.084 0 0 .084-.166.084-.25v-.166c0-.084-.084-.084-.084-.167 0 0-.083-.083-.166-.083h-.334c-.083 0-.083.083-.166.083 0 .083-.084.25-.084.333V5c.084 0 .084.083.084.083Z" />
		</SvgIcon>
	);
};

export default InfoIcon;
