import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Component, ErrorInfo } from "react";

import { DisplayableError } from "./DisplayableError";
import { IErrorBoundary } from "./ErrorBoundary";
import { IErrorContext } from "./ErrorContext";

export interface ICustomErrorBoundaryProps extends Required<IErrorBoundary> {
	asyncErrorContext: IErrorContext;
	appInsights: ReactPlugin;
}

export interface ICustomErrorBoundaryState {
	error: Error | DisplayableError | null;
}

export class ErrorBoundaryWithTracking extends Component<
	ICustomErrorBoundaryProps,
	ICustomErrorBoundaryState
> {
	constructor(props: ICustomErrorBoundaryProps) {
		super(props);
		this.state = { error: null };
	}

	static getDerivedStateFromError(
		error: Error | DisplayableError
	): ICustomErrorBoundaryState {
		return { error };
	}

	private trackException(
		error: Error | DisplayableError,
		errorInfo?: ErrorInfo
	) {
		if ((error as DisplayableError).doNotTrack !== true) {
			this.props.appInsights.trackException({
				error,
				exception: error,
				severityLevel: SeverityLevel.Error,
				properties: errorInfo,
			});
		}
	}

	componentDidCatch(error: Error | DisplayableError, errorInfo: ErrorInfo) {
		this.trackException(error, errorInfo);
	}

	render() {
		const error = this.props.asyncErrorContext.error ?? this.state.error;
		if (error !== null) {
			const { fallbackUI: FallbackUI } = this.props;
			return <FallbackUI error={error} />;
		}

		return this.props.children;
	}
}
