import Stack from "@mui/material/Stack";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import { useEffect } from "react";

import { DPSBeneficiary } from "../../../../../models";
import cs from "../../../../translations/cs.json";
import ConfirmDelete from "../../../Drawer/ConfirmDelete";
import { useDrawer } from "../../../Drawer/context";
import FormErrorMessage from "../../../Form/FormErrorMessage";
import { Trigger } from "../../../Link";
import { BodyTextM, HeadingL, HeadingM } from "../../../Typography";
import AddBeneficiary from "./AddBeneficiary";
import Person from "./Person";
import PersonError from "./PersonError";

const BeneficiariesList = ({
	contractType,
	removedBeneficiaries,
	setRemovedBeneficiaries,
}): JSX.Element => {
	const { setStep } = useDrawer();
	const {
		values,
		setFieldValue,
		touched,
		errors,
		setValues,
		validateForm,
		setTouched,
	} = useFormikContext();

	const errorMessage = () => {
		/* must have duplicity - rules - beneficiariesSchema */
		if (touched.beneficiaries && errors.beneficiaries) {
			if (values.beneficiaries.length > 10)
				return "Zabezpečených osob může být maximálně 10.";
			else return false;
		}
	};

	useEffect(() => {
		validateForm()
			.then((errors) => {
				setTouched(
					Object.keys(errors).filter((item) => item !== "valuesUnchanged")
				);
				// touch all fields with error to show initial error messages (yes, there exists invalid data in DB - old contracts)
				// don't touch valuesUnchanged field which is used to warn user to not send a form without changes
			})
			.catch((err) => {
				throw err;
			});
	}, []);

	return (
		<>
			<HeadingL withoutScale data-test="beneficient-list-header">
				{contractType
					? cs.product.beneficiaries[contractType]
					: cs.product.beneficiaries.default}
			</HeadingL>
			{errors?.beneficiaries && values.beneficiaries.length > 0 && (
				<FormErrorMessage name="fix-highlighted" sx={{ mb: 5 }}>
					<BodyTextM strong>Prosíme o doplňení údajů</BodyTextM>
					<br />
					Řádky, které jsou označeny, prosíme doplňte.
				</FormErrorMessage>
			)}
			<Stack spacing={5}>
				<FieldArray
					name="beneficiaries"
					render={({ push }) => (
						<>
							{values.beneficiaries.map(({ firstName, lastName }, index) => {
								const isError = errors.beneficiaries?.[index];
								return (
									<Stack spacing={4} key={index}>
										<Stack
											sx={{
												justifyContent: "space-between",
												alignItems: "center",
											}}
											direction="row"
										>
											<HeadingM
												withoutScale
												sx={{ mb: 0 }}
												data-test="beneficient-list-header-person"
											>
												{index + 1}. {cs.global.person}
											</HeadingM>
											<Stack direction="row">
												<Trigger
													sx={{ mr: 4 }}
													onClick={() => {
														setFieldValue("editedBeneficiar", index);
														setStep(1);
													}}
												>
													{cs.global.change}
												</Trigger>
												<ConfirmDelete
													handleConfirmDelete={() => {
														setValues(() => {
															const removedBeneficiar: DPSBeneficiary[] =
																values.beneficiaries.filter(
																	(_, i) => i === index
																);
															const removedBeneficiariesCopy = [
																...removedBeneficiaries,
															];
															removedBeneficiariesCopy.push({
																id: -removedBeneficiar[0].id,
															});
															if (values.beneficiaries[index].existsOnBE) {
																setRemovedBeneficiaries(
																	removedBeneficiariesCopy
																);
															}
															const removeBeneficiar = {
																...values,
																ratios: values.ratios.filter(
																	(_, i) => i !== index
																),
																beneficiaries: values.beneficiaries.filter(
																	(_, i) => i !== index
																),
															};
															return removeBeneficiar;
														});
													}}
												/>
											</Stack>
										</Stack>
										{isError ? (
											<PersonError firstName={firstName} lastName={lastName} />
										) : (
											<Person firstName={firstName} lastName={lastName} />
										)}
									</Stack>
								);
							})}
							{values.beneficiaries.length < 10 &&
								typeof errors.beneficiaries !== "object" && (
									<AddBeneficiary contractType={contractType} push={push} />
								)}
						</>
					)}
				/>
				<ErrorMessage
					name="valuesUnchanged"
					render={(msg) => (
						<FormErrorMessage name="valuesUnchanged">{msg}</FormErrorMessage>
					)}
				/>
				{typeof errors.beneficiaries === "object" && (
					<FormErrorMessage /* not a name, I do not want to scroll here */>
						Dokud nedoplníte údaje v současných osobách, nemůžete přidávat
						další.
					</FormErrorMessage>
				)}
				{errorMessage() && (
					<FormErrorMessage name="out-of-range">
						{errorMessage()}
					</FormErrorMessage>
				)}
			</Stack>
		</>
	);
};

export default BeneficiariesList;
