import { useContractId } from "../../context/ContractIDContext";
import { usePensionDetail } from "../../queryHooks";
import { Tab } from "../Tabs";
import ClientShared from "./ClientShared";

function Client(): JSX.Element {
	const id = useContractId();

	const result = usePensionDetail({ id });

	return (
		<Tab>
			<ClientShared
				isSuccess={result.isSuccess}
				isLoading={result.isLoading}
				person={result.data}
			/>
		</Tab>
	);
}
export default Client;
