import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";

import { BodyTextM } from "../Typography";

interface DescriptionProps {
	description: Exclude<string | Array<string> | ReactNode, null | undefined>;
	swipeable?: boolean;
	highlighted?: boolean;
	selector?: string;
}

function Description({
	description,
	swipeable,
	highlighted,
	selector,
}: DescriptionProps): JSX.Element {
	if (Array.isArray(description)) {
		return (
			<>
				{description.map((item) => {
					return (
						<BodyTextM
							key={item}
							sx={{ overflowWrap: "anywhere" }}
							data-test={selector}
						>
							{item}
						</BodyTextM>
					);
				})}
			</>
		);
	} else if (typeof description === "string") {
		return (
			<BodyTextM
				sx={{
					color: highlighted
						? { md: "primary.main" }
						: swipeable && "grey.dark",
					overflowWrap: "anywhere",
				}}
				data-test={selector}
			>
				{description}
			</BodyTextM>
		);
	} else {
		return (
			<BodyTextM
				sx={{ width: "100%", overflowWrap: "anywhere" }}
				data-test={selector}
			>
				{description}
			</BodyTextM>
		);
	}
}

export interface HeaderWithDescriptionProps extends DescriptionProps {
	title: string | ReactNode;
	subTitle?: string | ReactNode;
	textColor?: string;
	additionalElements?: Array<ReactNode> | ReactNode;
	highlighted?: boolean;
	valueSelector?: string;
	secondRow?: string | ReactNode;
}

function HeaderWithDescription({
	title,
	subTitle,
	description,
	swipeable,
	additionalElements = <></>,
	highlighted,
	valueSelector,
	secondRow,
	...rest
}: HeaderWithDescriptionProps): JSX.Element {
	return (
		<Stack>
			<Stack
				{...rest}
				sx={{
					flexDirection: { md: "row" },
				}}
			>
				<Stack
					sx={{
						display: { md: swipeable ? "none" : "block" },
						width: { md: "40%" },
						minWidth: { md: "40%" },
						pr: { md: 4 },
						color: (theme) =>
							swipeable
								? theme.palette.grey.ultraDark
								: theme.palette.grey.dark,
					}}
				>
					<Stack>{title && <BodyTextM strong>{title}</BodyTextM>}</Stack>
					<Stack>{subTitle && <BodyTextM>{subTitle}</BodyTextM>}</Stack>
				</Stack>
				<Stack
					sx={{
						flexDirection: { md: "row" },
						justifyContent: { md: "space-between" },
						width: { md: title ? "60%" : "100%" },
						".MuiButton-root": {
							p: 0,
						},
					}}
				>
					<Description
						description={description}
						swipeable={swipeable}
						highlighted={highlighted}
						selector={valueSelector}
					/>
					<Box>
						{/* <Box> prevents full width stretch of inside elements */}
						{Array.isArray(additionalElements)
							? additionalElements.map((item) => {
									return item;
							  })
							: additionalElements}
					</Box>
				</Stack>
			</Stack>
			{secondRow}
		</Stack>
	);
}

export function SubHeaderWithDescription({
	title,
	description,
	textColor = undefined,
	...rest
}: HeaderWithDescriptionProps): JSX.Element {
	return (
		<Stack {...rest}>
			<BodyTextM strong sx={{ color: textColor }}>
				{title}
			</BodyTextM>
			<BodyTextM>{description}</BodyTextM>
		</Stack>
	);
}

export default HeaderWithDescription;
