import { Stack } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";

import cs from "../../../translations/cs.json";
import { useDrawer } from "../../Drawer/context";
import Verification from "../../Form/Input/Verification";
import { TriggerArrowRight } from "../../Link/Trigger";
import { BodyTextM, HeadingL } from "../../Typography";

interface SMSProps {
	setSmsCode: Dispatch<SetStateAction<string>>;
	strategyEndpointStatus: MutationStatus;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const SMS = ({
	setSmsCode,
	strategyEndpointStatus,
	setFieldValue,
}: SMSProps): JSX.Element => {
	const { navigateByName, steps } = useDrawer();
	const { values, setStatus } = useFormikContext();

	useEffect(() => {
		setSmsCode(values.smsCode);
	}, [values]);

	useEffect(() => {
		setFieldValue("smsCode", "", false);
		if (strategyEndpointStatus === "error") {
			setStatus("error");
		}
		if (strategyEndpointStatus === "success") {
			setStatus("success");
		}
	}, []);

	const sendNewCode = () => {
		navigateByName(steps, "TelephoneNumberVerification");
	};

	return (
		<>
			<HeadingL>{cs.investmentStrategy.sms.main}</HeadingL>
			<Stack spacing={5}>
				<BodyTextM>{cs.investmentStrategy.sms.enterCode}</BodyTextM>
				<Stack spacing={3}>
					<BodyTextM strong>{cs.investmentStrategy.sms.code}</BodyTextM>
					<Verification
						name={"smsCode"}
						placeholder={"X"}
						length={8}
						/* eslint-disable */
						autoFocus
					/>
				</Stack>

				<TriggerArrowRight
					sx={{ alignSelf: "flex-start" }}
					onClick={sendNewCode}
				>
					{cs.investmentStrategy.sms.newCode}
				</TriggerArrowRight>
			</Stack>
		</>
	);
};

export default SMS;
