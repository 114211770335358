export const circleSize = 4;

export const animationConfig = {
	duration: 1000,
	onLoad: { duration: 1000 },
};

export const tickLabels = {
	fontFamily: `"NNNittiGroteskTextRegular", sans-serif`,
	fontSize: "14px",
	lineHeight: "21px",
};

export default {
	circleSize,
	animationConfig,
	tickLabels,
};
