/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PendingChangeType = {
    Other: 'Other',
    ChangeContribution: 'ChangeContribution'
} as const;
export type PendingChangeType = typeof PendingChangeType[keyof typeof PendingChangeType];


export function instanceOfPendingChangeType(value: any): boolean {
    for (const key in PendingChangeType) {
        if (Object.prototype.hasOwnProperty.call(PendingChangeType, key)) {
            if ((PendingChangeType as Record<string, PendingChangeType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PendingChangeTypeFromJSON(json: any): PendingChangeType {
    return PendingChangeTypeFromJSONTyped(json, false);
}

export function PendingChangeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PendingChangeType {
    return json as PendingChangeType;
}

export function PendingChangeTypeToJSON(value?: PendingChangeType | null): any {
    return value as any;
}

