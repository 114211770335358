import SvgIcon from "@mui/material/SvgIcon";

const PigIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path d="M24 13.5a7.8 7.8 0 0 0-4.5-6 .5.5 0 1 0-.4.8 6.6 6.6 0 0 1 3.8 5.3l.1 1c0 1.2-.3 2.5-.9 3.6L19.4 23H18l-1.3-1.7a.5.5 0 0 0-.5-.2H16c-1.6.2-2.7.2-4.4 0-.2 0-.4 0-.5.2l-1 1.7H9l-4.3-3.5C3 18 2 18 1.8 18h-.3a.5.5 0 0 1-.1-.4l-.4-3c0-.4.1-.6.3-.7 1 0 2-.5 3.7-2.7l.6-.6c.2-.2.2-.4.1-.6a16 16 0 0 0-1.4-2.7c3-.5 5 .5 5.5.8a.5.5 0 1 0 .5-1c-.7-.3-3-1.4-6.2-.8a1 1 0 0 0-.7.5 1 1 0 0 0 0 1c.7 1 1 1.8 1.3 2.3-.2 0-.3.3-.5.5-1.5 1.9-2.3 2.2-3 2.4-.8.1-1.3.8-1.2 1.6l.4 3c0 .5.2.9.5 1.1.3.3.7.3 1 .3 0 0 .7 0 2.3 1.3l4.2 3.5.6.2h1.2c.3 0 .6-.2.8-.4l.9-1.5c1.5.2 2.7.2 4.3 0l1 1.5c.2.3.5.4.8.4h1.4c.3 0 .6-.2.8-.5l2.7-4.7a8.1 8.1 0 0 0 1.1-4.3v-1Z" />
			<path d="M14.8 7.7a4 4 0 0 0 4-3.8c0-2.2-1.8-3.9-4-3.9s-4 1.7-4 3.9a4 4 0 0 0 4 3.8Zm0-6.7a3 3 0 0 1 3 2.9c0 1.6-1.4 2.8-3 2.8s-3-1.2-3-2.8a3 3 0 0 1 3-2.9ZM8.1 13.4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
		</SvgIcon>
	);
};

export default PigIcon;
