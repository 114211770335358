import SvgIcon from "@mui/material/SvgIcon";

const Files = (props): JSX.Element => {
	return (
		<SvgIcon width="20" height="16" viewBox="0 0 20 16" fill="none" {...props}>
			<path
				fillRule="evenodd"
				d="M0 1.5C0 .7.7 0 1.5 0h17c.8 0 1.5.7 1.5 1.5v2c0 .8-.7 1.5-1.5 1.5h-17C.7 5 0 4.3 0 3.5v-2ZM1.5 1c-.3 0-.5.2-.5.5v2c0 .3.2.5.5.5h17c.3 0 .5-.2.5-.5v-2c0-.3-.2-.5-.5-.5h-17ZM2 6.5v8c0 .3.2.5.5.5h15c.3 0 .5-.2.5-.5v-8h1v8c0 .8-.7 1.5-1.5 1.5h-15c-.8 0-1.5-.7-1.5-1.5v-8h1Z"
				clipRule="evenodd"
			/>
			<path
				fillRule="evenodd"
				d="M6.5 8.5c0-1.1.9-2 2-2h3a2 2 0 1 1 0 4h-3a2 2 0 0 1-2-2Zm2-1a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3Z"
				clipRule="evenodd"
			/>
			<path d="M2 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm17 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
		</SvgIcon>
	);
};

export default Files;
