/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorizeRequest
 */
export interface AuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeRequest
     */
    cipher: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeRequest
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeRequest
     */
    nonce: string;
}

/**
 * Check if a given object implements the AuthorizeRequest interface.
 */
export function instanceOfAuthorizeRequest(value: object): value is AuthorizeRequest {
    if (!('cipher' in value) || value['cipher'] === undefined) return false;
    if (!('tag' in value) || value['tag'] === undefined) return false;
    if (!('nonce' in value) || value['nonce'] === undefined) return false;
    return true;
}

export function AuthorizeRequestFromJSON(json: any): AuthorizeRequest {
    return AuthorizeRequestFromJSONTyped(json, false);
}

export function AuthorizeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'cipher': json['cipher'],
        'tag': json['tag'],
        'nonce': json['nonce'],
    };
}

export function AuthorizeRequestToJSON(value?: AuthorizeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cipher': value['cipher'],
        'tag': value['tag'],
        'nonce': value['nonce'],
    };
}

