import { Card, CardContent, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { motion } from "framer-motion";
import { useState } from "react";

import { useCookieConsents } from "../../../context/CookieContext";
import Modal from "../Modal";
import { CookieDialog } from "./CookieDialog";
import { CookieForm } from "./CookieForm";
import {
	cookieId,
	mandatoryCookieIds,
	marketingCookieIds,
	statisticalCookieIds,
} from "./CookieIds";

export interface ICookieFormValues {
	statistical: boolean;
	marketing: boolean;
}

const CookieDrawer = (): JSX.Element => {
	const [isExpanded, setExpanded] = useState(false);
	const isDesktop = useIsDesktop();
	const { setCookieConsent } = useCookieConsents();

	const allowAllCookies = () => {
		const allowed = [
			...mandatoryCookieIds,
			...statisticalCookieIds,
			...marketingCookieIds,
		];
		void setCookieConsent(allowed);
	};

	const allowSelected = (values: ICookieFormValues) => {
		const allowed: cookieId[] = [
			mandatoryCookieIds,
			values.statistical ? statisticalCookieIds : [],
			values.marketing ? marketingCookieIds : [],
		].flat();
		void setCookieConsent(allowed);
	};

	const allowNecesary = () => {
		void setCookieConsent(mandatoryCookieIds);
	};

	if (isDesktop)
		return (
			<Card
				sx={{
					position: "fixed",
					right: "50px",
					bottom: "50px",
					maxWidth: "550px",
				}}
			>
				<CardContent sx={{ p: 5 }}>
					<Stack spacing={5}>
						<CookieDialog
							onAllowAll={allowAllCookies}
							onAllowNecesary={allowNecesary}
							initial={!isExpanded}
							onExpand={() => setExpanded(true)}
						/>
						{isExpanded && (
							<motion.div
								initial={{
									height: 0,
								}}
								animate={{
									height: "auto",
									transition: {
										type: "linear",
									},
								}}
								exit={{
									height: 0,
								}}
							>
								<CookieForm onAllowSelected={allowSelected} />
							</motion.div>
						)}
					</Stack>
				</CardContent>
			</Card>
		);

	return (
		<Modal
			open={true}
			sx={{
				display: "flex",
				justifyContent: "flex-end !important",
				alignItems: "end",
			}}
		>
			<Stack
				sx={{
					py: 7,
					px: 4,
				}}
				spacing={5}
			>
				<CookieDialog
					onAllowAll={allowAllCookies}
					onAllowNecesary={allowNecesary}
					initial={!isExpanded}
					onExpand={() => setExpanded(true)}
				/>
				{isExpanded && (
					<motion.div
						initial={{
							height: 0,
						}}
						animate={{
							height: "auto",
							transition: {
								type: "linear",
							},
						}}
						exit={{
							height: 0,
						}}
					>
						<CookieForm onAllowSelected={allowSelected} />
					</motion.div>
				)}
			</Stack>
		</Modal>
	);
};

export default CookieDrawer;
