import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Dispatch, SetStateAction } from "react";

import cs from "../../translations/cs.json";
import SwitchBasic from "../Form/Input/Switch/SwitchBasic";
import { TriggerLink } from "../Link/Trigger";
import { BodyTextM, HeadingL } from "../Typography";

interface FillQuestionareDescisionProps {
	isSubmitting: boolean;
	wantFillQuestionare: boolean;
	setWantFillQuestionare: Dispatch<SetStateAction<boolean>>;
	displayExplanationFunc: () => void;
}

function FillQuestionareDecision({
	isSubmitting,
	wantFillQuestionare,
	setWantFillQuestionare,
	displayExplanationFunc,
}: FillQuestionareDescisionProps): JSX.Element {
	return (
		<Stack
			sx={{
				width: { md: "75%" },
				margin: { md: "auto" },
			}}
		>
			<HeadingL sx={{ textAlign: { md: "center" } }}>
				{cs.investmentStrategy.investmentQuestionare.investmentQuestionare}
			</HeadingL>
			<Stack spacing={4}>
				<BodyTextM component="p" sx={{ textAlign: { md: "center" } }}>
					{cs.investmentStrategy.investmentQuestionare.description}
				</BodyTextM>
				<Stack spacing={{ xs: 4, md: 6 }}>
					<BodyTextM component="p" sx={{ textAlign: { md: "center" } }}>
						{cs.investmentStrategy.investmentQuestionare.recommendations}
						<Box sx={{ display: "inline-block" }}>
							<TriggerLink onClick={displayExplanationFunc} variant="light">
								poučením
							</TriggerLink>
							.
						</Box>
					</BodyTextM>
					<Paper sx={{ p: 4 }}>
						<SwitchBasic
							disabled={isSubmitting}
							checked={!wantFillQuestionare}
							onChange={() => setWantFillQuestionare(!wantFillQuestionare)}
							label={cs.investmentStrategy.investmentQuestionare.dontWantFill}
							id="wantFillQuestionare"
							subLabel={
								<Box>
									{cs.investmentStrategy.investmentQuestionare.acknowledge}
									<Box sx={{ display: "inline-block" }}>
										<TriggerLink
											onClick={displayExplanationFunc}
											variant="light"
										>
											poučení
										</TriggerLink>
										.
									</Box>
								</Box>
							}
						/>
					</Paper>
				</Stack>
			</Stack>
		</Stack>
	);
}
export default FillQuestionareDecision;
