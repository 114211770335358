import Stack from "@mui/material/Stack";
import { useFormikContext } from "formik";

import useIsDesktop from "../../../../desktop/useIsDesktop";
import InformationList from "../../../InformationList";
import { HeadingL } from "../../../Typography";

function ChangesOverview(): JSX.Element {
	const { values } = useFormikContext();

	const content = (
		<>
			<HeadingL>Přehled změn</HeadingL>
			<InformationList
				information={[
					{
						title: "Nová výše příspěvku",
						description: `${values.monthlyContribution} Kč`,
					},
					{
						title: "Přispívá mi i zaměstnavatel",
						description: values.doesEmployerContribute ? "Ano" : "Ne",
						valueSelector: "pension-employer-contribution-change-overview",
					},
				]}
			/>
		</>
	);

	if (useIsDesktop()) return content;

	return <Stack spacing={5}>{content}</Stack>;
}

export default ChangesOverview;
