/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthenticationSourceType } from './AuthenticationSourceType';
import {
    AuthenticationSourceTypeFromJSON,
    AuthenticationSourceTypeFromJSONTyped,
    AuthenticationSourceTypeToJSON,
} from './AuthenticationSourceType';

/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    givenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    phoneNumber2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    birthdate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    birthNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    registeredContractNumber?: string | null;
    /**
     * 
     * @type {AuthenticationSourceType}
     * @memberof RegisterRequest
     */
    authenticationSource: AuthenticationSourceType;
}

/**
 * Check if a given object implements the RegisterRequest interface.
 */
export function instanceOfRegisterRequest(value: object): value is RegisterRequest {
    if (!('authenticationSource' in value) || value['authenticationSource'] === undefined) return false;
    return true;
}

export function RegisterRequestFromJSON(json: any): RegisterRequest {
    return RegisterRequestFromJSONTyped(json, false);
}

export function RegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'givenName': json['givenName'] == null ? undefined : json['givenName'],
        'surname': json['surname'] == null ? undefined : json['surname'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'phoneNumber2': json['phoneNumber2'] == null ? undefined : json['phoneNumber2'],
        'birthdate': json['birthdate'] == null ? undefined : json['birthdate'],
        'birthNumber': json['birth_number'] == null ? undefined : json['birth_number'],
        'registeredContractNumber': json['registered_contract_number'] == null ? undefined : json['registered_contract_number'],
        'authenticationSource': AuthenticationSourceTypeFromJSON(json['authenticationSource']),
    };
}

export function RegisterRequestToJSON(value?: RegisterRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'givenName': value['givenName'],
        'surname': value['surname'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'phoneNumber2': value['phoneNumber2'],
        'birthdate': value['birthdate'],
        'birth_number': value['birthNumber'],
        'registered_contract_number': value['registeredContractNumber'],
        'authenticationSource': AuthenticationSourceTypeToJSON(value['authenticationSource']),
    };
}

