import { Box } from "@mui/material";
import { ButtonProps as MUIButtonProps } from "@mui/material/Button";
import { useLocation } from "@reach/router";
import { GatsbyLinkProps } from "gatsby";
import { Button } from "gatsby-theme-material-ui";

import CaretRight from "../Icons/CaretRight";
import { LinkProps } from ".";

type GatsbyThemeMUIButtonProps = GatsbyLinkProps<unknown> &
	MUIButtonProps &
	LinkProps;
export interface InternalButtonLinkProps extends GatsbyThemeMUIButtonProps {
	variant?:
		| "contained"
		| "outlined"
		| "text"
		| "appBarLink"
		| "contactLink"
		| "clear";
}

const InternalButtonLink = ({
	children,
	href,
	variant = "contained",
	...rest
}: InternalButtonLinkProps): JSX.Element => {
	return (
		<Button
			to={href}
			variant={variant}
			underline="none"
			disableElevation={true}
			{...rest}
		>
			{children}
		</Button>
	);
};
export default InternalButtonLink;

export const InternalAttentionLink = ({
	children,
	startIcon,
	sx,
	to,
	...rest
}: InternalButtonLinkProps): JSX.Element => {
	const { pathname } = useLocation();

	return (
		<InternalButtonLink
			variant={pathname === to ? "text" : "clear"}
			size="small"
			{...rest}
			to={to}
			sx={{
				...sx,
				p: 3,
				textAlign: "left",
				".MuiButton-startIcon": {
					width: 40,
					m: 0,
				},
			}}
			startIcon={startIcon}
			endIcon={<CaretRight sx={{ width: 7 }} />}
		>
			<Box component="span" sx={{ flexGrow: 1 }}>
				{children}
			</Box>
		</InternalButtonLink>
	);
};
