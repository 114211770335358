import { graphql, useStaticQuery } from "gatsby";

import { SEO } from "../../../components/SEO";
import { BodyTextM } from "../../../components/Typography";
import withProtection from "../../../highOrderComponents/withProtection";

const useLocalTtranslations = () => {
	return useStaticQuery<Queries.PersonalInformationTranslationQuery>(
		graphql`
			query PersonalInformationTranslation {
				translations: file(name: { eq: "cs" }) {
					childTranslationsJson {
						profile {
							personalInformation {
								header
								content
							}
						}
					}
				}
			}
		`
	);
};

export const Title = (): JSX.Element =>
	useLocalTtranslations().translations.childTranslationsJson.profile
		.personalInformation.header;

const Info = ({ pageContext }): JSX.Element => {
	const content = useLocalTtranslations();
	const translations =
		content.translations.childTranslationsJson.profile.personalInformation;

	return (
		<>
			<BodyTextM dangerouslySetInnerHTML={{ __html: translations.content }} />
		</>
	);
};

export default withProtection(Info);

export const Head = (): JSX.Element => (
	<SEO title="Informace">
		<meta name="robots" content="noindex" />
	</SEO>
);
