import { Skeleton } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";

import Address from "../../Icons/Address";
import Graph from "../../Icons/Graph";
import Insurance from "../../Icons/Insurance";
import LightningUmbrella from "../../Icons/LightningUmbrella";
import Pig from "../../Icons/Pig";
import Suitcase from "../../Icons/Suitcase";
import TaxCertificateIcon from "../../Icons/TaxCertificate";
import { ISmartAction, SmartAction } from ".";

export const Changes = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="Changes"
			icon={<TaxCertificateIcon sx={{ width: "2.4rem", height: "2.4rem" }} />}
		>
			Seznam změn
		</SmartAction>
	);
};

export const InvestmentStrategy = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="InvestmentStrategy"
			icon={<Graph sx={{ width: "2.4rem", height: "2.4rem" }} />}
		>
			Změnit investiční strategii
		</SmartAction>
	);
};

export const NextPayment = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="NextPayment"
			icon={<Pig sx={{ width: "2.4rem", height: "2.4rem" }} />}
		>
			Moje příští platba
		</SmartAction>
	);
};

export const Payments = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="Payments"
			icon={<Pig sx={{ width: "2.4rem", height: "2.4rem" }} />}
		>
			Přehled Plateb
		</SmartAction>
	);
};

export const Report = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="Report"
			icon={<LightningUmbrella sx={{ width: "2.4rem", height: "2.4rem" }} />}
		>
			Nahlásit pojistnou událost
		</SmartAction>
	);
};

export const Fond = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="Fond"
			icon={<Pig sx={{ width: "2.4rem", height: "2.4rem" }} />}
		>
			Změnit výši&nbsp;příspěvku
		</SmartAction>
	);
};

export const TaxCertificate = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="TaxCertificate"
			icon={<TaxCertificateIcon sx={{ width: "2.4rem", height: "2.4rem" }} />}
		>
			Daňové potvrzení
		</SmartAction>
	);
};

export const EmployerContribution = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="EmployerContribution"
			icon={<Suitcase sx={{ width: "2rem", height: "2rem" }} />}
		>
			Příspěvek zaměstnavatele
		</SmartAction>
	);
};

export const Coverage = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="Coverage"
			icon={<Insurance sx={{ width: "2.4rem", height: "2.4rem" }} />}
		>
			Pojistná krytí
		</SmartAction>
	);
};

export const ChangeAdress = (props: ISmartAction) => {
	return (
		<SmartAction
			{...props}
			action="ChangeAdress"
			icon={<Address sx={{ width: "1.7rem", height: "2.2 rem" }} />}
		>
			Změnit adresu
		</SmartAction>
	);
};

export const SmartActionSkeleton = () => {
	const isDesktop = useIsDesktop();
	return (
		<Skeleton
			variant="rounded"
			height={isDesktop ? 92 : 74}
			sx={{ width: "33.33%", bgcolor: "primary.main" }}
		/>
	);
};

export const SmartActionComponents = {
	Changes,
	InvestmentStrategy,
	NextPayment,
	Payments,
	Report,
	Fond,
	TaxCertificate,
	EmployerContribution,
	Coverage,
	ChangeAdress,
	SmartActionSkeleton,
} as const;
