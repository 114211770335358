import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

const CircularStepLoader = (): JSX.Element => {
	return (
		<Stack
			sx={{
				position: "absolute", // https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height
				justifyContent: "space-around",
				alignItems: "center",
				width: "100%",
				height: "100%",
			}}
			direction="row"
		>
			<CircularProgress size={100} />
		</Stack>
	);
};

export default CircularStepLoader;
