import { Box, Stack } from "@mui/material";
import { Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import { Formik } from "formik";

import translation from "../../../translations/cs.json";
import FilterChartsDrawer from "../../Drawer/FilterChartsDrawer";
import Modal from "../../Drawer/Modal";
import { BodyTextM, HeadingL } from "../../Typography";
import Chart from "../Line";
import {
	fundsFilterType,
	fundsFormattedType,
	initialValues,
	validationSchema,
} from "./chartCardFunctions";

export interface ChartCardLayoutProps {
	funds: fundsFormattedType;
	isOpen: boolean;
	setIsOpen: (b: boolean) => void;
	data: fundsFormattedType;
	setFormValues: (f: fundsFilterType) => void;
}

const ChartCardLayout = ({
	funds,
	isOpen,
	setIsOpen,
	data,
	setFormValues,
}: ChartCardLayoutProps): JSX.Element => {
	if (funds.length > 0) {
		return (
			<Formik
				initialValues={initialValues(funds)}
				onSubmit={(values, { setSubmitting }) => {
					setFormValues(values);
					setIsOpen(false);
					setSubmitting(false);
				}}
				validationSchema={validationSchema}
			>
				<Box>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
					>
						<HeadingL>{translation.life.investing.fondsGraphHeading}</HeadingL>
						<Button onClick={() => setIsOpen(true)}>Filtrovat</Button>
						<Modal
							onClose={() => setIsOpen(false)}
							onOpen={() => setIsOpen(true)}
							open={isOpen}
						>
							<FilterChartsDrawer
								handleHideDrawer={() => setIsOpen(false)}
								fundNames={funds ? funds.map(({ name }) => name) : undefined}
							/>
						</Modal>
					</Stack>
					<BodyTextM>
						{translation.life.investing.fondsGraphDescription}
					</BodyTextM>
					<Chart data={data} />
				</Box>
			</Formik>
		);
	}
	return (
		<>
			<Skeleton height={379} />
			Nahrávání grafu fondů trvá zhruba minutu a půl, pracujeme na zlepšení.
		</>
	);
};
export default ChartCardLayout;
