/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentFrequency } from './PaymentFrequency';
import {
    PaymentFrequencyFromJSON,
    PaymentFrequencyFromJSONTyped,
    PaymentFrequencyToJSON,
} from './PaymentFrequency';

/**
 * 
 * @export
 * @interface LifePaymentInfo
 */
export interface LifePaymentInfo {
    /**
     * 
     * @type {string}
     * @memberof LifePaymentInfo
     */
    accountNumber: string;
    /**
     * 
     * @type {number}
     * @memberof LifePaymentInfo
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof LifePaymentInfo
     */
    constantSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof LifePaymentInfo
     */
    variableSymbol: string;
    /**
     * 
     * @type {PaymentFrequency}
     * @memberof LifePaymentInfo
     */
    frequency?: PaymentFrequency;
    /**
     * 
     * @type {string}
     * @memberof LifePaymentInfo
     */
    iban: string;
    /**
     * Null for extraordinary payments
     * @type {Date}
     * @memberof LifePaymentInfo
     */
    nextPaymentDate?: Date | null;
}

/**
 * Check if a given object implements the LifePaymentInfo interface.
 */
export function instanceOfLifePaymentInfo(value: object): value is LifePaymentInfo {
    if (!('accountNumber' in value) || value['accountNumber'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('constantSymbol' in value) || value['constantSymbol'] === undefined) return false;
    if (!('variableSymbol' in value) || value['variableSymbol'] === undefined) return false;
    if (!('iban' in value) || value['iban'] === undefined) return false;
    return true;
}

export function LifePaymentInfoFromJSON(json: any): LifePaymentInfo {
    return LifePaymentInfoFromJSONTyped(json, false);
}

export function LifePaymentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LifePaymentInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'accountNumber': json['accountNumber'],
        'amount': json['amount'],
        'constantSymbol': json['constantSymbol'],
        'variableSymbol': json['variableSymbol'],
        'frequency': json['frequency'] == null ? undefined : PaymentFrequencyFromJSON(json['frequency']),
        'iban': json['iban'],
        'nextPaymentDate': json['nextPaymentDate'] == null ? undefined : (new Date(json['nextPaymentDate'])),
    };
}

export function LifePaymentInfoToJSON(value?: LifePaymentInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountNumber': value['accountNumber'],
        'amount': value['amount'],
        'constantSymbol': value['constantSymbol'],
        'variableSymbol': value['variableSymbol'],
        'frequency': PaymentFrequencyToJSON(value['frequency']),
        'iban': value['iban'],
        'nextPaymentDate': value['nextPaymentDate'] == null ? undefined : ((value['nextPaymentDate'] as any).toISOString()),
    };
}

