import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export const CellSkeleton = () => (
	<Skeleton sx={{ display: "inline-block" }} width={50} />
);

const TableWithSkeleton = (): JSX.Element => {
	return (
		<TableContainer component={Box}>
			<MUITable aria-label="table">
				<TableHead>
					<TableRow>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
						<TableCell>
							<CellSkeleton />
						</TableCell>
					</TableRow>
				</TableBody>
			</MUITable>
		</TableContainer>
	);
};

export default TableWithSkeleton;
