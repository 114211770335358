export const unifyAllocation = (allocation) =>
	allocation
		.sort((a, b) => {
			// sort for proper changes detection
			return a.fundCode > b.fundCode ? 1 : b.fundCode > a.fundCode ? -1 : 0;
		})
		.map((item) => ({
			// we do not care names and oher props in form validations
			fundCode: item.fundCode,
			allocation: item.allocation,
		}));

export const checkInvestmentStrategyValuesChanges = (
	allocation,
	oldResourcesAllocation
): boolean => {
	const unifiedNewAllocation = unifyAllocation(allocation);
	const unificatedOldAllocation = unifyAllocation(oldResourcesAllocation);
	return (
		JSON.stringify(unifiedNewAllocation) !==
		JSON.stringify(unificatedOldAllocation)
	);
};
