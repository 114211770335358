import { Alert } from "@mui/material";

import cs from "../../translations/cs.json";
import { BodyTextM } from "../Typography";

export const YearlyStatementsBanner = (): JSX.Element => {
	return (
		<Alert
			severity="info"
			sx={{
				pr: 4,
				pl: 4,
			}}
		>
			<BodyTextM
				dangerouslySetInnerHTML={{
					__html: cs.adCampaigns.yearlyStatements.text,
				}}
			/>
		</Alert>
	);
};
