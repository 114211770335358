import { useMsal } from "@azure/msal-react";
import { Box, IconButton, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { useEffect, useRef, useState } from "react";

import cs from "../../translations/cs.json";
import Modal from "../Drawer/Modal";
import Close from "../Icons/Close";
import { TriggerButton } from "../Link";
import { BodyTextM, HeadingM } from "../Typography";
import { setActivity } from "./utils";
import {
	channelMessages,
	checkActivity,
	inactivityStatus,
	lastActivityKey,
} from "./utils";

// eslint-disable-next-line no-undef
type TInterval = ReturnType<typeof setInterval>;

interface ILogoutWarning {
	pageName?: string;
}

export const LogoutWarning = ({ pageName }: ILogoutWarning): JSX.Element => {
	const channel = new BroadcastChannel("automatic_logout");
	const [showWarning, setWarning] = useState(false);
	const { instance } = useMsal();
	const intervalRef = useRef<TInterval>();
	const isDesktop = useIsDesktop();

	const handleLogout = (nobroadcast?: boolean) => {
		void instance.logoutRedirect({
			postLogoutRedirectUri: "/bezpecne-odhlaseni",
		});
		clearInterval(intervalRef.current);
		localStorage.removeItem(lastActivityKey);
		if (!nobroadcast) {
			channel.postMessage(channelMessages.logout);
		}
	};

	const handleClose = (nobroadcast?: boolean) => {
		setActivity();
		setWarning(false);
		if (!nobroadcast) {
			channel.postMessage(channelMessages.closeWarning);
		}
	};

	const handleOpen = (nobroadcast?: boolean) => {
		setWarning(true);
		if (!nobroadcast) {
			channel.postMessage(channelMessages.openWarning);
		}
	};

	const handleEvent = () => {
		if (!showWarning) {
			setActivity();
		}
	};

	const handleInterval = () => {
		switch (checkActivity()) {
			case inactivityStatus.active:
				if (showWarning) {
					setWarning(false);
				}
				break;
			case inactivityStatus.inactive:
				handleLogout();
				break;
			case inactivityStatus.warning:
				handleOpen();
				break;
			default:
				handleLogout();
		}
	};

	const handleMessage = (event: MessageEvent<channelMessages>) => {
		switch (event.data) {
			case channelMessages.logout:
				handleLogout(true);
				break;
			case channelMessages.closeWarning:
				handleClose(true);
				break;
			case channelMessages.openWarning:
				handleOpen(true);
				break;
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleEvent);
		document.addEventListener("mousedown", handleEvent);
		document.addEventListener("scroll", handleEvent);
		channel.addEventListener("message", handleMessage);
		return function cleanup() {
			document.removeEventListener("keydown", handleEvent);
			document.removeEventListener("mousedown", handleEvent);
			document.removeEventListener("scroll", handleEvent);
			channel.removeEventListener("message", handleMessage);
		};
	});

	useEffect(() => {
		if (pageName === "prihlaseni-pro-partnery") {
			setActivity();
		}
		handleInterval();
		intervalRef.current = setInterval(handleInterval, 10000);
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	return (
		<Modal
			PaperProps={{ sx: { borderRadius: { md: 1 } } }}
			open={showWarning}
			onClose={() => handleClose()}
			disableDiscovery
			disableSwipeToOpen
		>
			<Box
				sx={{
					maxWidth: "500px",
					p: { md: "40px" },
					py: { xxs: 7 },
					px: { xxs: 4 },
				}}
			>
				<Stack
					direction="row"
					spacing={4}
					sx={{
						justifyContent: "space-between",
						alignItems: "center",
						mb: { md: 4, xxs: 5 },
					}}
				>
					<HeadingM sx={{ mb: 0 }}>{cs.logoutModal.title}</HeadingM>
					{isDesktop && (
						<IconButton onClick={() => handleClose()}>
							<Close sx={{ color: "primary.main" }} fontSize="large" />
						</IconButton>
					)}
				</Stack>
				<BodyTextM>{cs.logoutModal.description}</BodyTextM>
				<Stack spacing={4} direction="row" sx={{ mt: 5 }}>
					<TriggerButton
						variant="contained"
						size="small"
						sx={{ width: "50%", whiteSpace: "nowrap" }}
						onClick={() => handleClose()}
					>
						{cs.logoutModal.continue}
					</TriggerButton>
					<TriggerButton
						sx={{ width: "50%" }}
						variant="outlined"
						size="small"
						onClick={() => handleLogout()}
					>
						{cs.profile.logout.button}
					</TriggerButton>
				</Stack>
			</Box>
		</Modal>
	);
};
