import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import useIsDesktop from "desktop/useIsDesktop";

import { downloadImage } from "../../utils";
import Download from "../Icons/Download";
import { Trigger } from "../Link";
import { BodyTextM } from "../Typography";
import HowToUseQRCode from "./HowToUseQRCode";

const QrCodePayment = ({
	accountNumber = "",
	amount,
	constantSymbol,
	variableSymbol,
	iban,
}: {
	accountNumber: string;
	amount: string;
	constantSymbol: string;
	variableSymbol: string;
	iban: string;
}): JSX.Element => {
	const [changedAccountNumber, bankCode] = accountNumber.split("/");
	const imageSrc = `https://api.paylibo.com/paylibo/generator/czech/image?compress=true&size=160&accountNumber=${changedAccountNumber}&bankCode=${bankCode}&amount=${amount}&currency=CZK&vs=${variableSymbol}&ks=${constantSymbol}&iban=${iban}`;
	const isDesktop = useIsDesktop();

	return (
		<Stack spacing={3} sx={{ alignItems: "start" }}>
			<BodyTextM strong={true}>
				Nechce se Vám přepisovat údaje k nastavení příkazu?
			</BodyTextM>
			<BodyTextM>
				Jednoduše si nastavte příkaz pomocí QRka ve Vaší bankovní aplikaci.
				Pokud chcete QRko použít na nastavení trvalého příkazu, upravte si
				frekvenci placení na měsíční.
			</BodyTextM>
			<Stack spacing={3}>
				<HowToUseQRCode />
			</Stack>
			<Box>
				<img alt="QR platba" src={imageSrc} />
			</Box>

			{!isDesktop && (
				<Box>
					<Trigger onClick={() => downloadImage(imageSrc)}>
						<Download sx={{ mr: 3 }} />
						Uložit QR kód do galerie
					</Trigger>
				</Box>
			)}
		</Stack>
	);
};

export default QrCodePayment;
