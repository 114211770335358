import { Stack } from "@mui/material";

import cs from "../../translations/cs.json";
import { useDrawer } from "../Drawer/context";
import ModalContent from "../Drawer/ModalContent";
import ModalHeader from "../Drawer/ModalHeader";
import CloseIcon from "../Icons/Close";
import { Trigger } from "../Link";
import { HeadingS } from "../Typography";
import PensionCalculator from ".";

const PensionCalculatorModal = ({
	monthlyContribution,
	changeContributionUrl,
}: {
	monthlyContribution: number;
	changeContributionUrl: string;
}) => {
	const { hideDrawer } = useDrawer();
	return (
		<Stack
			sx={{
				width: {
					md: "960px",
				},
				maxWidth: "100%",
				maxHeight: { md: "700px" },
				overflow: "auto",
				transition: "200ms",
			}}
		>
			<ModalHeader>
				<Stack></Stack>
				<HeadingS mb={5} sx={{ mb: 0 }}>
					{cs.pensionCalculator.interactiveCalculator}
				</HeadingS>
				<Trigger onClick={hideDrawer}>
					<CloseIcon sx={{ width: "2rem", height: "2rem" }} />
				</Trigger>
			</ModalHeader>
			<ModalContent forceFullView>
				<Stack pt={6}>
					<PensionCalculator
						changeContributionUrl={changeContributionUrl}
						monthlyContribution={monthlyContribution}
						portalVersion
					/>
				</Stack>
			</ModalContent>
		</Stack>
	);
};

export default PensionCalculatorModal;
