import SvgIcon from "@mui/material/SvgIcon";

const MyAccountIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
			<path d="M10 9.665c1.703 0 3.085-2.412 3.085-3.987 0-1.59-1.413-2.935-3.085-2.935S6.915 4.087 6.915 5.678c0 1.575 1.382 3.987 3.085 3.987Zm0-6.088c1.22 0 2.252.962 2.252 2.101 0 1.158-1.113 3.154-2.252 3.154-1.139 0-2.252-1.996-2.252-3.154 0-1.139 1.031-2.101 2.252-2.101Z" />
			<path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0Zm0 19.167C4.946 19.167.833 15.054.833 10 .833 4.946 4.945.833 10 .833S19.167 4.945 19.167 10 15.054 19.167 10 19.167Z" />
			<path d="M13.431 11.01c-.596-.413-1.411-.418-2.08-.009-.268.134-.545.273-1.163.273-.707 0-.94-.144-1.178-.293a1.806 1.806 0 0 0-1.418-.3c-2.506.475-3.044 2.202-3.082 4.649-.004.23.18.42.41.423h.006a.417.417 0 0 0 .417-.41c.04-2.54.646-3.51 2.408-3.845.274-.053.563.003.788.17.33.206.703.44 1.649.44.816 0 1.233-.21 1.609-.4.377-.233.834-.24 1.159-.014 1.035.722 1.588 1.483 1.74 3.634.016.23.232.4.445.386a.417.417 0 0 0 .386-.445c-.153-2.172-.7-3.286-2.096-4.26Z" />
		</SvgIcon>
	);
};

export default MyAccountIcon;
