import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import { ProfileCode, StrategyCode } from "../../../../models";
import cs from "../../../translations/cs.json";
import { sortedFunds } from "../../../utils";
import FundsDescription from "../../Funds/Description";
import FundsDistribution from "../../Funds/Distribution";
import Inflation from "../../Icons/Inflation";
import { BodyTextM, HeadingL, HeadingM } from "../../Typography";
const InvestmentDefaultProfile = ({
	profile,
	strategy,
	allocation,
}): JSX.Element => {
	const sortedAllocation = sortedFunds(allocation);

	return (
		<>
			<HeadingL>
				{profile === undefined ? (
					<Skeleton sx={{ display: "inline-block" }} width={200} />
				) : (
					<>
						{profile.code === ProfileCode.Rejected
							? profile.name
							: `Vyšel Vám ${profile.name}`}
					</>
				)}
			</HeadingL>
			<HeadingL component="h2" color="primary">
				{profile === undefined ? (
					<Skeleton sx={{ display: "inline-block" }} width={100} />
				) : (
					profile.code !== ProfileCode.Rejected && (
						<>
							<Inflation
								sx={{
									verticalAlign: "text-top",
									marginRight: 4,
									fontSize: "0.875em",
								}}
							/>
							{
								cs.investmentStrategy.investmentQuestionare.fundsTypes[
									profile.code
								]
							}
						</>
					)
				)}
			</HeadingL>
			<Stack spacing={5}>
				{profile === undefined ? (
					<Skeleton sx={{ display: "inline-block" }} width={100} />
				) : (
					profile.code && (
						<>
							<BodyTextM component="p">
								{cs.investmentStrategy.investStrProf[profile.code]}
							</BodyTextM>
							<BodyTextM component="p">
								{strategy.code === StrategyCode.Prepension &&
									cs.investmentStrategy.investStrProf["PREPENSION"]}
							</BodyTextM>
						</>
					)
				)}
				<Stack gap={{ xs: 6, md: 4 }} flexDirection={{ md: "row-reverse" }}>
					<Stack sx={{ width: { md: "50%" } }}>
						<HeadingM>{cs.investmentStrategy.recomendedAllcoation}</HeadingM>
						<FundsDistribution allocation={sortedAllocation} />
					</Stack>
					<Stack sx={{ width: { md: "50%" } }}>
						<HeadingM>{cs.investmentStrategy.fundOverivew}</HeadingM>
						<FundsDescription allocation={sortedAllocation} />
					</Stack>
				</Stack>
			</Stack>
		</>
	);
};

export default InvestmentDefaultProfile;
