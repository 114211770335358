import { Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";

import cs from "../../../translations/cs.json";
import { CookieCollapsible } from "../../Collapsible";
import Switch from "../../Form/Input/Switch";
import ArrowIconLeft from "../../Icons/ArrowLeft";
import { TriggerButton } from "../../Link";
import { BodyTextM, BodyTextS } from "../../Typography";
import { ICookieFormValues } from ".";
import {
	cookieCategory,
	marketingCookieIds,
	statisticalCookieIds,
} from "./CookieIds";

interface ICookieForm {
	onAllowSelected: (values: ICookieFormValues) => void;
}

const categoryToCookieIds = {
	statistical: statisticalCookieIds,
	marketing: marketingCookieIds,
};

export const CookieForm = ({ onAllowSelected }: ICookieForm) => {
	const [expandedCategory, setCategory] = useState<cookieCategory | null>(null);
	return (
		<Formik
			initialValues={{ statistical: false, marketing: false }}
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);
				onAllowSelected(values);
			}}
		>
			{() => {
				return (
					<Form>
						<Stack
							spacing={4}
							sx={{
								pt: 4,
								borderTop: "1px solid",
								borderColor: "grey.light",
							}}
						>
							<Stack spacing={3}>
								{expandedCategory ? (
									<Stack
										direction="row"
										alignItems="center"
										spacing={3}
										onClick={() => setCategory(null)}
										sx={{ cursor: "pointer" }}
									>
										<ArrowIconLeft sx={{ color: "primary.main" }} />
										<BodyTextM strong>
											{cs.cookies.categories[expandedCategory].label}{" "}
											{cs.cookies.dialog.cookies}
										</BodyTextM>
									</Stack>
								) : (
									<BodyTextM strong>
										{cs.cookies.dialog.consentPreSettings}
									</BodyTextM>
								)}
								{expandedCategory ? (
									<>
										{categoryToCookieIds[expandedCategory].map((cookieId) => (
											<CookieCollapsible
												key={cookieId}
												headline={cs.cookies.cookieDetails[cookieId].label}
											>
												<BodyTextS margin={0}>
													{cs.cookies.cookieDetails[cookieId].description}
												</BodyTextS>
											</CookieCollapsible>
										))}
									</>
								) : (
									<>
										<CookieCollapsible
											headline={cs.cookies.categories.mandatory.label}
											sx={{ width: "100%" }}
											selector={"Vždy aktivní"}
										>
											<BodyTextM>
												{cs.cookies.categories.mandatory.description}
											</BodyTextM>
										</CookieCollapsible>
										<CookieCollapsible
											headline={cs.cookies.categories.statistical.label}
											sx={{ width: "100%" }}
											selector={
												<Switch
													name={"statistical"}
													sx={{
														width: "min-content",
														mr: 0,
														pr: 0,
														"&& .MuiSwitch-root": { mr: 0 },
													}}
												/>
											}
										>
											<BodyTextM>
												{cs.cookies.categories.statistical.description}
											</BodyTextM>
											<TriggerButton
												variant="text-light"
												onClick={() => setCategory("statistical")}
												size="small"
											>
												{cs.cookies.dialog.cookieDetails}
											</TriggerButton>
										</CookieCollapsible>
										<CookieCollapsible
											headline={cs.cookies.categories.marketing.label}
											sx={{ width: "100%" }}
											selector={
												<Switch
													name={"marketing"}
													sx={{
														width: "min-content",
														mr: 0,
														pr: 0,
														"&& .MuiSwitch-root": { mr: 0 },
													}}
												/>
											}
										>
											<BodyTextM>
												{cs.cookies.categories.marketing.description}
											</BodyTextM>
											<TriggerButton
												variant="text-light"
												onClick={() => setCategory("marketing")}
												size="small"
											>
												{cs.cookies.dialog.cookieDetails}
											</TriggerButton>
										</CookieCollapsible>
									</>
								)}
							</Stack>
							<TriggerButton
								type="submit"
								variant="contained"
								sx={{
									width: { md: "min-content", xxs: "50%" },
									maxWidth: "180px",
									py: { md: 3, xxs: "12px" },
									px: 4,
									whiteSpace: "nowrap",
								}}
								disableElevation={true}
								size="small"
							>
								{cs.cookies.dialog.allowSelected}
							</TriggerButton>
						</Stack>
					</Form>
				);
			}}
		</Formik>
	);
};
