import Box from "@mui/material/Box";

import image from "../../../images/change-request-confirmation.png";
import cs from "../../../translations/cs.json";
import Attention from "../../AttentionBlock";
import { BodyTextM, HeadingL } from "../../Typography";
export interface IChangeRequestConfirmation {
	heading?: string;
	description?: string;
	attention?: string;
	documentsAttentionOverride?: string | null;
}

function ChangeRequestConfirmation({
	heading = cs.global.successfulChangeRequest,
	description = cs.global.successfulChangeIsBeingProcessed,
	documentsAttentionOverride = undefined,
	attention,
}: IChangeRequestConfirmation): JSX.Element {
	return (
		<>
			<Box mb={5} sx={{ textAlign: "center" }}>
				<img src={image} alt="Potvrzení o odeslání změny" width={211} />
			</Box>
			<HeadingL>{heading}</HeadingL>
			{documentsAttentionOverride !== null && (
				<Attention severity="info" sx={{ mb: 4 }}>
					{documentsAttentionOverride ??
						cs.global.successfulChangeConfirmationInfo}
				</Attention>
			)}
			<BodyTextM component="p" marginY={6}>
				{description}
			</BodyTextM>
			{attention && (
				<Attention severity="warning" sx={{ mt: 5 }}>
					{attention}
				</Attention>
			)}
		</>
	);
}

export default ChangeRequestConfirmation;
