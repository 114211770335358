import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
	ChangeRequestType,
	DocumentDownloadResponse,
	FileDownloadInfo,
	GetChangesResponse,
	GetContactAddressesResponse,
	GetProfileResponse,
	PostLoginResponse,
} from "../../models";
import { usePrimaryAPIClient } from "../context/PrimaryAPIClient";
import { useQueryKeyFactory } from "../context/QueryKeyProvider";

interface IDocumentsProps {
	options?: UseQueryOptions<DocumentDownloadResponse[]>;
}

export const useDocuments = ({ options = {} }: IDocumentsProps = {}) => {
	const { documentsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<DocumentDownloadResponse[]>(
		createQueryKey({ key: ["getDocuments"] }),
		() => documentsApi.getUserDocumentsGet(),
		options
	);
};

interface IFileDownloadInfoProps {
	options?: UseQueryOptions<FileDownloadInfo>;
}

export const usePrecontractualDocs = ({
	options = {},
}: IFileDownloadInfoProps = {}) => {
	const { documentsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<FileDownloadInfo>(
		createQueryKey({
			key: ["getPrecontractualInfo"],
			includeUserIdentifier: false,
		}),
		() => documentsApi.getCurrentDPSPrecontractualInfoGet(),
		options
	);
};

interface IChangeRequestsProps<TResult = GetChangesResponse[]> {
	type?: ChangeRequestType;
	options?: UseQueryOptions<GetChangesResponse[], unknown, TResult>;
}

export const useChangeRequests = <
	TResult extends unknown = GetChangesResponse[]
>({
	type = ChangeRequestType.All,
	options = {},
}: IChangeRequestsProps<TResult> = {}) => {
	const { changeRequestsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<GetChangesResponse[], unknown, TResult>(
		createQueryKey({ key: ["getChangeRequests", type] }),
		() => changeRequestsApi.getChangeRequestsGet({ type }),
		options
	);
};

interface IGetProfileProps {
	options?: UseQueryOptions<GetProfileResponse>;
}

export const useProfile = ({ options = {} }: IGetProfileProps = {}) => {
	const { profileApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<GetProfileResponse>(
		createQueryKey({ key: ["getUserProfile"] }),
		() => profileApi.getProfileGet(),
		options
	);
};

interface IUsePhoneNumbers {
	options?: UseQueryOptions<string[], unknown, string[]>;
}

export const usePhoneNumbers = ({ options }: IUsePhoneNumbers = {}) => {
	const { authenticateApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<Array<string>>(
		createQueryKey({ key: ["getPhoneNumbers"] }),
		() => authenticateApi.getAuthenticationPhoneNumbersGet(),
		options
	);
};

interface IContactAdressesProps {
	options?: UseQueryOptions<GetContactAddressesResponse>;
}

export const useContactAdresses = ({
	options = {},
}: IContactAdressesProps = {}) => {
	const { profileApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<GetContactAddressesResponse>(
		createQueryKey({ key: ["getContactAdresses"] }),
		() => profileApi.getContactAddressesGet(),
		options
	);
};

interface IUsePostLogin {
	options?: UseQueryOptions<PostLoginResponse>;
}

export const usePostLogin = ({ options }: IUsePostLogin = {}) => {
	const { postLoginApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<PostLoginResponse>(
		createQueryKey({ key: ["getPostLogin"] }),
		() => postLoginApi.getPostLoginInfoGet(),
		options
	);
};

export const useConfirmContacts = () => {
	const { postLoginApi } = usePrimaryAPIClient();

	return useMutation({
		mutationFn: () => {
			return postLoginApi.confirmContactsPut();
		},
	});
};
