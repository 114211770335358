import Stack from "@mui/material/Stack";
import Box from "@mui/material/Stack";
import { StaticImage } from "gatsby-plugin-image";

import cs from "../../../translations/cs.json";
import { BodyTextM, HeadingL } from "../../Typography";

const InvestmentStrategyIntro = (): JSX.Element => {
	return (
		<Stack
			sx={{
				width: { md: "75%" },
				margin: { md: "auto" },
				textAlign: { md: "center" },
			}}
		>
			<HeadingL>{cs.investmentStrategy.savingsStrategy}</HeadingL>
			<Stack spacing={5}>
				<Box sx={{ alignSelf: { md: "center", xs: "self-start" } }}>
					<StaticImage
						src="../../../images/savings-strategy.jpg"
						alt={cs.investmentStrategy.savingsStrategy}
						width={216}
					/>
				</Box>

				{cs.investmentStrategy.intro.map((paragraph, i) => (
					<BodyTextM
						component="p"
						key={i}
						dangerouslySetInnerHTML={{ __html: paragraph }}
						sx={{ alignSelf: { md: "center" } }}
					/>
				))}
			</Stack>
		</Stack>
	);
};

export default InvestmentStrategyIntro;
