import { Button, Stack } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";

import { defaultConfig, useDataConfig } from "../../context/DataConfigContext";
import SimpleDrawer from "../Drawer/SimpleDrawer";
import SwitchInput from "../Form/Input/Switch";
import TextInput from "../Form/Input/Text";
import PigIcon from "../Icons/Pig";

export const DataConfig = () => {
	const [open, setOpen] = useState(false);
	const { config, setConfig, resetConfig } = useDataConfig();

	return (
		<>
			<Button
				sx={{ position: "fixed", bottom: "60px", left: "15px" }}
				startIcon={<PigIcon />}
				onClick={() => {
					setOpen(true);
				}}
			/>

			<Formik
				initialValues={config}
				onReset={resetConfig}
				onSubmit={(values, { setSubmitting }): void => {
					setConfig({
						...values,
						overrideToken:
							typeof values.overrideToken === "string" &&
							values.overrideToken.length > 0
								? values.overrideToken
								: null,
					});
					setSubmitting(false);
				}}
			>
				{({ resetForm, submitForm }) => (
					<SimpleDrawer
						open={open}
						onClose={() => setOpen(false)}
						title="Konfigurace"
						isCloseIcon
						customButton={
							<Stack direction={"row"} gap={4} sx={{ width: "100%" }}>
								<Button
									variant="contained"
									sx={{ flexGrow: 1, flexBasis: "50px" }}
									onClick={submitForm}
								>
									Uložit
								</Button>
								<Button
									variant="contained"
									sx={{ flexGrow: 1, flexBasis: "50px" }}
									fullWidth
									onClick={() => resetForm({ values: defaultConfig })}
								>
									Default
								</Button>
							</Stack>
						}
					>
						<Stack gap={4}>
							<SwitchInput name="includeFufi" label="Include fufi" />
							<TextInput
								name="overrideToken"
								label="Override token (remove Bearer prefix)"
							/>
						</Stack>
					</SimpleDrawer>
				)}
			</Formik>
		</>
	);
};
