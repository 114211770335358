import { FormControl } from "@mui/material";
import MUIRadioGroup from "@mui/material/RadioGroup";
import Stack, { StackProps } from "@mui/material/Stack";
import { ErrorMessage, useField } from "formik";
import { ReactNode } from "react";

import FormErrorMessage from "../../FormErrorMessage";

export interface RadioProps extends StackProps {
	name: string;
	label?: string;
	children: ReactNode;
	spacing?: number;
}

const RadioGroup = ({
	children,
	label,
	name,
	sx,
	spacing,
	...rest
}: RadioProps): JSX.Element => {
	const [{ value }, { initialValue }] = useField(name);
	return (
		<Stack sx={sx} spacing={spacing} {...rest}>
			{label && <span>{label}</span>}
			<FormControl>
				<MUIRadioGroup
					aria-labelledby={`radio-buttons-group-${name}`}
					defaultValue={initialValue}
					value={value}
					name={name}
				>
					<Stack spacing={spacing}>{children}</Stack>
				</MUIRadioGroup>
			</FormControl>
			<ErrorMessage
				name={name}
				render={(msg) => <FormErrorMessage name={name}>{msg}</FormErrorMessage>}
			/>
		</Stack>
	);
};

export default RadioGroup;
