import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { FC } from "react";

import cs from "../../../translations/cs.json";
import { BodyTextM, HeadingL } from "../../Typography";

export interface RatioDistributionProps {
	usedRatio: number;
	shareInputs: JSX.Element;
}

const RatioDistribution: FC<RatioDistributionProps> = ({
	usedRatio,
	shareInputs,
}) => {
	const leftToDistribute = 100 - usedRatio;
	return (
		<>
			{shareInputs}
			<Paper sx={{ py: 6, px: 4 }}>
				<Stack
					direction="row"
					sx={{
						justifyContent: "space-between",
						alignItems: "center",
						color: leftToDistribute < 0 ? "primary.dark" : "text.main",
					}}
				>
					<BodyTextM>
						{leftToDistribute < 0
							? cs.beneficiariesChange.divideOverflow
							: cs.beneficiariesChange.leftToDivide}
					</BodyTextM>
					<HeadingL strong withoutScale sx={{ mb: 0 }}>
						{leftToDistribute < 0
							? Intl.NumberFormat("cs-CZ", {
									minimumFractionDigits: 0,
									maximumFractionDigits: 2,
							  }).format(-leftToDistribute)
							: Intl.NumberFormat("cs-CZ", {
									minimumFractionDigits: 0,
									maximumFractionDigits: 2,
							  }).format(leftToDistribute)}
						&nbsp;%
					</HeadingL>
				</Stack>
			</Paper>
		</>
	);
};

export default RatioDistribution;
