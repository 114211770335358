import "./src/font.css";

import { PublicClientApplication } from "@azure/msal-browser";
import { NavigationClient } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import {
	AppInsightsContext,
	ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import {
	ApplicationInsights,
	ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { globalHistory } from "@reach/router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import csLocale from "date-fns/locale/cs";
import type { GatsbyBrowser } from "gatsby";
import { navigate } from "gatsby";

import { NavigationTabsProvider } from "./src/components/BottomNavigation/context";
import { SmartActionProvider } from "./src/components/Contract/SmartAction/context";
import { DrawerProvider } from "./src/components/Drawer/context";
import { cookies } from "./src/components/Drawer/CookieDrawer/CookieIds";
import { ErrorBoundaryWithAsyncContext } from "./src/components/ErrorBoundary";
import { DefaultErrorUIWithTheme } from "./src/components/ErrorBoundary/DefaultErrorUi";
import { globalFormikYupConfig } from "./src/components/Form/rules";
import Layout, { LayoutProvider } from "./src/components/Layout";
import CheckContactInfoLayout from "./src/components/Layout/CheckContactInfoLayout";
import DefaultLayout from "./src/components/Layout/Default";
import LoginLayout from "./src/components/Login/Layout";
import ProfileLayout from "./src/components/Profile/Layout";
import { CookieContextProvider } from "./src/context/CookieContext";
import { DataConfigContextProvider } from "./src/context/DataConfigContext";
import { PrimaryAPIClientProvider } from "./src/context/PrimaryAPIClient";
import { QueryKeyFactoryProvider } from "./src/context/QueryKeyProvider";
import ReactQueryClientProvider from "./src/context/ReactQueryContext";
import { SPErrorContextProvider } from "./src/context/SPErrorContext";
import { EnvironmentHelper } from "./src/functions/env";
import { getAllowedCookiesFromLocalStorage } from "./src/utils";

// Config object to be passed to Msal on creation
const domain = process.env.GATSBY_B2C_DOMAIN;

const authority = EnvironmentHelper.isBrowser()
	? localStorage.getItem("lastUsedAuthority")
	: "";
const msalConfig = {
	auth: {
		clientId: process.env.GATSBY_B2C_CLIENT_ID,
		knownAuthorities: [domain], // You must identify your tenant's domain as a known authority.
		redirectUri: "/prihlaseni",
		postLogoutRedirectUri: "/prihlaseni",
		navigateToLoginRequestUrl: false,
		authority,
	},
	cache: {
		cacheLocation: "localStorage",
	},
};

const msalInstance = new PublicClientApplication(msalConfig);

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
class CustomNavigationClient extends NavigationClient {
	/**
	 * Navigates to other pages within the same web application
	 * You can use the useRouter hook provided by next.js to take advantage of client-side routing
	 * @param url
	 * @param options
	 */
	async navigateInternal(url: string, options) {
		const relativePath = url.replace(window.location.origin, "");
		if (options.noHistory) {
			await navigate(relativePath, { replace: true });
		} else {
			await navigate(relativePath);
		}

		return false;
	}
}

const reactPlugin = new ReactPlugin();

globalFormikYupConfig();

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
	element,
}) => {
	// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
	const navigationClient = new CustomNavigationClient();
	msalInstance.setNavigationClient(navigationClient);

	const allowedCookies = getAllowedCookiesFromLocalStorage();

	const allowAppInsightCookies = allowedCookies.includes(cookies.appInsights);

	const appInsights = new ApplicationInsights({
		config: {
			connectionString: process.env.GATSBY_APP_INSIGHTS_CONNECTION_STRING,
			instrumentationKey: process.env.GATSBY_APP_INSIGHTS_INST_KEY,
			isCookieUseDisabled: !allowAppInsightCookies,
			disableCookiesUsage: !allowAppInsightCookies,
			extensions: [reactPlugin],
			extensionConfig: {
				[reactPlugin.identifier]: { history: globalHistory },
			},
		},
	});
	if (EnvironmentHelper.isBrowser()) {
		appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
			if (
				item.baseType === "PageviewData" ||
				item.baseType === "PageviewPerformanceData"
			) {
				if (item.baseData) {
					item.baseData.name = document.title;
				}
			}
		});
	}

	if (process.env.NODE_ENV !== "development") {
		appInsights.loadAppInsights();
	}

	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			<ErrorBoundaryWithAsyncContext fallbackUI={DefaultErrorUIWithTheme}>
				<DataConfigContextProvider>
					<MsalProvider instance={msalInstance}>
						<QueryKeyFactoryProvider>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={csLocale}
							>
								<PrimaryAPIClientProvider>
									<CookieContextProvider>
										<ReactQueryClientProvider>
											<SPErrorContextProvider>
												<LayoutProvider>
													<SmartActionProvider>
														<NavigationTabsProvider>
															{element}
														</NavigationTabsProvider>
													</SmartActionProvider>
												</LayoutProvider>
											</SPErrorContextProvider>
											{!EnvironmentHelper.isProd() && (
												<ReactQueryDevtools initialIsOpen={false} />
											)}
										</ReactQueryClientProvider>
									</CookieContextProvider>
								</PrimaryAPIClientProvider>
							</LocalizationProvider>
						</QueryKeyFactoryProvider>
					</MsalProvider>
				</DataConfigContextProvider>
			</ErrorBoundaryWithAsyncContext>
		</AppInsightsContext.Provider>
	);
};

const LayoutComponent = ({
	pageContext,
	children,
}: {
	pageContext: any;
	children: JSX.Element;
}) => {
	if (pageContext?.additionalProps?.hasTransition) {
		return <ProfileLayout pageContext={pageContext}>{children}</ProfileLayout>;
	}
	if (pageContext?.additionalProps?.loginLayout) {
		return <LoginLayout pageContext={pageContext}>{children}</LoginLayout>;
	}
	if (pageContext?.additionalProps?.checkContactInfoLayout) {
		return (
			<CheckContactInfoLayout pageContext={pageContext}>
				{children}
			</CheckContactInfoLayout>
		);
	}
	return <DefaultLayout pageContext={pageContext}>{children}</DefaultLayout>;
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
	element,
	props,
}) => {
	return (
		<DrawerProvider>
			<Layout
				{...props}
				appBar={props.pageContext?.additionalProps?.appBar}
				bottomNavigation={
					props.pageContext?.additionalProps?.hasBottomNavigation
				}
			>
				<ErrorBoundaryWithAsyncContext key={props.pageContext.page}>
					<LayoutComponent pageContext={props.pageContext}>
						{element}
					</LayoutComponent>
				</ErrorBoundaryWithAsyncContext>
			</Layout>
		</DrawerProvider>
	);
};
