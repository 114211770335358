/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileDownloadInfo
 */
export interface FileDownloadInfo {
    /**
     * 
     * @type {string}
     * @memberof FileDownloadInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FileDownloadInfo
     */
    mimeType: string;
    /**
     * 
     * @type {number}
     * @memberof FileDownloadInfo
     */
    fileSize: number;
    /**
     * 
     * @type {string}
     * @memberof FileDownloadInfo
     */
    downloadPath: string;
}

/**
 * Check if a given object implements the FileDownloadInfo interface.
 */
export function instanceOfFileDownloadInfo(value: object): value is FileDownloadInfo {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('mimeType' in value) || value['mimeType'] === undefined) return false;
    if (!('fileSize' in value) || value['fileSize'] === undefined) return false;
    if (!('downloadPath' in value) || value['downloadPath'] === undefined) return false;
    return true;
}

export function FileDownloadInfoFromJSON(json: any): FileDownloadInfo {
    return FileDownloadInfoFromJSONTyped(json, false);
}

export function FileDownloadInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileDownloadInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'mimeType': json['mimeType'],
        'fileSize': json['fileSize'],
        'downloadPath': json['downloadPath'],
    };
}

export function FileDownloadInfoToJSON(value?: FileDownloadInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'mimeType': value['mimeType'],
        'fileSize': value['fileSize'],
        'downloadPath': value['downloadPath'],
    };
}

