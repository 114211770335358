import { useMutation, useQuery } from "@tanstack/react-query";

import { ChangeContactAddressesPutRequest } from "../../apis";
import { GetContactsResponse } from "../../models";
import { usePrimaryAPIClient } from "../context/PrimaryAPIClient";
import { useQueryKeyFactory } from "../context/QueryKeyProvider";

export const useContacts = () => {
	const { contactsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<GetContactsResponse[]>(
		createQueryKey({ key: ["getAllContacts"] }),
		() => contactsApi.getAllContactsGet()
	);
};

export const useChangeContactAddresses = () => {
	const { profileApi } = usePrimaryAPIClient();

	return useMutation({
		mutationFn: ({
			changeContactAddressesRequest,
		}: ChangeContactAddressesPutRequest) => {
			return profileApi.changeContactAddressesPut({
				changeContactAddressesRequest,
			});
		},
	});
};
