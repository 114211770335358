import { PensionPhaseStatus } from "../../../models";
import cs from "../../translations/cs.json";
import Attention from ".";
interface RecurringPaymentProps {
	pensionPhaseStatus?: PensionPhaseStatus;
}

const RecurringPayment = ({
	pensionPhaseStatus,
}: RecurringPaymentProps): JSX.Element => {
	return (
		<>
			{pensionPhaseStatus === PensionPhaseStatus.Payout ? (
				<>
					<Attention severity="info">
						{cs.global["terminated-contract-info"]}
					</Attention>
				</>
			) : (
				<Attention severity="info" title="Trvalý příkaz">
					{cs.global["recurring-payment-info"]}
				</Attention>
			)}
		</>
	);
};

export default RecurringPayment;
