import Box, { BoxProps } from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import { ReactNode, useState } from "react";

import CaretDown from "../Icons/CaretDown";
import TriggerButton from "../Link/TriggerButton";
export interface Props extends BoxProps {
	headline: ReactNode;
	children: ReactNode;
}

const Collapsible = ({
	headline,
	children,
	sx,
	...rest
}: Props): JSX.Element => {
	const [checked, setChecked] = useState(false);
	const handleChange = () => {
		setChecked((prev) => !prev);
	};
	return (
		<Box
			{...rest}
			sx={{
				...sx,
				paddingTop: checked ? "0" : "1px",
				borderTopWidth: checked ? "2px" : "1px",
				borderTopStyle: "solid",
				borderTopColor: checked ? "primary.main" : "grey.light",
			}}
		>
			<TriggerButton
				variant="collapsible"
				size="small"
				endIcon={
					<CaretDown
						sx={{
							width: "14px",
							transform: checked ? "rotate(180deg)" : "rotate(0deg)",
							transformOrigin: "center center",
							transition: "transform 0.2s",
						}}
					/>
				}
				onClick={handleChange}
			>
				<span>{headline}</span>
			</TriggerButton>
			<Collapse in={checked} orientation="vertical">
				<Box sx={{ pt: 3, pb: 5 }}>{children}</Box>
			</Collapse>
		</Box>
	);
};

interface CookieCollapsibleProps extends BoxProps {
	headline: string;
	children: ReactNode;
	selector?: ReactNode;
}

export const CookieCollapsible = ({
	headline,
	children,
	selector,
	sx,
}: CookieCollapsibleProps): JSX.Element => {
	const [checked, setChecked] = useState(false);
	return (
		<Box
			sx={{
				...sx,
				border: "1px solid",
				borderColor: "grey.light",
				borderRadius: "4px",
			}}
		>
			<TriggerButton
				variant="cookie-collapsible"
				startIcon={
					<CaretDown
						sx={{
							width: "14px",
							transform: checked ? "rotate(180deg)" : "rotate(0deg)",
							transformOrigin: "center center",
							transition: "transform 0.2s",
						}}
					/>
				}
				size="small"
				onClick={() => setChecked((prev) => !prev)}
			>
				{headline}
				{selector && (
					<Box
						sx={{ ml: "auto" }}
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						{selector}
					</Box>
				)}
			</TriggerButton>
			<Collapse in={checked}>
				<Stack sx={{ pb: 4, px: 4 }} spacing={4}>
					{children}
				</Stack>
			</Collapse>
		</Box>
	);
};

export default Collapsible;
