import Box from "@mui/material/Box";
import { useFormikContext } from "formik";

import image from "../../../../../images/server.jpg";
import { useDrawer } from "../../../../Drawer/context";
import TriggerLink from "../../../../Link/Trigger";
import { BodyTextM, HeadingL } from "../../../../Typography";

export const ChangeRequestError800 = (): JSX.Element => {
	const { navigateByName, steps } = useDrawer();
	const { setSubmitting } = useFormikContext();

	return (
		<>
			<Box mb={5} sx={{ textAlign: "center" }}>
				<img src={image} alt="error" width={211} />
			</Box>
			<HeadingL>Litujeme, Vaši změnu se nepodařilo provést.</HeadingL>
			<BodyTextM>
				Zadali jste špatný SMS kód. Zkuste si{" "}
				<TriggerLink
					onClick={() => {
						setSubmitting(false);
						navigateByName(steps, "TelephoneNumberVerification");
					}}
				>
					poslat nový SMS kód
				</TriggerLink>
				.
			</BodyTextM>
		</>
	);
};

export default ChangeRequestError800;
