import { Box, Paper, Stack, StackProps } from "@mui/material";
import { ReactElement } from "react";

import { BodyTextM, HeadingM } from "../Typography";

interface Props extends StackProps {
	title?: string;
	description?: string;
	link?: ReactElement;
}

function ContactBox({ title, description, link, ...rest }: Props): JSX.Element {
	return (
		<Box {...rest}>
			<HeadingM withoutScale>{title}</HeadingM>
			<Paper
				sx={{
					height: "100%",
					p: 4,
				}}
			>
				<Stack spacing={3} sx={{ alignItems: "start" }}>
					<BodyTextM>{description}</BodyTextM>
					{link}
				</Stack>
			</Paper>
		</Box>
	);
}

export default ContactBox;
