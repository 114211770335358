import Box from "@mui/material/Box";
import { LinkProps as MUILinkProps } from "@mui/material/Link";
import { GatsbyLinkProps } from "gatsby";
import { Link } from "gatsby-theme-material-ui";

import ArrowIcon from "../Icons/Arrow";
import Back from "../Icons/Back";
import { LinkProps } from "./";

type GatsbyThemeMUILinkProps = GatsbyLinkProps<unknown> &
	MUILinkProps &
	LinkProps;

export type InternalLinkProps = GatsbyThemeMUILinkProps;

const InternalLink = ({
	children,
	...rest
}: InternalLinkProps): JSX.Element => {
	return (
		<Link underline="none" {...rest}>
			{children}
		</Link>
	);
};
export default InternalLink;

export const InternalArrowLink = ({
	children,
	...rest
}: InternalLinkProps): JSX.Element => (
	<Link underline="none" {...rest}>
		<Box component="span" sx={{ verticalAlign: "middle" }}>
			{children}
		</Box>
		<Box component="span" sx={{ whiteSpace: "nowrap" }}>
			&nbsp;
			<ArrowIcon
				sx={{
					width: "1.6rem",
					height: "1.6rem",
					ml: 2,
				}}
			/>
		</Box>
	</Link>
);

export const BackLink = ({
	sx,
	...rest
}: Omit<InternalLinkProps, "children">): JSX.Element => (
	<Link
		sx={{ display: "inline-block", width: "fit-content", ...sx }}
		underline="none"
		{...rest}
	>
		<Box component="span" sx={{ whiteSpace: "nowrap" }}>
			<Back
				sx={{
					width: "1.6rem",
					height: "1.6rem",
					mr: 2,
				}}
			/>
			&nbsp;
		</Box>
		<Box component="span" sx={{ verticalAlign: "middle" }}>
			Zpět
		</Box>
	</Link>
);
