import { motion } from "framer-motion";
import { ReactNode } from "react";

export const transition = {
	type: "spring",
	mass: 0.35,
	stiffness: 75,
	duration: 0.2,
};

export const animation = {
	index: {
		initial: { x: "-100%" },
		animate: { x: 0 },
		exit: { x: "-100%" },
		transition: transition,
	},
	subpages: {
		initial: { x: "100%" },
		animate: { x: 0 },
		exit: { x: "100%" },
		transition: transition,
	},
};

export interface Props {
	children: ReactNode;
	customKey: string;
	index?: boolean;
}

const pageTransition = ({ children, customKey, index }: Props): JSX.Element => {
	return (
		<motion.div key={customKey} {...animation[index ? "index" : "subpages"]}>
			{children}
		</motion.div>
	);
};

export default pageTransition;
