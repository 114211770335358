import { Stack } from "@mui/material";
import { ReactElement } from "react";

import {
	GetLifeTransactionsResponse,
	GetPensionTransactionsResponse,
} from "../../../models";
import { sortByDate } from "../../functions/dates";
import HandMoneyIcon from "../Icons/HandMoney";
import PigIcon from "../Icons/Pig";
import SingleTransactionRow from "../Transactions/SingleTransactionRow";
import { BodyTextM } from "../Typography";

interface Props {
	transactions:
		| GetPensionTransactionsResponse[]
		| GetLifeTransactionsResponse[];
	isIcon: boolean;
}

// TODO modifykovat typy podle toho s cim prijde byznys
const iconByType = {
	CONTRIB_CLIENT: <PigIcon sx={{ width: "2.4rem", height: "2.4rem" }} />,
	LIFE_INSURER: <PigIcon sx={{ width: "2.4rem", height: "2.4rem" }} />,
	CONTRIB_EMPLOYER: <HandMoneyIcon sx={{ width: "2rem", height: "2.4rem" }} />,
	CONTRIB_STATE: <HandMoneyIcon sx={{ width: "2rem", height: "2.4rem" }} />,
	LIFE_EMPLOYER: <HandMoneyIcon sx={{ width: "2rem", height: "2.4rem" }} />,
	LIFE_PAY_IN: <HandMoneyIcon sx={{ width: "2rem", height: "2.4rem" }} />,
	LIFE_PAY_OUT: <HandMoneyIcon sx={{ width: "2rem", height: "2.4rem" }} />,
};

export default function TransactionsContainer({
	transactions,
	isIcon,
}: Props): JSX.Element {
	return (
		<>
			{transactions.length > 0 ? (
				<Stack spacing={4}>
					{sortByDate<
						GetPensionTransactionsResponse | GetLifeTransactionsResponse
					>(transactions).map(({ type, ...transaction }, i) => (
						<SingleTransactionRow
							key={`${type}${i}${transaction.date}${transaction.amount}`}
							{...transaction}
							icon={isIcon ? (iconByType[type] as ReactElement) : undefined}
						/>
					))}
				</Stack>
			) : (
				<>
					<BodyTextM>Žádné transakce</BodyTextM>
					<br />
				</>
			)}
		</>
	);
}
