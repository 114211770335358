import { Paper } from "@mui/material";
import { Form, useFormikContext } from "formik";
import { useEffect } from "react";

import cs from "../../../translations/cs.json";
import Radio from "../../Form/Input/Radio";
import RadioGroup from "../../Form/Input/Radio/RadioGroup";
import { HeadingL, HeadingM, HeadingS } from "../../Typography";

const TelephoneNumberVerification = ({
	personalData,
	setPhoneNumber,
	authPhoneNumbers,
}): JSX.Element => {
	const { values } = useFormikContext();

	useEffect(() => {
		setPhoneNumber(() => values.phoneNumber);
	}, [values.phoneNumber]);

	return (
		<>
			<HeadingL>
				{cs.investmentStrategy.telephoneNumberVerification.main}
			</HeadingL>
			{authPhoneNumbers.length > 1 ? (
				<>
					<HeadingS
						sx={{
							mb: 5,
						}}
					>
						{
							cs.investmentStrategy.telephoneNumberVerification
								.moreThanOneNumber
						}
					</HeadingS>
				</>
			) : (
				<HeadingM sx={{ mb: 4 }}>
					{cs.investmentStrategy.telephoneNumberVerification.basic}
				</HeadingM>
			)}

			<Paper
				sx={{
					py: 4,
					px: 3,
				}}
			>
				<Form>
					<RadioGroup name="phoneNumber">
						{authPhoneNumbers.map((number) => {
							return (
								<Radio
									name="phoneNumber"
									id={number}
									value={number}
									key={number}
									label={number}
								/>
							);
						})}
					</RadioGroup>
				</Form>
			</Paper>
		</>
	);
};

export default TelephoneNumberVerification;
