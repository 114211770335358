import { Paper } from "@mui/material";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import { useLocation } from "@reach/router";
import useIsDesktop from "desktop/useIsDesktop";
import { useEffect } from "react";

import cs from "../../translations/cs.json";
import Contacts from "../Icons/Contacts";
import Documents from "../Icons/Documents";
import House from "../Icons/House";
import MyAccount from "../Icons/MyAccount";
import LinkTab from "../Link/LinkTab";
import { useNavigationTabs } from "./context";

export const bottomNavLinks = [
	{
		label: cs.bottomNavigationBar.dashboard,
		to: "smlouvy",
	},
	{
		label: cs.bottomNavigationBar.documents,
		to: "dokumenty",
	},
	{
		label: cs.bottomNavigationBar.profile,
		to: "profil",
	},
	{
		label: cs.bottomNavigationBar.contacts,
		to: "kontakty",
	},
];

export interface Props {
	variant?: "desktop";
}

function BottomNavigation({ variant }: Props): JSX.Element {
	const { activeTabId, setActiveTabId } = useNavigationTabs();
	const { pathname } = useLocation();

	useEffect(() => {
		let currentIcon;
		bottomNavLinks.forEach((link, index) => {
			if (pathname.includes(link.to)) {
				currentIcon = index;
			}
		});

		if (typeof currentIcon === "number") setActiveTabId(currentIcon);
		else setActiveTabId(false);
	}, []);

	return (
		<Paper
			sx={{
				position: variant === "desktop" ? "static" : "fixed",
				right: 0,
				bottom: 0,
				left: 0,
				display: {
					md: variant === "desktop" ? "block" : "none",
					xxs: variant === "desktop" ? "none" : "block",
				},
			}}
			elevation={variant === "desktop" ? 0 : 3}
		>
			<Tabs
				value={activeTabId}
				aria-label="Navigace"
				sx={{
					".MuiTabs-flexContainer": {
						justifyContent: "space-evenly",
						width: "100%",
					},
				}}
				TabIndicatorProps={{
					style: {
						bottom: variant === "desktop" ? 0 : "unset",
						top: variant === "desktop" ? "unset" : 0,
					},
				}}
			>
				<LinkTab
					to={`/${bottomNavLinks[0].to}/`}
					label={bottomNavLinks[0].label}
					icon={
						<Stack>
							<House sx={{ width: "2.1rem", height: "2.1rem" }} />
						</Stack>
					}
					variant={variant}
				/>
				<LinkTab
					to={`/${bottomNavLinks[1].to}/`}
					label={bottomNavLinks[1].label}
					icon={
						<Stack>
							<Documents sx={{ width: "1.8rem", height: "2.1rem" }} />
						</Stack>
					}
					variant={variant}
				/>
				<LinkTab
					to={`/${bottomNavLinks[2].to}${useIsDesktop() ? "/moje-udaje" : ""}/`}
					label={bottomNavLinks[2].label}
					icon={
						<Stack>
							<MyAccount sx={{ width: "2.4rem", height: "2.4rem" }} />
						</Stack>
					}
					variant={variant}
				/>
				<LinkTab
					to={`/${bottomNavLinks[3].to}/`}
					label={bottomNavLinks[3].label}
					icon={
						<Stack>
							<Contacts sx={{ width: "2.4rem", height: "1.8rem" }} />
						</Stack>
					}
					variant={variant}
				/>
			</Tabs>
		</Paper>
	);
}

export default BottomNavigation;
