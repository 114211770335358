/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface ChangeContactAddressesRequest
 */
export interface ChangeContactAddressesRequest {
    /**
     * 
     * @type {Address}
     * @memberof ChangeContactAddressesRequest
     */
    address: Address;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeContactAddressesRequest
     */
    isGlobalChange: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeContactAddressesRequest
     */
    contractNumber?: string | null;
}

/**
 * Check if a given object implements the ChangeContactAddressesRequest interface.
 */
export function instanceOfChangeContactAddressesRequest(value: object): value is ChangeContactAddressesRequest {
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('isGlobalChange' in value) || value['isGlobalChange'] === undefined) return false;
    return true;
}

export function ChangeContactAddressesRequestFromJSON(json: any): ChangeContactAddressesRequest {
    return ChangeContactAddressesRequestFromJSONTyped(json, false);
}

export function ChangeContactAddressesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeContactAddressesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'address': AddressFromJSON(json['address']),
        'isGlobalChange': json['isGlobalChange'],
        'contractNumber': json['contractNumber'] == null ? undefined : json['contractNumber'],
    };
}

export function ChangeContactAddressesRequestToJSON(value?: ChangeContactAddressesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': AddressToJSON(value['address']),
        'isGlobalChange': value['isGlobalChange'],
        'contractNumber': value['contractNumber'],
    };
}

