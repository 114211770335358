import SvgIcon from "@mui/material/SvgIcon";

const SelectArrowIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="20" height="12" viewBox="0 0 20 12" {...props}>
			<path d="M19.491.184a.75.75 0 0 0-1.058.075L10 9.982 1.566.26a.749.749 0 1 0-1.133.982l8.709 10.042a1.146 1.146 0 0 0 .858.39 1.128 1.128 0 0 0 .859-.392l8.707-10.04a.749.749 0 0 0-.075-1.057Z" />
		</SvgIcon>
	);
};

export default SelectArrowIcon;
