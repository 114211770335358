/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignMasterKeyRequest,
  AssignPidRequest,
  GetAdUsersResponse,
  GetODSClientsResponse,
} from '../models/index';
import {
    AssignMasterKeyRequestFromJSON,
    AssignMasterKeyRequestToJSON,
    AssignPidRequestFromJSON,
    AssignPidRequestToJSON,
    GetAdUsersResponseFromJSON,
    GetAdUsersResponseToJSON,
    GetODSClientsResponseFromJSON,
    GetODSClientsResponseToJSON,
} from '../models/index';

export interface AssignMasterKeyPatchRequest {
    assignMasterKeyRequest: AssignMasterKeyRequest;
}

export interface AssignPidPatchRequest {
    assignPidRequest: AssignPidRequest;
}

export interface GetAdUsersGetRequest {
    search?: string;
}

export interface GetODSClientsGetRequest {
    birthNumber?: string;
    contractNumber?: string;
}

export interface RemoveAdUserDeleteRequest {
    userId: string;
    employeeId?: string;
}

/**
 * 
 */
export class CallCenterApi extends runtime.BaseAPI {

    /**
     * Assign MasterKey to a Active Directory user.
     */
    async assignMasterKeyPatchRaw(requestParameters: AssignMasterKeyPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['assignMasterKeyRequest'] == null) {
            throw new runtime.RequiredError(
                'assignMasterKeyRequest',
                'Required parameter "assignMasterKeyRequest" was null or undefined when calling assignMasterKeyPatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assign-masterkey`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AssignMasterKeyRequestToJSON(requestParameters['assignMasterKeyRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign MasterKey to a Active Directory user.
     */
    async assignMasterKeyPatch(requestParameters: AssignMasterKeyPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignMasterKeyPatchRaw(requestParameters, initOverrides);
    }

    /**
     * Assign Pid to a Active Directory user.
     */
    async assignPidPatchRaw(requestParameters: AssignPidPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['assignPidRequest'] == null) {
            throw new runtime.RequiredError(
                'assignPidRequest',
                'Required parameter "assignPidRequest" was null or undefined when calling assignPidPatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assign-pid`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AssignPidRequestToJSON(requestParameters['assignPidRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign Pid to a Active Directory user.
     */
    async assignPidPatch(requestParameters: AssignPidPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignPidPatchRaw(requestParameters, initOverrides);
    }

    /**
     * Get list of current users.
     */
    async getAdUsersGetRaw(requestParameters: GetAdUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetAdUsersResponse>>> {
        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ad-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAdUsersResponseFromJSON));
    }

    /**
     * Get list of current users.
     */
    async getAdUsersGet(requestParameters: GetAdUsersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetAdUsersResponse>> {
        const response = await this.getAdUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of clients based on search parameter.
     */
    async getODSClientsGetRaw(requestParameters: GetODSClientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetODSClientsResponse>>> {
        const queryParameters: any = {};

        if (requestParameters['birthNumber'] != null) {
            queryParameters['birthNumber'] = requestParameters['birthNumber'];
        }

        if (requestParameters['contractNumber'] != null) {
            queryParameters['contractNumber'] = requestParameters['contractNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ods-clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetODSClientsResponseFromJSON));
    }

    /**
     * Get list of clients based on search parameter.
     */
    async getODSClientsGet(requestParameters: GetODSClientsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetODSClientsResponse>> {
        const response = await this.getODSClientsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove user from Active Directory
     */
    async removeAdUserDeleteRaw(requestParameters: RemoveAdUserDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling removeAdUserDelete().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['employeeId'] != null) {
            queryParameters['employeeId'] = requestParameters['employeeId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ad-users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove user from Active Directory
     */
    async removeAdUserDelete(requestParameters: RemoveAdUserDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeAdUserDeleteRaw(requestParameters, initOverrides);
    }

}
