import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useFormikContext } from "formik";

import { usePrecontractualDocs } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import { sortedFunds } from "../../../utils";
import Download from "../../Download";
import { useDrawer } from "../../Drawer/context";
import CheckboxInput from "../../Form/Input/Checkbox";
import FundsDistribution from "../../Funds/Distribution";
import ArrowIconRight from "../../Icons/ArrowIconRight";
import Trigger from "../../Link/Trigger";
import { BodyTextM, HeadingL, HeadingM } from "../../Typography";

const ChangesOverview = ({
	existingResourcesMissing,
	defaultAllocation,
	wantFillQuestionare,
}): JSX.Element => {
	const { data, isLoading } = usePrecontractualDocs();
	const {
		values: {
			newResourcesSelected: isNewResourceChanged,
			existingResourcesSelected: isExistingResourceChanged,
			existingResources: existingResourcesValue,
			newResources: newResourcesValue,
		},
	} = useFormikContext();
	const { setStep } = useDrawer();
	const handleSetStep = () => setStep(wantFillQuestionare ? 4 : 3);
	return (
		<Stack spacing={5}>
			<HeadingL m={0}>{cs.investmentStrategy.changesOverview.header}</HeadingL>
			<BodyTextM component="p">
				{cs.investmentStrategy.changesOverview.headerDescription}
			</BodyTextM>
			<Stack sx={{ flexDirection: { md: "row" }, gap: 5 }}>
				<Stack sx={{ width: { md: "50%" } }}>
					{isExistingResourceChanged ? (
						<HeadingM>
							{cs.investmentStrategy.changesOverview.titleExistingChanged}
						</HeadingM>
					) : (
						<>
							<HeadingM>
								{cs.investmentStrategy.changesOverview.titleExisting}
							</HeadingM>
							<BodyTextM mb={4}>
								{cs.investmentStrategy.changesOverview.descriptionUnchanged}
							</BodyTextM>
						</>
					)}
					<Stack spacing={5}>
						<FundsDistribution
							allocation={
								isExistingResourceChanged
									? sortedFunds(existingResourcesValue.allocation)
									: sortedFunds(defaultAllocation.existingResourcesAllocation)
							}
						/>
						{!existingResourcesMissing && (
							<Box>
								<Trigger
									onClick={() => {
										handleSetStep();
									}}
									icon={<ArrowIconRight />}
								>
									{cs.investmentStrategy.changesOverview.linkToChanges}
								</Trigger>
							</Box>
						)}
					</Stack>
				</Stack>
				<Stack sx={{ width: { md: "50%" } }}>
					{isNewResourceChanged ? (
						<HeadingM>
							{cs.investmentStrategy.changesOverview.titleNewChanged}
						</HeadingM>
					) : (
						<>
							<HeadingM>
								{cs.investmentStrategy.changesOverview.titleNew}
							</HeadingM>
							<BodyTextM mb={4}>
								{cs.investmentStrategy.changesOverview.descriptionUnchanged}
							</BodyTextM>
						</>
					)}
					<Stack spacing={5}>
						<FundsDistribution
							allocation={
								isNewResourceChanged
									? sortedFunds(newResourcesValue.allocation)
									: sortedFunds(defaultAllocation.newResourcesAllocation)
							}
						/>
						<Box>
							<Trigger
								onClick={() => {
									handleSetStep();
								}}
								icon={<ArrowIconRight />}
							>
								{cs.investmentStrategy.changesOverview.linkToChanges}
							</Trigger>
						</Box>
					</Stack>
				</Stack>
			</Stack>
			<HeadingM>
				{cs.investmentStrategy.changesOverview.titleDocuments}
			</HeadingM>
			<BodyTextM>
				{cs.investmentStrategy.changesOverview.descriptionDocuments}
			</BodyTextM>
			{!isLoading && (
				<Download key={data.downloadPath} isExternalUrl={true} {...data} />
			)}

			<Paper sx={{ p: 4 }}>
				{cs.investmentStrategy.changesOverview.overviewAccept}
				<CheckboxInput
					name="overviewAccept"
					label={cs.global.iAgree}
					isRequired // it is not required by yup, I just need the visual feedback
				/>
			</Paper>
		</Stack>
	);
};

export default ChangesOverview;
