import { AMLIncomeSource } from "..";
import BusinessIncomeSources from "../Numbers/BusinessIncome.json";
import OtherIncomeSources from "../Numbers/OtherIncome.json";
export interface IIncomeInformation {
	employmentType?: AMLIncomeSource;
	businessIncomeId?: string;
	otherIncomeId?: string;
}

export const getIncomeSourceInfo = ({
	employmentType,
	businessIncomeId,
	otherIncomeId,
}: IIncomeInformation) => {
	if (!employmentType || employmentType === AMLIncomeSource.Employment) return;

	const incomeSourceId = {
		[AMLIncomeSource.Business]: businessIncomeId,
		[AMLIncomeSource.Other]: otherIncomeId,
	}[employmentType];

	if (!incomeSourceId) return;

	const IncomeSource = {
		[AMLIncomeSource.Business]: BusinessIncomeSources,
		[AMLIncomeSource.Other]: OtherIncomeSources,
	}[employmentType].find((incomeSource) => incomeSource.Job === incomeSourceId);

	return IncomeSource;
};
