import { useMsal } from "@azure/msal-react";
import { useState } from "react";

interface useLogoutProps {
	isDisabled: boolean;
	click: () => void;
}

export default (): useLogoutProps => {
	const msal = useMsal();
	const [isDisabled, setIsDisabled] = useState(false);

	const click = async () => {
		setIsDisabled(true);

		try {
			await msal.instance.logoutRedirect();
		} catch (error) {
			setIsDisabled(false);
		}
	};

	return {
		isDisabled,
		click,
	};
};
