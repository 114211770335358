import { Query, useQueryClient } from "@tanstack/react-query";
import { createContext, ReactNode, useContext, useState } from "react";

import { CustomApiError } from "../types/errors";
import { FetchApiError } from "./PrimaryAPIClient";

export interface ISPErrorContext {
	hasError: boolean;
}

const SPErrorContext = createContext<ISPErrorContext>({ hasError: false });

export const useHasSPError = () => {
	return useContext(SPErrorContext);
};

export const SPErrorContextProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [erroredQueries, setErroredQueries] = useState<Query[]>([]);
	const client = useQueryClient();
	const qCache = client.getQueryCache();

	qCache.subscribe((event) => {
		if (
			event.type === "updated" &&
			(event.action.type === "error" || event.action.type === "success")
		) {
			if (
				event.action.type === "error" &&
				event.action.error instanceof FetchApiError &&
				event.action.error.errorResponse.code ===
					CustomApiError.StarPensionCallFailed
			) {
				const tracked = erroredQueries.some(
					(trackedQuery) => trackedQuery.queryHash === event.query.queryHash
				);
				!tracked && setErroredQueries((prev) => [...prev, event.query]);
			} else {
				setErroredQueries((prev) =>
					prev.filter(
						(trackedQuery) => trackedQuery.queryHash !== event.query.queryHash
					)
				);
			}
		}
	});
	return (
		<SPErrorContext.Provider value={{ hasError: erroredQueries.length > 0 }}>
			{children}
		</SPErrorContext.Provider>
	);
};
