import { Skeleton, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { usePensionDetail } from "../../queryHooks";
import cs from "../../translations/cs.json";
import { ContractType } from "../../types/contracts";
import { useSmartAction } from "../Contract/SmartAction/context";
import { useDrawer } from "../Drawer/context";
import CaretRight from "../Icons/CaretRight";
import { InternalButtonLink, TriggerButton } from "../Link";
import PensionCalculatorModal from "../PensionCalculator/Modal";
import { BodyTextM, HeadingM } from "../Typography";

const PensionNovelBanner = ({
	id,
	type,
	url,
}: {
	id: string;
	type: ContractType;
	url: string;
}): JSX.Element => {
	const bannerImage = () => {
		return useStaticQuery(
			graphql`
				query DocumentManagerTranslation {
					documents: file(relativePath: { eq: "novel-banner.png" }) {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			`
		);
	};

	const image = getImage(bannerImage().documents);

	const { setAction } = useSmartAction();

	const isD = useIsDesktop();

	const { data, isSuccess } = usePensionDetail({ id, type });

	const { showDrawer, setDrawerContent } = useDrawer();

	if (isSuccess)
		return (
			<BgImage
				image={isD && image}
				style={{
					backgroundPosition: "8px 8px",
					backgroundRepeat: "no-repeat",
					backgroundSize: "134px 112px",
					width: "100%",
				}}
			>
				<Stack sx={{ px: { md: 0, xxs: 4 } }}>
					<Stack
						sx={{
							justifyContent: "flex-end",
							width: "100%",
							height: { md: 128 },
							p: { md: 3, xxs: 4 },
							border: "1px solid",
							borderColor: "grey.light",
							borderRadius: 2,
						}}
						direction={isD ? "row" : "column"}
					>
						<Stack sx={{ display: "flex", justifyContent: "center", mr: 6 }}>
							<BodyTextM sx={{ mb: 2, color: "grey.softDark" }}>
								{cs.pensionNovel.attention}
							</BodyTextM>
							<HeadingM sx={{ maxWidth: 430, mb: 0 }}>
								{data?.contract.monthlyContributionClient < 1700 ? (
									<>
										{cs.pensionNovel.text}{" "}
										{data?.contract.monthlyContributionClient} Kč na{" "}
										<HeadingM sx={{ display: "inline", color: "primary.dark" }}>
											1 700 Kč
										</HeadingM>
									</>
								) : (
									<>{cs.pensionNovel.textMax}</>
								)}
							</HeadingM>
						</Stack>
						<Stack sx={{ mt: 4, mr: 5, textAlign: "center" }} spacing={3}>
							<InternalButtonLink
								size="large"
								variant="contained"
								onClick={() => setAction({ type: "Fond" })}
								to={url}
							>
								{cs.pensionNovel.changePensionSettings}
							</InternalButtonLink>
							<TriggerButton
								onClick={() => {
									showDrawer();
									setDrawerContent(
										<PensionCalculatorModal
											changeContributionUrl={url}
											monthlyContribution={
												data?.contract.monthlyContributionClient
											}
										/>
									);
								}}
								variant="text"
								size="small"
								endIcon={
									<CaretRight sx={{ width: "0.8rem", height: "1.3rem" }} />
								}
							>
								{cs.pensionNovel.getMoreInfo}
							</TriggerButton>
						</Stack>
					</Stack>
				</Stack>
			</BgImage>
		);
	else return <Skeleton variant="rounded" height={128} width="100%" />;
};

export default PensionNovelBanner;
