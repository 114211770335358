export const orange = {
	"000": "#FFF7EE",
	100: "#F6BF80",
	300: "#EE7F00",
	500: "#EA650D",
	700: "#E64415",
};

export const blue = {
	"000": "#CCDBF2",
	100: "#99B7E5",
	300: "#6693D8",
	500: "#336FCB",
	700: "#004BBE",
};

export const purple = {
	"000": "#E1DDEF",
	100: "#BDB6DD",
	300: "#9B91CC",
	500: "#796ABB",
	700: "#35219B",
};

export const green = {
	"000": "#D2E9E4",
	100: "#A7D3CA",
	300: "#7EBEAF",
	500: "#5AA896",
	700: "#347663",
};

export const grey = {
	"000": "#F1EDEB",
	100: "#E5DED9",
	200: "#CAC7C7",
	400: "#999999",
	600: "#666666",
	800: "#414141",
};
