import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

import { LoggedOutBar } from "../../components/AppBar/Bars";
import { SEO } from "../../components/SEO";
import { BodyTextL, HeadingL } from "../../components/Typography";
import ChromeIcon from "../../images/chrome.svg";
import EdgeIcon from "../../images/edge.svg";
import FirefoxIcon from "../../images/firefox.svg";
import SafariIcon from "../../images/safari.svg";
import unsupportedBrowser from "../../images/unsupported-browser.png";
import cs from "../../translations/cs.json";

interface BrowserProps {
	link: string;
	icon: JSX.Element;
	name: string;
}

const Browser = ({ link, icon, name }: BrowserProps): JSX.Element => {
	return (
		<Link href={link} target="_blank" sx={{ mx: 4 }}>
			<Stack sx={{ alignItems: "center" }}>
				<img src={icon} width="42" height="42" alt={name} />
				<Box sx={{ mt: 2 }}>{name}</Box>
			</Stack>
		</Link>
	);
};

export const AppBar = (): JSX.Element => <LoggedOutBar />;

const UnsupportedBrowser = ({ pageContext }): JSX.Element => {
	return (
		<Stack
			sx={{
				alignItems: "center",
				maxWidth: { md: "630px", xxs: "100%" },
				mx: 4,
				margin: "auto",
				textAlign: "center",
			}}
		>
			<img src={unsupportedBrowser} alt="Nepodporovaný browser" />
			<HeadingL sx={{ mt: 6 }}>{cs.unsupportedBrowser.heading}</HeadingL>
			<BodyTextL>{cs.unsupportedBrowser.text}</BodyTextL>
			<Stack sx={{ mt: 6 }} direction="row">
				<Browser
					name="Chrome"
					icon={ChromeIcon}
					link="https://www.google.com/intl/cs_CZ/chrome/"
				/>
				<Browser
					name="Firefox"
					icon={FirefoxIcon}
					link="https://www.mozilla.org/cs/firefox/new/"
				/>
				<Browser
					name="Safari"
					icon={SafariIcon}
					link="https://www.apple.com/safari/m"
				/>
				<Browser
					name="Edge"
					icon={EdgeIcon}
					link="https://www.microsoft.com/cs-cz/edge"
				/>
			</Stack>
		</Stack>
	);
};

export default UnsupportedBrowser;

export const Head = (): JSX.Element => (
	<SEO title="Nepodporovaný prohlížeč">
		<meta name="robots" content="noindex" />
	</SEO>
);
