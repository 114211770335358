import { Alert, AlertColor } from "@mui/material";
import { FC, ReactNode } from "react";

import { BodyTextM } from "../../Typography";

export interface FormErrorMessageProps {
	children: ReactNode;
	name?: string;
	severity?: AlertColor;
}

const FormErrorMessage: FC<FormErrorMessageProps> = ({
	children,
	name,
	severity = "error",
	...rest
}) => {
	return (
		<Alert
			data-error-name={name}
			sx={{ mt: 4, border: 0, scrollMargin: "200px" }}
			severity={severity}
			{...rest}
		>
			{typeof children === "string" ? (
				<BodyTextM
					dangerouslySetInnerHTML={{
						__html: children,
					}}
				/>
			) : (
				<BodyTextM>{children}</BodyTextM>
			)}
		</Alert>
	);
};

export default FormErrorMessage;
