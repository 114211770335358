import { Stack } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import { FieldAttributes, useField } from "formik";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";

export type onRadioChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface RadioProps extends FieldAttributes<any> {
	disabled?: boolean;
	error?: boolean;
	name: string;
	value: string | boolean;
	label: string;
	isRequired?: boolean;
	id: string;
}

const CustomIcon = styled("span")(({ theme }) => ({
	borderRadius: "50%",
	width: 32,
	height: 32,
	boxShadow: `0 0 0 1px ${theme.palette.grey.softDark}`,
}));

const CustomCheckedIcon = styled(CustomIcon)(({ theme }) => ({
	"&:before": {
		display: "block",
		width: 32,
		height: 32,
		backgroundImage: `radial-gradient(${theme.palette.primary.main},${theme.palette.primary.main} 34%,transparent 38%)`,
		content: '""',
	},
}));

const RadioInput = ({
	disabled,
	name,
	label,
	subLabel,
	id,
	value,
	isRequired,
	...rest
}: RadioProps) => {
	const [field] = useField(name);
	const { isSubmitting } = useFormikContext();

	return (
		<Stack direction="row" alignItems="flex-start">
			<Radio
				disabled={disabled || isSubmitting}
				{...rest}
				id={id}
				required={isRequired}
				disableRipple
				color="default"
				checkedIcon={<CustomCheckedIcon />}
				icon={<CustomIcon />}
				{...field}
				value={value}
				sx={{ mr: 2 }}
			/>
			<Stack>
				<InputLabel
					htmlFor={id}
					variant="subLabel"
					sx={{
						position: "relative",
						mb: 0,
						pt: "13px",
						pb: 2,
						pl: 0,
						color: "#0b0b0b !important", // I didn't find a better way how to change focus color separately for label and subLabel, subLabel isn't MUI supported, so maybe there isn't better way how to do it
						fontWeight: 600,
					}}
				>
					{label}
				</InputLabel>
				{subLabel && (
					<InputLabel
						htmlFor={id}
						variant="subLabel"
						sx={{
							position: "relative",
							pl: 0,
							color: "grey.dark",
						}}
					>
						{subLabel}
					</InputLabel>
				)}
			</Stack>
		</Stack>
	);
};

export default RadioInput;
