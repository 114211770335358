import { TriggerButton } from "../Link";
import useLogout from "./useLogout";

function LogoutButton(): JSX.Element {
	const { click, isDisabled } = useLogout();

	return (
		<TriggerButton onClick={click} disabled={isDisabled}>
			Odhlásit se
		</TriggerButton>
	);
}

export default LogoutButton;
