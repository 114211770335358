import SvgIcon from "@mui/material/SvgIcon";

const Checkbox0 = (props): JSX.Element => {
	return (
		<SvgIcon width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
			<path d="M25 1H5a4 4 0 0 0-4 4v24a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V9 h-1 v+20 a3 3 0 0 1 -3 3 h-24 a3 3 0 0 1 -3 -3 v-24 a3 3 0 0 1 3 -3 h20 v-1 " />
		</SvgIcon>
	);
};

export default Checkbox0;
