import SvgIcon from "@mui/material/SvgIcon";

const PhoneIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="12" height="16" viewBox="0 0 12 16" {...props}>
			<path d="M9.546 0H2.454C1.284 0 .334.951.334 2.12V13.88c0 1.17.95 2.121 2.12 2.121h7.092c1.169 0 2.12-.952 2.12-2.121V2.12C11.667.95 10.716 0 9.547 0ZM11 13.879c0 .802-.652 1.454-1.454 1.454H2.454A1.456 1.456 0 0 1 1 13.88V2.12C1 1.319 1.652.667 2.454.667h7.092C10.348.667 11 1.319 11 2.12v11.758Z" />
			<path d="M8.492 1.459H3.495c-.965 0-1.75.785-1.75 1.751v6.98c0 .556.452 1.01 1.009 1.01h1.365a.333.333 0 1 0 0-.667H2.754a.343.343 0 0 1-.343-.343V3.21c0-.598.487-1.085 1.085-1.085h4.996c.599 0 1.085.487 1.085 1.085v6.98c0 .189-.154.343-.343.343h-1.58a.333.333 0 1 0 0 .666h1.58c.557 0 1.01-.453 1.01-1.01V3.21c0-.965-.786-1.75-1.752-1.75ZM5.994 11.662c-.775 0-1.405.63-1.405 1.405a.333.333 0 1 0 .666 0 .74.74 0 1 1 .34.622.333.333 0 1 0-.36.561A1.407 1.407 0 0 0 7.4 13.067c0-.775-.631-1.405-1.406-1.405Z" />
			<path d="m3.851 6.111 2.547-2.547a.333.333 0 1 0-.47-.471L3.38 5.64a.333.333 0 1 0 .471.471ZM8.104 5.95 5.557 8.495a.333.333 0 1 0 .471.471l2.547-2.546a.333.333 0 1 0-.471-.472ZM3.702 8.5c.085 0 .17-.032.236-.097L8.52 3.82a.333.333 0 1 0-.47-.472L3.465 7.931a.333.333 0 0 0 .236.57Z" />
		</SvgIcon>
	);
};

export default PhoneIcon;
