import SvgIcon from "@mui/material/SvgIcon";

const Adress = (props): JSX.Element => {
	return (
		<SvgIcon width="17" height="22" viewBox="0 0 17 22" fill="none" {...props}>
			<path d="M12 7.02a.5.5 0 0 0-.37.6A3.23 3.23 0 1 1 9.3 5.24a.5.5 0 1 0 .25-.97A4.27 4.27 0 1 0 12.6 7.4a.5.5 0 0 0-.6-.37Z" />
			<path d="M8.5 0A8.56 8.56 0 0 0 0 8.6c0 4.63 7.8 12.9 8.14 13.24a.5.5 0 0 0 .72 0C9.2 21.5 17 13.24 17 8.61A8.56 8.56 0 0 0 8.5 0Zm0 20.76C6.95 19.07 1 12.31 1 8.61A7.56 7.56 0 0 1 8.5 1 7.56 7.56 0 0 1 16 8.6c0 3.7-5.95 10.47-7.5 12.16Z" />
		</SvgIcon>
	);
};

export default Adress;
