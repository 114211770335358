import { Dialog, Drawer } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";

import AgreementDrawerContent from ".";

interface IAgreementDrawer {
	open: boolean;
	onClose: () => void;
}

export const AgreementDrawer = ({ open, onClose }: IAgreementDrawer) => {
	const isDesktop = useIsDesktop();

	const Container = isDesktop ? Dialog : Drawer;
	return (
		<Container open={open} onClose={onClose}>
			<AgreementDrawerContent onClose={onClose} />
		</Container>
	);
};
