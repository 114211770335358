import { SetContributionRequest } from "../../../../models";

export const mapContributionRequest = (
	// eslint-disable-next-line no-undef
	values: Partial<SetContributionRequest>,
	initialValues: SetContributionRequest
): SetContributionRequest => {
	const newValues = {
		contribution:
			typeof values.contribution === "number" &&
			values.contribution !== initialValues.contribution
				? values.contribution
				: null,
		doesEmployerContribute:
			typeof values.doesEmployerContribute === "boolean" &&
			values.doesEmployerContribute !== initialValues.doesEmployerContribute
				? values.doesEmployerContribute
				: null,
	};

	return newValues;
};
