/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LifeContractStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    InsuredEvent: 'InsuredEvent',
    PaidUp: 'PaidUp',
    RegisteredDeath: 'RegisteredDeath'
} as const;
export type LifeContractStatus = typeof LifeContractStatus[keyof typeof LifeContractStatus];


export function instanceOfLifeContractStatus(value: any): boolean {
    for (const key in LifeContractStatus) {
        if (Object.prototype.hasOwnProperty.call(LifeContractStatus, key)) {
            if ((LifeContractStatus as Record<string, LifeContractStatus>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LifeContractStatusFromJSON(json: any): LifeContractStatus {
    return LifeContractStatusFromJSONTyped(json, false);
}

export function LifeContractStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): LifeContractStatus {
    return json as LifeContractStatus;
}

export function LifeContractStatusToJSON(value?: LifeContractStatus | null): any {
    return value as any;
}

