import { Box, Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import { IconButton } from "gatsby-theme-material-ui";

import DownloadIcon from "../../components/Icons/Download";
import InfoIcon from "../../components/Icons/Info";
import { dateTimeFormat } from "../../functions/dates";
import { BodyTextM, BodyTextS } from "../Typography";

export interface DownloadProps {
	name: string;
	dateCreated: Date;
	fileSize?: number;
	mimeType: string;
	href: string;
	infoIcon?: boolean;
	tagNew?: boolean;
	downloadPath?: string;
	isExternalUrl?: string;
}

function Download({
	name,
	dateCreated,
	fileSize,
	mimeType,
	infoIcon,
	tagNew,
	downloadPath,
	isExternalUrl,
}: DownloadProps): JSX.Element {
	const formattedSize =
		fileSize > 999 ? `${fileSize.toString().slice(0, -3)}kB` : `${fileSize}B`;
	return (
		<Stack
			direction="row"
			sx={{
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				alignItems: "center",
			}}
			spacing={4}
		>
			<IconButton
				to={
					isExternalUrl
						? downloadPath
						: `${process.env.GATSBY_API_BASE_URL}/${downloadPath}`
				}
				color="primary"
				download
				target="_blank"
				disabled={!downloadPath}
			>
				<DownloadIcon
					sx={{ width: "1.6rem", height: "2rem" }}
					color="inherit"
				/>
			</IconButton>
			<Stack sx={{ flexGrow: 1, flexBasis: "50%", minWidth: 0 }}>
				<BodyTextM
					sx={{
						overflow: "hidden",
						color: "primary.main",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					<Link
						href={
							isExternalUrl
								? downloadPath
								: `${process.env.GATSBY_API_BASE_URL}/${downloadPath}`
						}
						target="_blank"
						variant="light"
					>
						{name}
					</Link>

					<Box component="span" sx={{ whiteSpace: "nowrap" }}>
						&nbsp;
						{infoIcon && (
							<InfoIcon
								color="text"
								sx={{
									width: "1.8rem",
									height: "1.8rem",
									ml: 2,
								}}
							/>
						)}
						{tagNew && <Chip label="Nové" sx={{ mb: 1, ml: 2 }} />}
					</Box>
				</BodyTextM>
				<BodyTextS mb={0} light>
					{dateCreated && dateTimeFormat.format(new Date(dateCreated))} (
					{fileSize ? `${formattedSize}, ` : ""}
					{mimeType.replace(/application\//g, "")})
				</BodyTextS>
			</Stack>
		</Stack>
	);
}

export default Download;
