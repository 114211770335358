import { Box, Grid, Stack } from "@mui/material";
import { useLocation } from "@reach/router";
import useIsDesktop from "desktop/useIsDesktop";
import { AnimatePresence } from "framer-motion";
import { PageProps } from "gatsby";
import { ComponentType } from "react";

import { Title as AdrTitle } from "../../pages/profile/address";
import { Title as AmlTitle } from "../../pages/profile/aml";
import { Title as ChaTitle } from "../../pages/profile/changes";
import { Title as ComTitle } from "../../pages/profile/communication-contract";
import { Title as ConTitle } from "../../pages/profile/contact";
import { Title as InfTitle } from "../../pages/profile/info";
import { Title as MarTitle } from "../../pages/profile/marketing";
import { Title as PerTitle } from "../../pages/profile/personal-information";
import { LoggedInBar } from "../AppBar";
import { BackLink } from "../Link/InternalLink";
import PageTransition from "../PageTransition";
import { HeadingL } from "../Typography";
import Menu from "./Menu";
const titleBySubPage: Record<string, ComponentType | string> = {
	"souhlas-s-elektronickou-komunikaci-a-kontraktaci": ComTitle,
	adresy: AdrTitle,
	zmeny: ChaTitle,
	kontakty: ConTitle,
	"moje-udaje": InfTitle,
	"marketingovy-souhlas": MarTitle,
	"ochrana-osobich-udaju": PerTitle,
	"doplneni-identifikace": AmlTitle,
};

export const AppBar = (): JSX.Element => <LoggedInBar />;

export interface LayoutProps extends PageProps {
	pageContext: Record<string, unknown>;
}
function Layout({ children, pageContext }: LayoutProps): JSX.Element {
	const isD = useIsDesktop();
	const { pathname } = useLocation();

	const entry = Object.entries(titleBySubPage).find(([key]) =>
		pathname.includes(key)
	);

	const Title = entry ? entry[1] : undefined;

	const content = (
		<Box px={isD ? 0 : 4}>
			{!useIsDesktop() && !pageContext.additionalProps.isMobileCrossroad && (
				<BackLink mb={5} to="/profil" />
			)}
			{Title && (
				<HeadingL withoutScale>
					{typeof Title === "string" ? Title : <Title />}
				</HeadingL>
			)}
			<Stack spacing={5}>{children}</Stack>
		</Box>
	);

	if (isD)
		return (
			<AnimatePresence exitBeforeEnter>
				<Grid container>
					<Grid item md={5}>
						<Menu />
					</Grid>
					<Grid item md={1} />
					<Grid item md={6}>
						{content}
					</Grid>
				</Grid>
			</AnimatePresence>
		);

	return (
		<PageTransition
			customKey={pathname}
			index={pageContext.additionalProps?.isMobileCrossroad as boolean}
		>
			<AnimatePresence exitBeforeEnter>{content}</AnimatePresence>
		</PageTransition>
	);
}

export default Layout;
