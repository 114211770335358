export const overRecommendedAllocations = {
	// no inputs, just for custom error messages
	dynBalMaxAlloc: undefined,
	dynConMaxAlloc: undefined,
	balDynMaxAlloc: undefined,
	balConMaxAlloc: undefined,
	conDynMaxAlloc: undefined,
	conBalMaxAlloc: undefined,
	prepensionBalMaxAlloc: undefined,
	prepensionDynMaxAlloc: undefined,
};

export const setInitialData = (
	results,
	personalData,
	existingResourcesMissing,
	authPhoneNumbers
) => {
	return {
		phoneNumber: authPhoneNumbers[0],
		smsCode: "",
		existingResources: {
			valuesChanged: undefined,
			allocation: results?.investmentStrategy?.allocation,
			...overRecommendedAllocations,
		},
		newResources: {
			valuesChanged: undefined,
			allocation: results?.investmentStrategy?.allocation,
			...overRecommendedAllocations,
		},
		existingResourcesSelected: !existingResourcesMissing,
		newResourcesSelected: true,
		overviewAccept: false,
		selectedResources: false, // no input, just for custom error message
	};
};

export const existingResourcesAllocation = (funds, initialValues) =>
	funds.map((fund, i) => {
		return {
			fundCode: fund.code,
			allocation: fund.balanceRatio,
			fundName:
				initialValues?.existingResources?.allocation?.[i]
					.fundName /*fix fund.title inconsistency*/,
		};
	});

export const newResourcesAllocation = (funds, initialValues) =>
	funds.map((fund, i) => {
		return {
			fundCode: fund.code,
			allocation: fund.depositRatio,
			fundName:
				initialValues?.newResources?.allocation?.[i]
					.fundName /*fix fund.title inconsistency*/,
		};
	});
