import SvgIcon from "@mui/material/SvgIcon";

const ContractsIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
			<path d="M9.58 3.66a.5.5 0 0 0-.7.1L6.02 7.71l-1.9-1.84a.5.5 0 0 0-.7.72l2.32 2.24a.5.5 0 0 0 .75-.07l3.2-4.4a.5.5 0 0 0-.11-.69Z" />
			<path d="M15.7 20.1a.5.5 0 0 0-.5.5V23H1V1h14.2v2.9a.5.5 0 1 0 1 0V.5a.5.5 0 0 0-.5-.5H.5a.5.5 0 0 0-.5.5v23c0 .28.22.5.5.5h15.2a.5.5 0 0 0 .5-.5v-2.9a.5.5 0 0 0-.5-.5Z" />
			<path d="m23.8 8.77-1.57-1.2.09-.1a2.42 2.42 0 0 0-.46-3.41 2.41 2.41 0 0 0-3.4.46L10.1 15.47a1 1 0 0 0-.2.52l-.23 2.66a1 1 0 0 0 .08.47l-.89 1.16a.5.5 0 0 0 .8.6l.89-1.16.12.02a1 1 0 0 0 .34-.06l2.5-.93a1 1 0 0 0 .46-.33l7.64-10.05 1.17.9-2.68 3.52a.5.5 0 1 0 .79.6l2.99-3.92a.5.5 0 0 0-.1-.7ZM13.2 17.8l-2.5.93.22-2.66 5.21-6.85 2.28 1.73-5.21 6.85Zm8.33-10.95L19 10.16l-2.27-1.73 2.52-3.3a1.43 1.43 0 1 1 2.27 1.73ZM8.88 10.57l-2.86 3.92-1.9-1.83a.5.5 0 1 0-.7.72l2.32 2.23c.09.1.22.14.34.14h.05a.5.5 0 0 0 .36-.2l3.2-4.4a.5.5 0 0 0-.81-.58Z" />
		</SvgIcon>
	);
};

export default ContractsIcon;
