import { Dialog, DialogProps } from "@mui/material";

import useIsDesktop from "../../desktop/useIsDesktop";

const Modal = ({ children, onClose, ...rest }: DialogProps): JSX.Element => {
	const isDesktop = useIsDesktop();

	return (
		<Dialog onClose={onClose} fullScreen={!isDesktop} {...rest}>
			{children}
		</Dialog>
	);
};

export default Modal;
