import { navigate } from "gatsby";
import { Button } from "gatsby-theme-material-ui";

import cs from "../../translations/cs.json";
import Attention from "../AttentionBlock";
import { useErrorContext } from "../ErrorBoundary/ErrorContext";

export interface IErrorMessage {
	title?: string;
	message?: string;
	buttonText?: string;
	customButton?: JSX.Element | null;
}

export const HomeButton = () => {
	const { resetError } = useErrorContext();
	return (
		<Button
			variant="contained"
			onClick={() => {
				resetError();
				void navigate("/");
			}}
		>
			{cs.global.home}
		</Button>
	);
};

export default function GeneralMessage({
	title = cs.errors.somethingWentWrong,
	message = cs.errors.general,
	buttonText = cs.errors.tryAgain,
	customButton,
}: IErrorMessage): JSX.Element {
	const button = (() => {
		if (customButton === null) return null;
		return (
			customButton ?? (
				<Button onClick={() => window.location.reload()} variant="contained">
					{buttonText}
				</Button>
			)
		);
	})();
	return (
		<>
			<Attention
				sx={{ minWidth: { md: "450px", xs: 0 } }}
				severity="error"
				title={title}
			>
				{message}
			</Attention>
			{button}
		</>
	);
}
