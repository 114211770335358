import { Alert } from "@mui/material";

import cs from "../../translations/cs.json";
import { BodyTextM } from "../Typography";

const StarPensionWarning = () => {
	return (
		<Alert
			severity="error"
			elevation={4}
			sx={{
				zIndex: 999,
			}}
		>
			<BodyTextM>{cs.global.starPensionUnavailableWarning}</BodyTextM>
		</Alert>
	);
};

export default StarPensionWarning;
