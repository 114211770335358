/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLoginPayloadResponse
 */
export interface CreateLoginPayloadResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateLoginPayloadResponse
     */
    targetUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLoginPayloadResponse
     */
    issuedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLoginPayloadResponse
     */
    expiresAt: string;
}

/**
 * Check if a given object implements the CreateLoginPayloadResponse interface.
 */
export function instanceOfCreateLoginPayloadResponse(value: object): value is CreateLoginPayloadResponse {
    if (!('targetUrl' in value) || value['targetUrl'] === undefined) return false;
    if (!('issuedAt' in value) || value['issuedAt'] === undefined) return false;
    if (!('expiresAt' in value) || value['expiresAt'] === undefined) return false;
    return true;
}

export function CreateLoginPayloadResponseFromJSON(json: any): CreateLoginPayloadResponse {
    return CreateLoginPayloadResponseFromJSONTyped(json, false);
}

export function CreateLoginPayloadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLoginPayloadResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'targetUrl': json['targetUrl'],
        'issuedAt': json['issuedAt'],
        'expiresAt': json['expiresAt'],
    };
}

export function CreateLoginPayloadResponseToJSON(value?: CreateLoginPayloadResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'targetUrl': value['targetUrl'],
        'issuedAt': value['issuedAt'],
        'expiresAt': value['expiresAt'],
    };
}

