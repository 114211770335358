import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { ChangeEvent } from "react";

import NumberInput from "../../../Form/Input/Number";

interface ShareProps {
	name: string;
	placeholder?: string;
	label: JSX.Element;
	errorMessage?: JSX.Element;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Share = ({
	label,
	name,
	placeholder,
	errorMessage,
	onChange,
}: ShareProps): JSX.Element => (
	<Paper sx={{ p: 4 }}>
		<Stack>
			<Stack
				direction="row"
				sx={{
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Stack>{label}</Stack>
				<NumberInput
					sx={{ width: 88 }}
					min={0}
					max={100}
					name={name}
					suffix=" %"
					decimalScale={0}
					placeholder={placeholder}
					customOnChange={onChange}
					allowNegative={false}
				/>
			</Stack>
			{errorMessage}
		</Stack>
	</Paper>
);
export default Share;
