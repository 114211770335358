import SvgIcon from "@mui/material/SvgIcon";

const EnvelopeIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="21" height="15" viewBox="0 0 21 15" {...props}>
			<path d="M19.53 0H1.47C.66 0 0 .66 0 1.47v11.97c0 .81.66 1.47 1.47 1.47h18.06c.81 0 1.47-.66 1.47-1.47V1.47C21 .67 20.34 0 19.53 0ZM1.47 1h18.06c.22 0 .4.16.45.37-.04.02-.08.03-.11.06l-8.38 7.02c-.57.48-1.4.48-1.98 0L1.13 1.43c-.03-.03-.07-.04-.1-.06.04-.21.22-.37.44-.37ZM20 12.9 16.09 7.7a.5.5 0 0 0-.8.6l4.22 5.62H1.5L5.72 8.3a.5.5 0 1 0-.8-.6L1 12.9V2.62l7.87 6.6a2.53 2.53 0 0 0 3.26 0L20 2.61V12.9Z" />
		</SvgIcon>
	);
};

export default EnvelopeIcon;
