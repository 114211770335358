import ArrowIcon from "./Arrow";

const ArrowIconLeft = ({ sx, ...rest }): JSX.Element => {
	return (
		<ArrowIcon
			{...rest}
			sx={{ ...sx, transform: "scale(-1, 1)", transformOrigin: "center" }}
		/>
	);
};

export default ArrowIconLeft;
