import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CaretRight = (props: SvgIconProps): JSX.Element => {
	return (
		<SvgIcon width="8" height="14" viewBox="0 0 8 14" fill="none" {...props}>
			<path d="m7.7 6.7-.2-.3L.8.6a.5.5 0 1 0-.6.8L6.7 7 .2 12.6a.5.5 0 1 0 .6.8l6.7-5.8a.8.8 0 0 0 .2-1Z" />
		</SvgIcon>
	);
};

export default CaretRight;
