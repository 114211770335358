/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductType = {
    Ds: 'DS',
    Pf: 'PF',
    Uf: 'UF',
    Ts: 'TS',
    Cl: 'CL',
    Sl: 'SL',
    Clf: 'CLF',
    Na: 'NA'
} as const;
export type ProductType = typeof ProductType[keyof typeof ProductType];


export function instanceOfProductType(value: any): boolean {
    for (const key in ProductType) {
        if (Object.prototype.hasOwnProperty.call(ProductType, key)) {
            if ((ProductType as Record<string, ProductType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ProductTypeFromJSON(json: any): ProductType {
    return ProductTypeFromJSONTyped(json, false);
}

export function ProductTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductType {
    return json as ProductType;
}

export function ProductTypeToJSON(value?: ProductType | null): any {
    return value as any;
}

