import { Box, Skeleton, Stack } from "@mui/material";

const FileSkeleton = (): JSX.Element => (
	<Stack
		direction="row"
		alignItems="center"
		sx={{ width: "100%", mt: -3 }}
		spacing={4}
	>
		<Skeleton
			sx={{
				width: "1.6rem",
			}}
			height={20}
			variant="rectangular"
		/>
		<Box flexGrow={1}>
			<Skeleton height={16} width="100%" sx={{ my: 3 }} variant="rectangular" />
			<Skeleton height={16} width="100%" sx={{ my: 3 }} variant="rectangular" />
		</Box>
	</Stack>
);

export default FileSkeleton;
