import {
	GetDPSDetailResponse,
	PensionPhaseStatus,
	ProductType,
} from "../../../../models";
import PigIcon from "../../../components/Icons/Pig";
import { useContractId } from "../../../context/ContractIDContext";
import { useContractTypeContext } from "../../../context/ContractTypeContext";
import { usePensionDetail } from "../../../queryHooks";
import { useContractById } from "../../../queryHooks";
import ShieldIcon from "../../Icons/Shield";
import ProductOverview from "../../Overview/ProductOverview";

const ppOverviewSx = {
	mb: -4,
	mx: -4,
	display: { xxs: "none", md: "flex" },
	pt: { md: 4 },
	flexGrow: 1,
	pb: 4,
	height: 136,
	".MuiCardContent-root": {
		display: { md: "flex" },
		flexGrow: { md: 1 },
	},
};

function PensionOverview() {
	const id = useContractId();
	const { contractType } = useContractTypeContext();
	const isUf = contractType === ProductType.Uf;
	const detail = usePensionDetail({ id, options: { enabled: isUf } });
	const { data, isSuccess } = useContractById({
		id: id,
		options: { refetchOnMount: false },
	});

	if (
		(data && data.pensionPhaseStatus !== PensionPhaseStatus.Saving) ||
		detail.isError
	)
		return <></>;

	const icon =
		contractType === ProductType.Uf ? (
			<PigIcon sx={{ width: "3.2rem", height: "3.2rem", mr: 4 }} />
		) : (
			<ShieldIcon sx={{ width: "2.5rem", height: "2.9rem", mr: 4 }} />
		);

	if (!isSuccess || (isUf && !detail.isSuccess))
		return <ProductOverview icon={icon} sx={ppOverviewSx} />;

	return (
		<ProductOverview
			title={"pig icon"}
			icon={icon}
			totalSavings={data?.amount}
			sx={ppOverviewSx}
			isFundsChangeInProgress={
				isUf
					? (detail.data as GetDPSDetailResponse).isFundsChangeInProgress
					: undefined
			}
		/>
	);
}

export default PensionOverview;
