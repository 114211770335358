import {
	AuthenticationResult,
	InteractionStatus,
	InteractionType,
	SilentRequest,
} from "@azure/msal-browser";
import {
	useIsAuthenticated,
	useMsal,
	useMsalAuthentication,
} from "@azure/msal-react";
import { Stack } from "@mui/material";
import { navigate } from "gatsby";
import React, { ComponentType, useEffect } from "react";

import { PostLoginResponse } from "../../models";
import { DrawerManager } from "../components/DrawerManager";
import { EnvironmentHelper } from "../functions/env";
import { usePostLogin } from "../queryHooks";
import cs from "../translations/cs.json";

const getAuthRequest = (auth: string | null): SilentRequest => {
	const request: SilentRequest = {
		scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE || ""],
		redirectUri: "",
		authority: auth ?? undefined,
	};
	if (auth) request.authority = auth;
	return request;
};

const setupInitialConsentDrawer = (
	data: PostLoginResponse,
	isAirBank: boolean
) => {
	if (data.startElectronicContactsConfirmation && !isAirBank)
		void navigate("/jsou-vase-udaje-spravne");
};

const getIsUserNotFound = (result: AuthenticationResult) => {
	const isNotFound =
		!result.authority.includes("_change") &&
		result.account &&
		result.account.idTokenClaims &&
		!result.account.idTokenClaims.extension_PID &&
		!result.account.idTokenClaims.extension_MPK;
	return isNotFound;
};

// Gets last token issuer url
const getLastUsedAuthority = () => {
	return EnvironmentHelper.isBrowser()
		? localStorage.getItem("lastUsedAuthority")
		: null;
};

export default function withProtection<T extends React.JSX.IntrinsicAttributes>(
	PageComponent: ComponentType<T>,
	url?: string
): (props: T) => JSX.Element {
	return function wrapperWithProtection(props: T): JSX.Element {
		const lastUsedAuthority = getLastUsedAuthority();
		const { instance, inProgress } = useMsal();
		const isAuth = useIsAuthenticated();
		const { login, result } = useMsalAuthentication(
			InteractionType.Redirect,
			getAuthRequest(lastUsedAuthority)
		);
		const { data, isLoading, isSuccess, isFetching } = usePostLogin();

		const isPID = result?.account?.idTokenClaims?.extension_PID;
		const isAirBank =
			result?.account?.idTokenClaims?.partner_code === "AirBank";

		useEffect(() => {
			if (
				inProgress === InteractionStatus.None &&
				result &&
				getIsUserNotFound(result)
			) {
				void navigate("/uzivatel-nenalezen/");
			}
		}, [inProgress, result]);

		useEffect(() => {
			// attempt silent login to refresh token
			if (!isAuth && inProgress === InteractionStatus.None) {
				if (lastUsedAuthority)
					void login(
						InteractionType.Redirect,
						getAuthRequest(lastUsedAuthority)
					);
				else void navigate("/prihlaseni-nebo-registrace/");
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isAuth, inProgress]);

		useEffect(() => {
			if (isPID && isSuccess && !isFetching) {
				setupInitialConsentDrawer(data, isAirBank);
			}
		}, [isPID, isSuccess, isFetching, isAirBank, data]);

		if (
			inProgress !== InteractionStatus.None ||
			!result ||
			getIsUserNotFound(result) ||
			isLoading ||
			(data?.startElectronicContactsConfirmation &&
				url &&
				url !== "jsou-vase-udaje-spravne" &&
				url !== "uvitaci-obrazovka-airbank")
		) {
			return (
				<Stack sx={{ px: { md: 4, xxs: 0 } }}>{cs.global.pageIsLoading}</Stack>
			);
		}

		return (
			<>
				{instance.getActiveAccount() && isAuth && (
					<>
						<PageComponent {...props} />
						<DrawerManager
							disableAgreementDrawer={
								url === "uvitaci-obrazovka-airbank" ||
								EnvironmentHelper.isLocal()
							}
							disableAmlAndBankIdDrawers={
								process.env.GATSBY_FEATURE_IS_AML_AND_BANK_ID_ENABLED !== "true"
							}
						/>
					</>
				)}
			</>
		);
	};
}
