/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChangesDisabledReason } from './ChangesDisabledReason';
import {
    ChangesDisabledReasonFromJSON,
    ChangesDisabledReasonFromJSONTyped,
    ChangesDisabledReasonToJSON,
} from './ChangesDisabledReason';
import type { PensionPendingChange } from './PensionPendingChange';
import {
    PensionPendingChangeFromJSON,
    PensionPendingChangeFromJSONTyped,
    PensionPendingChangeToJSON,
} from './PensionPendingChange';
import type { ProductType } from './ProductType';
import {
    ProductTypeFromJSON,
    ProductTypeFromJSONTyped,
    ProductTypeToJSON,
} from './ProductType';

/**
 * 
 * @export
 * @interface AllowedChangesItem
 */
export interface AllowedChangesItem {
    /**
     * 
     * @type {string}
     * @memberof AllowedChangesItem
     */
    contractId: string;
    /**
     * 
     * @type {ProductType}
     * @memberof AllowedChangesItem
     */
    productType: ProductType;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    hasAnyFailedChanges: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    isElectronicContactsChangeInProgress: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    isContactAddressChangeInProgress: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    canChangeEmployerOrClientContribution: boolean;
    /**
     * 
     * @type {string}
     * @memberof AllowedChangesItem
     */
    contributionDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    canChangeContactAddress: boolean;
    /**
     * 
     * @type {string}
     * @memberof AllowedChangesItem
     */
    contactAddressDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllowedChangesItem
     */
    beneficiariesDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    canChangeElectronicContacts: boolean;
    /**
     * 
     * @type {string}
     * @memberof AllowedChangesItem
     */
    electronicContactsDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    canChangeTaxOptimum: boolean;
    /**
     * 
     * @type {string}
     * @memberof AllowedChangesItem
     */
    taxOptimumDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    canChangeAmlIdentification: boolean;
    /**
     * 
     * @type {string}
     * @memberof AllowedChangesItem
     */
    amlIdentificationDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    canChangeIsEmployerContributing: boolean;
    /**
     * 
     * @type {string}
     * @memberof AllowedChangesItem
     */
    isEmployerContributingDescription?: string | null;
    /**
     * 
     * @type {Array<PensionPendingChange>}
     * @memberof AllowedChangesItem
     */
    pendingChanges?: Array<PensionPendingChange> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    canChangeInvestmentStrategy?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedChangesItem
     */
    canChangeBeneficiaries?: boolean | null;
    /**
     * 
     * @type {Array<ChangesDisabledReason>}
     * @memberof AllowedChangesItem
     */
    reasons?: Array<ChangesDisabledReason> | null;
}

/**
 * Check if a given object implements the AllowedChangesItem interface.
 */
export function instanceOfAllowedChangesItem(value: object): value is AllowedChangesItem {
    if (!('contractId' in value) || value['contractId'] === undefined) return false;
    if (!('productType' in value) || value['productType'] === undefined) return false;
    if (!('hasAnyFailedChanges' in value) || value['hasAnyFailedChanges'] === undefined) return false;
    if (!('isElectronicContactsChangeInProgress' in value) || value['isElectronicContactsChangeInProgress'] === undefined) return false;
    if (!('isContactAddressChangeInProgress' in value) || value['isContactAddressChangeInProgress'] === undefined) return false;
    if (!('canChangeEmployerOrClientContribution' in value) || value['canChangeEmployerOrClientContribution'] === undefined) return false;
    if (!('canChangeContactAddress' in value) || value['canChangeContactAddress'] === undefined) return false;
    if (!('canChangeElectronicContacts' in value) || value['canChangeElectronicContacts'] === undefined) return false;
    if (!('canChangeTaxOptimum' in value) || value['canChangeTaxOptimum'] === undefined) return false;
    if (!('canChangeAmlIdentification' in value) || value['canChangeAmlIdentification'] === undefined) return false;
    if (!('canChangeIsEmployerContributing' in value) || value['canChangeIsEmployerContributing'] === undefined) return false;
    return true;
}

export function AllowedChangesItemFromJSON(json: any): AllowedChangesItem {
    return AllowedChangesItemFromJSONTyped(json, false);
}

export function AllowedChangesItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllowedChangesItem {
    if (json == null) {
        return json;
    }
    return {
        
        'contractId': json['contractId'],
        'productType': ProductTypeFromJSON(json['productType']),
        'hasAnyFailedChanges': json['hasAnyFailedChanges'],
        'isElectronicContactsChangeInProgress': json['isElectronicContactsChangeInProgress'],
        'isContactAddressChangeInProgress': json['isContactAddressChangeInProgress'],
        'canChangeEmployerOrClientContribution': json['canChangeEmployerOrClientContribution'],
        'contributionDescription': json['contributionDescription'] == null ? undefined : json['contributionDescription'],
        'canChangeContactAddress': json['canChangeContactAddress'],
        'contactAddressDescription': json['contactAddressDescription'] == null ? undefined : json['contactAddressDescription'],
        'beneficiariesDescription': json['beneficiariesDescription'] == null ? undefined : json['beneficiariesDescription'],
        'canChangeElectronicContacts': json['canChangeElectronicContacts'],
        'electronicContactsDescription': json['electronicContactsDescription'] == null ? undefined : json['electronicContactsDescription'],
        'canChangeTaxOptimum': json['canChangeTaxOptimum'],
        'taxOptimumDescription': json['taxOptimumDescription'] == null ? undefined : json['taxOptimumDescription'],
        'canChangeAmlIdentification': json['canChangeAmlIdentification'],
        'amlIdentificationDescription': json['amlIdentificationDescription'] == null ? undefined : json['amlIdentificationDescription'],
        'canChangeIsEmployerContributing': json['canChangeIsEmployerContributing'],
        'isEmployerContributingDescription': json['isEmployerContributingDescription'] == null ? undefined : json['isEmployerContributingDescription'],
        'pendingChanges': json['pendingChanges'] == null ? undefined : ((json['pendingChanges'] as Array<any>).map(PensionPendingChangeFromJSON)),
        'canChangeInvestmentStrategy': json['canChangeInvestmentStrategy'] == null ? undefined : json['canChangeInvestmentStrategy'],
        'canChangeBeneficiaries': json['canChangeBeneficiaries'] == null ? undefined : json['canChangeBeneficiaries'],
        'reasons': json['reasons'] == null ? undefined : ((json['reasons'] as Array<any>).map(ChangesDisabledReasonFromJSON)),
    };
}

export function AllowedChangesItemToJSON(value?: AllowedChangesItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractId': value['contractId'],
        'productType': ProductTypeToJSON(value['productType']),
        'hasAnyFailedChanges': value['hasAnyFailedChanges'],
        'isElectronicContactsChangeInProgress': value['isElectronicContactsChangeInProgress'],
        'isContactAddressChangeInProgress': value['isContactAddressChangeInProgress'],
        'canChangeEmployerOrClientContribution': value['canChangeEmployerOrClientContribution'],
        'contributionDescription': value['contributionDescription'],
        'canChangeContactAddress': value['canChangeContactAddress'],
        'contactAddressDescription': value['contactAddressDescription'],
        'beneficiariesDescription': value['beneficiariesDescription'],
        'canChangeElectronicContacts': value['canChangeElectronicContacts'],
        'electronicContactsDescription': value['electronicContactsDescription'],
        'canChangeTaxOptimum': value['canChangeTaxOptimum'],
        'taxOptimumDescription': value['taxOptimumDescription'],
        'canChangeAmlIdentification': value['canChangeAmlIdentification'],
        'amlIdentificationDescription': value['amlIdentificationDescription'],
        'canChangeIsEmployerContributing': value['canChangeIsEmployerContributing'],
        'isEmployerContributingDescription': value['isEmployerContributingDescription'],
        'pendingChanges': value['pendingChanges'] == null ? undefined : ((value['pendingChanges'] as Array<any>).map(PensionPendingChangeToJSON)),
        'canChangeInvestmentStrategy': value['canChangeInvestmentStrategy'],
        'canChangeBeneficiaries': value['canChangeBeneficiaries'],
        'reasons': value['reasons'] == null ? undefined : ((value['reasons'] as Array<any>).map(ChangesDisabledReasonToJSON)),
    };
}

