import { TriggerButton } from "../../Link";
import { useDrawer } from "../context";
import { NextButtonProps } from "../NextButton";

const NextButton = ({
	numberOfSteps,
	customFunction,
	label,
	onCloseCallback,
	...rest
}: NextButtonProps & { onCloseCallback?: () => void }): JSX.Element => {
	const { hideMultistepDrawer, step, nextStep } = useDrawer();

	const handleClick = async (finalFunction: () => void) => {
		if (customFunction) {
			/* MDN OK:
			https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/await
			and it is intended here */
			// eslint-disable-next-line
			const final = await customFunction();
			final && finalFunction();
		} else {
			finalFunction();
		}
	};

	if (step < numberOfSteps - 1)
		return (
			<TriggerButton
				data-test="multistep-drawer-next-button"
				onClick={() => handleClick(nextStep)}
				{...rest}
			>
				{label ? label : "Pokračovat"}
			</TriggerButton>
		);
	else
		return (
			<TriggerButton
				data-test="multistep-drawer-confirm-button"
				onClick={() => {
					onCloseCallback && onCloseCallback();
					handleClick(hideMultistepDrawer);
				}}
				{...rest}
			>
				{label ? label : "Rozumím"}
			</TriggerButton>
		);
};

export default NextButton;
