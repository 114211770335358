/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PendingChangeDetail
 */
export interface PendingChangeDetail {
    /**
     * Will only be included when ChangeType is ChangeContribution
     * @type {number}
     * @memberof PendingChangeDetail
     */
    readonly newClientContribution?: number | null;
    /**
     * Will only be included when ChangeType is ChangeContribution
     * @type {boolean}
     * @memberof PendingChangeDetail
     */
    readonly willEmployerContribute?: boolean | null;
}

/**
 * Check if a given object implements the PendingChangeDetail interface.
 */
export function instanceOfPendingChangeDetail(value: object): value is PendingChangeDetail {
    return true;
}

export function PendingChangeDetailFromJSON(json: any): PendingChangeDetail {
    return PendingChangeDetailFromJSONTyped(json, false);
}

export function PendingChangeDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PendingChangeDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'newClientContribution': json['newClientContribution'] == null ? undefined : json['newClientContribution'],
        'willEmployerContribute': json['willEmployerContribute'] == null ? undefined : json['willEmployerContribute'],
    };
}

export function PendingChangeDetailToJSON(value?: Omit<PendingChangeDetail, 'newClientContribution'|'willEmployerContribute'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

