import Box from "@mui/material/Box";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import { ErrorMessage, FieldAttributes, useField } from "formik";
import { ChangeEvent, FC } from "react";

import Checkbox0 from "../../../Icons/Checkbox0";
import Checkbox1 from "../../../Icons/Checkbox1";
import FormErrorMessage from "../../FormErrorMessage";

export type onCheckboxInputChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface CheckboxInputProps
	extends FieldAttributes<any>,
		CheckboxProps {
	disabled?: boolean;
	error?: boolean;
	name: string;
	isRequired?: boolean;
	labelProps?: InputLabelProps;
}

const CheckboxInput: FC<CheckboxInputProps> = ({
	disabled,
	error,
	name,
	label,
	isRequired,
	labelProps = {},
	...rest
}) => {
	const { sx: labelSx, ...labelRest } = labelProps;
	const [field] = useField(name);
	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Checkbox
					className={`input ${error ? "invalid" : ""}`}
					required={isRequired}
					id={`${name}-name`}
					icon={
						<Checkbox0
							sx={{ width: "3.2rem", height: "3.2rem" }}
							color="grey"
						/>
					}
					checkedIcon={<Checkbox1 sx={{ width: "3.2rem", height: "3.2rem" }} />}
					disabled={disabled}
					disableRipple
					checked={field.value}
					{...field}
					{...rest}
				/>
				<InputLabel
					shrink
					htmlFor={`${name}-name`}
					sx={{
						mb: 0,
						pl: 3,
						py: 3,
						cursor: "pointer",
						whiteSpace: "normal",
						...labelSx,
					}}
					required={isRequired}
					{...labelRest}
				>
					{label}
				</InputLabel>
			</Box>
			<Box>
				<ErrorMessage
					name={name}
					render={(msg) => (
						<FormErrorMessage name={name}>{msg}</FormErrorMessage>
					)}
				/>
			</Box>
		</Box>
	);
};

export default CheckboxInput;
