import SvgIcon from "@mui/material/SvgIcon";

const Error = (props): JSX.Element => {
	return (
		<SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
			<path d="M21.4 19.3 12.3 1.4v-.1l-.4-.5c-.2-.1-.5-.3-.8-.3-.3 0-.6.1-.8.3-.3.2-.4.4-.5.6L.7 19.3l-.2.7c0 .2 0 .5.3.8.2.3.4.4.6.5.3.2.5.2.7.2h16.7c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H1.9l-.2-.1-.1-.1v-.1l.1-.3L10.8 2v-.1l.1-.2.2-.2.1.1.2.3 9.1 17.9c.1.2.4.3.7.2.3-.2.4-.5.2-.7Z" />
			<path d="M11.6 15.3V7.6c0-.3-.2-.5-.5-.5s-.5.2-.5.5v7.7c0 .3.2.5.5.5s.5-.2.5-.5Zm-1.4 2.5c0 .6.4 1 .9 1s.9-.4.9-.9-.4-.9-.9-1c-.5 0-.9.4-.9.9Zm.9 0c.1.1 0 .1 0 .1s-.1 0 0-.1Z" />
		</SvgIcon>
	);
};

export default Error;
