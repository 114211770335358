import { Paper, Skeleton, Stack } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useFormikContext } from "formik";
import { Dispatch, SetStateAction } from "react";

import { Address, ContactAddress, ProductType } from "../../../models";
import { useContactAdresses, useProfile } from "../../queryHooks";
import cs from "../../translations/cs.json";
import AddressTemplate from "../AddressBox/AddressTemplate";
import countries from "../Form/countries.json";
import Radio from "../Form/Input/Radio";
import RadioGroup from "../Form/Input/Radio/RadioGroup";
import Switch from "../Form/Input/Switch";
import TextInput from "../Form/Input/Text";
import Autocomplete from "../Form/Select/Autocomplete";
import { BodyTextM, HeadingL, HeadingM } from "../Typography";

const ChangeAddress = ({
	contractNumber,
	title,
	comesValidCountryCodeFromApi,
	setComesValidCountryCodeFromApi,
}: ContactAddress & {
	comesValidCountryCodeFromApi: boolean;
	setComesValidCountryCodeFromApi: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
	const nullCountry = { label: "-", value: "" };
	const { values, setFieldValue } = useFormikContext();
	const addresses = useContactAdresses();
	const profileResult = useProfile();

	const fillAddress = (item: Address) => {
		setFieldValue("street", item.street);
		setFieldValue("houseNumber", item.houseNumber);
		setFieldValue("zip", item.zip);
		setFieldValue("town", item.town);
		setFieldValue("country", item.country);
		setComesValidCountryCodeFromApi(item.country !== "");
	};

	const clearAddress = () => {
		setFieldValue("street", "");
		setFieldValue("houseNumber", "");
		setFieldValue("zip", "");
		setFieldValue("town", "");
		setFieldValue("country", "");
	};

	return (
		<Stack spacing={4}>
			<Stack>
				<HeadingL>{cs.global.fillNewInfo}</HeadingL>
				<HeadingM sx={{ mb: 1 }}>{cs.global.contactAddress}</HeadingM>
				<BodyTextM>
					{title} ({contractNumber})
				</BodyTextM>
			</Stack>
			{!addresses.isLoading &&
				addresses.data?.contactAddresses &&
				addresses.data?.contactAddresses.length > 1 &&
				addresses.data?.isGlobalContactAddressChangeEnabled && (
					<Paper sx={{ p: 4 }}>
						<Switch
							label={cs.profile.person.changeAddressDrawer.changeAllAddresses}
							id="changeAllAddresses"
							name="changeAllAddresses"
						/>
					</Paper>
				)}
			<Stack spacing={5}>
				<Paper
					sx={{
						py: 4,
						px: 3,
					}}
				>
					<RadioGroup name={"addressType"}>
						{profileResult?.data?.permanentAddresses?.map((address, i) => {
							return (
								<Radio
									name="addressType"
									value={`permanent${i}`}
									id={`permanent${i}`}
									key={i}
									label={cs.profile.person.changeAddressDrawer.sameAsPermanent}
									subLabel={<AddressTemplate address={address} />}
									onClick={() => fillAddress(address)}
								/>
							);
						})}
						{addresses.isSuccess ? (
							addresses.data.contactAddresses.map((item, i) => {
								const address = item.address;
								if (contractNumber !== item.contractNumber)
									// don't let user select the same address as he is changing
									return (
										<Radio
											name="addressType"
											value={`contact-${item.contractNumber}`}
											id={`contact-${item.contractNumber}`}
											key={i}
											label={
												item.type === ProductType.Cl
													? `${cs.profile.person.changeAddressDrawer.sameAsLife} - ${item.title} (${item.contractNumber})`
													: cs.profile.person.changeAddressDrawer.sameAsPension
											}
											subLabel={<AddressTemplate address={address} />}
											onClick={() => fillAddress(address)}
										/>
									);
							})
						) : (
							<Stack p={4}>
								<Skeleton />
								<Skeleton />
								<Skeleton />
							</Stack>
						)}
						<Radio
							name="addressType"
							value={"other"}
							id={"other"}
							label={cs.profile.person.changeAddressDrawer.anotherAddress}
							onClick={() => {
								clearAddress();
							}}
						/>
					</RadioGroup>
				</Paper>
				{(values.addressType === "other" ||
					comesValidCountryCodeFromApi === false) && (
					<Paper
						sx={{
							p: 4,
						}}
					>
						<Stack spacing={5}>
							<TextInput
								name={"street"}
								label={cs.global.street}
								disabled={false}
							/>
							<TextInput
								name={"houseNumber"}
								label={cs.global.houseNumber}
								disabled={false}
							/>
							<TextInput name={"zip"} label={cs.global.zip} disabled={false} />
							<TextInput
								name={"town"}
								label={cs.global.city}
								disabled={false}
							/>
							<Autocomplete
								label={cs.global.country}
								name={`country`}
								options={[
									...countries.map((country) => {
										return {
											label: country.country_name_cz,
											value: country.country_code_a3,
										};
									}),
								]}
								getOptionLabel={(option) => option.value}
								filterOptions={(options, state) => {
									const filteredOptions = createFilterOptions({
										stringify: (option) => option.value + option.label, // whisper options by values and also labels
									})(options, state);
									return [nullCountry, ...filteredOptions];
								}}
								renderOption={(props, option) => (
									<Box component="li" {...props}>
										{option.label}
									</Box>
								)}
								noOptionsText={cs.global.noCountriesFound}
							/>
						</Stack>
					</Paper>
				)}
			</Stack>
		</Stack>
	);
};

export default ChangeAddress;
