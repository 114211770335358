/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GenderEnum = {
    Male: 'Male',
    Female: 'Female',
    Unknown: 'Unknown'
} as const;
export type GenderEnum = typeof GenderEnum[keyof typeof GenderEnum];


export function instanceOfGenderEnum(value: any): boolean {
    for (const key in GenderEnum) {
        if (Object.prototype.hasOwnProperty.call(GenderEnum, key)) {
            if ((GenderEnum as Record<string, GenderEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function GenderEnumFromJSON(json: any): GenderEnum {
    return GenderEnumFromJSONTyped(json, false);
}

export function GenderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderEnum {
    return json as GenderEnum;
}

export function GenderEnumToJSON(value?: GenderEnum | null): any {
    return value as any;
}

