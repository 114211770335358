/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ZZJInformationRequest } from './ZZJInformationRequest';
import {
    ZZJInformationRequestFromJSON,
    ZZJInformationRequestFromJSONTyped,
    ZZJInformationRequestToJSON,
} from './ZZJInformationRequest';

/**
 * 
 * @export
 * @interface GetZZJPreviewRequest
 */
export interface GetZZJPreviewRequest {
    /**
     * 
     * @type {ZZJInformationRequest}
     * @memberof GetZZJPreviewRequest
     */
    zzjInformation: ZZJInformationRequest;
}

/**
 * Check if a given object implements the GetZZJPreviewRequest interface.
 */
export function instanceOfGetZZJPreviewRequest(value: object): value is GetZZJPreviewRequest {
    if (!('zzjInformation' in value) || value['zzjInformation'] === undefined) return false;
    return true;
}

export function GetZZJPreviewRequestFromJSON(json: any): GetZZJPreviewRequest {
    return GetZZJPreviewRequestFromJSONTyped(json, false);
}

export function GetZZJPreviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetZZJPreviewRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'zzjInformation': ZZJInformationRequestFromJSON(json['zzjInformation']),
    };
}

export function GetZZJPreviewRequestToJSON(value?: GetZZJPreviewRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'zzjInformation': ZZJInformationRequestToJSON(value['zzjInformation']),
    };
}

