import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import cs from "../../translations/cs.json";
import {
	DistributionProps,
	fundsDistributionDefault,
} from "../DPS/ChangeSavingsStrategy";
import { BodyTextS, HeadingS } from "../Typography";

const FundsDescription = ({
	allocation = fundsDistributionDefault,
}: DistributionProps): JSX.Element => (
	<Stack spacing={4}>
		{allocation.map((fund, i) =>
			fund.fundCode !== undefined ? (
				<Paper
					sx={{
						p: { md: 5, xxs: 4 },
					}}
					key={fund.fundCode}
				>
					<HeadingS mb={0}>
						{cs.investmentStrategy.fundsNames[fund.fundCode]}
					</HeadingS>
					<BodyTextS mb={0} light>
						{cs.investmentStrategy.fundsRisk[fund.fundCode]}
					</BodyTextS>
				</Paper>
			) : (
				<Paper
					sx={{
						p: { md: 5, xxs: 4 },
					}}
					key={i}
				>
					<Skeleton variant="rectangular" />
					<Skeleton variant="rectangular" />
				</Paper>
			)
		)}
	</Stack>
);

export default FundsDescription;
