import { AppBarProps as BarProps, default as Bar } from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import { ReactNode } from "react";

import { BackNavigationBar, LoggedInBar, LoggedOutBar } from "./Bars";

export { BackNavigationBar, LoggedInBar, LoggedOutBar };
export interface AppBarProps extends BarProps {
	children: ReactNode;
}

const AppBar = ({ children, sx }: AppBarProps): JSX.Element => {
	return (
		<Bar
			sx={{
				position: { md: "static", xxs: "sticky" },
				zIndex: { md: 0, xxs: (theme) => theme.zIndex.drawer },
				pr: "0 !important", // workaround for bug in MUI: https://github.com/mui/material-ui/issues/10000, https://github.com/mui/material-ui/issues/17353
				boxShadow: "far",
				backgroundColor: "white.main",
				...sx,
			}}
		>
			<Container maxWidth="md" sx={{ px: 4 }} disableGutters>
				{children}
			</Container>
		</Bar>
	);
};

export default AppBar;
