import { Stack } from "@mui/material";
import { useState } from "react";

import { useContractId } from "../../context/ContractIDContext";
import { useContractTypeContext } from "../../context/ContractTypeContext";
import { usePensionDocuments } from "../../queryHooks";
import DocumentManager from "../DocumentManager";
import DocumentsError from "../DocumentManager/DocumentsError";
import { ErrorBoundaryWithAsyncContext } from "../ErrorBoundary";
import HelpBox from "../InfoBox/HelpBox";
import { Tab } from "../Tabs";

export function Docs(): JSX.Element {
	const id = useContractId();
	const { contractType } = useContractTypeContext();
	const [docsEndpointErrorCode, setDocsEndpointErrorCode] = useState();

	const result = usePensionDocuments({
		id: id,
		type: contractType,
		options: {
			onError: (error) => setDocsEndpointErrorCode(error.errorResponse.code),
		},
	});

	if (result.isSuccess) return <DocumentManager data={result.data} />;

	if (result.isError)
		return <DocumentsError errorCode={docsEndpointErrorCode} />;

	return <DocumentManager isLoading />;
}

const DocumentTab = (): JSX.Element => {
	return (
		<Tab>
			<ErrorBoundaryWithAsyncContext>
				<Stack
					sx={{
						mt: { md: 7 },
					}}
				>
					<Docs />
				</Stack>
			</ErrorBoundaryWithAsyncContext>
			<HelpBox />
		</Tab>
	);
};

export default DocumentTab;
