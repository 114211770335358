/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ChangeType = {
    Unknown: 'Unknown',
    AskForContact: 'AskForContact',
    ChangeElectronicContacts: 'ChangeElectronicContacts',
    ChangeContactAddress: 'ChangeContactAddress',
    ChangeNewDpsResources: 'ChangeNewDPSResources',
    ChangeExistingDpsResources: 'ChangeExistingDPSResources',
    ChangeNewAndExistingDpsResources: 'ChangeNewAndExistingDPSResources',
    SetContactDetails: 'SetContactDetails',
    SetDeliveryAddress: 'SetDeliveryAddress',
    SetPermanentAddress: 'SetPermanentAddress',
    SetMarketingConsent: 'SetMarketingConsent',
    SetDsFunds: 'SetDsFunds',
    SetFunds: 'SetFunds',
    SetClientIdentEmail: 'SetClientIdentEmail',
    SetClientIdentMsisdn: 'SetClientIdentMsisdn',
    SetContractTransStmtDelivery: 'SetContractTransStmtDelivery',
    SetContractTaxStmtDelivery: 'SetContractTaxStmtDelivery',
    SetContractMonthlyPaymentOwner: 'SetContractMonthlyPaymentOwner',
    SetContractPaymentFrequency: 'SetContractPaymentFrequency',
    SetContractIsTaxOptimum: 'SetContractIsTaxOptimum',
    SetContractTaxDeductable: 'SetContractTaxDeductable',
    SetContractVoluntaryPayIn: 'SetContractVoluntaryPayIn',
    SetContractEmployer: 'SetContractEmployer',
    SetTaxStmtDelivery: 'SetTaxStmtDelivery',
    SetPaymentType: 'SetPaymentType',
    SetInflation: 'SetInflation',
    SetBeneficiaries: 'SetBeneficiaries',
    SetEmailNotification: 'SetEmailNotification',
    UnsetClientIdent: 'UnsetClientIdent',
    SetAmlIdentification: 'SetAmlIdentification'
} as const;
export type ChangeType = typeof ChangeType[keyof typeof ChangeType];


export function instanceOfChangeType(value: any): boolean {
    for (const key in ChangeType) {
        if (Object.prototype.hasOwnProperty.call(ChangeType, key)) {
            if ((ChangeType as Record<string, ChangeType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ChangeTypeFromJSON(json: any): ChangeType {
    return ChangeTypeFromJSONTyped(json, false);
}

export function ChangeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeType {
    return json as ChangeType;
}

export function ChangeTypeToJSON(value?: ChangeType | null): any {
    return value as any;
}

