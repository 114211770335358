/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PostLoginResponse,
} from '../models/index';
import {
    PostLoginResponseFromJSON,
    PostLoginResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class PostLoginApi extends runtime.BaseAPI {

    /**
     * Put confirmation of electronic contacts for the current user.
     */
    async confirmContactsPutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/post-login/confirm-contacts`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Put confirmation of electronic contacts for the current user.
     */
    async confirmContactsPut(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmContactsPutRaw(initOverrides);
    }

    /**
     * Get initial, post login, actions for the current user.
     */
    async getPostLoginInfoGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/post-login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLoginResponseFromJSON(jsonValue));
    }

    /**
     * Get initial, post login, actions for the current user.
     */
    async getPostLoginInfoGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLoginResponse> {
        const response = await this.getPostLoginInfoGetRaw(initOverrides);
        return await response.value();
    }

}
