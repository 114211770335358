/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentFrequency = {
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    HalfYearly: 'HalfYearly',
    Yearly: 'Yearly',
    Other: 'Other'
} as const;
export type PaymentFrequency = typeof PaymentFrequency[keyof typeof PaymentFrequency];


export function instanceOfPaymentFrequency(value: any): boolean {
    for (const key in PaymentFrequency) {
        if (Object.prototype.hasOwnProperty.call(PaymentFrequency, key)) {
            if ((PaymentFrequency as Record<string, PaymentFrequency>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PaymentFrequencyFromJSON(json: any): PaymentFrequency {
    return PaymentFrequencyFromJSONTyped(json, false);
}

export function PaymentFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFrequency {
    return json as PaymentFrequency;
}

export function PaymentFrequencyToJSON(value?: PaymentFrequency | null): any {
    return value as any;
}

