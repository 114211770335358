import cs from "../../translations/cs.json";
import { BodyTextXS } from "../Typography";

const Version = () => {
	return (
		<BodyTextXS
			sx={{
				mb: 0,
				px: { md: 0, xs: 4 },
				color: "grey.main",
				textAlign: "right",
			}}
		>{`${cs.global.portalVersion} ${process.env.GATSBY_VERSION}`}</BodyTextXS>
	);
};

export default Version;
