import { Box, Paper, Skeleton, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { ReactNode } from "react";

import { BodyTextM, HeadingL } from "../Typography";
import Chart from "./Pie";
import { PieChartProps } from "./props";

interface ChartCardProps {
	title: string;
	subTitle: ReactNode;
	chartData: Array<PieChartProps>;
	total: number;
}

const PieChartCard = ({
	title,
	subTitle,
	chartData,
	total,
}: ChartCardProps): JSX.Element => {
	const isDesktop = useIsDesktop();
	return (
		<Box>
			<HeadingL withoutScale>{title}</HeadingL>
			<Paper
				elevation={isDesktop ? 4 : 0}
				sx={{ py: { md: 4 }, px: { md: 5 } }}
			>
				<BodyTextM>{subTitle}</BodyTextM>
				{!chartData ? (
					<>
						<Skeleton
							variant="circular"
							height={316}
							width={316}
							sx={{ mt: 7, mx: "auto" }}
						/>
						<Stack spacing={4} sx={{ mt: 7 }}>
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
						</Stack>
					</>
				) : (
					total !== 0 && <Chart data={chartData} />
				)}
			</Paper>
		</Box>
	);
};

export default PieChartCard;
