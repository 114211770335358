import { graphql, useStaticQuery } from "gatsby";

import QuestionIcon from "../Icons/Question";
import { InternalArrowLink } from "../Link/InternalLink";
import { OverviewBase } from "../Overview";
import InfoBox, { AlertProps } from ".";

const HelpBox = ({ noImage, ...rest }: AlertProps): JSX.Element => {
	interface imageProps {
		intro_image: {
			publicURL: string;
		};
	}

	const introImage = (): imageProps => {
		return useStaticQuery<Queries.HelpBoxHelpBoxImageQuery>(
			graphql`
				query HelpBoxImage {
					image: file(relativePath: { eq: "helpbox.png" }) {
						publicURL
					}
				}
			`
		);
	};
	return (
		<OverviewBase
			sx={{
				flexGrow: { md: noImage ? 0 : 1 },
				width: { md: noImage ? "100%" : "57.5%", xxs: "100%" },
				"&.MuiCard-root": {
					alignSelf: "center",
					px: { md: noImage ? 0 : 4, xxs: 0 },
				},
				".MuiCardMedia-root": {
					mt: { md: 8 },
					pb: { md: noImage ? 0 : "174px", xxs: 0 },
					backgroundPosition: "bottom center",
					backgroundSize: "338px",
				},
			}}
			image={!noImage ? introImage().image.publicURL : undefined}
		>
			<InfoBox
				title="Potřebujete upravit smlouvu nebo poradit?"
				action={
					<InternalArrowLink to="/kontakty/">
						Zobrazit kontakty
					</InternalArrowLink>
				}
				icon={<QuestionIcon sx={{ width: 32, height: 32 }} color="text" />}
				{...rest}
			>
				Obraťte se na svého poradce nebo na naše operátory. Přesně pro tohle tu
				jsou.
			</InfoBox>
		</OverviewBase>
	);
};

export default HelpBox;
