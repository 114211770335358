import { LoadingButton } from "@mui/lab";
import { Box, Stack } from "@mui/material";
import { ErrorMessage, Formik, useFormikContext } from "formik";

import { ProductType } from "../../../../models";
import { usePrimaryAPIClient } from "../../../context/PrimaryAPIClient";
import cs from "../../../translations/cs.json";
import { ContractType } from "../../../types/contracts";
import ModalFooter from "../../Drawer/ModalFooter";
import { MultiStepChildren } from "../../Drawer/MultiStepDrawer";
import countries from "../../Form/countries.json";
import FormErrorMessage from "../../Form/FormErrorMessage";
import CheckboxInput from "../../Form/Input/Checkbox";
import RadioInput from "../../Form/Input/Radio";
import RadioGroup from "../../Form/Input/Radio/RadioGroup";
import TextInput from "../../Form/Input/Text";
import Select from "../../Form/Select";
import { BodyTextM, HeadingS, HeadingXS } from "../../Typography";
import { AMLIncomeSource } from "..";
import AverageIncome from "../Numbers/AverageIncome.json";
import BusinessIncomeSources from "../Numbers/BusinessIncome.json";
import OtherIncomeSources from "../Numbers/OtherIncome.json";
import PropertySources from "../Numbers/SourceOfProperty.json";
import { AMLFormDefaultValues, IAMLFormValues, mapAMLRequest } from ".";
import { getIncomeSourceInfo } from "./helpers";
import { AMLQuestionsSchema } from "./schema";

export interface IAMLQuestions {
	helpText: string;
}

export const AMLQuestions = ({ helpText }: IAMLQuestions) => {
	const {
		values: {
			businessIncomeId,
			employmentType,
			otherIncomeId,
			usaResident,
			greenCardHolder,
			politicallyExposed,
			taxResidence,
			propertySources: propertySourcesField,
		},
		setFieldValue,
	} = useFormikContext<IAMLFormValues>();
	const incomeSourceInfo = getIncomeSourceInfo({
		employmentType,
		businessIncomeId,
		otherIncomeId,
	});
	const specifyIncome = incomeSourceInfo?.InfoRequired && employmentType;
	return (
		<Stack gap={4}>
			<HeadingS marginBottom={0}>{cs.AML.amlReasoning.greeting}</HeadingS>
			<BodyTextM>{helpText}</BodyTextM>
			<CheckboxInput
				name={"usaResident"}
				label={"Jsem daňovým rezidentem USA"}
				checked={usaResident}
				onChange={(_, checked) => {
					setFieldValue("usaResident", checked);
				}}
			/>
			<CheckboxInput
				name={"greenCardHolder"}
				checked={greenCardHolder}
				label={
					"Jsem držitelem zelené karty nebo je můj investiční poradce či zástupce americká osoba."
				}
				onChange={(_, checked) => {
					setFieldValue("greenCardHolder", checked);
				}}
			/>
			<CheckboxInput
				name={"politicallyExposed"}
				checked={politicallyExposed}
				label={"Jsem politicky exponovanou osobou."}
				onChange={(_, checked) => {
					setFieldValue("politicallyExposed", checked);
				}}
			/>
			<Select name={"taxResidence"} label={"Země kde platíte daně"} isRequired>
				{countries.map((country) => (
					<option key={country.country_code_a3} value={country.country_code_a3}>
						{country.country_name_cz}
					</option>
				))}
			</Select>
			{taxResidence !== "CZE" && (
				<TextInput label={"DIČ"} name="taxNumber" isRequired />
			)}
			<HeadingXS marginBottom={0}>
				{cs.AML.amlQuestionaire.incomeSource.label}
			</HeadingXS>
			<RadioGroup name="employmentType">
				<RadioInput
					name={"employmentType"}
					value={AMLIncomeSource.Employment}
					id="employment"
					label={cs.AML.amlQuestionaire.incomeSource.employment}
				/>
				<RadioInput
					name={"employmentType"}
					value={AMLIncomeSource.Business}
					id="business"
					label={cs.AML.amlQuestionaire.incomeSource.business}
				/>
				<RadioInput
					name={"employmentType"}
					value={AMLIncomeSource.Other}
					id="other"
					label={cs.AML.amlQuestionaire.incomeSource.other}
				/>
			</RadioGroup>
			{employmentType === AMLIncomeSource.Business && (
				<Select
					name={"businessIncomeId"}
					label={cs.AML.amlQuestionaire.incomeSource.businessArea}
					isRequired
				>
					<option key={"placeholder"} value={""}>
						{cs.global.pleaseSelectAnOption}
					</option>
					{BusinessIncomeSources.map((businessType) => (
						<option key={businessType.Id} value={businessType.Job}>
							{businessType.Job}
						</option>
					))}
				</Select>
			)}
			{employmentType === AMLIncomeSource.Other && (
				<Select
					name={"otherIncomeId"}
					label={cs.AML.amlQuestionaire.incomeSource.specifiyIncomeSource}
					isRequired
				>
					<option key={"placeholder"} value={""}>
						{cs.global.pleaseSelectAnOption}
					</option>
					{OtherIncomeSources.map((otherIncomeId) => (
						<option key={otherIncomeId.Id} value={otherIncomeId.Job}>
							{otherIncomeId.Job}
						</option>
					))}
				</Select>
			)}
			{specifyIncome && (
				<TextInput
					label={cs.AML.amlQuestionaire.specify}
					name={`incomeSourceSpecification`}
					required
					isRequired
				/>
			)}
			<HeadingXS marginBottom={0}>
				{cs.AML.amlQuestionaire.propertySource.label}
			</HeadingXS>
			<Stack>
				{PropertySources.map((propertySource) => (
					<CheckboxInput
						key={propertySource.Id}
						name={`propertySources.${propertySource.Name}`}
						label={propertySource.Name}
						checked={propertySourcesField[propertySource.Name]}
						onChange={(_, checked) => {
							setFieldValue(`propertySources.${propertySource.Name}`, checked);
						}}
					/>
				))}
				<ErrorMessage
					name="propertySources"
					render={(msg) => (
						<FormErrorMessage name="ratios">{msg}</FormErrorMessage>
					)}
				/>
				{propertySourcesField["Jiné"] && (
					<TextInput
						label={cs.AML.amlQuestionaire.specifyPropertySource}
						name="propertySourceSpecification"
						isRequired
					/>
				)}
			</Stack>
			<HeadingXS marginBottom={0}>
				{cs.AML.amlQuestionaire.averageIncome.label}
			</HeadingXS>
			<RadioGroup name="averageIncome">
				{AverageIncome.map((averageIncome) => (
					<RadioInput
						isRequired
						key={averageIncome.Id}
						name={`averageIncome`}
						value={averageIncome.Name}
						id={`averageIncome-${averageIncome.Id}`}
						label={averageIncome.Name}
					/>
				))}
			</RadioGroup>
		</Stack>
	);
};

interface IGetAMLQuestionDrawerStep {
	contractId: string;
	contractType: ContractType;
	onSubmitCallback?: (error?: Error) => void;
	saveText: string;
}

export const GetAMLQuestionsDrawerStep = ({
	contractId,
	contractType,
	onSubmitCallback,
	helpText,
	saveText,
}: IGetAMLQuestionDrawerStep & IAMLQuestions): MultiStepChildren => {
	const { contractsDpsApi, contractsPpApi } = usePrimaryAPIClient();
	return {
		component: (
			<Formik
				initialValues={AMLFormDefaultValues}
				validationSchema={AMLQuestionsSchema}
				validateOnChange={false}
				isInitialValid={false}
				onSubmit={async (values, { setSubmitting, setStatus }) => {
					try {
						setSubmitting(true);
						setStatus("submitting");
						if (contractType === ProductType.Uf) {
							await contractsDpsApi.setDPSAmlIdentificationPut({
								contractId,
								setAmlIdentificationRequest: mapAMLRequest(values),
							});
						} else {
							await contractsPpApi.setPPAmlIdentificationPut({
								contractId,
								setAmlIdentificationRequest: mapAMLRequest(values),
							});
						}
						setStatus("success");
						setSubmitting(false);
						onSubmitCallback && onSubmitCallback();
					} catch (error) {
						onSubmitCallback && onSubmitCallback(new Error("Failed to submit"));
					}
				}}
			>
				{(props) => {
					return (
						<Stack
							sx={{
								position: "absolute",
								top: 0,
								right: 0,
								bottom: 0,
								left: 0,
								justifyContent: "space-between",
							}}
						>
							<Box sx={{ overflowY: "auto", py: 7, px: 4 }}>
								<AMLQuestions helpText={helpText} />
							</Box>
							<ModalFooter>
								<LoadingButton
									variant="contained"
									disableElevation
									loading={props.isSubmitting}
									disabled={props.isSubmitting}
									onClick={() => {
										props.handleSubmit();
									}}
								>
									{saveText}
								</LoadingButton>
							</ModalFooter>
						</Stack>
					);
				}}
			</Formik>
		),
		stepName: "AMLQuestions",
		stepConfig: {
			disableFooter: true,
			forceFullView: true,
		},
	};
};
