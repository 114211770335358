/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChangeType } from './ChangeType';
import {
    ChangeTypeFromJSON,
    ChangeTypeFromJSONTyped,
    ChangeTypeToJSON,
} from './ChangeType';
import type { ChangeAdditionalData } from './ChangeAdditionalData';
import {
    ChangeAdditionalDataFromJSON,
    ChangeAdditionalDataFromJSONTyped,
    ChangeAdditionalDataToJSON,
} from './ChangeAdditionalData';
import type { ProductType } from './ProductType';
import {
    ProductTypeFromJSON,
    ProductTypeFromJSONTyped,
    ProductTypeToJSON,
} from './ProductType';
import type { RequirementStatus } from './RequirementStatus';
import {
    RequirementStatusFromJSON,
    RequirementStatusFromJSONTyped,
    RequirementStatusToJSON,
} from './RequirementStatus';

/**
 * 
 * @export
 * @interface GetChangesResponse
 */
export interface GetChangesResponse {
    /**
     * 
     * @type {ProductType}
     * @memberof GetChangesResponse
     */
    productType: ProductType;
    /**
     * 
     * @type {string}
     * @memberof GetChangesResponse
     */
    productId: string;
    /**
     * 
     * @type {ChangeType}
     * @memberof GetChangesResponse
     */
    changeType: ChangeType;
    /**
     * 
     * @type {RequirementStatus}
     * @memberof GetChangesResponse
     */
    status: RequirementStatus;
    /**
     * 
     * @type {Date}
     * @memberof GetChangesResponse
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetChangesResponse
     */
    resolvedDate?: Date | null;
    /**
     * 
     * @type {ChangeAdditionalData}
     * @memberof GetChangesResponse
     */
    additionalData?: ChangeAdditionalData;
}

/**
 * Check if a given object implements the GetChangesResponse interface.
 */
export function instanceOfGetChangesResponse(value: object): value is GetChangesResponse {
    if (!('productType' in value) || value['productType'] === undefined) return false;
    if (!('productId' in value) || value['productId'] === undefined) return false;
    if (!('changeType' in value) || value['changeType'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    return true;
}

export function GetChangesResponseFromJSON(json: any): GetChangesResponse {
    return GetChangesResponseFromJSONTyped(json, false);
}

export function GetChangesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChangesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'productType': ProductTypeFromJSON(json['productType']),
        'productId': json['productId'],
        'changeType': ChangeTypeFromJSON(json['changeType']),
        'status': RequirementStatusFromJSON(json['status']),
        'createdDate': (new Date(json['createdDate'])),
        'resolvedDate': json['resolvedDate'] == null ? undefined : (new Date(json['resolvedDate'])),
        'additionalData': json['additionalData'] == null ? undefined : ChangeAdditionalDataFromJSON(json['additionalData']),
    };
}

export function GetChangesResponseToJSON(value?: GetChangesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productType': ProductTypeToJSON(value['productType']),
        'productId': value['productId'],
        'changeType': ChangeTypeToJSON(value['changeType']),
        'status': RequirementStatusToJSON(value['status']),
        'createdDate': ((value['createdDate']).toISOString()),
        'resolvedDate': value['resolvedDate'] == null ? undefined : ((value['resolvedDate'] as any).toISOString()),
        'additionalData': ChangeAdditionalDataToJSON(value['additionalData']),
    };
}

