import SvgIcon from "@mui/material/SvgIcon";

const BackIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
			<path d="M21 10.2502H2.4975L12.1602 1.55782C12.4678 1.28092 12.4932 0.806317 12.2159 0.498717C11.94 0.191117 11.4649 0.164717 11.1573 0.442517L0.7988 9.76052C0.625 9.91772 0.4887 10.1062 0.3921 10.3235C0.2979 10.5378 0.25 10.7648 0.25 10.9992C0.25 11.2336 0.2979 11.4606 0.3931 11.6774C0.4893 11.8937 0.6265 12.0832 0.7998 12.2384L11.1572 21.5577C11.3003 21.6866 11.4795 21.7501 11.6582 21.7501C11.8638 21.7501 12.0684 21.6661 12.2158 21.5021C12.4931 21.1935 12.4678 20.7199 12.1601 20.4425L2.4995 11.7502H21C21.4141 11.7502 21.75 11.4143 21.75 11.0002C21.75 10.5861 21.4141 10.2502 21 10.2502Z" />
		</SvgIcon>
	);
};

export default BackIcon;
