import Box, { BoxProps } from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { FieldArray, useFormikContext } from "formik";
import { useEffect } from "react";

import { useQuestions } from "../../queryHooks";
import cs from "../../translations/cs.json";
import FormErrorMessage from "../Form/FormErrorMessage";
import RadioGroup from "../Form/Input/Radio/RadioGroup";
import FormSubmit from "../Form/Submit";
import { HeadingL } from "../Typography";
import Answers from "./Answers";

interface QuestionsProps extends BoxProps {
	showSubmitButton: boolean;
	setSubmitInnerForm: (submitInnerForm: () => void) => void;
}

function Questions({
	showSubmitButton,
	setSubmitInnerForm,
	...rest
}: QuestionsProps): JSX.Element {
	const { data, isLoading } = useQuestions();
	const { errors, touched, submitForm, values } = useFormikContext();
	useEffect(() => {
		setSubmitInnerForm(() => submitForm);
	}, []);

	if (!isLoading) {
		return (
			<Box {...rest}>
				<Stack spacing={6}>
					{data.map((question, i) => {
						return (
							<Stack key={i}>
								<HeadingL>{question.content}</HeadingL>
								<FieldArray
									name={`answers.${i}`}
									render={() => (
										<Paper sx={{ p: 4 }}>
											<Stack spacing={4}>
												{data[i].maxRequiredAnswers > 1 ? (
													<Answers answers={data[i].answers} i={i} />
												) : (
													<RadioGroup name={`answers.${i}`} spacing={4}>
														<Answers
															answers={data[i].answers}
															i={i}
															type="radio"
														/>
													</RadioGroup>
												)}
												{touched?.answers?.[i] && (
													<>
														{errors?.answers?.[i] === true && (
															<FormErrorMessage name="chooseAtLeastOneAnswer">
																{cs.errorMessages.chooseAtLeastOneAnswer}
															</FormErrorMessage>
														)}
														{errors?.answers?.[i] === "wrongCombination" && (
															<FormErrorMessage name="wrongCombination">
																{cs.errorMessages.wrongCombination}
															</FormErrorMessage>
														)}
													</>
												)}
											</Stack>
										</Paper>
									)}
								/>
							</Stack>
						);
					})}
					{showSubmitButton && (
						<FormSubmit
							text={{
								submitting: cs.global.processing,
								label: cs.investmentStrategy.investmentQuestionare.confirm,
							}}
						/>
					)}
				</Stack>
			</Box>
		);
	} else
		return (
			<Box>
				<Skeleton height={80} />
				<Skeleton height={40} />
				<Skeleton height={40} />
				<Skeleton height={40} />
				<Box mt={3}>
					<Skeleton height={80} />
					<Skeleton height={40} />
					<Skeleton height={40} />
					<Skeleton height={40} />
				</Box>
			</Box>
		);
}
export default Questions;
