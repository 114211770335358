import SvgIcon from "@mui/material/SvgIcon";

const CaretDown = (props): JSX.Element => {
	return (
		<SvgIcon width="14" height="8" viewBox="0 0 14 8" fill="none" {...props}>
			<path d="M13.3.1a.5.5 0 0 0-.7 0L7 6.8 1.4.2a.5.5 0 1 0-.8.6l5.8 6.7a.8.8 0 0 0 1 .2l.2-.2L13.4.8c.2-.2.1-.5 0-.7Z" />
		</SvgIcon>
	);
};

export default CaretDown;
