/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentDownloadResponse
 */
export interface DocumentDownloadResponse {
    /**
     * 
     * @type {number}
     * @memberof DocumentDownloadResponse
     */
    nodeId: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDownloadResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDownloadResponse
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDownloadResponse
     */
    mimeType: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentDownloadResponse
     */
    fileSize: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentDownloadResponse
     */
    dateCreated: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentDownloadResponse
     */
    downloadPath: string;
}

/**
 * Check if a given object implements the DocumentDownloadResponse interface.
 */
export function instanceOfDocumentDownloadResponse(value: object): value is DocumentDownloadResponse {
    if (!('nodeId' in value) || value['nodeId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('mimeType' in value) || value['mimeType'] === undefined) return false;
    if (!('fileSize' in value) || value['fileSize'] === undefined) return false;
    if (!('dateCreated' in value) || value['dateCreated'] === undefined) return false;
    if (!('downloadPath' in value) || value['downloadPath'] === undefined) return false;
    return true;
}

export function DocumentDownloadResponseFromJSON(json: any): DocumentDownloadResponse {
    return DocumentDownloadResponseFromJSONTyped(json, false);
}

export function DocumentDownloadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentDownloadResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'nodeId': json['nodeId'],
        'name': json['name'],
        'type': json['type'],
        'mimeType': json['mimeType'],
        'fileSize': json['fileSize'],
        'dateCreated': (new Date(json['dateCreated'])),
        'downloadPath': json['downloadPath'],
    };
}

export function DocumentDownloadResponseToJSON(value?: DocumentDownloadResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nodeId': value['nodeId'],
        'name': value['name'],
        'type': value['type'],
        'mimeType': value['mimeType'],
        'fileSize': value['fileSize'],
        'dateCreated': ((value['dateCreated']).toISOString()),
        'downloadPath': value['downloadPath'],
    };
}

