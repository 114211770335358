/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FundCode = {
    Con: 'CON',
    Bal: 'BAL',
    Grow: 'GROW'
} as const;
export type FundCode = typeof FundCode[keyof typeof FundCode];


export function instanceOfFundCode(value: any): boolean {
    for (const key in FundCode) {
        if (Object.prototype.hasOwnProperty.call(FundCode, key)) {
            if ((FundCode as Record<string, FundCode>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FundCodeFromJSON(json: any): FundCode {
    return FundCodeFromJSONTyped(json, false);
}

export function FundCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundCode {
    return json as FundCode;
}

export function FundCodeToJSON(value?: FundCode | null): any {
    return value as any;
}

