import { Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const BankIcons = () => {
	return (
		<Stack
			direction="row"
			spacing={3}
			sx={{ justifyContent: "space-around", alignItems: "center", my: 4 }}
		>
			<StaticImage src="../../images/icons/AirBank.svg" alt="airbank" />

			<StaticImage
				src="../../images/icons/CeskaSporitelna.svg"
				alt="ceskaSporitelna"
			/>

			<StaticImage src="../../images/icons/CSOB.svg" alt="csob" />

			<StaticImage src="../../images/icons/FIO.svg" alt="fioBanka" />

			<StaticImage
				src="../../images/icons/KomercniBanka.svg"
				alt="komercniBanka"
			/>

			<StaticImage src="../../images/icons/Moneta-Money.svg" alt="moneta" />

			<StaticImage
				src="../../images/icons/Raiffeisen_Bank.svg"
				alt="raiffeisenbank"
			/>
		</Stack>
	);
};

export default BankIcons;
