import { ReactNode } from "react";

export const SEO = ({
	title,
	description,
	children,
}: {
	title: string;
	description?: string;
	children?: ReactNode;
}): JSX.Element => {
	return (
		<>
			<title>{title}</title>
			<meta name="description" content={description} />
			{children}
		</>
	);
};
