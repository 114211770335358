/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeContactAddressesRequest,
  GetContactAddressesResponse,
  GetProfileResponse,
} from '../models/index';
import {
    ChangeContactAddressesRequestFromJSON,
    ChangeContactAddressesRequestToJSON,
    GetContactAddressesResponseFromJSON,
    GetContactAddressesResponseToJSON,
    GetProfileResponseFromJSON,
    GetProfileResponseToJSON,
} from '../models/index';

export interface ChangeContactAddressesPutRequest {
    changeContactAddressesRequest?: ChangeContactAddressesRequest;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     * Change user\'s contact addresses
     */
    async changeContactAddressesPutRaw(requestParameters: ChangeContactAddressesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/profile/contact-addresses`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeContactAddressesRequestToJSON(requestParameters['changeContactAddressesRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change user\'s contact addresses
     */
    async changeContactAddressesPut(requestParameters: ChangeContactAddressesPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeContactAddressesPutRaw(requestParameters, initOverrides);
    }

    /**
     * Get user\'s contact addresses
     */
    async getContactAddressesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContactAddressesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/profile/contact-addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContactAddressesResponseFromJSON(jsonValue));
    }

    /**
     * Get user\'s contact addresses
     */
    async getContactAddressesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContactAddressesResponse> {
        const response = await this.getContactAddressesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get user profile information
     */
    async getProfileGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProfileResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProfileResponseFromJSON(jsonValue));
    }

    /**
     * Get user profile information
     */
    async getProfileGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProfileResponse> {
        const response = await this.getProfileGetRaw(initOverrides);
        return await response.value();
    }

}
