import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MUITabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactNode } from "react";

import { ProductType } from "../../../models";
import { useContractTypeContext } from "../../context/ContractTypeContext";
import AppBar from "../AppBar";
import { useLayout } from "../Layout/context";

interface Props {
	children: ReactNode;
	tabIndex: number | false;
	setTabIndex: (tabIndex: number) => void;
}

/**
 * Samostatny prouzek s taby bezestavovy
 * @param children
 * @param tabIndex
 * @param setTabIndex
 * @constructor
 */
function TabItems({ children, tabIndex, setTabIndex }: Props): JSX.Element {
	const { secondaryAppBarContent } = useLayout();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const tabsPanelSize = {
		height: isDesktop ? 42 : 38, // bigger height due to bigger font size on desktop
		shadow: 12,
	};
	const { contractType } = useContractTypeContext();
	return (
		<Box
			sx={{
				position: { md: "static", xxs: "fixed" },
				zIndex: ({ zIndex }) => zIndex.drawer,
				width: {
					md: contractType === ProductType.Cl ? "100%" : "58.5%",
					xxs: "100%",
				},
				mt: -1,
				mr: { md: contractType === ProductType.Cl ? 0 : 6 },
				backgroundColor: "transparent",
			}}
		>
			<AppBar sx={{ display: { md: "block", xxs: "none" }, mt: { md: 4 } }}>
				{secondaryAppBarContent}
			</AppBar>
			<Box
				sx={{
					width: { md: "calc(100% + 40px)" },
					height: tabsPanelSize.height + tabsPanelSize.shadow,
					overflow: "hidden",
					ml: { md: "-20px" },
					marginBottom: `-${tabsPanelSize.shadow}px`,
					p: { md: "0 20px" },
				}}
			>
				<MUITabs
					value={tabIndex}
					onChange={(event, newTabIndex) => {
						setTabIndex(newTabIndex);
					}}
					variant="scrollable"
					scrollButtons={false}
					sx={{
						minHeight: 0,
						boxShadow: "far",
						backgroundColor: "white.main",
						pointerEvents: "all",
					}}
				>
					{children}
				</MUITabs>
			</Box>
		</Box>
	);
}

export default TabItems;
