/* eslint-disable @typescript-eslint/no-unsafe-return */

export enum gtmConsentState {
	denied = "denied",
	granted = "granted",
}

//only works properly with arguments
export function gtag() {
	if (!window.dataLayer) {
		window.dataLayer = [];
	}
	// eslint-disable-next-line prefer-rest-params
	window.dataLayer.push(arguments);
}

export const updateGTMConsents = (
	analyticsConsent: boolean,
	adsConsent: boolean
): void => {
	gtag("consent", "update", {
		ad_storage: adsConsent ? gtmConsentState.granted : gtmConsentState.denied,
		ad_user_data: adsConsent ? gtmConsentState.granted : gtmConsentState.denied,
		ad_personalization: adsConsent
			? gtmConsentState.granted
			: gtmConsentState.denied,
		analytics_storage: analyticsConsent
			? gtmConsentState.granted
			: gtmConsentState.denied,
	});
};
