/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentFrequency } from './PaymentFrequency';
import {
    PaymentFrequencyFromJSON,
    PaymentFrequencyFromJSONTyped,
    PaymentFrequencyToJSON,
} from './PaymentFrequency';
import type { DeliveryType } from './DeliveryType';
import {
    DeliveryTypeFromJSON,
    DeliveryTypeFromJSONTyped,
    DeliveryTypeToJSON,
} from './DeliveryType';

/**
 * 
 * @export
 * @interface ContractParametersDPS
 */
export interface ContractParametersDPS {
    /**
     * 
     * @type {string}
     * @memberof ContractParametersDPS
     */
    contractNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersDPS
     */
    signatureDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersDPS
     */
    registrationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersDPS
     */
    effectiveDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersDPS
     */
    retirementDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractParametersDPS
     */
    isTaxOptimum: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContractParametersDPS
     */
    monthlyContributionClient: number;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersDPS
     */
    lastMonthlyContributionChangeDate?: Date | null;
    /**
     * 
     * @type {PaymentFrequency}
     * @memberof ContractParametersDPS
     */
    contributionFrequency: PaymentFrequency;
    /**
     * 
     * @type {boolean}
     * @memberof ContractParametersDPS
     */
    doesEmployerContribute: boolean;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ContractParametersDPS
     */
    taxStatementDelivery: DeliveryType;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ContractParametersDPS
     */
    transactionsStatementDelivery: DeliveryType;
}

/**
 * Check if a given object implements the ContractParametersDPS interface.
 */
export function instanceOfContractParametersDPS(value: object): value is ContractParametersDPS {
    if (!('contractNumber' in value) || value['contractNumber'] === undefined) return false;
    if (!('signatureDate' in value) || value['signatureDate'] === undefined) return false;
    if (!('isTaxOptimum' in value) || value['isTaxOptimum'] === undefined) return false;
    if (!('monthlyContributionClient' in value) || value['monthlyContributionClient'] === undefined) return false;
    if (!('contributionFrequency' in value) || value['contributionFrequency'] === undefined) return false;
    if (!('doesEmployerContribute' in value) || value['doesEmployerContribute'] === undefined) return false;
    if (!('taxStatementDelivery' in value) || value['taxStatementDelivery'] === undefined) return false;
    if (!('transactionsStatementDelivery' in value) || value['transactionsStatementDelivery'] === undefined) return false;
    return true;
}

export function ContractParametersDPSFromJSON(json: any): ContractParametersDPS {
    return ContractParametersDPSFromJSONTyped(json, false);
}

export function ContractParametersDPSFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractParametersDPS {
    if (json == null) {
        return json;
    }
    return {
        
        'contractNumber': json['contractNumber'],
        'signatureDate': (new Date(json['signatureDate'])),
        'registrationDate': json['registrationDate'] == null ? undefined : (new Date(json['registrationDate'])),
        'effectiveDate': json['effectiveDate'] == null ? undefined : (new Date(json['effectiveDate'])),
        'retirementDate': json['retirementDate'] == null ? undefined : (new Date(json['retirementDate'])),
        'isTaxOptimum': json['isTaxOptimum'],
        'monthlyContributionClient': json['monthlyContributionClient'],
        'lastMonthlyContributionChangeDate': json['lastMonthlyContributionChangeDate'] == null ? undefined : (new Date(json['lastMonthlyContributionChangeDate'])),
        'contributionFrequency': PaymentFrequencyFromJSON(json['contributionFrequency']),
        'doesEmployerContribute': json['doesEmployerContribute'],
        'taxStatementDelivery': DeliveryTypeFromJSON(json['taxStatementDelivery']),
        'transactionsStatementDelivery': DeliveryTypeFromJSON(json['transactionsStatementDelivery']),
    };
}

export function ContractParametersDPSToJSON(value?: ContractParametersDPS | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractNumber': value['contractNumber'],
        'signatureDate': ((value['signatureDate']).toISOString()),
        'registrationDate': value['registrationDate'] == null ? undefined : ((value['registrationDate'] as any).toISOString()),
        'effectiveDate': value['effectiveDate'] == null ? undefined : ((value['effectiveDate'] as any).toISOString()),
        'retirementDate': value['retirementDate'] == null ? undefined : ((value['retirementDate'] as any).toISOString()),
        'isTaxOptimum': value['isTaxOptimum'],
        'monthlyContributionClient': value['monthlyContributionClient'],
        'lastMonthlyContributionChangeDate': value['lastMonthlyContributionChangeDate'] == null ? undefined : ((value['lastMonthlyContributionChangeDate'] as any).toISOString()),
        'contributionFrequency': PaymentFrequencyToJSON(value['contributionFrequency']),
        'doesEmployerContribute': value['doesEmployerContribute'],
        'taxStatementDelivery': DeliveryTypeToJSON(value['taxStatementDelivery']),
        'transactionsStatementDelivery': DeliveryTypeToJSON(value['transactionsStatementDelivery']),
    };
}

