import SvgIcon from "@mui/material/SvgIcon";

const TaxCertificate = (props): JSX.Element => {
	return (
		<SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
			<path d="M8.5 14.2h-4a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1Z" />
			<path d="M8 20H1.5V1h13v8.1a.5.5 0 1 0 1 0V.5c0-.3-.3-.5-.6-.5H1C.7 0 .5.2.5.5v20c0 .4.2.6.5.6h7a.5.5 0 1 0 0-1Z" />
			<path d="M11.3 9.3H4.6a.5.5 0 1 0 0 1h6.7a.5.5 0 1 0 0-1Zm0-4.8H4.6a.5.5 0 1 0 0 1h6.7a.5.5 0 1 0 0-1Zm6.1 11.7c5.5 0 6.9-1.8 7-2l.1-.3c0-.4-.2-.9-.6-1.2-.8-.8-2.6-1.7-6.6-1.7-4.7 0-6.3 1.3-6.8 2l-.2.7c0 .3.2.6.4.9.7.6 2.5 1.6 6.7 1.6Zm-6-2.6c.2-.4 1.4-1.6 5.9-1.6 3.8 0 5.4.9 6 1.4l.2.4c-.4.3-2 1.4-6 1.4s-5.6-1-6.1-1.4v-.2Zm12.2 7.9S22.4 23 17.4 23s-6.1-1.5-6.2-1.5a.5.5 0 1 0-.8.6c.2.1 1.5 1.9 7 1.9s6.9-1.8 7-2c.2-.2.1-.5-.1-.7a.5.5 0 0 0-.7.2Z" />
			<path d="M23.6 18.7s-1.2 1.6-6.2 1.6-6.1-1.5-6.1-1.6a.5.5 0 0 0-.9.6c.2.2 1.5 2 7 2s6.9-1.8 7-2c.2-.2.1-.5-.1-.7a.5.5 0 0 0-.7.1Z" />
			<path d="M17.4 18.9c5.5 0 6.9-1.8 7-2 .2-.2.1-.5-.1-.7a.5.5 0 0 0-.7.1S22.4 18 17.4 18s-6.1-1.6-6.2-1.6a.5.5 0 0 0-.8.6c.2.2 1.5 2 7 2Z" />
		</SvgIcon>
	);
};

export default TaxCertificate;
