import { Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";

import { useTransactions } from "../../queryHooks";
import { ContractType } from "../../types/contracts";
import { TransactionsListSkeleton } from "../DPS/Transactions/TransactionsList";
import TransactionsContainer from "../Transactions/TransactionsContainer";
import { HeadingS } from "../Typography";
import { ContractDTO } from ".";

export interface ITransaction {
	type: ContractType;
	date: string;
	amount: string;
}

/**
 * Komponenta podporuje loading stav ktery se ukazuje, dokud nenacte transakce
 * k idcku. Transakce nezacne nacitat, dokud nedostane idcko
 * @param id
 * @param type
 * @constructor
 */

function TransactionsById({
	id,
	type,
}: Pick<ContractDTO, "id" | "type"> | Record<string, never>): JSX.Element {
	const { data, isLoading } = useTransactions({
		type: type,
		params: {
			contractId: id,
			count: 4,
		},
		options: {
			enabled: !!id,
			refetchOnWindowFocus: false,
		},
	});
	const isDesktop = useIsDesktop();

	return (
		<Stack>
			<HeadingS>Poslední platby</HeadingS>
			{data && !isLoading ? (
				<TransactionsContainer transactions={data} isIcon={isDesktop} />
			) : (
				<TransactionsListSkeleton skeletonsCount={4} />
			)}
		</Stack>
	);
}

export default TransactionsById;
