import "./style.css";

import Stack from "@mui/material/Stack";
import { globalHistory, navigate, useLocation } from "@reach/router";
import { useFormikContext } from "formik";
import { RefObject, useEffect, useRef, useState } from "react";

import cs from "../../../translations/cs.json";
import { ErrorBoundaryWithAsyncContext } from "../../ErrorBoundary";
import { GetDefaultModalErrorUI } from "../../ErrorBoundary/DefaultErrorUi";
import BackIcon from "../../Icons/Back";
import CloseIcon from "../../Icons/Close";
import { Trigger } from "../../Link";
import { HeadingS } from "../../Typography";
import { useDrawer } from "../context";
import DrawerStep from "../DrawerStep";
import { SubmitStatus } from "../GlobalSteps/SubmitStatusStep";
import ModalContent from "../ModalContent";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import { MultiStepDrawerProps } from "../MultiStepDrawer";
import WarningModal from "../WarningModal";
import FormNextButton from "./FormNextButton";
const handleScrollTop = (scrollRef: RefObject<HTMLElement>) => {
	const error = document.querySelector("[data-error-name]");
	if (!error) {
		scrollRef?.current?.scrollTo(0, 0);
	}
};

export const handleScrollToError = (): void => {
	const error = document.querySelector("[data-error-name]");
	if (error) {
		error.scrollIntoView({ behavior: "smooth" });
	}
};

function FormMultiStepDrawer({
	children,
	title,
	disableClose = false,
	hasStepper,
	displayExplanation,
	reactQueryStatus,
	pageIsLoading,
	fullscreenZZJ,
	wideModal,
	dataTest,
	onCloseCallback,
}: MultiStepDrawerProps): JSX.Element {
	const { hideMultistepDrawer, step, previousStep, setSteps, stepNames } =
		useDrawer();
	const scrollRef = useRef(null);
	const [isOpenModal, setIsOpenModal] = useState(false);

	const handleChange = () => {
		setIsOpenModal(false);
	};

	const steps = children.filter(
		(item) => item !== false && item !== undefined && item !== null
	);

	const customAttributes = steps[step]?.stepConfig ?? {
		...children[step]?.stepConfig,
	};

	const { validateForm, setFieldTouched, setSubmitting, isSubmitting, status } =
		useFormikContext();

	const numberOfSteps = steps.length;

	const isFormSubmitting = isSubmitting || status === SubmitStatus.Submitting;

	useEffect(() => {
		handleScrollTop(scrollRef);
		setSteps(steps);
	}, [step, displayExplanation]);

	const location = useLocation();

	useEffect(() => {
		navigate(`${location.pathname}${location.search}#${steps[0].stepName}`);
	}, []);

	useEffect(() => {
		const stepNamefromURL = location.hash.substring(1);
		const currentStepFromURLIndex = stepNames.indexOf(stepNamefromURL);

		const lastName = stepNames[stepNames.length - 1];
		const firstName = stepNames[0];

		return globalHistory.listen(({ action }) => {
			if (action === "POP" && stepNamefromURL !== lastName) {
				if (currentStepFromURLIndex === 0 && stepNamefromURL === firstName) {
					// close the drawer when user wants to navigate to the state before the modal was opened
					hideMultistepDrawer();
					onCloseCallback && onCloseCallback();
				} else {
					previousStep();
				}
			}
		});
	}, [location]);

	return (
		<Stack
			data-test={dataTest ?? "form-multistep-drawer"}
			sx={{
				width: { md: fullscreenZZJ || wideModal ? "1015px" : "560px" }, // +15px for Chrome scrollbar
				maxWidth: "100%",
				maxHeight: { md: "700px" },
				overflow: "auto",
				transition: "200ms",
			}}
			className="formMultiStepModal"
		>
			<ErrorBoundaryWithAsyncContext
				fallbackUI={GetDefaultModalErrorUI({ onClose: hideMultistepDrawer })}
			>
				<>
					<ModalHeader
						hasStepper={hasStepper}
						numberOfSteps={steps.length}
						wideModal={wideModal}
					>
						<Trigger
							onClick={() => {
								if (customAttributes.customBackFunction) {
									const goBack = customAttributes.customBackFunction();
									if (goBack) {
										previousStep();
									}
								} else {
									previousStep();
								}
							}}
							sx={{
								visibility:
									step === 0 || step === numberOfSteps - 1
										? "hidden"
										: "visible",
							}}
							data-test="modal-header-back-button"
						>
							<BackIcon
								sx={{ width: "2.1rem", height: "2.1rem" }}
								onClick={() => setSubmitting(false)}
							/>
						</Trigger>
						<HeadingS mb={5} sx={{ mb: 0 }} withoutScale={!wideModal}>
							{title}
						</HeadingS>
						<Trigger
							onClick={() => setIsOpenModal(true)}
							sx={{
								visibility:
									disableClose || step === numberOfSteps - 1
										? "hidden"
										: "visible",
							}}
						>
							<CloseIcon sx={{ width: "2rem", height: "2rem" }} />
						</Trigger>
						<WarningModal
							onHideMultistepDrawer={onCloseCallback}
							isOpenModal={isOpenModal}
							handleChange={handleChange}
						/>
					</ModalHeader>
					<ModalContent
						scrollRef={scrollRef}
						forceFullView={
							customAttributes.forceFullView ||
							steps[step]?.stepConfig?.forceFullView
						}
					>
						{steps.map((item, i) => (
							<DrawerStep
								value={step}
								key={i}
								index={i}
								sx={item?.drawerStepSx}
							>
								{item.component ? item.component : item}
							</DrawerStep>
						))}
					</ModalContent>
					{!(steps[step]?.stepConfig?.disableFooter === true) && (
						<ModalFooter>
							{steps[step]?.stepConfig?.customNextButton ?? (
								<FormNextButton
									numberOfSteps={numberOfSteps}
									{...customAttributes}
									onCloseCallback={onCloseCallback}
									customFunction={() => {
										handleScrollToError();
										if (customAttributes.customFunction) {
											// customFunction must be preserved if provided
											return customAttributes.customFunction();
										} else {
											// form needs to be checked before submit, before fields are hidden by next step's UI
											return validateForm().then((result) => {
												if (Object.keys(result).length > 0) {
													Object.keys(result).map((item) =>
														setFieldTouched(item, true)
													);
													return false;
												}
												return true;
											});
										}
									}}
									label={isFormSubmitting && cs.global.processingWithDots}
									disabled={
										reactQueryStatus === "loading" ||
										isFormSubmitting ||
										pageIsLoading
									}
								/>
							)}
						</ModalFooter>
					)}
				</>
			</ErrorBoundaryWithAsyncContext>
		</Stack>
	);
}

export default FormMultiStepDrawer;
