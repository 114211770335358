import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {
	ErrorMessage,
	FieldAttributes,
	useField,
	useFormikContext,
} from "formik";
import { ChangeEvent, FC } from "react";

import FormErrorMessage from "../../FormErrorMessage";

export type onTextInputChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface TextInputProps extends FieldAttributes<any> {
	disabled?: boolean;
	error?: boolean;
	name: string;
	isRequired?: boolean;
	min?: number;
	max?: number;
	hideError?: boolean;
}

const TextInput: FC<TextInputProps> = ({
	// performant formik input can´t be used with datepicker for some reason, so this is original non-performant text input backup for use with datepicker
	disabled = false,
	name,
	label,
	isRequired = false,
	hideError,
	...rest
}) => {
	const [field, { error }, { setValue }] = useField(name);
	const { isSubmitting } = useFormikContext();

	return (
		<Stack>
			{label && (
				<InputLabel shrink htmlFor={`${name}-name`} required={isRequired}>
					{label}
				</InputLabel>
			)}
			<TextField
				className={`input ${error ? "invalid" : ""}`}
				type="text"
				required={isRequired}
				id={`${name}-name`}
				disabled={isSubmitting || disabled}
				variant="outlined"
				{...field}
				{...rest}
			/>
			{!hideError && (
				<ErrorMessage
					name={name}
					render={(msg) => (
						<FormErrorMessage name={name}>{msg}</FormErrorMessage>
					)}
				/>
			)}
		</Stack>
	);
};

export default TextInput;
