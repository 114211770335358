import { Skeleton } from "@mui/material";

import { contractLink } from "../../functions";
import { useContracts } from "../../queryHooks";
import { ContractType } from "../../types/contracts";
import PensionNovelBanner from ".";

const PensionNovelBannerWrapper = (): JSX.Element => {
	const { data, isSuccess } = useContracts();

	if (isSuccess) {
		const primaryPensionProduct = data.find(
			(contract) =>
				(contract.type === "PF" || contract.type === "UF") &&
				contract.isChildrenContract === false
		);
		if (primaryPensionProduct)
			return (
				<PensionNovelBanner
					id={primaryPensionProduct.id}
					type={primaryPensionProduct.type as ContractType}
					url={contractLink(
						primaryPensionProduct.id,
						primaryPensionProduct.type,
						"smlouva"
					)}
				/>
			);
		else return <></>;
	}

	return <Skeleton variant="rounded" height={"128px"} width="100%" />;
};

export default PensionNovelBannerWrapper;
