import { Form, Formik } from "formik";
import { Dispatch, ReactNode, SetStateAction, useEffect } from "react";
import { array, lazy, object, string } from "yup";

import { InvestorProfileResult } from "../../../models";
import { PossibleAnswer } from "../../../models";
import { usePrimaryAPIClient } from "../../context/PrimaryAPIClient";
import { useQuestions } from "../../queryHooks";
import cs from "../../translations/cs.json";
import CircularStepLoader from "../Drawer/CircularStepLoader";
import { useDrawer } from "../Drawer/context";

interface InvestmentQuestionareProps {
	setSubmitting: (setSubmitting: boolean) => void;
	setResultsFunc: Dispatch<SetStateAction<InvestorProfileResult | undefined>>;
	children: ReactNode;
	wantFillQuestionare: boolean;
	setApiAnswersHolder: Dispatch<
		SetStateAction<Array<PossibleAnswer> | undefined>
	>;
	apiAnswersHolder: Array<PossibleAnswer>;
}

function InvestmentQuestionareForm({
	setSubmitting,
	setResultsFunc,
	children,
	wantFillQuestionare,
	setFormikAnswersHolder,
	setApiAnswersHolder,
	formikAnswersHolder,
}: InvestmentQuestionareProps): JSX.Element {
	const { data, isLoading } = useQuestions();
	const { nextStep } = useDrawer();

	useEffect(() => {
		setSubmitting(isLoading);
	}, [isLoading]);

	const { investorProfileApi } = usePrimaryAPIClient();

	const formikValuesToAPIFriendlyFormat = (formikValues) => {
		const apiFriendlyValues = formikValues.answers.map((answer, i) => {
			const choices = () => {
				if (Array.isArray(answer)) {
					// switches
					const answerOptions = answer.map((value, j) => {
						if (Object.values(value)[0] === true) return Object.keys(value)[0];
					});
					return answerOptions.filter((option) => option !== undefined);
				} else {
					return [answer]; // radios
				}
			};
			return { questionId: data[i].id, choices: choices() };
		});
		return apiFriendlyValues;
	};

	const validationSchema = object({
		answers: array().of(
			// schema can be generated per input based on questions definition or can be dynamic based on input value (for radio comes string, for checkbox comes array), the second way is used there
			lazy((val) =>
				Array.isArray(val)
					? array()
							.test("atLeastOneSelected", true, (answers) => {
								return !Object.values(answers).every(
									(item) => Object.values(item)[0] === false
								);
							})
							.test("rightCombination", "wrongCombination", (answers) => {
								// for question #3 make sense only first answer or only one of 2-4 or 2-4
								if (
									answers[0].CZ_A1_LowKnowledgeNew &&
									(answers[1].CZ_A2_SelfStudyExperienceNew ||
										answers[2].CZ_A3_StudyExperienceNew ||
										answers[3].CZ_A4_WorkExperienceNew)
								) {
									return false;
								} else return true;
							})
					: string().test(
							"oneSelected",
							cs.errorMessages.chooseOneAnswer,
							(answer) => {
								return answer !== undefined;
							}
					  )
			)
		),
	});

	if (!isLoading) {
		const answers: Array<PossibleAnswer> = data.map((question, i) => {
			if (question.maxRequiredAnswers > 1) {
				const defaultSwitches = question.answers.map((answer) => ({
					[answer.id]: false,
				}));
				return defaultSwitches;
			} else return undefined;
		});
		return (
			<Formik
				initialValues={{
					answers: formikAnswersHolder ? formikAnswersHolder : answers,
				}}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					const answers = formikValuesToAPIFriendlyFormat(values);
					setFormikAnswersHolder(values.answers);
					setApiAnswersHolder(answers);
					investorProfileApi
						.postInvestorProfilePost({
							userAnswersRequest: {
								rejected: !wantFillQuestionare,
								answers,
							},
						})
						.then((result) => {
							setResultsFunc(result);
							nextStep();
						});
				}}
			>
				{() => {
					return <Form>{children}</Form>;
				}}
			</Formik>
		);
	} else {
		return <CircularStepLoader />;
	}
}
export default InvestmentQuestionareForm;
