import { Box, Stack } from "@mui/material";
import { useFormikContext } from "formik";

import cs from "../../translations/cs.json";
import TriggerButton from "../Link/TriggerButton";
import { BodyTextM, HeadingS } from "../Typography";
import { useDrawer } from "./context";
import SimpleDrawer from "./SimpleDrawer";

interface IWarningModal {
	isOpenModal: boolean;
	handleChange: () => void;
	onHideMultistepDrawer?: () => void;
}

const WarningModal = ({
	isOpenModal,
	handleChange,
	onHideMultistepDrawer,
}: IWarningModal): JSX.Element => {
	const { hideMultistepDrawer } = useDrawer();

	const { isSubmitting } = useFormikContext();

	return (
		<>
			<SimpleDrawer open={isOpenModal}>
				<Stack spacing={6}>
					<Box sx={{ textAlign: "center" }}>
						<HeadingS>{cs.investmentStrategy.warningModal.closeModal}</HeadingS>
						<BodyTextM>
							{cs.investmentStrategy.warningModal.description}
						</BodyTextM>
					</Box>
					<Stack
						sx={{
							flexDirection: "row",
						}}
					>
						<TriggerButton
							sx={{ width: "50%", mr: 2 }}
							disabled={isSubmitting}
							onClick={
								onHideMultistepDrawer
									? () => {
											onHideMultistepDrawer();
											hideMultistepDrawer();
									  }
									: hideMultistepDrawer
							}
						>
							{cs.investmentStrategy.warningModal.yes}
						</TriggerButton>
						<TriggerButton
							sx={{ width: "50%", ml: 2 }}
							variant="outlined"
							disabled={isSubmitting}
							onClick={() => handleChange()}
						>
							{cs.investmentStrategy.warningModal.no}
						</TriggerButton>
					</Stack>
				</Stack>
			</SimpleDrawer>
		</>
	);
};

export default WarningModal;
