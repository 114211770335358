/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ActiveDirectoryAction = {
    Continue: 'Continue',
    ShowBlockPage: 'ShowBlockPage',
    ValidationError: 'ValidationError'
} as const;
export type ActiveDirectoryAction = typeof ActiveDirectoryAction[keyof typeof ActiveDirectoryAction];


export function instanceOfActiveDirectoryAction(value: any): boolean {
    for (const key in ActiveDirectoryAction) {
        if (Object.prototype.hasOwnProperty.call(ActiveDirectoryAction, key)) {
            if ((ActiveDirectoryAction as Record<string, ActiveDirectoryAction>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ActiveDirectoryActionFromJSON(json: any): ActiveDirectoryAction {
    return ActiveDirectoryActionFromJSONTyped(json, false);
}

export function ActiveDirectoryActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveDirectoryAction {
    return json as ActiveDirectoryAction;
}

export function ActiveDirectoryActionToJSON(value?: ActiveDirectoryAction | null): any {
    return value as any;
}

