import SvgIcon from "@mui/material/SvgIcon";

const QuestionIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path d="M17.2 15.6V.5c0-.3-.3-.5-.5-.5H.5C.2 0 0 .2 0 .5v15.1c0 .3.2.5.5.5h1.8v4.5a.5.5 0 0 0 .8.3L8 16.1h8.8c.2 0 .5-.2.5-.5Zm-1-.5H7.7c-.1 0-.2 0-.3.2l-4.1 4v-3.7c0-.3-.3-.5-.5-.5H1V1h15.2v14.1Z" />
			<path d="M23.5 6.8h-3.9a.5.5 0 1 0 0 1H23V18h-1.8c-.2 0-.5.3-.5.5v3.8l-4-4.1-.4-.2H8.6a.5.5 0 1 0 0 1h7.5l4.8 4.9a.5.5 0 0 0 .8-.4V19h1.8c.3 0 .5-.2.5-.5V7.3c0-.3-.2-.5-.5-.5ZM8 2.9c-1.5 0-2.6.6-3.1 1.7V5l.2.3h.4c.2 0 .2-.2.3-.3.3-.7 1-1.1 2.1-1.1 1.3 0 2 .5 2 1.5 0 .3 0 .7-.8 1.4H9c-.6.5-1.2 1-1.2 1.9v1.2c0 .3.2.5.5.5s.5-.2.5-.5V8.7c0-.5.3-.7.8-1v-.2c1-.7 1.4-1.3 1.4-2.1 0-1.5-1.3-2.5-3-2.5Zm.3 8.6a.6.6 0 1 0 0 1.2.6.6 0 0 0 0-1.2Z" />
		</SvgIcon>
	);
};

export default QuestionIcon;
