import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { default as MUIStepper } from "@mui/material/Stepper";

import { useDrawer } from "./context";

interface StepperProps {
	numberOfSteps: number;
}
const StepIcon = () => {
	return <></>;
};

const Stepper = ({ numberOfSteps }: StepperProps): JSX.Element => {
	const { step } = useDrawer();

	return (
		<Box sx={{ pb: 3, px: 4 }}>
			<MUIStepper
				activeStep={step}
				className={step === numberOfSteps - 1 && "last-step"}
			>
				{[...Array(numberOfSteps)].map((x, i) => {
					return (
						<Step key={i}>
							<StepLabel StepIconComponent={StepIcon}></StepLabel>
						</Step>
					);
				})}
			</MUIStepper>
		</Box>
	);
};

export default Stepper;
