import { Skeleton } from "@mui/material";

import {
	GetLifeDetailResponse,
	GetLifeDetailV2Response,
	ProductType,
} from "../../../../models";
import { useContractId } from "../../../context/ContractIDContext";
import { useLifeDetail } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
interface ILifeTitle {
	productName: string | undefined;
}

export const Title = ({ productName }: ILifeTitle) => {
	return productName ? productName : <Skeleton />;
};

export const FufiTitle = () => {
	const contractId = useContractId();
	const { data, isSuccess } = useLifeDetail<GetLifeDetailV2Response>({
		id: contractId,
		contractType: ProductType.Clf,
		options: {
			enabled: !!contractId,
		},
	});

	const title = (() => {
		if (!isSuccess) return;
		return typeof data.productDescription === "string"
			? data.productDescription
			: cs.global.notInNDP;
	})();

	return <Title productName={title} />;
};

export const LifeTitle = () => {
	const contractId = useContractId();
	const { data } = useLifeDetail<GetLifeDetailResponse>({
		id: contractId,
		options: {
			enabled: !!contractId,
		},
	});

	return <Title productName={data?.productName} />;
};
