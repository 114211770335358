import { createContext, ReactNode, useContext, useState } from "react";

export type BottomNavigationState = "unset" | "hidden" | "shown";
interface ILayoutContext {
	setAppBar: (element: ReactNode) => void;
	appBarContent: ReactNode;
	setSecondaryAppBar: (element: ReactNode) => void;
	secondaryAppBarContent: ReactNode;
	bottomNavigationState: BottomNavigationState;
	setBottomNavigation: (state: BottomNavigationState) => void;
}

const LayoutContext = createContext<ILayoutContext>(undefined);

export default LayoutContext;

export interface LayoutProps {
	children: ReactNode;
	appBarContent?: ReactNode;
	secondaryAppBarContent?: ReactNode;
	isBottomNavigationBarHidden?: boolean;
}

export const LayoutProvider = ({
	children,
	appBarContent: appBarContentProp,
	secondaryAppBarContent: secondaryAppBarContentProp,
}: LayoutProps): JSX.Element => {
	const [appBarContent, setAppBar] = useState<ReactNode>(appBarContentProp);
	const [secondaryAppBarContent, setSecondaryAppBar] = useState<ReactNode>(
		secondaryAppBarContentProp
	);
	const [bottomNavigation, setBottomNavigation] =
		useState<BottomNavigationState>("unset");
	return (
		<LayoutContext.Provider
			value={{
				appBarContent,
				setAppBar,
				secondaryAppBarContent,
				setSecondaryAppBar,
				bottomNavigationState: bottomNavigation,
				setBottomNavigation,
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
};

export function useLayout(): ILayoutContext {
	return useContext(LayoutContext);
}
