import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { FieldAttributes } from "formik";
import { ChangeEvent, FC, ReactNode } from "react";

export type onSwitchChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface SwitchBasicProps extends SwitchProps, FieldAttributes<any> {
	label?: string;
	subLabel?: ReactNode;
	id: string;
}

const SwitchBasic: FC<SwitchBasicProps> = ({ subLabel, id, ...rest }) => {
	return (
		<Stack>
			<FormControlLabel control={<Switch disableRipple id={id} />} {...rest} />
			{subLabel && (
				<InputLabel htmlFor={id} variant="subLabel">
					{subLabel}
				</InputLabel>
			)}
		</Stack>
	);
};

export default SwitchBasic;
