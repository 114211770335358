import { ErrorBoundaryWithAsyncContext } from "../../../components/ErrorBoundary";
import ChangesTable from "../../../components/Profile/ChangesTable";
import { SEO } from "../../../components/SEO";
import withProtection from "../../../highOrderComponents/withProtection";
import cs from "../../../translations/cs.json";

export const Title = cs.profile.changes.header;

function Changes({ pageContext }): JSX.Element {
	return (
		<>
			<ErrorBoundaryWithAsyncContext>
				<ChangesTable />
			</ErrorBoundaryWithAsyncContext>
		</>
	);
}
export default withProtection(Changes);

export const Head = (): JSX.Element => (
	<SEO title={Title}>
		<meta name="robots" content="noindex" />
	</SEO>
);
