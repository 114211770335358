import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { graphql, useStaticQuery } from "gatsby";

import { GetPPDetailResponse } from "../../../models";
import { useContractId } from "../../context/ContractIDContext";
import { usePensionDetail } from "../../queryHooks";
import EvaluationsContributionsChart from "../Charts/EvaluationsContributionsChart";
import ShieldIcon from "../Icons/Shield";
import HelpBox from "../InfoBox/HelpBox";
import ProductOverview from "../Overview/ProductOverview";
import { Tab } from "../Tabs";

interface imageProps {
	intro_image: {
		publicURL: string;
	};
}

const introImage = (): imageProps => {
	return useStaticQuery<Queries.PPExportCoinsImageQuery>(
		graphql`
			query PPExportCoinsImage {
				intro_image: file(relativePath: { eq: "coins.png" }) {
					publicURL
				}
			}
		`
	);
};

function Export(): JSX.Element {
	const id = useContractId();
	const image = introImage();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const result = usePensionDetail<GetPPDetailResponse>({ id: id });

	return (
		<Tab>
			{isMobile && (
				<ProductOverview
					image={image.intro_image.publicURL}
					icon={
						<ShieldIcon sx={{ width: "2.5rem", height: "2.9rem", mr: 4 }} />
					}
					totalSavings={result.isSuccess && result.data.savedAmount}
					claimedSavings={
						result.isSuccess && (result.data.paidOutAmount ?? undefined)
					}
					sx={{
						mx: -4,
					}}
				/>
			)}
			<Box sx={{ width: { md: "50%" }, mt: { md: 7 } }}>
				<EvaluationsContributionsChart />
			</Box>
			<HelpBox />
		</Tab>
	);
}

export default Export;
