import Stack from "@mui/material/Stack";

import { ChangeType, ProductType } from "../../../models";
import Download from "../../components/Download";
import CollapsibleTable from "../../components/Table/Collapsible";
import { dateTimeFormat } from "../../functions/dates";
import { useChangeRequests } from "../../queryHooks";
import cs from "../../translations/cs.json";
import { TransactionSkeleton } from "../Transactions/SingleTransactionRow";
import { BodyTextM, HeadingS } from "../Typography";

function ChangesTable(): JSX.Element {
	const titles = ["Produktová linie", "Číslo smlouvy", "Typ změny"];

	const result = useChangeRequests();

	if (result.isSuccess) {
		if (result.data.length === 0) {
			return <Stack sx={{ mt: 3 }}>{cs.profile.changes.noChanges}</Stack>;
		}

		const rows: {
			productType: ProductType;
			productId: string;
			changeType: ChangeType;
			content: JSX.Element;
		}[] = result.data.map((change) => {
			return {
				productType: cs.profile.changes.productType[change.productType],
				productId: change.productId,
				changeType: cs.profile.changes.changeType[change.changeType],
				content: (
					<>
						<Stack>
							<HeadingS mb={0} withoutScale>
								{cs.profile.changes.createdDate}
							</HeadingS>
							<BodyTextM>
								{dateTimeFormat.format(new Date(change.createdDate))}
							</BodyTextM>
						</Stack>
						<Stack>
							<HeadingS mb={0} withoutScale>
								{cs.profile.changes.resolvedDate}
							</HeadingS>
							<BodyTextM>
								{change.resolvedDate
									? dateTimeFormat.format(new Date(change.resolvedDate))
									: "-"}
							</BodyTextM>
						</Stack>
						<Stack>
							<HeadingS mb={0} withoutScale>
								{cs.profile.changes.state}
							</HeadingS>
							<BodyTextM>{cs.profile.changes.status[change.status]}</BodyTextM>
						</Stack>
						{change.additionalData && (
							<>
								{change.additionalData.zzjDocumentInfo && (
									<Stack>
										<HeadingS mb={2} withoutScale>
											{cs.investmentStrategy.zzj}
										</HeadingS>
										<Download
											key={change.additionalData?.zzjDocumentInfo?.downloadPath}
											{...change.additionalData?.zzjDocumentInfo}
										/>
									</Stack>
								)}
								<Stack>
									<HeadingS mb={2} withoutScale>
										{cs.investmentStrategy.precontractualInfo}
									</HeadingS>
									<Download
										key={
											change.additionalData?.dpsPrecontractualInfo?.downloadPath
										}
										isExternalUrl={true}
										{...change.additionalData?.dpsPrecontractualInfo}
									/>
								</Stack>
							</>
						)}
					</>
				),
			};
		});

		return <CollapsibleTable titles={titles} rows={rows} />;
	}

	return (
		<>
			{[...Array(5).keys()].map((_, index) => (
				<TransactionSkeleton key={index} />
			))}
		</>
	);
}
export default ChangesTable;
