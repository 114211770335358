import format from "date-fns/format";

import { PensionPendingChange } from "../../../models";
import cs from "../../translations/cs.json";

export const contributionCoreSystemChangeDescription = (
	pendingChanges: PensionPendingChange[] | undefined
) => {
	return (
		pendingChanges &&
		pendingChanges.map((change, i) => {
			const validFrom = format(new Date(change.validFromDate), "d. M. yyyy");

			return `Od ${validFrom} bude váš nový příspěvek ${change.detail.newClientContribution} Kč.`;
		})
	);
};

export const employerContributionCoreSystemChangeDescription = (
	pendingChanges: PensionPendingChange[] | undefined,
	doesEmployerContribute: boolean
) => {
	if (pendingChanges) {
		const contributionChange = pendingChanges.find(
			(change) => change.changeType === "ChangeContribution"
		);

		if (contributionChange === undefined) return null;

		const willEmployerContribute =
			contributionChange?.detail.willEmployerContribute;
		if (
			willEmployerContribute === undefined ||
			willEmployerContribute === null ||
			willEmployerContribute === doesEmployerContribute
		)
			return null;

		const validFrom = format(
			new Date(contributionChange.validFromDate),
			"d. M. yyyy"
		);
		const employerContributes = contributionChange.detail.willEmployerContribute
			? cs.global.will
			: cs.global.willNot;
		return `Od ${validFrom} vám zaměstnavatel ${employerContributes} přispívat.`;
	}
};
