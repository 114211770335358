import { Box, Stack } from "@mui/material";

import RoundedLinearProgress from "../LinearProgress/Rounded";
import { BodyTextM } from "../Typography";

const LabelledProgress = ({
	title,
	value,
	formattedValue,
}: {
	title: string;
	value: number;
	formattedValue: string;
}): JSX.Element => (
	<Box>
		<Stack spacing={3} direction="row" justifyContent="space-between">
			<BodyTextM>{title}</BodyTextM>
			<BodyTextM strong>{formattedValue}</BodyTextM>
		</Stack>
		<RoundedLinearProgress value={value} />
	</Box>
);

export default LabelledProgress;
