/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Assignee } from './Assignee';
import {
    AssigneeFromJSON,
    AssigneeFromJSONTyped,
    AssigneeToJSON,
} from './Assignee';
import type { InsuranceTypeEnum } from './InsuranceTypeEnum';
import {
    InsuranceTypeEnumFromJSON,
    InsuranceTypeEnumFromJSONTyped,
    InsuranceTypeEnumToJSON,
} from './InsuranceTypeEnum';
import type { Coverage } from './Coverage';
import {
    CoverageFromJSON,
    CoverageFromJSONTyped,
    CoverageToJSON,
} from './Coverage';
import type { Person } from './Person';
import {
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
} from './Person';
import type { BeneficiaryParty } from './BeneficiaryParty';
import {
    BeneficiaryPartyFromJSON,
    BeneficiaryPartyFromJSONTyped,
    BeneficiaryPartyToJSON,
} from './BeneficiaryParty';

/**
 * 
 * @export
 * @interface NdpInsuredPerson
 */
export interface NdpInsuredPerson {
    /**
     * 
     * @type {Person}
     * @memberof NdpInsuredPerson
     */
    person: Person;
    /**
     * 
     * @type {InsuranceTypeEnum}
     * @memberof NdpInsuredPerson
     */
    insuranceType: InsuranceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NdpInsuredPerson
     */
    insuranceStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpInsuredPerson
     */
    commonStatusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpInsuredPerson
     */
    commonStatusDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NdpInsuredPerson
     */
    order: number;
    /**
     * 
     * @type {Array<BeneficiaryParty>}
     * @memberof NdpInsuredPerson
     */
    beneficiaries: Array<BeneficiaryParty>;
    /**
     * 
     * @type {Array<Coverage>}
     * @memberof NdpInsuredPerson
     */
    coverages: Array<Coverage>;
    /**
     * 
     * @type {Array<Assignee>}
     * @memberof NdpInsuredPerson
     */
    assignees: Array<Assignee>;
}

/**
 * Check if a given object implements the NdpInsuredPerson interface.
 */
export function instanceOfNdpInsuredPerson(value: object): value is NdpInsuredPerson {
    if (!('person' in value) || value['person'] === undefined) return false;
    if (!('insuranceType' in value) || value['insuranceType'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    if (!('beneficiaries' in value) || value['beneficiaries'] === undefined) return false;
    if (!('coverages' in value) || value['coverages'] === undefined) return false;
    if (!('assignees' in value) || value['assignees'] === undefined) return false;
    return true;
}

export function NdpInsuredPersonFromJSON(json: any): NdpInsuredPerson {
    return NdpInsuredPersonFromJSONTyped(json, false);
}

export function NdpInsuredPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): NdpInsuredPerson {
    if (json == null) {
        return json;
    }
    return {
        
        'person': PersonFromJSON(json['person']),
        'insuranceType': InsuranceTypeEnumFromJSON(json['insuranceType']),
        'insuranceStatus': json['insuranceStatus'] == null ? undefined : json['insuranceStatus'],
        'commonStatusCode': json['commonStatusCode'] == null ? undefined : json['commonStatusCode'],
        'commonStatusDescription': json['commonStatusDescription'] == null ? undefined : json['commonStatusDescription'],
        'order': json['order'],
        'beneficiaries': ((json['beneficiaries'] as Array<any>).map(BeneficiaryPartyFromJSON)),
        'coverages': ((json['coverages'] as Array<any>).map(CoverageFromJSON)),
        'assignees': ((json['assignees'] as Array<any>).map(AssigneeFromJSON)),
    };
}

export function NdpInsuredPersonToJSON(value?: NdpInsuredPerson | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': PersonToJSON(value['person']),
        'insuranceType': InsuranceTypeEnumToJSON(value['insuranceType']),
        'insuranceStatus': value['insuranceStatus'],
        'commonStatusCode': value['commonStatusCode'],
        'commonStatusDescription': value['commonStatusDescription'],
        'order': value['order'],
        'beneficiaries': ((value['beneficiaries'] as Array<any>).map(BeneficiaryPartyToJSON)),
        'coverages': ((value['coverages'] as Array<any>).map(CoverageToJSON)),
        'assignees': ((value['assignees'] as Array<any>).map(AssigneeToJSON)),
    };
}

