import { Alert, AlertProps as MuiAlertProps } from "@mui/material";
import Box from "@mui/material/Box";

import { BodyTextM, HeadingL } from "../Typography";

export type AlertProps = Omit<MuiAlertProps, "severity">;

const InfoBox = ({
	title,
	action,
	sx,
	children,
	icon,
	...alertProps
}: AlertProps) => {
	return (
		<Alert
			{...alertProps}
			sx={{
				py: 3,
				px: 4,
				border: "1px solid",
				borderColor: "grey.light",
				backgroundColor: "white.main",
				...sx,
				flexDirection: "row-reverse",
				boxShadow: "far",
				".MuiAlert-icon": {
					// switch margins to reflect reversed row
					mr: 0,
					ml: 4,
				},
				".MuiAlert-message": {
					flexGrow: 1, // for wide layouts
				},
			}}
			severity={undefined}
			icon={icon ? icon : false}
		>
			{title && (
				<HeadingL sx={{ display: "inline-block", mb: 3 }} scaleDown>
					{title}
				</HeadingL>
			)}
			<Box mb={2}>
				<BodyTextM>{children}</BodyTextM>
			</Box>
			{action}
		</Alert>
	);
};

export default InfoBox;
