/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PensionBeneficiaryAddress } from './PensionBeneficiaryAddress';
import {
    PensionBeneficiaryAddressFromJSON,
    PensionBeneficiaryAddressFromJSONTyped,
    PensionBeneficiaryAddressToJSON,
} from './PensionBeneficiaryAddress';
import type { PensionBeneficiaryRelationship } from './PensionBeneficiaryRelationship';
import {
    PensionBeneficiaryRelationshipFromJSON,
    PensionBeneficiaryRelationshipFromJSONTyped,
    PensionBeneficiaryRelationshipToJSON,
} from './PensionBeneficiaryRelationship';

/**
 * 
 * @export
 * @interface PensionBeneficiary
 */
export interface PensionBeneficiary {
    /**
     * 
     * @type {number}
     * @memberof PensionBeneficiary
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PensionBeneficiary
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PensionBeneficiary
     */
    lastName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PensionBeneficiary
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PensionBeneficiary
     */
    birthNumber?: string | null;
    /**
     * 
     * @type {PensionBeneficiaryRelationship}
     * @memberof PensionBeneficiary
     */
    relationship?: PensionBeneficiaryRelationship;
    /**
     * 
     * @type {number}
     * @memberof PensionBeneficiary
     */
    ratio?: number | null;
    /**
     * 
     * @type {PensionBeneficiaryAddress}
     * @memberof PensionBeneficiary
     */
    address?: PensionBeneficiaryAddress;
}

/**
 * Check if a given object implements the PensionBeneficiary interface.
 */
export function instanceOfPensionBeneficiary(value: object): value is PensionBeneficiary {
    return true;
}

export function PensionBeneficiaryFromJSON(json: any): PensionBeneficiary {
    return PensionBeneficiaryFromJSONTyped(json, false);
}

export function PensionBeneficiaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PensionBeneficiary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'birthNumber': json['birthNumber'] == null ? undefined : json['birthNumber'],
        'relationship': json['relationship'] == null ? undefined : PensionBeneficiaryRelationshipFromJSON(json['relationship']),
        'ratio': json['ratio'] == null ? undefined : json['ratio'],
        'address': json['address'] == null ? undefined : PensionBeneficiaryAddressFromJSON(json['address']),
    };
}

export function PensionBeneficiaryToJSON(value?: PensionBeneficiary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth'] as any).toISOString()),
        'birthNumber': value['birthNumber'],
        'relationship': PensionBeneficiaryRelationshipToJSON(value['relationship']),
        'ratio': value['ratio'],
        'address': PensionBeneficiaryAddressToJSON(value['address']),
    };
}

