import { Stack } from "@mui/material";
import { useIsTablet } from "desktop/useIsDesktop";
import { useField } from "formik";

import cs from "../../translations/cs.json";
import {
	possibleTaxSavingFormula,
	yearlyStateContributionFormula,
	yearlyStateContributionFormulaAfterNovel,
} from "../DPS/formulas";
import { BodyTextM, HeadingM, HeadingS } from "../Typography";
import AmountInput from "./AmountInput";
import PossibleTaxSaving from "./PossibleTaxSaving";

const PortalResult = ({ amount }: { amount: number }) => {
	const isTablet = useIsTablet();

	const [field] = useField("amount");
	const monthlyStateContribution =
		yearlyStateContributionFormula(field.value) / 12;
	const monthlyStateContributionAfterNovel =
		yearlyStateContributionFormulaAfterNovel(field.value) / 12;
	const possibleTaxSaving = possibleTaxSavingFormula(field.value);

	return (
		<Stack direction={isTablet ? "row" : "column"} spacing={isTablet ? 0 : 6}>
			<Stack
				sx={{ width: isTablet ? "50%" : "100%", mr: isTablet ? "3%" : 0 }}
				spacing={2}
			>
				<HeadingM sx={{ mb: 4, color: "primary.main" }}>
					{cs.pensionCalculator.result.currentSetting}:
				</HeadingM>
				<Stack spacing={5}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{ minHeight: 42 }}
					>
						<HeadingS mb={0} withoutScale strong>
							{cs.pensionCalculator.result.currentSavings}:
						</HeadingS>
						<HeadingM mb={0}>
							{amount}
							{"\u00A0"}Kč
						</HeadingM>
					</Stack>
					<Stack spacing={3}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<BodyTextM>
								{cs.pensionCalculator.result.stateContributionTillDate}
							</BodyTextM>
							<HeadingM mb={0}>
								{monthlyStateContribution}
								{"\u00A0"}Kč
							</HeadingM>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<BodyTextM>
								{cs.pensionCalculator.result.possibleTaxSaving}
							</BodyTextM>
							<HeadingM mb={0}>
								{possibleTaxSaving}
								{"\u00A0"}Kč
							</HeadingM>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Stack
				sx={{ width: isTablet ? "50%" : "100%", mr: isTablet ? "3%" : 0 }}
				spacing={2}
			>
				<HeadingM sx={{ mb: 4, color: "primary.main" }}>
					{cs.pensionCalculator.result.resultAfterNovel}:
				</HeadingM>
				<Stack spacing={5}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<HeadingS mb={0} withoutScale strong>
							{cs.pensionCalculator.result.yourContribution}:
						</HeadingS>
						<Stack direction="row" sx={{ alignItems: "center" }}>
							<AmountInput />
						</Stack>
					</Stack>
					<Stack spacing={3}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<BodyTextM>
								{cs.pensionCalculator.result.stateContributionFromJuly}
							</BodyTextM>
							<HeadingM
								mb={0}
								sx={{
									color:
										field.value < 1150
											? "#E64415"
											: field.value === 1150
											? "#404040"
											: "#538B4C",
								}}
							>
								{monthlyStateContributionAfterNovel}
								{"\u00A0"}Kč
							</HeadingM>
						</Stack>
						<PossibleTaxSaving />
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default PortalResult;
