import Stack, { StackProps } from "@mui/material/Stack";

const DarkBox = ({ sx, children, ...rest }: StackProps): JSX.Element => (
	<Stack
		sx={{
			p: 5,
			borderRadius: "4px",
			backgroundColor: "grey.ultraLight",
			...sx,
		}}
		{...rest}
	>
		{children}
	</Stack>
);

export default DarkBox;
