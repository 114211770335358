import InputLabel from "@mui/material/InputLabel";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { ErrorMessage, FieldAttributes, useField } from "formik";
import { ChangeEvent, FC } from "react";

import SelectArrowIcon from "../../Icons/SelectArrow";
import FormErrorMessage from "../FormErrorMessage";

export type onSelectChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface SelectProps extends NativeSelectProps, FieldAttributes<any> {
	disabled?: boolean;
	name: string;
	isRequired?: boolean;
}

const Select: FC<SelectProps> = ({
	disabled,
	name,
	label,
	isRequired,
	children,
	isLoading,
	...rest
}) => {
	const [field, { error }] = useField(name);
	if (isLoading) {
		return (
			<>
				<Skeleton variant="text" />
				<Skeleton variant="rectangular" height={48} />
			</>
		);
	}
	return (
		<Stack>
			{label && (
				<InputLabel shrink htmlFor={`${name}-name`} required={isRequired}>
					{label}
				</InputLabel>
			)}
			<NativeSelect
				className={`input ${error ? "invalid" : ""}`}
				required={isRequired}
				disabled={disabled}
				id={`${name}-name`}
				variant="outlined"
				IconComponent={SelectArrowIcon}
				{...rest}
				{...field}
			>
				{children}
			</NativeSelect>
			<ErrorMessage
				name={name}
				render={(msg) => <FormErrorMessage name={name}>{msg}</FormErrorMessage>}
			/>
		</Stack>
	);
};

export const NativeSelectInput = ({
	disabled,
	name,
	label,
	isRequired,
	children,
	isLoading,
	...rest
}: SelectProps) => {
	if (isLoading) {
		return (
			<>
				<Skeleton variant="text" />
				<Skeleton variant="rectangular" height={48} />
			</>
		);
	}
	return (
		<Stack>
			{label && (
				<InputLabel shrink htmlFor={`${name}-name`} required={isRequired}>
					{label}
				</InputLabel>
			)}
			<NativeSelect
				required={isRequired}
				disabled={disabled}
				id={`${name}-name`}
				variant="outlined"
				IconComponent={SelectArrowIcon}
				{...rest}
			>
				{children}
			</NativeSelect>
		</Stack>
	);
};

export default Select;
