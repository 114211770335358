import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { FallbackUI } from ".";
import { DefaultErrorUI } from "./DefaultErrorUi";
import { ErrorBoundaryWithTracking } from "./ErrorBoundaryComponent";
import { ErrorContextProvider, useErrorContext } from "./ErrorContext";

export interface IErrorBoundary {
	fallbackUI?: FallbackUI;
	children: JSX.Element;
}

const ErrorBoundary = ({ fallbackUI, children }: Required<IErrorBoundary>) => {
	const errorContext = useErrorContext();
	const reactPlugin = useAppInsightsContext();

	return (
		<ErrorBoundaryWithTracking
			fallbackUI={fallbackUI}
			appInsights={reactPlugin}
			asyncErrorContext={errorContext}
		>
			{children}
		</ErrorBoundaryWithTracking>
	);
};

export const ErrorBoundaryWithAsyncContext = ({
	fallbackUI = DefaultErrorUI,
	children,
}: IErrorBoundary) => {
	return (
		<ErrorContextProvider>
			<ErrorBoundary fallbackUI={fallbackUI}>{children}</ErrorBoundary>
		</ErrorContextProvider>
	);
};
