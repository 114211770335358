import SvgIcon from "@mui/material/SvgIcon";

const ArmchairIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path d="M20.0068 18.9268C19.9082 18.67 19.6211 18.542 19.3603 18.6397C19.1025 18.7393 18.9746 19.0284 19.0732 19.2862L20.205 22.2266C20.2812 22.4248 20.4706 22.5469 20.6718 22.5469C20.7314 22.5469 20.7919 22.5362 20.8515 22.5137C21.1093 22.4141 21.2372 22.125 21.1386 21.8672L20.0068 18.9268Z" />
			<path d="M4.6392 18.6397C4.3814 18.5411 4.0918 18.669 3.9927 18.9268L2.8618 21.8672C2.7627 22.125 2.8916 22.4141 3.1489 22.5137C3.208 22.5362 3.269 22.5469 3.3286 22.5469C3.5293 22.5469 3.7187 22.4248 3.7954 22.2266L4.9263 19.2862C5.0254 19.0284 4.8965 18.7393 4.6392 18.6397Z" />
			<path d="M17.0518 12.9883H6.9478C6.6714 12.9883 6.4478 13.2119 6.4478 13.4883C6.4478 13.7647 6.6714 13.9883 6.9478 13.9883H17.0518C17.3282 13.9883 17.5518 13.7647 17.5518 13.4883C17.5518 13.2119 17.3281 12.9883 17.0518 12.9883Z" />
			<path d="M21.9639 8.0136V4.9805C21.9639 2.7857 20.1787 1 17.9834 1H6.2427C4.0479 1 2.2622 2.7856 2.2622 4.9805V8.0122H2.0488C0.9189 8.0123 0 8.9312 0 10.0611V16.6553C0 16.9317 0.2236 17.1553 0.5 17.1553H23.5C23.7764 17.1553 24 16.9317 24 16.6553V10.0611C24 8.9355 23.0878 8.0206 21.9639 8.0136ZM23 16.1553H1V10.0611C1 9.483 1.4707 9.0123 2.0488 9.0123H3.4751C4.0532 9.0123 4.5239 9.483 4.5239 10.0611V11.2266C4.5239 11.503 4.7475 11.7266 5.0239 11.7266C5.3003 11.7266 5.5239 11.503 5.5239 11.2266V10.0611C5.5239 8.9312 4.605 8.0123 3.4751 8.0123H3.2622V4.9805C3.2622 3.3369 4.5991 2 6.2427 2H17.9834C19.627 2 20.9639 3.3369 20.9639 4.9805V8.0122H20.5254C19.3955 8.0122 18.4756 8.9311 18.4756 10.061V11.2265C18.4756 11.5029 18.6992 11.7265 18.9756 11.7265C19.252 11.7265 19.4756 11.5029 19.4756 11.2265V10.061C19.4756 9.4829 19.9463 9.0122 20.5254 9.0122H21.9512C22.5293 9.0123 23 9.483 23 10.0611V16.1553Z" />
		</SvgIcon>
	);
};

export default ArmchairIcon;
