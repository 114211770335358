import SvgIcon from "@mui/material/SvgIcon";

const House = (props): JSX.Element => {
	return (
		<SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
			<path d="M18.93 10.53a.5.5 0 0 0-.49.5v9.47H9.61a.5.5 0 0 0-.5.5c0 .28.23.5.5.5h9.32a.5.5 0 0 0 .5-.5v-9.97a.5.5 0 0 0-.5-.5Zm-8.83 4.2a.5.5 0 0 0-.49-.5H5.96a.5.5 0 0 0-.49.5v5.77H3.31v-9.47a.5.5 0 0 0-.5-.5.5.5 0 0 0-.48.5V21c0 .28.22.5.49.5h3.14a.5.5 0 0 0 .5-.5v-5.77H9.6a.5.5 0 0 0 .5-.5ZM21 8.44 10.8.6a.49.49 0 0 0-.6.01L.74 8.46a.5.5 0 0 0-.07.7.5.5 0 0 0 .7.07l9.15-7.6 9.88 7.61a.48.48 0 0 0 .69-.1.5.5 0 0 0-.1-.7Z" />
			<path d="M16.19 18.36a.5.5 0 0 0 .49-.5v-3.48a.5.5 0 0 0-.5-.5H12.6a.5.5 0 0 0-.49.5v3.48c0 .28.22.5.5.5h3.58Zm-3.1-3.48h2.6v2.48h-2.6v-2.48Z" />
		</SvgIcon>
	);
};

export default House;
