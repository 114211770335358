import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import useIsDesktop from "desktop/useIsDesktop";
import { useFormikContext } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import {
	Allocation,
	PossibleAnswer,
	SfdrPreference,
	ZZJInformationRequest,
} from "../../../../models";
import { useQueryKeyFactory } from "../../../context/QueryKeyProvider";
import { blobToBase64 } from "../../../functions";
import { useZZJ } from "../../../queryHooks";
import CircularStepLoader from "../../Drawer/CircularStepLoader";
import ZoomIcon from "../../Icons/Zoom";

interface Props {
	wantFillQuestionare: boolean;
	recommendedAllocation: Allocation[];
	answers: Array<PossibleAnswer>;
	contractId: number;
	setSubmitting: (isSubmitting: boolean) => void;
	setZzjInformation: Dispatch<SetStateAction<ZZJInformationRequest>>;
	sfdrPreference: SfdrPreference;
	page: number;
	setZzjPageIsLoading: Dispatch<SetStateAction<boolean>>;
	fullscreenZZJ: boolean;
	setFullscreenZZJ: Dispatch<SetStateAction<boolean>>;
}

const ZZJ = ({
	setSubmitting,
	wantFillQuestionare,
	recommendedAllocation,
	answers,
	sfdrPreference,
	contractId,
	setZzjInformation,
	page,
	setZzjPageIsLoading,
	fullscreenZZJ,
	setFullscreenZZJ,
}: Props): JSX.Element => {
	const [zzjFile, setZzjFile] = useState();
	const { createQueryKey } = useQueryKeyFactory();
	const isDesktop = useIsDesktop();

	pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	// https://github.com/wojtekmaj/react-pdf/issues/97

	const { values } = useFormikContext();

	const acceptRecommendedStrategyExistingResources =
		JSON.stringify(recommendedAllocation) ===
		JSON.stringify(values.existingResources.allocation);

	const acceptRecommendedStrategyNewResources =
		JSON.stringify(recommendedAllocation) ===
		JSON.stringify(values.newResources.allocation);

	const zzjInformation: ZZJInformationRequest = {
		investmentQuestionnaire: {
			rejected: !wantFillQuestionare,
			answers: wantFillQuestionare ? answers : [],
		},
		sfdrPreference,
		existingResources: values.existingResourcesSelected
			? {
					acceptRecommendedStrategy: acceptRecommendedStrategyExistingResources,
					customAllocation: acceptRecommendedStrategyExistingResources
						? undefined
						: values.existingResources.allocation,
			  }
			: null,
		newResources: values.newResourcesSelected
			? {
					acceptRecommendedStrategy: acceptRecommendedStrategyNewResources,
					customAllocation: acceptRecommendedStrategyNewResources
						? undefined
						: values.newResources.allocation,
			  }
			: null,
	};

	useEffect(() => {
		setZzjInformation(zzjInformation);
		setZzjPageIsLoading(true);
	}, []);

	const { data, isLoading } = useZZJ({
		key: createQueryKey({
			key: [
				values.existingResourcesSelected,
				values.newResourcesSelected,
				wantFillQuestionare,
				answers,
				acceptRecommendedStrategyExistingResources,
				acceptRecommendedStrategyNewResources,
				values.newResources.allocation,
				values.existingResources.allocation,
			],
		}), // regenerate ZZJ only if user has changed any of input parameters otherwise show previously generated ZZJ stored in cache
		contractId,
		getZZJPreviewRequest: {
			zzjInformation,
		},
	});

	useEffect(() => {
		setSubmitting(true);
		if (!isLoading) {
			const processZZJResponse = async () => {
				const zzjBase64 = await blobToBase64(data);
				setSubmitting(false);
				setZzjFile(zzjBase64);
			};
			void processZZJResponse();
		}
	}, [isLoading]);

	return (
		<>
			<Box
				sx={{
					height: "100%",
					overflowY: "scroll",
					".react-pdf__Document, .react-pdf__message": {
						height: "100%",
					},
					".react-pdf__Page": {
						display: "flex",
						justifyContent: "center",
					},
				}}
			>
				<Document
					file={zzjFile}
					noData={<CircularStepLoader />}
					loading={<CircularStepLoader />}
				>
					{!isDesktop && (
						<IconButton
							sx={{
								position: "absolute",
								zIndex: 1,
								top: 8,
								right: 8,
								px: isDesktop ? 3 : "10px",
							}}
							onClick={() => setFullscreenZZJ(!fullscreenZZJ)}
							variant="contained"
						>
							{fullscreenZZJ ? (
								<FullscreenExitIcon
									sx={{ width: "2.4rem", height: "2.4rem" }}
								/>
							) : (
								<ZoomIcon sx={{ width: "1.8rem", height: "2.2rem" }} />
							)}
						</IconButton>
					)}
					<Page
						pageNumber={page}
						renderAnnotationLayer={false}
						renderTextLayer={false}
						width={fullscreenZZJ || isDesktop ? 1000 : 800}
						loading={<CircularStepLoader />}
						onLoadSuccess={() => {
							setZzjPageIsLoading(false);
						}}
					/>
				</Document>
			</Box>
		</>
	);
};

export default ZZJ;
