import {
	ContractPaymentInfo,
	GetLifeDetailResponse,
	PaymentInfoType,
} from "../../../../models";
import { NumberToCZK } from "../../../functions";
import { useLifeDetail } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import InformationList from "../../InformationList";
import CtaPay from "./Pay";

export const getDebtStatusFromPaymentInfo = (
	paymentInfo?: ContractPaymentInfo
): DebtStatus | undefined => {
	if (
		paymentInfo === undefined ||
		(paymentInfo.clientCredit > 0 && paymentInfo.employerCredit > 0)
	)
		return undefined;

	const paymentInstructions = paymentInfo.list.find(
		(paymentInfo) => paymentInfo.type === PaymentInfoType.Regular
	);

	if (!paymentInstructions) return;

	const isClientDebt = paymentInfo.clientCredit < 0;
	const debtStatus: DebtStatus = isClientDebt
		? {
				accountNumber: paymentInstructions.client.accountNumber,
				constantSymbol: paymentInstructions.client.constantSymbol,
				variableSymbol: paymentInstructions.client.variableSymbol,
				debtAmmount: paymentInfo.clientCredit * -1,
				debtMessage: cs.unpaidBalance.unpaidByKlient,
		  }
		: {
				accountNumber: paymentInstructions.employer.accountNumber,
				constantSymbol: paymentInstructions.employer.constantSymbol,
				variableSymbol: paymentInstructions.employer.variableSymbol,
				debtAmmount: paymentInfo.employerCredit * -1,
				debtMessage: cs.unpaidBalance.unpaidByEmployer,
		  };

	return debtStatus;
};

export type DebtStatus = {
	debtAmmount: number | string;
	debtMessage: string;
	accountNumber: string;
	constantSymbol: string;
	variableSymbol: string;
};

interface IDebtCtaPay {
	debtStatus: DebtStatus;
}

export const DebtCtaPay = ({
	debtStatus: {
		accountNumber,
		constantSymbol,
		debtAmmount,
		debtMessage,
		variableSymbol,
	},
}: IDebtCtaPay): JSX.Element => {
	return (
		<CtaPay
			message={
				<InformationList
					title={cs.unpaidBalance.paymentOfUnpaidBalance}
					information={[
						{
							title: cs.global.bankAccountNumber,
							description: accountNumber,
						},
						{
							title: cs.global.amount,
							description:
								typeof debtAmmount === "number"
									? NumberToCZK(debtAmmount)
									: debtAmmount,
						},
						{
							title: cs.global.constantSymbol,
							description: constantSymbol,
						},
						{
							title: cs.global.variableSymbol,
							description: variableSymbol,
						},
					]}
				/>
			}
		>
			{debtMessage}{" "}
			{typeof debtAmmount === "number" ? NumberToCZK(debtAmmount) : debtAmmount}
		</CtaPay>
	);
};

interface IDebtCtaPayById {
	contractId: string;
}

export const DebtCtaPayById = ({ contractId }: IDebtCtaPayById) => {
	const { data } = useLifeDetail<GetLifeDetailResponse>({ id: contractId });
	const debtStatus = getDebtStatusFromPaymentInfo(data?.paymentInfo);
	if (!debtStatus || !(debtStatus.debtAmmount > 0)) return <></>;
	return <DebtCtaPay debtStatus={debtStatus} />;
};
