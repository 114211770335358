import { Link as MUILink } from "@mui/material";
import Box from "@mui/material/Box";
import { LinkBaseProps } from "@mui/material/Link/Link";

import ArrowIcon from "../Icons/Arrow";
import ArrowDownIcon from "../Icons/ArrowDown";
import { BasicTriggerProps } from "./";

type TriggerProps = {
	icon?: JSX.Element;
	disabled?: boolean;
} & BasicTriggerProps &
	LinkBaseProps;

const Trigger = ({
	children,
	onClick,
	type = "button",
	icon,
	disabled,
	sx,
	...rest
}: TriggerProps): JSX.Element => {
	return (
		<MUILink
			type={type}
			onClick={onClick}
			component="button"
			underline="none"
			{...rest}
			sx={
				disabled
					? { ...sx, color: "grey.light", pointerEvents: "none" }
					: { ...sx }
			}
		>
			{icon ? (
				<>
					<Box component="span" sx={{ verticalAlign: "middle" }}>
						{children}
					</Box>
					<Box component="span" sx={{ whiteSpace: "nowrap" }}>
						&nbsp;
						{icon}
					</Box>
				</>
			) : (
				children
			)}
		</MUILink>
	);
};
export default Trigger;

export const TriggerLink = ({
	children,
	onClick,
	type = "button",
	icon,
	sx,
	...rest
}: TriggerProps): JSX.Element => {
	return (
		<Trigger
			type={type}
			onClick={onClick}
			component="button"
			icon={icon}
			{...rest}
			sx={{
				...sx,
				verticalAlign: "unset",
				fontWeight: "normal",
				fontFamily: `"NNNittiGroteskTextRegular", sans-serif`,
			}}
			underline="always"
		>
			{children}
		</Trigger>
	);
};

export const TriggerArrowDown = ({
	children,
	...props
}: LinkBaseProps & BasicTriggerProps): JSX.Element => (
	<Trigger
		{...props}
		icon={
			<ArrowDownIcon
				sx={{
					width: "1.6rem",
					height: "1.6rem",
					ml: 2,
				}}
			/>
		}
	>
		{children}
	</Trigger>
);

export const TriggerArrowRight = ({
	children,
	...props
}: LinkBaseProps & BasicTriggerProps): JSX.Element => (
	<Trigger
		{...props}
		icon={
			<ArrowIcon
				sx={{
					width: "1.6rem",
					height: "1.6rem",
					ml: 2,
				}}
			/>
		}
	>
		{children}
	</Trigger>
);
