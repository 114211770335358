import Tab, { TabProps } from "@mui/material/Tab";
import { Link } from "gatsby-theme-material-ui";

import { BodyTextS } from "../Typography";

export interface Props extends TabProps {
	variant?: "desktop";
}

const LinkTab = ({ sx, label, icon, variant, ...rest }: Props): JSX.Element => {
	return (
		<Tab
			component={Link}
			{...rest}
			sx={() => ({
				...sx,
				width: "25%",
				minWidth: variant === "desktop" ? "100px" : "60px",
				maxWidth: "94px",
				height: variant === "desktop" ? "80px" : "64px",
				minHeight: "0",
				padding: 2,
				textTransform: "none",
			})}
			label={<BodyTextS mb={0}>{label}</BodyTextS>}
			icon={icon}
		/>
	);
};

export default LinkTab;
