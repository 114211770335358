import SvgIcon from "@mui/material/SvgIcon";

const ArrowIconDown = (props): JSX.Element => {
	return (
		<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path d="M15 7.2a.5.5 0 0 0-.7 0l-5.8 6.5V1.3a.5.5 0 0 0-1 0v12.4L1.7 7.2A.5.5 0 1 0 1 8l6.2 6.9a1.1 1.1 0 0 0 .8.4 1.1 1.1 0 0 0 .8-.4l6.2-7c.2-.2.2-.5 0-.7Z" />
		</SvgIcon>
	);
};

export default ArrowIconDown;
