/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Rider
 */
export interface Rider {
    /**
     * 
     * @type {string}
     * @memberof Rider
     */
    riderId: string;
    /**
     * 
     * @type {string}
     * @memberof Rider
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Rider
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof Rider
     */
    validFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Rider
     */
    validTo?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Rider
     */
    isMainRider: boolean;
    /**
     * 
     * @type {number}
     * @memberof Rider
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Rider
     */
    filePath?: string | null;
}

/**
 * Check if a given object implements the Rider interface.
 */
export function instanceOfRider(value: object): value is Rider {
    if (!('riderId' in value) || value['riderId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('isMainRider' in value) || value['isMainRider'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    return true;
}

export function RiderFromJSON(json: any): Rider {
    return RiderFromJSONTyped(json, false);
}

export function RiderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Rider {
    if (json == null) {
        return json;
    }
    return {
        
        'riderId': json['riderId'],
        'name': json['name'],
        'description': json['description'],
        'validFrom': json['validFrom'] == null ? undefined : (new Date(json['validFrom'])),
        'validTo': json['validTo'] == null ? undefined : (new Date(json['validTo'])),
        'isMainRider': json['isMainRider'],
        'amount': json['amount'],
        'filePath': json['filePath'] == null ? undefined : json['filePath'],
    };
}

export function RiderToJSON(value?: Rider | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'riderId': value['riderId'],
        'name': value['name'],
        'description': value['description'],
        'validFrom': value['validFrom'] == null ? undefined : ((value['validFrom'] as any).toISOString()),
        'validTo': value['validTo'] == null ? undefined : ((value['validTo'] as any).toISOString()),
        'isMainRider': value['isMainRider'],
        'amount': value['amount'],
        'filePath': value['filePath'],
    };
}

