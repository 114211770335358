import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";

import cs from "../../../translations/cs.json";
import FormErrorMessage from "../../Form/FormErrorMessage";
import Share from "../../Form/Input/Share";
import RatioDistribution from "../../Form/RatioDistribution";
import { BodyTextM, BodyTextS, HeadingL } from "../../Typography";

const ShareDistribution = (): JSX.Element => {
	const { values } = useFormikContext();
	const sumOfRatios: number = values.ratios.reduce((acc, obj) => {
		if (obj.ratio === undefined) return acc;
		else return acc + obj.ratio;
	}, 0);
	return (
		<Stack sx={{ justifyContent: "space-between", height: "100%" }}>
			<Box>
				<HeadingL data-test="beneficient-funds-change-header">
					{cs.beneficiariesChange.heading}
				</HeadingL>
				<Stack spacing={4}>
					<BodyTextM>{cs.beneficiariesChange.description}</BodyTextM>
					<RatioDistribution
						usedRatio={sumOfRatios}
						shareInputs={
							<>
								<FieldArray
									name="beneficiaries"
									render={() => {
										return (
											<>
												{values.beneficiaries.map(
													({ firstName, lastName }, index) => {
														return (
															<Share
																key={index}
																label={
																	<>
																		<BodyTextM
																			strong
																			data-test="beneficient-changing-funds-persons"
																		>
																			{index + 1}. {cs.global.person}
																		</BodyTextM>
																		<BodyTextS
																			sx={{ mb: 0 }}
																			data-test="beneficient-changing-funds-name-surname"
																		>
																			{firstName} {lastName}
																		</BodyTextS>
																	</>
																}
																name={`ratios.${index}.ratio`}
															/>
														);
													}
												)}
											</>
										);
									}}
								/>
								<ErrorMessage
									name="ratios"
									render={(msg) => (
										<FormErrorMessage name="ratios">{msg}</FormErrorMessage>
									)}
								/>
							</>
						}
					/>
				</Stack>
				<ErrorMessage
					name="valuesUnchanged"
					render={(msg) => (
						<FormErrorMessage name="valuesUnchanged">{msg}</FormErrorMessage>
					)}
				/>
			</Box>
		</Stack>
	);
};

export default ShareDistribution;
