import { Stack } from "@mui/material";

import { AMLRedirect } from "../../../components/AML";
import { BankIDAmlButton } from "../../../components/AML/identification";
import { SEO } from "../../../components/SEO";
import { BodyTextM, HeadingS } from "../../../components/Typography";
import withProtection from "../../../highOrderComponents/withProtection";
import cs from "../../../translations/cs.json";

export const Title = "Identifikace";

const AML = () => {
	return (
		<Stack gap={4}>
			<HeadingS marginBottom={0}>{cs.AML.amlReasoning.greeting}</HeadingS>
			<BodyTextM>{cs.AML.amlReasoning.insurancePayout}</BodyTextM>
			<BankIDAmlButton actionInfo={{ redirect: AMLRedirect.Profile }} />
		</Stack>
	);
};

export const Head = (): JSX.Element => (
	<SEO title={Title}>
		<meta name="robots" content="noindex" />
	</SEO>
);

export default withProtection(AML);
