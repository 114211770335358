import { List, ListItem, Stack } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import cs from "../../translations/cs.json";
import { ContractType } from "../../types/contracts";
import ChangeRequestError from "../Drawer/GlobalSteps/ChangeRequestError";
import { MultiStepChildren } from "../Drawer/MultiStepDrawer";
import SimpleDrawer from "../Drawer/SimpleDrawer";
import { TriggerButton } from "../Link";
import { TriggerButtonProps } from "../Link/TriggerButton";
import { BodyTextM, HeadingS } from "../Typography";
import { AMLRedirect, AMLScenario, BankIdLastActionKey } from ".";

export const getBankIdAuthorizationUri = (): string => {
	return `${process.env.GATSBY_BANK_ID_BASE_URL as string}/auth?client_id=${
		process.env.GATSBY_BANK_ID_CLIENT_ID as string
	}&redirect_uri=${encodeURIComponent(
		`${window.location.origin}/bank-id/callback`
	)}&scope=profile.birthnumber%20profile.verification%20profile.legalstatus%20openid%20profile.gender%20profile.addresses%20profile.birthdate%20profile.name%20profile.birthplaceNationality%20profile.idcards&response_type=token&state=Bank%20iD%20works%21&nonce=${uuidv4()}&prompt=login&display=page&acr_values=loa3`;
};

export type AMLActionInfo = {
	redirect: AMLRedirect;
	contractId: string;
	contractType: ContractType;
};

// eslint-disable-next-line no-undef
type BankIdProps = Partial<TriggerButtonProps> & {
	actionInfo: AMLActionInfo;
};

export const BankIDAmlButton = ({
	onClick,
	actionInfo,
	...rest
}: BankIdProps) => {
	return (
		<TriggerButton
			href={getBankIdAuthorizationUri()}
			variant="bank-id"
			fullWidth
			onClick={() => {
				localStorage.setItem(BankIdLastActionKey, JSON.stringify(actionInfo));
				onClick && onClick();
			}}
			{...rest}
		>
			{cs.AML.fillOutAml}
		</TriggerButton>
	);
};

interface IBankIdContent {
	scenario: AMLScenario;
	showOfflineInstructions?: boolean;
}

export const BankIdIdentificationDrawerContent = ({
	scenario,
	showOfflineInstructions = false,
}: IBankIdContent) => {
	return (
		<Stack direction={"column"} gap={4}>
			<HeadingS marginBottom={0}>{cs.AML.amlReasoning.greeting}</HeadingS>
			<BodyTextM>
				{scenario === "contribution"
					? cs.AML.amlReasoning.monthlyContribution
					: cs.AML.amlReasoning.insurancePayout}
			</BodyTextM>
			<BodyTextM strong>{cs.AML.amlInstructions.online}</BodyTextM>
			{showOfflineInstructions && (
				<>
					<BodyTextM>
						{cs.AML.amlInstructions.offline.physicalIdentification}
					</BodyTextM>
					<BodyTextM>1. {cs.AML.amlInstructions.offline.nnLocation}</BodyTextM>
					<Stack>
						<BodyTextM>
							2. {cs.AML.amlInstructions.offline.paidLocations.label}
						</BodyTextM>
						<List sx={{ pl: 4, listStyleType: "disc" }}>
							<ListItem sx={{ display: "list-item", pl: 0 }}>
								{cs.AML.amlInstructions.offline.paidLocations.govermentOffice}
							</ListItem>
							<ListItem sx={{ display: "list-item", pl: 0 }}>
								{cs.AML.amlInstructions.offline.paidLocations.notarius}
							</ListItem>
							<ListItem sx={{ display: "list-item", pl: 0 }}>
								{cs.AML.amlInstructions.offline.paidLocations.czechPoint}
							</ListItem>
						</List>
						<BodyTextM>{cs.AML.amlInstructions.offline.sendIdList}</BodyTextM>
					</Stack>
				</>
			)}
		</Stack>
	);
};

interface IBankIdIdentificationDrawer extends IBankIdContent {
	action: AMLActionInfo;
	open?: boolean;
	onClick?: () => void;
	onCloseCallback?: () => void;
	disabled?: boolean;
}

export const BankIdIdentification = ({
	onCloseCallback,
	action,
	onClick,
	open = false,
	...rest
}: IBankIdIdentificationDrawer) => {
	return (
		<SimpleDrawer
			title={cs.AML.fillOutAml}
			open={open}
			isCloseIcon
			customButton={<BankIDAmlButton onClick={onClick} actionInfo={action} />}
			onClose={() => {
				onCloseCallback && onCloseCallback();
			}}
		>
			<BankIdIdentificationDrawerContent {...rest} />
		</SimpleDrawer>
	);
};

interface IGetBankIdIndetificationDrawerStep {
	actionInfo: AMLActionInfo;
	scenario: AMLScenario;
	onSkip: () => void;
}

export const GetBankIdIndetificationDrawerStep = ({
	actionInfo,
	scenario,
	onSkip,
}: IGetBankIdIndetificationDrawerStep): MultiStepChildren => {
	return {
		component: <BankIdIdentificationDrawerContent scenario={scenario} />,
		stepName: "AMLIdentification",
		stepConfig: {
			customNextButton: (
				<Stack direction="row" gap={4}>
					<BankIDAmlButton actionInfo={actionInfo} />
					<TriggerButton variant="outlined" onClick={onSkip}>
						Přeskočit
					</TriggerButton>
				</Stack>
			),
		},
	};
};

interface IGetBankIdIndetificationDrawerErrorStep {
	errorMessage?: string;
	onSkip: () => void;
}

export const GetBankIdIndetificationDrawerErrorStep = ({
	errorMessage,
	onSkip,
}: IGetBankIdIndetificationDrawerErrorStep): MultiStepChildren => {
	return {
		stepName: "BankIdError",
		component: (
			<ChangeRequestError
				description={
					errorMessage ?? cs.AML.bankIdErrorMessages.bankIdValidationError
				}
			/>
		),
		stepConfig: {
			customFunction() {
				onSkip();
			},
		},
	};
};
