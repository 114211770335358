/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Contacts
 */
export interface Contacts {
    /**
     * 
     * @type {string}
     * @memberof Contacts
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contacts
     */
    phoneAdditional?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contacts
     */
    email?: string | null;
}

/**
 * Check if a given object implements the Contacts interface.
 */
export function instanceOfContacts(value: object): value is Contacts {
    return true;
}

export function ContactsFromJSON(json: any): Contacts {
    return ContactsFromJSONTyped(json, false);
}

export function ContactsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contacts {
    if (json == null) {
        return json;
    }
    return {
        
        'phone': json['phone'] == null ? undefined : json['phone'],
        'phoneAdditional': json['phoneAdditional'] == null ? undefined : json['phoneAdditional'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function ContactsToJSON(value?: Contacts | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'phone': value['phone'],
        'phoneAdditional': value['phoneAdditional'],
        'email': value['email'],
    };
}

