import { FetchStatus, QueryStatus } from "@tanstack/react-query";

import { FetchApiError } from "../../../context/PrimaryAPIClient";
import cs from "../../../translations/cs.json";
import { CustomApiError } from "../../../types/errors";
import ChangeRequestConfirmation, {
	IChangeRequestConfirmation,
} from "./ChangeRequestConfirmation";
import ChangeRequestError, { IChangeRequestError } from "./ChangeRequestError";
import ChangeRequestLoading, {
	IChangeRequestLoading,
} from "./ChangeRequestLoading";

export const mapQueryStatusToSubmitStatus = (
	status: QueryStatus | FetchStatus
): SubmitStatus => {
	switch (status) {
		case "loading":
			return SubmitStatus.Submitting;
		case "error":
			return SubmitStatus.Error;
		case "success":
			return SubmitStatus.Success;
		default:
			return SubmitStatus.Idle;
	}
};

export const mapFetchApiErrorToSubmitStatus = (
	error: unknown
): SubmitStatus => {
	if (error instanceof FetchApiError) {
		switch (error.errorResponse.code) {
			case CustomApiError.StarPensionCallBlocked:
				return SubmitStatus.Blocked;
			default:
				return SubmitStatus.Error;
		}
	}
	return SubmitStatus.Error;
};

export enum SubmitStatus {
	Success = "success",
	Submitting = "submitting",
	Blocked = "blocked",
	Error = "error",
	Idle = "idle",
}

// Creates type union from enum.
type StatusType = `${SubmitStatus}`;

type StatusProps<TStepProps> =
	| {
			customElement: JSX.Element;
	  }
	| TStepProps;

interface ISubmitStatusStepProps {
	status: StatusType;
	successProps?: StatusProps<IChangeRequestConfirmation>;
	loadingProps?: StatusProps<IChangeRequestLoading>;
	errorProps?: StatusProps<IChangeRequestError>;
}

export const SubmitStatusStep = ({
	status,
	successProps = {},
	loadingProps = {},
	errorProps = {},
}: ISubmitStatusStepProps) => {
	const statusToComponent: Record<SubmitStatus, JSX.Element> = {
		[SubmitStatus.Success]:
			"customElement" in successProps ? (
				successProps.customElement
			) : (
				<ChangeRequestConfirmation {...successProps} />
			),
		[SubmitStatus.Submitting]:
			"customElement" in loadingProps ? (
				loadingProps.customElement
			) : (
				<ChangeRequestLoading {...loadingProps} />
			),
		[SubmitStatus.Blocked]: (
			<ChangeRequestConfirmation
				description={cs.global.changeIsBlocked}
				documentsAttentionOverride={null}
			/>
		),
		[SubmitStatus.Error]:
			"customElement" in errorProps ? (
				errorProps.customElement
			) : (
				<ChangeRequestError {...errorProps} />
			),
		[SubmitStatus.Idle]: <></>,
	};

	return statusToComponent[status];
};
