import { Alert } from "@mui/material";
import Stack from "@mui/material/Stack";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { bottomLeftBannerLink } from "../../constants";
import { InternalArrowLink } from "../Link/InternalLink";
import { BodyTextM, HeadingL, HeadingM } from "../Typography";

interface imageProps {
	intro_image: {
		publicURL: string;
	};
}

const introImage = (): imageProps => {
	return useStaticQuery<Queries.ShipBoxShipImageQuery>(
		graphql`
			query ShipBoxShipImage {
				intro_image: file(relativePath: { eq: "mobils.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		`
	);
};

const BottomLeftBanner = (): JSX.Element => {
	const image = getImage(introImage().intro_image);
	return (
		<Alert
			sx={{
				flexDirection: "row-reverse",
				py: 3,
				px: 4,
				boxShadow: "far",
				border: "1px solid",
				borderColor: "grey.light",
				backgroundColor: "white.main",
				".MuiAlert-message": {
					flexGrow: 1, // for wide layouts
				},
			}}
			icon={false}
			severity={undefined}
		>
			<BgImage
				image={image}
				style={{
					backgroundPosition: "right bottom",
					backgroundRepeat: "no-repeat",
					backgroundSize: "122px 115px",
				}}
			>
				<HeadingL sx={{ display: "inline-block", mb: 3 }} scaleDown>
					Pečujte o sebe s aplikací Plus Care
				</HeadingL>
				<Stack mb={0} spacing={2} sx={{ color: "grey.dark" }}>
					<BodyTextM component="p" sx={{ pr: "80px" }}>
						<BodyTextM strong>Benefit pro klienty NN</BodyTextM>
					</BodyTextM>
					<BodyTextM component="p" sx={{ pr: "50px", textAlign: "justify" }}>
						Aplikace Plus Care vám pomůže s péčí o zdraví, ale dokáže i ušetřit
						peníze. Například se slevou 10 % na Pilulka.cz nebo díky přehledu
						benefitů vaší zdravotní pojišťovny.
					</BodyTextM>
					<BodyTextM>Kód členství pro klienty NN:</BodyTextM>
				</Stack>
				<HeadingM>nnprevence</HeadingM>
				<InternalArrowLink to={bottomLeftBannerLink} target="_blank">
					Více informací zde
				</InternalArrowLink>
			</BgImage>
		</Alert>
	);
};

export default BottomLeftBanner;
