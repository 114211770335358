import {
	Divider,
	IconButton,
	Paper,
	Skeleton,
	Stack,
	StackProps,
} from "@mui/material";
import Box from "@mui/material/Box";
import { isValidElement, ReactElement } from "react";

import InfoIcon from "../../components/Icons/Info";
import { useDrawer } from "../Drawer/context";
import HeaderWithDescription, {
	HeaderWithDescriptionProps,
} from "../HeaderWithDescription";
import { HeadingM } from "../Typography";

export interface Props extends StackProps {
	title?: string;
	infoIconDrawer?: ReactElement;
	action?: ReactElement;
	information: (HeaderWithDescriptionProps | ReactElement | false)[];
	isLoading?: boolean;
	secondaryAction?: ReactElement;
}

function InformationList({
	title,
	infoIconDrawer,
	action,
	secondaryAction,
	information,
	isLoading,
	sx,
	...rest
}: Props): JSX.Element {
	const { showDrawer, setDrawerContent } = useDrawer();
	return (
		<Stack {...rest} sx={{ height: { md: "auto", xxs: "100%" }, ...sx }}>
			{((title || action) && !isLoading) || isLoading === undefined ? ( // show loading skeletons but only for InformationList which uses isLoading prop
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
				>
					{title && (
						<>
							{infoIconDrawer ? (
								<Stack direction="row" alignItems="flex-start">
									<HeadingM
										sx={{
											display: "inline",
											verticalAlign: "middle",
											mr: 2,
											mb: 4,
										}}
										withoutScale
									>
										{title}
									</HeadingM>
									&nbsp;
									<IconButton
										data-test="info-icon"
										onClick={() => {
											showDrawer();
											setDrawerContent(infoIconDrawer);
										}}
										sx={{ p: 1 }}
										size="small"
									>
										<InfoIcon
											color="text"
											sx={{ width: "1.8rem", height: "1.8rem" }}
										/>
									</IconButton>
								</Stack>
							) : (
								<HeadingM withoutScale mb={4}>
									{title}
								</HeadingM>
							)}
						</>
					)}
					{action}
				</Stack>
			) : (
				isLoading && <Skeleton width={250} sx={{ mb: 4 }} /> // TODO: Don´t show skeletons when the title prop is not set (make a difference between unset title prop and title set to undefined). Title is set to undefined when we are waiting on response from API and title is unset when we do not want to show any title at all.
			)}
			<Paper
				sx={{
					height: "100%",
					p: { md: 5, xxs: 4 },
				}}
			>
				<Stack spacing={3} divider={<Divider />}>
					{information.map((singleInformation, i) => {
						if (singleInformation) {
							if (isValidElement(singleInformation)) {
								return singleInformation;
							} else {
								const params = isLoading
									? {
											title: singleInformation.title || (
												<Skeleton width="100%" />
											),
											description: <Skeleton width="100%" />,
											additionalElements:
												singleInformation.additionalElements && (
													<Skeleton width={50} sx={{ ml: 3 }} />
												),
									  }
									: singleInformation;
								return <HeaderWithDescription key={i} {...params} />;
							}
						}
					})}
				</Stack>
				<Box sx={{ alignItems: "end" }}>{secondaryAction}</Box>
			</Paper>
		</Stack>
	);
}

export default InformationList;

export interface InformationListSkeletonProps extends StackProps {
	title?: string;
	length: number;
	action?: ReactElement;
}

export function InformationListSkeleton({
	length,
	title,
	action,
	sx,
}: InformationListSkeletonProps): JSX.Element {
	return (
		<InformationList
			title={title}
			information={new Array(length).fill({
				title: <Skeleton />,
				description: <Skeleton width={"100%"} />,
			})}
			action={action}
			sx={sx}
		/>
	);
}
