import { useIsAuthenticated } from "@azure/msal-react";
import { Button } from "gatsby-theme-material-ui";
import { useEffect } from "react";

import { PortalAppBar } from "../../components/AppBar/Bars";
import Attention from "../../components/AttentionBlock";
import ErrorContent from "../../components/ErrorPage/ErrorContent";
import CaretRight from "../../components/Icons/CaretRight";
import { useLayout } from "../../components/Layout/context";
import { SEO } from "../../components/SEO";

export const AppBar = (): JSX.Element => <PortalAppBar />;

const NotFoundPageContent = ({ pageContext }): JSX.Element => {
	const isAuthorized = useIsAuthenticated();
	return (
		<ErrorContent>
			<Attention severity="error" title="Stránka nenalezena">
				Ajaj! Možná jste se překlikli nebo stránka neexistuje. Pokud chcete
				kouknout na Portál, klikněte na Chci na Portál.
			</Attention>
			<Button
				to={isAuthorized ? "/smlouvy" : "/prihlaseni-nebo-registrace"}
				variant="contained"
				endIcon={<CaretRight />}
			>
				Chci na Portál
			</Button>
		</ErrorContent>
	);
};

const NotFoundPage = (): JSX.Element => {
	const isAuthorized = useIsAuthenticated();
	const { setBottomNavigation } = useLayout();
	useEffect(() => {
		if (isAuthorized) setBottomNavigation("shown");
		return () => {
			setBottomNavigation("unset");
		};
	}, [isAuthorized]);

	return <NotFoundPageContent />;
};

export default NotFoundPage;

export const Head = (): JSX.Element => (
	<SEO title="Stránka nenalezena">
		<meta name="robots" content="noindex" />
	</SEO>
);
