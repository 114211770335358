import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { default as MUISlider } from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import { FieldAttributes, useField } from "formik";
import { FC } from "react";

import { BodyTextM, BodyTextS } from "../../Typography";
import FormErrorMessage from "../FormErrorMessage";
import NumberInput from "../Input/Number";
import Switch from "../Input/Switch";

export interface SliderProps extends FieldAttributes<any> {
	disabled?: boolean;
	error?: boolean;
	name: string;
	minLabel: string;
	maxLabel: string;
	min: number;
	max: number;
	step: number;
	defaultValue: number;
	label: string;
	subLabel?: string;
	values?: { [key: string]: number | string | boolean };
	switchTitle?: string;
	showSwitch: boolean;
	inputIgnoresMaxInterval: boolean;
	isRequired?: boolean;
}

const Slider: FC<SliderProps> = ({
	disabled = false,
	error = false,
	name,
	minLabel,
	maxLabel,
	min,
	max,
	step,
	defaultValue,
	label,
	subLabel,
	values,
	switchTitle,
	showSwitch,
	inputIgnoresMaxInterval,
	isRequired,
	...rest
}) => {
	const [field, meta] = useField(name);

	return (
		<>
			{showSwitch && (
				<Switch name="showSlider" label={switchTitle} sx={{ mb: 5 }} />
			)}
			{((values && values.showSlider) || !showSwitch) && (
				<>
					<Stack
						direction="row"
						sx={{ justifyContent: "space-between", alignItems: "center" }}
					>
						<Stack>
							<InputLabel htmlFor={name} required={isRequired}>
								{label}
							</InputLabel>
							<BodyTextS mb={0}>{subLabel}</BodyTextS>
						</Stack>
						<NumberInput
							sx={{ width: 88 }}
							min={min}
							max={max}
							inputIgnoresMaxInterval={inputIgnoresMaxInterval}
							hideError={true}
							data-test={`${name}-input`}
							{...rest}
							{...field}
						/>
					</Stack>
					<Box sx={{ p: 4 }}>
						<MUISlider
							className={`input ${error ? "invalid" : ""}`}
							id={`${name}-name`}
							data-test={`${name}-slider-input`}
							disabled={disabled}
							defaultValue={defaultValue}
							min={min}
							max={max}
							step={step}
							{...rest}
							{...field}
						/>
						<Stack direction="row" sx={{ justifyContent: "space-between" }}>
							<BodyTextM sx={{ ml: -4 }}>{minLabel}</BodyTextM>
							<BodyTextM sx={{ mr: -4 }}>{maxLabel}</BodyTextM>
						</Stack>
					</Box>
				</>
			)}
			{meta.error && (
				<FormErrorMessage name={name}>{meta.error}</FormErrorMessage>
			)}
		</>
	);
};

export default Slider;
