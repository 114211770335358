import { Typography, TypographyProps } from "@mui/material";
import { ComponentType, ElementType } from "react";

/**
 * Nejsem si jistej, jak moc toto dokaze poradit, dal jsem to sem, protoze neco
 * takoveho by nam melo pomoc usmernit material ui, typy pro propertu elevation
 *
 * Beru to tak, ze timto akceptujeme v elevation nejenom indexy (presto indexy
 * podporujeme dale).
 */
declare module "@mui/material/Paper" {
	interface PaperTypeMap<P = unknown, D extends ElementType = "div"> {
		props: P & {
			/**
			 * Shadow depth, corresponds to `dp` in the spec.
			 * It accepts values between 0 and 24 inclusive.
			 * @default 1
			 */
			elevation?: number | "close" | "far" | "extraFar";
		};
	}
}

type CustomVariantProps = Omit<TypographyProps, "variant">;

function withCustomization<T extends CustomVariantProps>(
	Component: ComponentType<T>
) {
	return function wrapperWithCustomization({
		strong,
		light,
		withoutScale,
		scaleDown,
		...props
	}: T & {
		strong?: boolean;
		light?: boolean;
		withoutScale?: boolean;
		scaleDown?: boolean;
	}): JSX.Element {
		if (strong) {
			props.component = "strong";
		}
		if (light) {
			props.sx = { ...props.sx, color: "grey.dark" };
		}
		if (withoutScale) {
			props.className = "withoutScale";
		}
		if (scaleDown) {
			props.className = "scaleDown";
		}

		return <Component {...props} />;
	};
}

function FHeadingXL(props: CustomVariantProps): JSX.Element {
	return <Typography component="h1" {...props} variant="h1" />;
}
export const HeadingXL = withCustomization(FHeadingXL);

function FHeadingL(props: CustomVariantProps): JSX.Element {
	return <Typography component="h1" {...props} variant="h1" />;
}
export const HeadingL = withCustomization(FHeadingL);

function FHeadingM(props: CustomVariantProps): JSX.Element {
	return <Typography component="h2" {...props} variant="h2" />;
}
export const HeadingM = withCustomization(FHeadingM);

function FHeadingS(props: CustomVariantProps): JSX.Element {
	return <Typography component="h3" {...props} variant="h3" />;
}
export const HeadingS = withCustomization(FHeadingS);

function FHeadingXS(props: CustomVariantProps): JSX.Element {
	return <Typography component="h4" {...props} variant="h4" />;
}
export const HeadingXS = withCustomization(FHeadingXS);

function FBodyTextXS(props: CustomVariantProps): JSX.Element {
	return <Typography {...props} variant="bodyTextXS" />;
}
export const BodyTextXS = withCustomization(FBodyTextXS);

function FBodyTextS(props: CustomVariantProps): JSX.Element {
	return <Typography {...props} variant="bodyTextS" />;
}
export const BodyTextS = withCustomization(FBodyTextS);

function FBodyTextM(props: CustomVariantProps): JSX.Element {
	return <Typography {...props} variant="bodyTextM" />;
}
export const BodyTextM = withCustomization(FBodyTextM);

function FBodyTextL(props: CustomVariantProps): JSX.Element {
	return <Typography {...props} variant="bodyTextL" />;
}
export const BodyTextL = withCustomization(FBodyTextL);
