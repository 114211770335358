import { createContext, ReactNode, useContext } from "react";

import { ContractType } from "../types/contracts";
import {
	PrimaryAPIClientContext,
	usePrimaryAPIClient,
} from "./PrimaryAPIClient";

export interface IContractTypeContext {
	contractType: ContractType;
}

const ContractTypeContext = createContext<IContractTypeContext>({});

export function useContractTypeContext(): IContractTypeContext {
	return useContext(ContractTypeContext);
}

interface Props {
	children: ReactNode;
	contractType?: ContractType;
}

/**
 * Vytvareno s pozadavkem tam vlozit contractType
 * @param children
 * @param contractType
 * @constructor
 */
export function ContractTypeProvider({
	children,
	contractType,
}: Props): JSX.Element {
	const APIs = usePrimaryAPIClient();
	return (
		<ContractTypeContext.Provider
			value={{
				contractType,
			}}
		>
			<PrimaryAPIClientContext.Provider
				value={{
					...APIs,
				}}
			>
				{children}
			</PrimaryAPIClientContext.Provider>
		</ContractTypeContext.Provider>
	);
}
