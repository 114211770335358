import { FauxLoggedInBar } from "../../components/AppBar/Bars";
import Attention from "../../components/AttentionBlock";
import ErrorContent from "../../components/ErrorPage/ErrorContent";
import { PhoneLink } from "../../components/Link/ContactLink";
import { SEO } from "../../components/SEO";

export const AppBar = (): JSX.Element => <FauxLoggedInBar />;

const PidError = ({ pageContext }): JSX.Element => {
	return (
		<ErrorContent>
			<Attention severity="success" title="Ještě jeden krok do portálu">
				<p>
					Dobrá zpráva, už jen jeden krok vás dělí od přístupu do Vašeho
					portálu.
				</p>

				<p>
					<b>
						Pro bezpečí Vašeho účtu potřebujeme ještě ověřit, že jste to
						skutečně vy.
					</b>{" "}
					Ověření se snažíme provést co nejrychleji.{" "}
					<b>
						Účet Vám v následujících dnech vytvoříme, budou-li Vaše údaje v
						souhlasit s tím, co máte uvedeno na smlouvě.
					</b>
				</p>

				<p>
					<b>
						Pokud však potřebujete registraci dokončit rychle, zavolejte nám
						prosím na číslo{" "}
						<PhoneLink phone="+420244090800">244 090 800</PhoneLink>
					</b>{" "}
					a společně to dáme dohromady. Jsme tu pro vás od pondělí do pátku v
					čase 8 - 18 hod.
				</p>
			</Attention>
		</ErrorContent>
	);
};

export default PidError;

export const Head = (): JSX.Element => (
	<SEO title="Uživatel nenalezen">
		<meta name="robots" content="noindex" />
	</SEO>
);
