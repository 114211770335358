import { useMsal } from "@azure/msal-react";
import { useState } from "react";

import { LoginButtonType } from ".";

const localLogin =
	process.env.GATSBY_LOGIN_FLOW === "SIMPLE" &&
	process.env.GATSBY_B2C_SIGNUP_SIGNIN_POLICY_SIMPLE
		? process.env.GATSBY_B2C_SIGNUP_SIGNIN_POLICY_SIMPLE
		: "B2C_1A_LOGINPOLICY";

const journeyToPolicyName = {
	"bank-id": "B2C_1A_BANKIDPOLICY",
	"local-login": localLogin,
	"local-registration": "B2C_1A_REGISTRATIONPOLICY",
};

interface useLoginProps {
	isDisabled: boolean;
	click: () => void;
}

export default (journey: LoginButtonType): useLoginProps => {
	const msal = useMsal();
	const [isDisabled, setIsDisabled] = useState(false);

	const click = async () => {
		setIsDisabled(true);

		try {
			const domain = process.env.GATSBY_B2C_DOMAIN as string;
			const tenantId = process.env.GATSBY_B2C_TENANT_ID as string;
			const policy = journeyToPolicyName[journey];
			const authority = `https://${domain}/${tenantId}/${policy}`;
			localStorage.setItem("lastUsedAuthority", authority);

			await msal.instance.loginRedirect({
				scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE || ""],
				authority,
			});
		} catch (error) {
			setIsDisabled(false);
		} finally {
			setIsDisabled(false);
		}
	};

	return {
		isDisabled,
		click,
	};
};
