import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import csLocale from "date-fns/locale/cs";
import useIsDesktop from "desktop/useIsDesktop";
import {
	VictoryAxis,
	VictoryChart,
	VictoryContainer,
	VictoryLine,
	VictoryScatter,
} from "victory";

import { getXAxisLabelFormats } from "../../functions/dates";
import ChartLabel from "./Label";
import Props from "./props";
import { Chart as ChartTypes } from "./props";
import { animationConfig, circleSize, tickLabels } from "./styles";

const Chart = ({ data }: Props): JSX.Element => {
	const theme = useTheme();
	const isDesktop = useIsDesktop();

	const isDiffBetweenYPointsMinimal = (data: ChartTypes[]) => {
		const differences = [];
		for (const object of data) {
			const dataArray = object.data;
			const values = dataArray.map((item) => item.value);
			const highestValue = Math.max(...values);
			const lowestValue = Math.min(...values);
			const difference = highestValue - lowestValue;
			differences.push(difference);
		}
		const even = (element: number) => element <= 0.0005;
		const answer = differences.some(even);
		return answer;
	};

	const findHighestValue = (data: ChartTypes[]) => {
		let highestValue = -Infinity;
		for (const object of data) {
			const dataArray = object.data;
			const values = dataArray.map((item) => item.value);
			const objectHighestValue = Math.max(...values);
			if (objectHighestValue > highestValue) {
				highestValue = objectHighestValue;
			}
		}
		return highestValue;
	};
	const highest = findHighestValue(data);

	return (
		<Box sx={{ svg: { overflow: "visible" } }}>
			<VictoryChart
				domainPadding={{ x: [0, circleSize], y: [25, circleSize] }}
				width={isDesktop ? 800 : 400}
				padding={{ top: 40, bottom: 40, left: 50, right: 25 }}
				containerComponent={
					<VictoryContainer style={{ touchAction: "auto" }} />
				}
				minDomain={
					isDiffBetweenYPointsMinimal(data) === true && { y: highest - 0.25 }
				}
				maxDomain={
					isDiffBetweenYPointsMinimal(data) === true && { y: highest + 0.25 }
				}
			>
				<VictoryAxis
					animate={animationConfig}
					dependentAxis
					style={{
						grid: { stroke: theme.palette.grey.light, strokeWidth: 1 },
						axis: { stroke: "transparent" },
						tickLabels: {
							...tickLabels,
							fill: theme.palette.grey.dark,
						},
					}}
				/>
				<VictoryAxis
					animate={animationConfig}
					crossAxis
					style={{
						axis: { stroke: theme.palette.grey.softDark },
						tickLabels: {
							...tickLabels,
							fill: theme.palette.grey.dark,
						},
					}}
					tickFormat={(tickValue) =>
						format(new Date(tickValue), getXAxisLabelFormats(data, isDesktop), {
							locale: csLocale,
						})
					}
				/>
				{data.map((line) => (
					<VictoryLine
						animate={animationConfig}
						x="date"
						y="value"
						style={{
							data: {
								stroke: line.palette.main,
								strokeWidth: 2,
							},
						}}
						data={line.data}
						key={line.name}
					/>
				))}
				{data.map((line) => (
					<VictoryScatter
						animate={animationConfig}
						x="date"
						y="value"
						style={{
							data: {
								fill: line.palette.main,
							},
						}}
						size={circleSize}
						data={line.data}
						key={line.name}
					/>
				))}
			</VictoryChart>
			{data.map((line) => {
				return (
					<ChartLabel key={line.name} color={line.palette.main}>
						{line.name}
					</ChartLabel>
				);
			})}
		</Box>
	);
};

export default Chart;
