import { bool, number, object, string } from "yup";

import { FundCode, StrategyCode } from "../../../../../models";
import cs from "../../../../translations/cs.json";
import { MultiStepChildren } from "../../../Drawer/MultiStepDrawer";
import { allocationSchema } from "../../../Form/rules";
import {
	existingResourcesAllocation,
	newResourcesAllocation,
} from "./dataFormatting";
import { checkInvestmentStrategyValuesChanges } from "./utils";

function findObjectIndexByStepName(
	array: number[] | MultiStepChildren[],
	stepName: string
) {
	const stepNames = array.map((obj) => obj.stepName);
	const index = stepNames.indexOf(stepName);
	if (index === -1) return 99999;
	else return index;
}

export const isChangeAllocationStep = (
	step: number,
	steps: number[] | MultiStepChildren[]
): boolean => {
	return (
		step >= findObjectIndexByStepName(steps, "EditInvestmentStrategy1") ||
		step >= findObjectIndexByStepName(steps, "EditInvestmentStrategy2")
	);
};

export const isSelectedResourcesStep = (
	step: number,
	steps: number[] | MultiStepChildren[]
): boolean => {
	return step >= findObjectIndexByStepName(steps, "InvestmentWhatToChange");
};

export const isPhoneVerificationStep = (
	step: number,
	steps: number[] | MultiStepChildren[]
): boolean => step >= findObjectIndexByStepName(steps, "SMS");

export const conservativeAllocation = (results): boolean =>
	results?.investmentStrategy?.code === StrategyCode.Con ||
	results?.investmentStrategy?.code === StrategyCode.Rejected ||
	results?.investmentStrategy?.code === StrategyCode.Prepension;

const maxFund = (parent, fundCode, maxAllocation) => {
	return (
		parent.allocation.find((el) => el.fundCode === fundCode).allocation <
			maxAllocation || parent.overrideFundsAllocationRecommendation
	);
};

export const maxFundAllocation = (ruleName, fundCode, maxAllocation) => {
	return number().test(
		ruleName,
		cs.investmentStrategy.outOfRecommendedStrategyInterval,
		(value, { parent /* all key: values */, path /* current key */ }) =>
			maxFund(parent, fundCode, maxAllocation)
	);
};

export const needsOverride = (results) => {
	return bool().test(
		"needsOverride",
		cs.investmentStrategy.editInvestmentStrategy.acceptRisksError,
		(value, { parent /* all key: values */, path /* current key */ }) => {
			return (
				(conservativeAllocation(results) &&
					maxFund(parent, FundCode.Bal, 41) &&
					maxFund(parent, FundCode.Grow, 1)) ||
				(results?.investmentStrategy.code === StrategyCode.Bal &&
					maxFund(parent, FundCode.Con, 41) &&
					maxFund(parent, FundCode.Grow, 11)) ||
				(results?.investmentStrategy.code === StrategyCode.Dyn &&
					maxFund(parent, FundCode.Con, 1) &&
					maxFund(parent, FundCode.Bal, 51))
			);
		}
	);
};

export const allocationRecommendation = (results) => ({
	conBalMaxAlloc:
		// max allocations taken from Stela 1
		// funds with max allocation 100% are useless to test with custom rule, we already checking max 100% allocation for every fund by another rule
		conservativeAllocation(results) &&
		maxFundAllocation("conBalMaxAlloc", FundCode.Bal, 41),
	conDynMaxAlloc:
		conservativeAllocation(results) &&
		maxFundAllocation("conDynMaxAlloc", FundCode.Grow, 1),
	balConMaxAlloc:
		results?.investmentStrategy.code === StrategyCode.Bal &&
		maxFundAllocation("balConMaxAlloc", FundCode.Con, 41),
	balDynMaxAlloc:
		results?.investmentStrategy.code === StrategyCode.Bal &&
		maxFundAllocation("balDynMaxAllo", FundCode.Grow, 11),
	dynConMaxAlloc:
		results?.investmentStrategy.code === StrategyCode.Dyn &&
		maxFundAllocation("dynConMaxAlloc", FundCode.Con, 1),
	dynBalMaxAlloc:
		results?.investmentStrategy.code === StrategyCode.Dyn &&
		maxFundAllocation("dynBalMaxAlloc", FundCode.Bal, 51),
	prepensionBalMaxAlloc:
		conservativeAllocation(results) &&
		maxFundAllocation("prepensionBalMaxAlloc", FundCode.Bal, 41),
	prepensionDynMaxAlloc:
		conservativeAllocation(results) &&
		maxFundAllocation("prepensionDynMaxAlloc", FundCode.Grow, 1),
});

export const maxRecommendedAllocations = {
	// Taken from Stela 1, this data don´t come from any API
	[StrategyCode.Prepension]: [
		{
			fundCode: "CON",
			allocation: 100,
		},
		{
			fundCode: "BAL",
			allocation: 40,
		},
		{
			fundCode: "GROW",
			allocation: 0,
		},
	],
	[StrategyCode.Rejected]: [
		{
			fundCode: "CON",
			allocation: 100,
		},
		{
			fundCode: "BAL",
			allocation: 40,
		},
		{
			fundCode: "GROW",
			allocation: 0,
		},
	],
	[StrategyCode.Con]: [
		{
			fundCode: "CON",
			allocation: 100,
		},
		{
			fundCode: "BAL",
			allocation: 40,
		},
		{
			fundCode: "GROW",
			allocation: 0,
		},
	],
	[StrategyCode.Bal]: [
		{
			fundCode: "CON",
			allocation: 0,
		},
		{
			fundCode: "BAL",
			allocation: 100,
		},
		{
			fundCode: "GROW",
			allocation: 10,
		},
	],
	[StrategyCode.Dyn]: [
		{
			fundCode: "CON",
			allocation: 0,
		},
		{
			fundCode: "BAL",
			allocation: 50,
		},
		{
			fundCode: "GROW",
			allocation: 100,
		},
	],
};

const phoneVerificationSchema = (step: number, steps) =>
	isPhoneVerificationStep(step, steps)
		? string()
				.required(cs.investmentStrategy.errorMessages.verificationCode)
				.matches(/^[0-9]+$/, cs.errorMessages.onlyNumbers)
				.min(8, cs.investmentStrategy.errorMessages.eightNumbers)
				.max(8, cs.investmentStrategy.errorMessages.eightNumbers)
		: string();

// tell what custom error from yup schema should be displayed per what field from formik field array
// yup can´t set validation rule for specific index in field array, that's why we doing this map
export const errorMap = {
	[StrategyCode.Rejected]: {
		[FundCode.Con]: "",
		[FundCode.Bal]: "conBalMaxAlloc",
		[FundCode.Grow]: "conDynMaxAlloc",
	},
	[StrategyCode.Prepension]: {
		[FundCode.Con]: "",
		[FundCode.Bal]: "prepensionBalMaxAlloc",
		[FundCode.Grow]: "prepensionDynMaxAlloc",
	},
	[StrategyCode.Con]: {
		[FundCode.Con]: "",
		[FundCode.Bal]: "conBalMaxAlloc",
		[FundCode.Grow]: "conDynMaxAlloc",
	},
	[StrategyCode.Bal]: {
		[FundCode.Con]: "balConMaxAlloc",
		[FundCode.Bal]: "",
		[FundCode.Grow]: "balDynMaxAlloc",
	},
	[StrategyCode.Dyn]: {
		[FundCode.Con]: "dynConMaxAlloc",
		[FundCode.Bal]: "dynBalMaxAlloc",
		[FundCode.Grow]: "",
	},
};

export const validationSchema = (
	step: number,
	steps,
	initialValues,
	results,
	funds
) =>
	object().shape({
		overviewAccept: bool().when(
			["newResourcesSelected", "existingResourcesSelected"],
			{
				is: (
					newResourcesSelected: boolean,
					existingResourcesSelected: boolean
				): boolean => {
					let increment = -1;
					if (newResourcesSelected) {
						increment -= 1;
					}
					if (existingResourcesSelected) {
						increment -= 1;
					}
					return isSelectedResourcesStep(step + increment, steps);
				},
				then: bool().oneOf(
					[true],
					cs.investmentStrategy.changesOverview.overviewAcceptError
				),
				otherwise: bool(),
			}
		),
		selectedResources: bool().when(
			["newResourcesSelected", "existingResourcesSelected"],
			{
				is: (
					newResourcesSelected: boolean,
					existingResourcesSelected: boolean
				): boolean => {
					return isSelectedResourcesStep(step, steps)
						? newResourcesSelected || existingResourcesSelected
						: true;
				},
				then: bool(),
				otherwise: bool().oneOf(
					[true],
					cs.investmentStrategy.whatToChange.error
				),
			}
		),
		existingResources: object().when(["existingResourcesSelected"], {
			is: (existingResourcesSelected: boolean): boolean =>
				existingResourcesSelected && isChangeAllocationStep(step, steps),
			then: object().shape({
				valuesChanged: bool().when(["allocation"], {
					is: (allocation): boolean =>
						checkInvestmentStrategyValuesChanges(
							allocation,
							existingResourcesAllocation(funds, initialValues)
						),
					then: bool().optional(),
					otherwise: bool().required(cs.errorMessages.makeChanges),
				}),
				allocation: allocationSchema,
				...allocationRecommendation(results),
				overrideFundsAllocationRecommendation: needsOverride(results),
			}),
			otherwise: object(),
		}),
		newResources: object().when(
			["newResourcesSelected", "existingResourcesSelected"],
			{
				is: (
					newResourcesSelected: boolean,
					existingResourcesSelected: boolean
				): boolean =>
					newResourcesSelected &&
					isChangeAllocationStep(
						existingResourcesSelected ? step - 1 : step,
						steps
					),
				then: object().shape({
					valuesChanged: bool().when(["allocation"], {
						is: (allocation): boolean =>
							checkInvestmentStrategyValuesChanges(
								allocation,
								newResourcesAllocation(funds, initialValues)
							),
						then: bool().optional(),
						otherwise: bool().required(cs.errorMessages.makeChanges),
					}),
					allocation: allocationSchema,
					...allocationRecommendation(results),
					overrideFundsAllocationRecommendation: needsOverride(results),
				}),
				otherwise: object(),
			}
		),
		smsCode: string().when(
			["newResourcesSelected", "existingResourcesSelected"],
			{
				is: (newResourcesSelected, existingResourcesSelected): boolean =>
					newResourcesSelected && existingResourcesSelected,
				then: phoneVerificationSchema(step, steps),
				otherwise: phoneVerificationSchema(step, steps),
			}
		),
	});
