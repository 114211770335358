import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { NotInMVPButton } from "../../../components/Drawer/NotInMVP";
import InformationList from "../../../components/InformationList";
import { SEO } from "../../../components/SEO";
import { dateTimeFormat } from "../../../functions/dates";
import withProtection from "../../../highOrderComponents/withProtection";
import { useProfile } from "../../../queryHooks";
import cs from "../../../translations/cs.json";

export const Title = "Moje údaje";

function InfoContent() {
	const result = useProfile();

	const ai = useAppInsightsContext();
	const handleClick = (changeText) => {
		ai.trackEvent({
			name: `[Change] ${changeText}`,
			properties: {
				type: "not in MVP",
			},
		});
	};

	const { client } = result.data || { client: undefined };

	return (
		<>
			<InformationList
				isLoading={result.isLoading}
				information={[
					{
						title: "Titul před jménem",
						description: client?.degreeBefore ?? "-",
					},
					{
						title: "Jméno",
						description: client?.firstName ?? "-",
					},
					{
						title: "Příjmení",
						description: client?.lastName ?? "-",
					},
					{
						title: "Titul za jménem",
						description: client?.degreeAfter ?? "-",
					},
					{
						title: "Datum narození",
						description: client?.dateOfBirth
							? dateTimeFormat.format(new Date(client?.dateOfBirth))
							: "-",
					},
					{
						title: "Rodné číslo (číslo pojištěnce)",
						description: client?.birthNumber ?? "-",
					},
				]}
			/>
			<NotInMVPButton
				text={cs.profile.person.changePersonInfo}
				onClick={() => {
					handleClick(cs.profile.person.changePersonInfo);
				}}
			/>
		</>
	);
}

const Info = ({ pageContext }): JSX.Element => {
	return <InfoContent />;
};

export default withProtection(Info);

export const Head = (): JSX.Element => (
	<SEO title={Title}>
		<meta name="robots" content="noindex" />
	</SEO>
);
