import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { ErrorMessage } from "formik";

import cs from "../../../translations/cs.json";
import Attention from "../../AttentionBlock";
import FormErrorMessage from "../../Form/FormErrorMessage";
import Switch from "../../Form/Input/Switch";
import { BodyTextM, HeadingL, HeadingM } from "../../Typography";

const InvestmentWhatToChange = ({
	existingResourcesMissing,
}: {
	existingResourcesMissing: boolean;
}): JSX.Element => {
	return (
		<Stack>
			<HeadingL>{cs.investmentStrategy.whatToChange.title}</HeadingL>
			<Stack spacing={4}>
				<BodyTextM
					component="p"
					dangerouslySetInnerHTML={{
						__html: cs.investmentStrategy.whatToChange.description,
					}}
				/>
				<Stack sx={{ flexDirection: { md: "row" }, gap: 4 }}>
					<Paper sx={{ width: { md: "50%" }, p: 4 }}>
						<HeadingM>{cs.investmentStrategy.existingResources.title}</HeadingM>
						<Stack spacing={4}>
							<BodyTextM>
								{cs.investmentStrategy.existingResources.label}
							</BodyTextM>
							{existingResourcesMissing && (
								<Attention sx={{ mb: 5 }} severity="info">
									{cs.investmentStrategy.existingResources.missing}
								</Attention>
							)}
							<Switch
								name="existingResourcesSelected"
								label={cs.global.change}
								disabled={existingResourcesMissing}
							/>
						</Stack>
					</Paper>
					<Paper sx={{ width: { md: "50%" }, p: 4 }}>
						<HeadingM>{cs.investmentStrategy.newResources.title}</HeadingM>
						<Stack spacing={4}>
							<BodyTextM>{cs.investmentStrategy.newResources.label}</BodyTextM>
							<Switch name="newResourcesSelected" label={cs.global.change} />
						</Stack>
					</Paper>
				</Stack>
				<ErrorMessage
					name="selectedResources"
					render={(msg) => (
						<FormErrorMessage name="selectedResources">{msg}</FormErrorMessage>
					)}
				/>
			</Stack>
		</Stack>
	);
};

export default InvestmentWhatToChange;
