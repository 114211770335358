import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { addYears } from "date-fns";
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { v4 as uuidv4 } from "uuid";

import { cookieId, cookies } from "../components/Drawer/CookieDrawer/CookieIds";
import { EnvironmentHelper } from "../functions/env";
import { updateGTMConsents } from "../gtm";
import { usePrimaryAPIClient } from "./PrimaryAPIClient";
interface Props {
	children: ReactNode;
}

export interface ICookieConsent {
	id: string;
	consentSet: boolean;
	allowedCookies: cookieId[];
	expires?: string;
}

interface IConsentInfoContext {
	data: ICookieConsent;
	setCookieConsent: (allowed: cookieId[]) => Promise<void>;
}
const CookieContext = createContext<IConsentInfoContext>("");

const defaultConsent: ICookieConsent = {
	id: uuidv4(),
	consentSet: false,
	allowedCookies: [],
};

const LoadConsentFromLocal = () => {
	if (EnvironmentHelper.isBrowser()) {
		const consentJson = localStorage.getItem("cookieConsent");
		if (!consentJson) {
			localStorage.setItem("cookieConsent", JSON.stringify(defaultConsent));
			return defaultConsent;
		}
		const consent: ICookieConsent = JSON.parse(consentJson);
		return consent;
	} else return defaultConsent;
};

const now = new Date().toISOString();

export const useCookieConsents = (): IConsentInfoContext => {
	return useContext(CookieContext);
};

export const CookieContextProvider = ({ children }: Props): JSX.Element => {
	const [consent, setConsent] = useState(LoadConsentFromLocal());
	const ai = useAppInsightsContext();
	const { consentApi } = usePrimaryAPIClient();

	useEffect(() => {
		const localConsent = consent;
		if (
			localConsent.consentSet &&
			localConsent.expires &&
			localConsent.expires < now
		) {
			localStorage.setItem(
				"cookieConsent",
				JSON.stringify({ ...defaultConsent, id: localConsent.id })
			);
			setConsent(defaultConsent);
		} else {
			setConsent(localConsent);
		}
	}, []);

	const setCookieConsent = async (allowed: cookieId[]) => {
		if (allowed.includes(cookies.appInsights)) {
			const cookieManager = ai.getCookieMgr();
			cookieManager.setEnabled(true);
		}

		updateGTMConsents(
			allowed.includes(cookies.googleAnalytics),
			allowed.includes(cookies.googleAds)
		);

		const date = addYears(Date.now(), 1).toISOString();

		const reqBody = {
			id: consent.id,
			cookieConsent: allowed.join(", "),
			expirationDate: date,
		};

		try {
			await consentApi.setCookiesConsentsPut({
				setCookieConsentRequest: reqBody,
			});
			localStorage.setItem(
				"cookieConsent",
				JSON.stringify({
					id: consent.id,
					consentSet: true,
					allowedCookies: allowed,
					expires: date,
				})
			);
			setConsent(LoadConsentFromLocal());
		} catch {
			setConsent({
				id: consent.id,
				consentSet: true,
				allowedCookies: allowed,
			});
		}
	};

	return (
		<CookieContext.Provider value={{ data: consent, setCookieConsent }}>
			{children}
		</CookieContext.Provider>
	);
};
