import { Address } from "../../../models";
import { countryCode2CountryName } from "../../utils";

export const indicateStarPensionEmptyAddress = (address: Address) => {
	if (address.zip === "00000") return true;
};

const AddressTemplate = ({
	address,
}: {
	address: Address | undefined;
}): JSX.Element => {
	if (!address || Object.keys(address).length === 0) {
		return <>{"-"}</>;
	}
	return (
		<>
			{address.street ?? ""} {address.houseNumber ?? ""}
			<br />
			{address.zip && !indicateStarPensionEmptyAddress(address)
				? address.zip
				: ""}{" "}
			{address.town ?? ""}
			<br />
			{address.country && !indicateStarPensionEmptyAddress(address)
				? countryCode2CountryName(address.country)
				: ""}
		</>
	);
};

export default AddressTemplate;
