/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActiveDirectoryAction } from './ActiveDirectoryAction';
import {
    ActiveDirectoryActionFromJSON,
    ActiveDirectoryActionFromJSONTyped,
    ActiveDirectoryActionToJSON,
} from './ActiveDirectoryAction';

/**
 * 
 * @export
 * @interface ActiveDirectoryUserIdResponse
 */
export interface ActiveDirectoryUserIdResponse {
    /**
     * 
     * @type {string}
     * @memberof ActiveDirectoryUserIdResponse
     */
    version: string;
    /**
     * 
     * @type {ActiveDirectoryAction}
     * @memberof ActiveDirectoryUserIdResponse
     */
    action: ActiveDirectoryAction;
    /**
     * 
     * @type {string}
     * @memberof ActiveDirectoryUserIdResponse
     */
    userMessage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiveDirectoryUserIdResponse
     */
    status?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActiveDirectoryUserIdResponse
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveDirectoryUserIdResponse
     */
    objectId: string;
}

/**
 * Check if a given object implements the ActiveDirectoryUserIdResponse interface.
 */
export function instanceOfActiveDirectoryUserIdResponse(value: object): value is ActiveDirectoryUserIdResponse {
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('objectId' in value) || value['objectId'] === undefined) return false;
    return true;
}

export function ActiveDirectoryUserIdResponseFromJSON(json: any): ActiveDirectoryUserIdResponse {
    return ActiveDirectoryUserIdResponseFromJSONTyped(json, false);
}

export function ActiveDirectoryUserIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveDirectoryUserIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'version': json['version'],
        'action': ActiveDirectoryActionFromJSON(json['action']),
        'userMessage': json['userMessage'] == null ? undefined : json['userMessage'],
        'status': json['status'] == null ? undefined : json['status'],
        'statusCode': json['statusCode'],
        'objectId': json['objectId'],
    };
}

export function ActiveDirectoryUserIdResponseToJSON(value?: ActiveDirectoryUserIdResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'version': value['version'],
        'action': ActiveDirectoryActionToJSON(value['action']),
        'userMessage': value['userMessage'],
        'status': value['status'],
        'statusCode': value['statusCode'],
        'objectId': value['objectId'],
    };
}

