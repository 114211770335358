import { TriggerArrowDown } from "../../Link/Trigger";

interface LoadMoreButtonProps {
	getMoreTransactions: () => void;
}

const LoadMoreButton = ({
	getMoreTransactions,
}: LoadMoreButtonProps): JSX.Element => {
	return (
		<TriggerArrowDown onClick={getMoreTransactions} sx={{ py: 4 }}>
			Starší pohyby
		</TriggerArrowDown>
	);
};

export default LoadMoreButton;
