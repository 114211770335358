import Box from "@mui/material/Box";
import { ReactNode } from "react";

export interface ModalContentProps {
	scrollRef?: HTMLElement;
	children: ReactNode;
	forceFullView?: boolean;
}

const ModalContent = ({
	children,
	scrollRef,
	forceFullView = false,
}: ModalContentProps): JSX.Element => {
	return (
		<Box
			ref={scrollRef}
			sx={{
				flexGrow: 1,
				overflowY: "auto",
				py: forceFullView ? 0 : 7,
				px: forceFullView ? 0 : 4,
			}}
		>
			{children}
		</Box>
	);
};

export default ModalContent;
