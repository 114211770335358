import { Stack } from "@mui/material";
import { Form as FormikForm } from "formik";
import { FC } from "react";

import FormSubmit from "./Submit";

export interface formProps {
	className?: string;
	spacing?: number;
	actions?: JSX.Element;
	isSubmitDisabled?: boolean;
}

const Form: FC<formProps> = ({
	children,
	actions,
	spacing = 4,
	isSubmitDisabled = false,
	...rest
}): JSX.Element => {
	return (
		<FormikForm
			noValidate // you need this to turn off browser default validation, thank this, all errors show up at once
			{...rest}
		>
			<Stack spacing={spacing}>
				{children}
				<Stack
					direction={{ xxs: "column", xs: "row" }}
					spacing={{ xxs: 0, xs: 4 }}
					justifyContent="space-between"
				>
					{actions}
					<FormSubmit
						disabled={isSubmitDisabled}
						sx={{
							flexGrow: actions ? 0 : 1,
							minWidth: "164px",
						}}
						text={{
							submitting: "Zpracovávám...",
							label: "Potvrdit",
						}}
					/>
				</Stack>
			</Stack>
		</FormikForm>
	);
};

export default Form;
