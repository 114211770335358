import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Button from "@mui/material/Button";
import { useState } from "react";

import {
	Beneficiary,
	ChangesDisabledReason,
	GetLifeDetailResponse,
	InsuredPersonRole,
	PensionBeneficiary,
	ProductType,
} from "../../../models";
import { useContractId } from "../../context/ContractIDContext";
import { useContractTypeContext } from "../../context/ContractTypeContext";
import { useLifeDetail } from "../../queryHooks";
import cs from "../../translations/cs.json";
import { useDrawer } from "../Drawer/context";
import InfoIconDrawer from "../Drawer/InfoIconDrawer";
import { useSelectedPerson } from "../ZivotniPojisteni/Tabs/components/Persons/SelectedPersonContext";
import BeneficiariesByMail from "./BeneficiariesByMail";
import BeneficiariesChangeForm from "./BeneficiariesChangeForm";
import BeneficiariesNotEditable from "./BeneficiariesNotEditable";
import BeneficiaryChangeInProgress from "./BeneficiaryChangeInProgress";
import HasNoBeneficiaries from "./HasNoBeneficiaries";

const messages: {
	[key in ChangesDisabledReason]: JSX.Element;
} = {
	[ChangesDisabledReason.BeneficiaryChangeInProgress]: (
		<BeneficiaryChangeInProgress />
	),
	[ChangesDisabledReason.BeneficiaryIsABank]: <BeneficiariesByMail />,
	[ChangesDisabledReason.HasNoBeneficiaries]: <HasNoBeneficiaries />,
	[ChangesDisabledReason.None]: <BeneficiariesByMail />,
};

export const ReasonToMessage = ({
	reasons,
	customReason,
	title,
}: {
	reasons: ChangesDisabledReason[];
	title: string;
	customReason?: string;
}): JSX.Element => {
	return (
		<InfoIconDrawer
			description={
				<>
					{customReason
						? customReason
						: reasons.map((reason) => messages[reason])}
				</>
			}
			title={title}
		/>
	);
};

type ChangeBeneficiariesButtonProps = {
	beneficiaries: PensionBeneficiary[] | Beneficiary[];
	editable: boolean;
	isLoading: boolean;
	refetchOnSuccess: () => Promise<void>;
	refetchOnError: () => Promise<void>;
	uiStatus: {
		canChangeBeneficiaries: boolean | undefined;
		reasons: ChangesDisabledReason[];
	};
	customDisabledReason?: string;
};

// TODO fix prop drilling horrible mess,
// remove UI status completely or better merge with allowed changes, removed editable (never used, check)
const ChangeBeneficiariesButton = ({
	beneficiaries,
	refetchOnSuccess,
	refetchOnError,
	editable = true,
	uiStatus,
	isLoading,
	customDisabledReason,
	...rest
}: ChangeBeneficiariesButtonProps): JSX.Element => {
	const { showDrawer, setDrawerContent } = useDrawer();
	const { contractType } = useContractTypeContext();
	const id = useContractId();
	const ai = useAppInsightsContext();
	const [formWasSend, setFormWasSend] = useState<boolean>(false); //basic "optimistic update" , waiting on refetch can be sometimes slow and the button should be disabled immediately after form submit

	const title =
		beneficiaries.length > 0
			? contractType
				? cs.product.beneficiaries.changeBeneficiaries[contractType]
				: cs.product.beneficiaries.changeBeneficiaries.default
			: contractType
			? cs.product.beneficiaries.addBeneficiar[contractType]
			: cs.product.beneficiaries.addBeneficiar.default;

	const modalContent = (() => {
		if (isLoading) return undefined;
		if (formWasSend)
			return (
				<ReasonToMessage
					reasons={[ChangesDisabledReason.BeneficiaryChangeInProgress]}
					title={title}
				/>
			);
		if (editable) {
			return uiStatus.canChangeBeneficiaries ? (
				<BeneficiariesChangeForm
					beneficiaries={beneficiaries}
					refetchOnSuccess={refetchOnSuccess}
					refetchOnError={refetchOnError}
					contractType={contractType}
					id={id}
					setFormWasSend={setFormWasSend}
				/>
			) : (
				<ReasonToMessage
					reasons={uiStatus.reasons}
					customReason={customDisabledReason}
					title={title}
				/>
			);
		} else {
			return <BeneficiariesNotEditable title={title} />;
		}
	})();

	return (
		<Button
			data-test="add-beneficiary-button"
			{...rest}
			variant="contained"
			sx={{
				alignSelf: { md: "flex-start" },
				width: { md: "auto", xxs: "100%" },
			}}
			onClick={() => {
				ai.trackEvent({
					name: `${
						contractType
							? cs.product.beneficiaries.changeBeneficiaries[contractType]
							: cs.product.beneficiaries.changeBeneficiaries.default
					} [dialog]`,
				});
				showDrawer();
				setDrawerContent(modalContent);
			}}
			disabled={isLoading}
		>
			{title}
		</Button>
	);
};

export default ChangeBeneficiariesButton;

export const ChangeBeneficiariesButtonWithLifeData = () => {
	const contractId = useContractId();
	const { data, refetch, isSuccess } = useLifeDetail<GetLifeDetailResponse>({
		id: contractId,
	});
	const { selectedPersonIndex } = useSelectedPerson();
	const insuredPerson = isSuccess
		? data.insuredPersons[selectedPersonIndex]
		: undefined;
	return (
		<ChangeBeneficiariesButton
			beneficiaries={insuredPerson ? insuredPerson.beneficiaries : []}
			editable={(
				[
					InsuredPersonRole.PolicyHolder,
					InsuredPersonRole.PolicyHolderAndFirst,
					InsuredPersonRole.PolicyHolderAndSecond,
				] as string[]
			).includes(insuredPerson?.role as string)}
			isLoading={!isSuccess}
			refetch={refetch}
			uiStatus={
				isSuccess
					? data.uiStatus
					: {
							canChangeBeneficiaries: undefined,
							reasons: [],
					  }
			}
		/>
	);
};

export const ChangeBeneficiariesButtonWithFufiData = () => {
	return (
		<ChangeBeneficiariesButton
			beneficiaries={[]}
			editable
			isLoading={false}
			refetch={() => {
				return;
			}}
			uiStatus={{
				canChangeBeneficiaries: false,
				reasons: [],
			}}
			customDisabledReason={cs.global.notInNDP}
		/>
	);
};

export const ChangeBeneficiariesButtonByLifeContractType = () => {
	const { contractType } = useContractTypeContext();
	if (
		![ProductType.Cl, ProductType.Clf].includes(contractType as "CL" | "CLF")
	) {
		throw new Error(
			`Wrong contract type in ChangeBeneficiariesButtonByLifeContractType: ${contractType}`
		);
	}
	if (contractType === ProductType.Cl)
		return <ChangeBeneficiariesButtonWithLifeData />;
	if (contractType === ProductType.Clf)
		return <ChangeBeneficiariesButtonWithFufiData />;

	return <></>;
};
