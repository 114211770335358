import { useFormikContext } from "formik";

import cs from "../../../../translations/cs.json";
import { useDrawer } from "../../../Drawer/context";
import PlusIcon from "../../../Icons/Plus";
import { Trigger } from "../../../Link";

const AddBeneficiary = ({ contractType, push }) => {
	const { setStep } = useDrawer();
	const { values, setFieldValue, setFieldTouched } = useFormikContext();
	return (
		<Trigger
			data-test="add-new-beneficiary-button"
			icon={
				<PlusIcon
					sx={{
						width: "1.4rem",
						height: "1.4rem",
						ml: 2,
					}}
				/>
			}
			sx={{ alignSelf: "flex-start", mt: 6 }}
			onClick={() => {
				if (values.beneficiaries.length === 0)
					setFieldTouched("beneficiaries", false);
				push({
					firstName: "",
					lastName: "",
					dateOfBirth: "",
					birthNumber: "",
					address: {
						street: "",
						houseNumber: "",
						town: "",
						zip: "",
						country: "",
					},
				});
				setFieldValue(`ratios.${values.beneficiaries.length}.ratio`, 0);
				setFieldValue("editedBeneficiar", values.beneficiaries.length);
				setStep(1);
			}}
		>
			{contractType
				? cs.product.beneficiaries.addBeneficiar[contractType]
				: cs.product.beneficiaries.addBeneficiar.default}
		</Trigger>
	);
};
export default AddBeneficiary;
