import { Box } from "@mui/material";

import EnvelopeIcon from "../Icons/Envelope";
import PhoneIcon from "../Icons/Phone";
import { InternalButtonLink } from "../Link";
import { HeadingL } from "../Typography";
import ContactBox from "./ContactBox";

function General(): JSX.Element {
	return (
		<Box mb={6}>
			<HeadingL withoutScale>Rádi Vám pomůžeme</HeadingL>
			<ContactBox
				title="Klientská linka"
				description="Volejte od pondělí do pátku od 8 do 18 hodin."
				link={
					<InternalButtonLink
						to="tel:+420244090800"
						variant="text"
						size="small"
						startIcon={<PhoneIcon sx={{ width: "1.1rem", height: "1.6rem" }} />}
					>
						244 090 800
					</InternalButtonLink>
				}
				mb={5}
			></ContactBox>
			<ContactBox
				title="E-mailová adresa"
				description="Upřednostňujete raději komunikaci mailem? Neváhejte nám napsat."
				link={
					<InternalButtonLink
						to="mailto:dotazy@nn.cz"
						variant="text"
						size="small"
						startIcon={
							<EnvelopeIcon sx={{ width: "1.4rem", height: "1rem" }} />
						}
					>
						dotazy@nn.cz
					</InternalButtonLink>
				}
			></ContactBox>
		</Box>
	);
}
export default General;
