import { Stack, StackProps } from "@mui/material";

/**
 * Kontejner pro obsah stranky, kdyz je pouzit TabsLayout
 * @param props
 * @constructor
 */
export function Tab(props: StackProps): JSX.Element {
	return (
		<Stack
			gap={{ xxs: 7, md: 8 }}
			{...props}
			role="tabpanel"
			sx={{
				pb: 4,
				px: 4,
				...props.sx,
				overflowX: "hidden", // If internal component is too wide, horizontal scrollbar prevents swipe event. Swipe event must be available always.
			}}
		/>
	);
}
export default Tab;
