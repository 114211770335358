import { Box, Stack } from "@mui/material";
import { useLocation } from "@reach/router";
import useIsDesktop from "desktop/useIsDesktop";
import { ReactNode } from "react";

import { useCookieConsents } from "../../context/CookieContext";
import { useHasSPError } from "../../context/SPErrorContext";
import CookieDrawer from "../Drawer/CookieDrawer";
import StarPensionWarning from "../StarPensionWarning";
import { HeadingXL } from "../Typography";
import {
	BackgroundImageByPage,
	BannerByPage,
	MobileBannerByPage,
	TitleByPage,
} from ".";

export interface LayoutBodyProps {
	pageProps: {
		name: string;
		verticalCenter?: boolean;
		appBar?: string;
	};
	children: ReactNode;
}

const getTitle = (page: string): string | undefined => {
	if (!page) {
		return;
	}
	return page.includes("profile") ? TitleByPage.profile : TitleByPage[page];
};

const LayoutBody = ({ pageProps, children }: LayoutBodyProps): JSX.Element => {
	const isD = useIsDesktop();
	const { data: cookieData } = useCookieConsents();
	const { hasError: isSPError } = useHasSPError();
	const title = getTitle(pageProps.name);
	const BackgroundImage: any = BackgroundImageByPage[pageProps.name];

	const location = useLocation();

	const desktopContent = (
		<Stack
			id="desktop-content-layout-body"
			sx={{
				position: BackgroundImage ? "relative" : undefined,
				justifyContent: pageProps.verticalCenter ? "center" : "flex-start",
				alignSelf: pageProps.verticalCenter ? "stretch" : "flex-start",
				width: "100%",
				maxWidth: "962px",
				mx: "auto",
			}}
		>
			{isSPError && (
				<Box sx={{ mb: 4 }}>
					<StarPensionWarning />
				</Box>
			)}
			{BannerByPage[pageProps.name]}
			{title && <HeadingXL>{title}</HeadingXL>}
			{pageProps.verticalCenter ? (
				<Stack sx={{ justifyContent: "center" }}>{children}</Stack>
			) : (
				children
			)}
		</Stack>
	);

	return (
		<Stack
			id="layout-body-container"
			sx={{
				width: "100%",
				p: { md: 7, xxs: 4 },
			}}
		>
			{isD ? (
				<>
					{BackgroundImage ? (
						<BackgroundImage>{desktopContent}</BackgroundImage>
					) : (
						desktopContent
					)}
				</>
			) : (
				<Stack gap={2}>
					{MobileBannerByPage[pageProps.name]}
					{children}
				</Stack>
			)}
			{!cookieData.consentSet &&
				location.pathname !== "/penzijni-kalkulacka/" && <CookieDrawer />}
		</Stack>
	);
};

export default LayoutBody;
