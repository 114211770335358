/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetContractsResponse,
  Response,
} from '../models/index';
import {
    GetContractsResponseFromJSON,
    GetContractsResponseToJSON,
    ResponseFromJSON,
    ResponseToJSON,
} from '../models/index';

export interface GetContractsByPidGetRequest {
    pid: number;
}

export interface GetPIDFromContractIdGetRequest {
    contractId: string;
}

/**
 * 
 */
export class DevOnlyApi extends runtime.BaseAPI {

    /**
     * Get list of contracts for a PID.
     */
    async getContractsByPidGetRaw(requestParameters: GetContractsByPidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetContractsResponse>>> {
        if (requestParameters['pid'] == null) {
            throw new runtime.RequiredError(
                'pid',
                'Required parameter "pid" was null or undefined when calling getContractsByPidGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contracts/{pid}`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters['pid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetContractsResponseFromJSON));
    }

    /**
     * Get list of contracts for a PID.
     */
    async getContractsByPidGet(requestParameters: GetContractsByPidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetContractsResponse>> {
        const response = await this.getContractsByPidGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get PID from contractID
     */
    async getPIDFromContractIdGetRaw(requestParameters: GetPIDFromContractIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Response>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getPIDFromContractIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contracts/get-pid/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseFromJSON(jsonValue));
    }

    /**
     * Get PID from contractID
     */
    async getPIDFromContractIdGet(requestParameters: GetPIDFromContractIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const response = await this.getPIDFromContractIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
