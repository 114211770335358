import { PensionPhaseStatus } from "../../../models";
import cs from "../../translations/cs.json";
import Attention from ".";
interface CorrectPaymentSymbolsProps {
	pensionPhaseStatus?: PensionPhaseStatus;
}
const CorrectPaymentSymbols = ({
	pensionPhaseStatus,
}: CorrectPaymentSymbolsProps): JSX.Element => {
	return (
		<>
			{pensionPhaseStatus === PensionPhaseStatus.Payout ? (
				<Attention severity="warning">
					{cs.global.canceledPermanentPayment}
				</Attention>
			) : (
				<Attention severity="warning">
					Při platbě převodem zadejte pečlivě <strong>variabilní</strong> a{" "}
					<strong>konstantní</strong> symbol, aby byla platba správně spárována.
				</Attention>
			)}
		</>
	);
};

export default CorrectPaymentSymbols;
