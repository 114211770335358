import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Collapsible from "../../Collapsible";
import { BodyTextM } from "../../Typography";
interface imageProps {
	intro_image: {
		publicURL: string;
	};
}
// layout: CONSTRAINED
const useLocalTtranslations = (): imageProps => {
	return useStaticQuery<Queries.AgreementContentImagesQuery>(
		graphql`
			query AgreementContentImages {
				intro_image: file(relativePath: { eq: "1st-step.png" }) {
					childImageSharp {
						gatsbyImageData(width: 350, placeholder: BLURRED)
					}
				}
				translations: file(name: { eq: "cs" }) {
					childTranslationsJson {
						firstLogin {
							preheader
							intro
							accept
						}
						profile {
							communicationContractation {
								header
								content
							}
							personalInformation {
								header
								content
							}
							marketing {
								header
								content
							}
						}
					}
				}
			}
		`
	);
};

const AgreementContent = (): JSX.Element => {
	const content = useLocalTtranslations();
	const image = getImage(content.intro_image);
	const translations = content.translations.childTranslationsJson;
	return (
		<Stack
			spacing={5}
			sx={{
				px: 4,
			}}
		>
			<Stack>
				<GatsbyImage image={image} alt="Intro" style={{ margin: "auto" }} />
			</Stack>
			<BodyTextM>{translations.firstLogin.preheader}</BodyTextM>
			<BodyTextM>{translations.firstLogin.intro}</BodyTextM>
			<Box>
				<Collapsible
					headline={translations.profile.communicationContractation.header}
				>
					<BodyTextM>
						{translations.profile.communicationContractation.content}
					</BodyTextM>
				</Collapsible>

				<Collapsible headline={translations.profile.personalInformation.header}>
					<BodyTextM
						dangerouslySetInnerHTML={{
							__html: translations.profile.personalInformation.content,
						}}
					/>
				</Collapsible>
			</Box>
			<BodyTextM>{translations.firstLogin.accept}</BodyTextM>
		</Stack>
	);
};

export default AgreementContent;
