import { createContext, useContext, useState } from "react";

import { DisplayableError } from "./DisplayableError";

export interface IErrorContext {
	error: Error | DisplayableError | null;
	setError: (error: Error | DisplayableError) => void;
	resetError: () => void;
}

export const ErrorContext = createContext({} as IErrorContext);

export const useErrorContext = () => {
	return useContext(ErrorContext);
};

interface IProps {
	children: JSX.Element;
}

export const ErrorContextProvider = ({ children }: IProps) => {
	const [error, setError] = useState<Error | DisplayableError | null>(null);
	const resetError = () => {
		setError(null);
	};
	return (
		<ErrorContext.Provider value={{ error, setError, resetError }}>
			{children}
		</ErrorContext.Provider>
	);
};
