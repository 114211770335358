export type AMLScenario = "default" | "profile" | "contribution";
export enum AMLRedirect {
	Profile = "profile",
	Default = "default",
	Contribution = "contribution",
	Strategy = "strategy",
}

export enum AMLIncomeSource {
	Employment = "Mzda/plat ze zaměstnání",
	Business = "Příjem z podnikání, členství v orgánu společnosti, společnictví či prokury",
	Other = "Jiné",
}

export enum AMLRiskType {
	High = "HighRisk",
	Medium = "RiskWithLimit",
	Low = "LowRisk",
}

export const BankIdLastActionKey = "bankIdLastAction";
