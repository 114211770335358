import { IErrorMessage } from "../ErrorPage/GeneralMessage";

export type ErrorDisplay = IErrorMessage | { customJSX: JSX.Element };

export class DisplayableError extends Error {
	public constructor(
		public message: string,
		public readonly display: ErrorDisplay,
		public readonly doNotTrack?: boolean
	) {
		super();
	}
}
