import { StaticImage } from "gatsby-plugin-image";

export default function LoginImage({ opacity }: { opacity?: boolean }) {
	return (
		<StaticImage
			src="../images/login-desktop.png"
			alt="man with desktop computer"
			style={{
				position: "absolute",
				zIndex: -1,
				left: "-95px",
				opacity: opacity ? 0.5 : 1,
			}}
		/>
	);
}
