/*hook to use in components shared between DPS and PP to avoid writing a complicated fetch function each time */

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
	GetDPSTransactionsGetRequest,
	GetLifeTransactionsGetRequest,
	GetPPTransactionsGetRequest,
} from "../../apis";
import {
	DocumentDownloadResponse,
	GetDPSDetailResponse,
	GetLifeTransactionsResponse,
	GetPensionTransactionsResponse,
	GetPPDetailResponse,
	ProductType,
} from "../../models";
import { useContractTypeContext } from "../context/ContractTypeContext";
import { usePrimaryAPIClient } from "../context/PrimaryAPIClient";
import { useQueryKeyFactory } from "../context/QueryKeyProvider";
import { ContractType } from "../types/contracts";

interface IPensionDetailProps<
	TResult = GetDPSDetailResponse | GetPPDetailResponse
> {
	id: string;
	type?: ContractType;
	options?: UseQueryOptions<
		GetDPSDetailResponse | GetPPDetailResponse,
		unknown,
		TResult
	>;
}

export const usePensionDetail = <
	TResult = GetDPSDetailResponse | GetPPDetailResponse,
>({
	id,
	type,
	options = {},
}: IPensionDetailProps<TResult>) => {
	const { contractsDpsApi, contractsPpApi } = usePrimaryAPIClient();
	const { contractType } = useContractTypeContext();
	const { createQueryKey } = useQueryKeyFactory();

	const pensionProductType: ContractType | undefined = type || contractType;
	const acceptedTypes: ContractType[] = [ProductType.Uf, ProductType.Pf];

	const fetchMethod = () => {
		if (!pensionProductType || !acceptedTypes.includes(pensionProductType)) {
			throw new Error(
				"No or wrong contract type provided to use pension detail hook"
			);
		}
		if (pensionProductType === ProductType.Uf) {
			return contractsDpsApi.getDPSDetailGet({ contractId: id });
		} else {
			return contractsPpApi.getPPDetailGet({ contractId: id });
		}
	};

	return useQuery<GetDPSDetailResponse | GetPPDetailResponse, unknown, TResult>(
		createQueryKey({
			key: ["getContractDetail", id, pensionProductType],
			includeUserIdentifier: false,
		}),
		fetchMethod,
		options
	);
};

interface IPensionDocumentsProps {
	id: string;
	type: ContractType;
	options: UseQueryOptions<DocumentDownloadResponse[]>;
}

export const usePensionDocuments = ({
	id,
	type,
	options = {},
}: IPensionDocumentsProps) => {
	const { documentsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	const acceptedTypes: ContractType[] = [ProductType.Uf, ProductType.Pf];

	const fetchMethod = () => {
		if (!acceptedTypes.includes(type)) {
			throw new Error(
				"No or wrong contract type provided to use pension documents hook"
			);
		}
		if (type === ProductType.Uf) {
			return documentsApi.getDPSDocumentsGet({ contractId: id });
		} else {
			return documentsApi.getPPDocumentsGet({ contractId: id });
		}
	};

	return useQuery<DocumentDownloadResponse[]>(
		createQueryKey({
			key: ["getPensionDocuments", id],
			includeUserIdentifier: false,
		}),
		fetchMethod,
		options
	);
};

interface TransactionsProps {
	type: ContractType;
	params:
		| GetDPSTransactionsGetRequest
		| GetLifeTransactionsGetRequest
		| GetPPTransactionsGetRequest;
	options?: UseQueryOptions<
		GetLifeTransactionsResponse[] | GetPensionTransactionsResponse[]
	>;
}

export const useTransactions = ({
	type,
	params,
	options = {},
}: TransactionsProps) => {
	const { contractsDpsApi, contractsPpApi, contractsLifeApi } =
		usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	const acceptedTypes = [ProductType.Uf, ProductType.Pf, ProductType.Cl];

	/*sort type filter array due to how query keys are compared (array order matters) */
	if (params.typeFilter) {
		params.typeFilter.sort();
	}

	const fetchMethod = () => {
		if (!acceptedTypes.includes(type)) {
			throw new Error(
				"Incorrect or missing contract type in useTransactions Hook"
			);
		}
		if (type === ProductType.Pf) {
			return contractsPpApi.getPPTransactionsGet(
				params as GetPPTransactionsGetRequest
			);
		}
		if (type === ProductType.Uf) {
			return contractsDpsApi.getDPSTransactionsGet(
				params as GetDPSTransactionsGetRequest
			);
		} else {
			return contractsLifeApi.getLifeTransactionsGet(
				params as GetLifeTransactionsGetRequest
			);
		}
	};

	return useQuery<
		GetLifeTransactionsResponse[] | GetPensionTransactionsResponse[]
	>(
		createQueryKey({
			key: ["getTransactions", params],
			includeUserIdentifier: false,
		}),
		() => {
			return fetchMethod();
		},
		options
	);
};
