/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PensionBeneficiaryRelationship = {
    Other: 'Other',
    HusbandOrWife: 'HusbandOrWife',
    Fiance: 'Fiance',
    RegisteredPartner: 'RegisteredPartner',
    Child: 'Child',
    BrotherOrSister: 'BrotherOrSister',
    Parent: 'Parent'
} as const;
export type PensionBeneficiaryRelationship = typeof PensionBeneficiaryRelationship[keyof typeof PensionBeneficiaryRelationship];


export function instanceOfPensionBeneficiaryRelationship(value: any): boolean {
    for (const key in PensionBeneficiaryRelationship) {
        if (Object.prototype.hasOwnProperty.call(PensionBeneficiaryRelationship, key)) {
            if ((PensionBeneficiaryRelationship as Record<string, PensionBeneficiaryRelationship>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PensionBeneficiaryRelationshipFromJSON(json: any): PensionBeneficiaryRelationship {
    return PensionBeneficiaryRelationshipFromJSONTyped(json, false);
}

export function PensionBeneficiaryRelationshipFromJSONTyped(json: any, ignoreDiscriminator: boolean): PensionBeneficiaryRelationship {
    return json as PensionBeneficiaryRelationship;
}

export function PensionBeneficiaryRelationshipToJSON(value?: PensionBeneficiaryRelationship | null): any {
    return value as any;
}

