import {
	AutocompleteProps,
	default as MUIAutocomplete,
} from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useField, useFormikContext } from "formik";
import { ChangeEvent, FC } from "react";

import TextInput from "../../Input/Text";

export type onSelectChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface Props extends AutocompleteProps {
	disabled?: boolean;
	name: string;
	label?: string;
	isRequired?: boolean;
	validationFunction: () => void;
}

const Autocomplete: FC<Props> = ({
	disabled,
	name,
	label,
	isRequired,
	options,
	isLoading,
	validationFunction,
	...rest
}) => {
	const [field] = useField(name);
	const { setFieldValue, setFieldTouched } = useFormikContext();

	if (isLoading) {
		return (
			<>
				<Skeleton variant="text" />
				<Skeleton variant="rectangular" height={48} />
			</>
		);
	}

	interface Country {
		label: string;
		value: string;
	}

	return (
		<Stack>
			{label && (
				<InputLabel shrink htmlFor={`${name}-name`} required={isRequired}>
					{label}
				</InputLabel>
			)}
			<MUIAutocomplete
				{...rest}
				disablePortal
				options={options}
				disabled={disabled}
				onChange={(e, value) => {
					if (value) setFieldValue(name, value.value);
					else setFieldValue(name, "");
				}}
				onBlur={(e) => {
					const countryExists = (value: string) =>
						options.some((country: Country) => country.value === value);
					if (!countryExists(e.target.value)) setFieldValue(name, "");
					setFieldTouched(name);
				}}
				inputValue={field.value || ""}
				renderInput={(params) => (
					<TextInput {...rest} {...params} name={name} disablePerformance />
				)}
			/>
		</Stack>
	);
};

export default Autocomplete;
