import { InternalArrowLink } from "../Link/InternalLink";
import Attention from "./index";
export default function NewPortalAttention() {
	return (
		<Attention
			severity="info"
			action={
				<InternalArrowLink
					to="/registrace/"
					sx={{
						color: "info.main",
					}}
				>
					Zaregistrovat se
				</InternalArrowLink>
			}
		>
			Jste tady poprvé od června 2022? Prosíme, registrujte se.
		</Attention>
	);
}
