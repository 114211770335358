import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { ReactNode } from "react";

export interface DrawerStepProps {
	children: ReactNode;
	value: number;
	index: number;
	sx: SxProps;
}

function DrawerStep({
	children,
	value,
	index,
	sx,
	...rest
}: DrawerStepProps): JSX.Element {
	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			sx={{ position: "relative", minHeight: "100%", ...sx }} // https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height
			{...rest}
		>
			{value === index && <>{children}</>}
		</Box>
	);
}

export default DrawerStep;
