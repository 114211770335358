import Box from "@mui/material/Box";

import { BodyTextM } from "../Typography";

function NoDownload(): JSX.Element {
	return (
		<Box>
			<BodyTextM>Nejsou k dispozici žádné dokumenty.</BodyTextM>
		</Box>
	);
}

export default NoDownload;
