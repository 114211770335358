import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Helper hook to determine if current device is desktop
 */
export default function useIsDesktop(): boolean {
	return useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
}

export function useIsTablet(): boolean {
	return useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));
}
