import useIsDesktop from "desktop/useIsDesktop";
import { PageProps } from "gatsby";

import { PensionPhaseStatus, ProductType } from "../../../models";
import { BackNavigationBar, LoggedInBar } from "../../components/AppBar";
import Client from "../../components/DPS/Client";
import ContractDetail from "../../components/DPS/ContractDetail";
import Docs from "../../components/DPS/Docs";
import Export from "../../components/DPS/Export";
import Transactions from "../../components/DPS/Transactions";
import BackButtonLayout from "../../components/Layout/BackButton";
import { SEO } from "../../components/SEO";
import { TabsWithQueryParameter } from "../../components/Tabs";
import {
	ContractIDProvider,
	extractContractIDFromURL,
} from "../../context/ContractIDContext";
import { useContractId } from "../../context/ContractIDContext";
import { ContractTypeProvider } from "../../context/ContractTypeContext";
import withProtection from "../../highOrderComponents/withProtection";
import { useSPErrorTabsRedirect } from "../../hooks/useSPError";
import {
	useAllowedChangesForContract,
	useContractById,
	usePensionDetail,
} from "../../queryHooks";
import cs from "../../translations/cs.json";

const title = "Doplňkové penzijní spoření";

export const AppBar = (pageProps: PageProps): JSX.Element =>
	useIsDesktop() ? (
		<LoggedInBar />
	) : (
		<BackNavigationBar title={title} id={extractContractIDFromURL(pageProps)} />
	);

const DPSContent = () => {
	const id = useContractId();
	const { data, isSuccess } = useContractById({
		id: id,
		options: { refetchOnMount: false },
	});
	const { isError } = usePensionDetail({ id, type: ProductType.Uf });
	void useSPErrorTabsRedirect();

	const { data: allowedData, isSuccess: allowedIsSuccess } =
		useAllowedChangesForContract({
			id: id,
			options: {
				enabled: !!id,
			},
		});

	if (isSuccess) {
		return (
			<TabsWithQueryParameter
				queryParamKey="main-tab"
				tabs={[
					data?.pensionPhaseStatus === PensionPhaseStatus.Payout
						? null
						: { label: "Detail", slug: "detail", disabled: isError },
					{ label: "Platby", slug: "pohyby-platby", disabled: isError },
					{ label: "Klient", slug: "klient", disabled: isError },
					{ label: "Smlouva", slug: "smlouva", disabled: isError },
					{ label: "Dokumenty", slug: "dokumenty" },
				]}
			>
				{data?.pensionPhaseStatus !== PensionPhaseStatus.Payout && <Export />}
				<Transactions />
				<Client />
				<ContractDetail
					pendingChanges={allowedData?.pendingChanges || undefined}
					isSuccessPendingChanges={allowedIsSuccess}
				/>
				<Docs />
			</TabsWithQueryParameter>
		);
	}

	return <>{cs.global.pageIsLoading}</>;
};

function DoplnkovePenzijniSporeni(pageProps: PageProps): JSX.Element {
	return (
		<ContractIDProvider pageProps={pageProps}>
			<BackButtonLayout title={title}>
				<ContractTypeProvider contractType={ProductType.Uf}>
					<ContractIDProvider pageProps={pageProps}>
						<DPSContent />
					</ContractIDProvider>
				</ContractTypeProvider>
			</BackButtonLayout>
		</ContractIDProvider>
	);
}

export default withProtection(DoplnkovePenzijniSporeni);

export const Head = (): JSX.Element => (
	<SEO title={title}>
		<meta name="robots" content="noindex" />
	</SEO>
);
