import spacing from "./spacing";
const typographyComponents = {
	headingXXL: {
		fontSize: "3.6rem",
		lineHeight: 1.1,
		fontFamily: "NNNittiGroteskHeading",
	},
	headingXL: {
		fontSize: "2.8rem",
		lineHeight: 1.1,
		fontFamily: "NNNittiGroteskHeading",
		marginBottom: spacing[5],
	},
	headingL: {
		fontSize: "2.4rem",
		lineHeight: 1.1,
		fontFamily: "NNNittiGroteskHeading",
		marginBottom: spacing[5],
	},
	headingM: {
		fontSize: "2rem",
		lineHeight: 1.1,
		fontFamily: "NNNittiGroteskHeading",
		marginBottom: spacing[5],
	},
	headingS: {
		fontSize: "1.6rem",
		lineHeight: 1.1,
		fontFamily: "NNNittiGroteskHeading",
		marginBottom: spacing[5],
	},
	headingXS: {
		fontSize: "1.4rem",
		lineHeight: 1.1,
		fontFamily: "NNNittiGroteskHeading",
		marginBottom: spacing[5],
	},
	bodyTextL: {
		fontSize: "2rem",
		lineHeight: 1.5,
	},
	bodyTextM: {
		fontSize: "1.6rem",
		lineHeight: 1.5,
	},
	bodyTextS: {
		fontSize: "1.4rem",
		lineHeight: 1.5,
		marginBottom: spacing[6],
	},
	bodyTextXS: {
		fontSize: "1.2rem",
		lineHeight: 1.5,
		marginBottom: spacing[6],
	},
	button: {
		fontFamily: "NNNittiGroteskTextRegular",
	},
};

export default typographyComponents;
