import Stack from "@mui/material/Stack";
import { ErrorMessage, useField, useFormikContext } from "formik";
import { ChangeEvent, FC } from "react";

import FormErrorMessage from "../../FormErrorMessage";
import SwitchBasic, { SwitchBasicProps } from "./SwitchBasic";

export type onSwitchChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface SwitchProps extends Omit<SwitchBasicProps, "id"> {
	error?: boolean;
	name: string;
	hideError?: boolean;
	disabled?: boolean;
}

const SwitchInput: FC<Omit<SwitchProps, "id">> = ({
	error,
	name,
	hideError = false,
	disabled = false,
	...rest
}) => {
	const [field] = useField(name);
	const { isSubmitting } = useFormikContext();

	return (
		<Stack sx={{ alignItems: "start" }}>
			<SwitchBasic
				disabled={disabled || isSubmitting}
				className={`input ${error ? "invalid" : ""}`}
				checked={field.value}
				{...rest}
				{...field}
				id={`${name}-name`}
			/>
			{!hideError && (
				<ErrorMessage
					name={name}
					render={(msg) => (
						<FormErrorMessage name={name}>{msg}</FormErrorMessage>
					)}
				/>
			)}
		</Stack>
	);
};

export default SwitchInput;
