import { PossibleAnswer } from "../../../models";
import RadioInput from "../Form/Input/Radio";
import Switch from "../Form/Input/Switch";

function Answers({
	answers,
	i,
	type,
}: {
	answers: PossibleAnswer[];
	i: number;
	type?: "radio" | string;
}): JSX.Element {
	return (
		<>
			{answers.map((option, j) => {
				if (type === "radio")
					return (
						<RadioInput
							name={`answers.${i}`}
							value={option.id}
							label={option.content}
							id={option.id}
						/>
					);
				else
					return (
						<Switch
							name={`answers.${i}.${j}.${option.id}`}
							label={option.content}
						/>
					);
			})}
		</>
	);
}
export default Answers;
