import {
	useAppInsightsContext,
	withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ComponentClass, ComponentType } from "react";

/**
 * Shortcut HOC passing reactPlugin
 * @param Component
 * @param componentName
 * @param className
 */
export default function withAITrackingShortcut<P>(
	Component: ComponentType<P>,
	componentName?: string,
	className?: string
): ComponentClass<P> {
	const reactPlugin = useAppInsightsContext();
	return withAITracking<P>(reactPlugin, Component, componentName, className);
}
