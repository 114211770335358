import { Formik } from "formik";
import { boolean, object, string } from "yup";

import {
	useAllowedChangesForContract,
	usePensionDetail,
	usePutTaxOptimum,
} from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import { ContractType } from "../../../types/contracts";
import { useDrawer } from "../../Drawer/context";
import FormMultiStepDrawer from "../../Drawer/FormMultiStepDrawer";
import {
	mapFetchApiErrorToSubmitStatus,
	SubmitStatus,
	SubmitStatusStep,
} from "../../Drawer/GlobalSteps/SubmitStatusStep";
import { TaxOptimumInfo, TaxOptimumOverview } from "./TaxOptimumSteps";

export interface ITaxOptimumFormValues {
	isTaxOptimum: boolean;
}

export interface ITaxOptimumDialog {
	contractId: string;
	contractType: ContractType;
}

export const TaxOptimumDialog = ({
	contractId,
	contractType,
}: ITaxOptimumDialog) => {
	const { setStep } = useDrawer();
	const { refetch: refetchAllowedChanges } = useAllowedChangesForContract({
		id: contractId,
	});
	const { data: isTaxOptimum, refetch: refetchPensionDetail } =
		usePensionDetail<boolean>({
			id: contractId,
			type: contractType,
			options: {
				select: (data) => {
					return data.contract.isTaxOptimum;
				},
			},
		});

	const { mutateAsync } = usePutTaxOptimum({ contractId, contractType });
	return (
		<Formik
			initialValues={{ isTaxOptimum }}
			validationSchema={object().shape({
				isTaxOptimum: boolean().strict(),
				valuesChanged: string().parentHasChanges({
					isTaxOptimum,
				}),
			})}
			onSubmit={async ({ isTaxOptimum }, { setStatus }) => {
				try {
					if (isTaxOptimum === undefined) {
						return;
					}
					setStatus(SubmitStatus.Submitting);
					await mutateAsync({ isActive: isTaxOptimum });
					setStatus(SubmitStatus.Success);
					void refetchPensionDetail();
				} catch (error) {
					await refetchAllowedChanges();
					setStatus(mapFetchApiErrorToSubmitStatus(error));
				}
			}}
		>
			{({ submitForm, status }) => {
				return (
					<FormMultiStepDrawer title={cs.taxOptimumDialog.taxOptimum}>
						{{
							component: <TaxOptimumInfo key="tax-optimum-info" />,
							stepName: "tax-optimum-info",
						}}
						{{
							component: <TaxOptimumOverview key="tax-optimum-overview" />,
							stepName: "tax-optimum-overview",
							stepConfig: {
								customFunction: () => {
									void submitForm();
									setStep(2);
								},
							},
						}}
						{{
							component: (
								<SubmitStatusStep
									status={status}
									key="tax-optimum-after-submit"
								/>
							),
							stepName: "tax-optimum-after-submit",
							stepConfig: {
								//work around default custom form validation function, need to return true, otherwise drawer won't close.
								customFunction: () => true,
							},
						}}
					</FormMultiStepDrawer>
				);
			}}
		</Formik>
	);
};
