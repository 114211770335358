/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PossibleAnswer } from './PossibleAnswer';
import {
    PossibleAnswerFromJSON,
    PossibleAnswerFromJSONTyped,
    PossibleAnswerToJSON,
} from './PossibleAnswer';

/**
 * 
 * @export
 * @interface Questions
 */
export interface Questions {
    /**
     * 
     * @type {number}
     * @memberof Questions
     */
    minRequiredAnswers: number;
    /**
     * 
     * @type {string}
     * @memberof Questions
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Questions
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof Questions
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof Questions
     */
    maxRequiredAnswers: number;
    /**
     * 
     * @type {Array<PossibleAnswer>}
     * @memberof Questions
     */
    answers: Array<PossibleAnswer>;
}

/**
 * Check if a given object implements the Questions interface.
 */
export function instanceOfQuestions(value: object): value is Questions {
    if (!('minRequiredAnswers' in value) || value['minRequiredAnswers'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('number' in value) || value['number'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    if (!('maxRequiredAnswers' in value) || value['maxRequiredAnswers'] === undefined) return false;
    if (!('answers' in value) || value['answers'] === undefined) return false;
    return true;
}

export function QuestionsFromJSON(json: any): Questions {
    return QuestionsFromJSONTyped(json, false);
}

export function QuestionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Questions {
    if (json == null) {
        return json;
    }
    return {
        
        'minRequiredAnswers': json['minRequiredAnswers'],
        'id': json['id'],
        'number': json['number'],
        'content': json['content'],
        'maxRequiredAnswers': json['maxRequiredAnswers'],
        'answers': ((json['answers'] as Array<any>).map(PossibleAnswerFromJSON)),
    };
}

export function QuestionsToJSON(value?: Questions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'minRequiredAnswers': value['minRequiredAnswers'],
        'id': value['id'],
        'number': value['number'],
        'content': value['content'],
        'maxRequiredAnswers': value['maxRequiredAnswers'],
        'answers': ((value['answers'] as Array<any>).map(PossibleAnswerToJSON)),
    };
}

