import { useMsal } from "@azure/msal-react";
import { Formik } from "formik";

import { ConsentApi } from "../../../../apis/ConsentApi";
import { ConsentType } from "../../../../models";
import { Configuration } from "../../../../runtime";
import { agreement } from "../../Form/rules";
import FormMultiStepDrawer from "../FormMultiStepDrawer";
import AgreementContent from "./AgreementContent";
import AgreementForm from "./AgreementForm";

const AgreementDrawerContent = ({ onClose }): JSX.Element => {
	const { instance } = useMsal();
	return (
		<Formik
			noValidate
			initialValues={{ agreement: null }}
			validationSchema={agreement}
			onSubmit={async ({ agreement }, { setSubmitting, setStatus }) => {
				setSubmitting(true);
				setStatus("submitting");
				const api = new ConsentApi(
					new Configuration({
						async accessToken() {
							// TODO odchytit kdyz se nepovede acquirenout https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md#acquiring-an-access-token
							// TODO az se objevi druhe volani, udelame abstraktni metodu
							const tokenResponse = await instance.acquireTokenSilent({
								scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE],
							});
							return `Bearer ${tokenResponse.accessToken}`;
						},
						basePath: process.env.GATSBY_API_BASE_URL,
					})
				);

				const setConsentsPutMarketingPromise = api.setConsentsPut({
					setConsentRequest: {
						consentType: ConsentType.Marketing,
						consent: agreement === "true",
					},
				});

				const setConsentsPutElectronicCommunicationPromise = api.setConsentsPut(
					{
						setConsentRequest: {
							consentType: ConsentType.ElectronicCommunication,
							consent: true,
						},
					}
				);

				const setConsentsPutElectronicContractingPromise = api.setConsentsPut({
					setConsentRequest: {
						consentType: ConsentType.ElectronicContracting,
						consent: true,
					},
				});

				await Promise.all([
					setConsentsPutMarketingPromise,
					setConsentsPutElectronicCommunicationPromise,
					setConsentsPutElectronicContractingPromise,
				]);

				setSubmitting(false);
				setStatus("success");
				onClose();
			}}
		>
			{({ submitForm }) => (
				<FormMultiStepDrawer title="Poslední krok do Portálu" disableClose>
					{[
						{
							component: <AgreementContent key="AgreementContent" />,
							stepConfig: {
								customFunction: () => {
									/* just turn off default multistep form validation */
									/* this is an unusual case where form is after additional info, not before */
									return true;
								},
								stepName: "AgreementContent",
							},
						},
						{
							component: <AgreementForm key="AgreementContent" />,
							stepConfig: {
								label: "Dokončit registraci",
								customFunction: submitForm,
							},
							stepName: "AgreementForm",
						},
					]}
				</FormMultiStepDrawer>
			)}
		</Formik>
	);
};

export default AgreementDrawerContent;
