import { ReactElement } from "react";

import { Address } from "../../../models";
import InformationList from "../InformationList";
import AddressTemplate from "./AddressTemplate";

export interface AddressBoxProps {
	address?: Address;
	label?: string;
	subLabel?: string;
	isLoading?: boolean;
	editAction?: ReactElement;
}

const AddressBox = ({
	address,
	label,
	subLabel,
	isLoading,
	editAction,
	...rest
}: AddressBoxProps): JSX.Element => {
	return (
		<>
			<InformationList
				isLoading={isLoading}
				information={[
					{
						title: label,
						subTitle: subLabel,
						description: <AddressTemplate address={address} />,
					},
				]}
				secondaryAction={editAction}
				{...rest}
			/>
		</>
	);
};

export default AddressBox;
