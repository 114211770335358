/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocumentDownloadResponse } from './DocumentDownloadResponse';
import {
    DocumentDownloadResponseFromJSON,
    DocumentDownloadResponseFromJSONTyped,
    DocumentDownloadResponseToJSON,
} from './DocumentDownloadResponse';
import type { FileDownloadInfo } from './FileDownloadInfo';
import {
    FileDownloadInfoFromJSON,
    FileDownloadInfoFromJSONTyped,
    FileDownloadInfoToJSON,
} from './FileDownloadInfo';

/**
 * 
 * @export
 * @interface ChangeAdditionalData
 */
export interface ChangeAdditionalData {
    /**
     * 
     * @type {FileDownloadInfo}
     * @memberof ChangeAdditionalData
     */
    dpsPrecontractualInfo: FileDownloadInfo;
    /**
     * 
     * @type {DocumentDownloadResponse}
     * @memberof ChangeAdditionalData
     */
    zzjDocumentInfo?: DocumentDownloadResponse;
}

/**
 * Check if a given object implements the ChangeAdditionalData interface.
 */
export function instanceOfChangeAdditionalData(value: object): value is ChangeAdditionalData {
    if (!('dpsPrecontractualInfo' in value) || value['dpsPrecontractualInfo'] === undefined) return false;
    return true;
}

export function ChangeAdditionalDataFromJSON(json: any): ChangeAdditionalData {
    return ChangeAdditionalDataFromJSONTyped(json, false);
}

export function ChangeAdditionalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeAdditionalData {
    if (json == null) {
        return json;
    }
    return {
        
        'dpsPrecontractualInfo': FileDownloadInfoFromJSON(json['dpsPrecontractualInfo']),
        'zzjDocumentInfo': json['zzjDocumentInfo'] == null ? undefined : DocumentDownloadResponseFromJSON(json['zzjDocumentInfo']),
    };
}

export function ChangeAdditionalDataToJSON(value?: ChangeAdditionalData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dpsPrecontractualInfo': FileDownloadInfoToJSON(value['dpsPrecontractualInfo']),
        'zzjDocumentInfo': DocumentDownloadResponseToJSON(value['zzjDocumentInfo']),
    };
}

