/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthenticationSourceType } from './AuthenticationSourceType';
import {
    AuthenticationSourceTypeFromJSON,
    AuthenticationSourceTypeFromJSONTyped,
    AuthenticationSourceTypeToJSON,
} from './AuthenticationSourceType';

/**
 * 
 * @export
 * @interface ValidateBirthNumberRequest
 */
export interface ValidateBirthNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidateBirthNumberRequest
     */
    birthdate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidateBirthNumberRequest
     */
    birthNumber?: string | null;
    /**
     * 
     * @type {AuthenticationSourceType}
     * @memberof ValidateBirthNumberRequest
     */
    authenticationSource: AuthenticationSourceType;
}

/**
 * Check if a given object implements the ValidateBirthNumberRequest interface.
 */
export function instanceOfValidateBirthNumberRequest(value: object): value is ValidateBirthNumberRequest {
    if (!('authenticationSource' in value) || value['authenticationSource'] === undefined) return false;
    return true;
}

export function ValidateBirthNumberRequestFromJSON(json: any): ValidateBirthNumberRequest {
    return ValidateBirthNumberRequestFromJSONTyped(json, false);
}

export function ValidateBirthNumberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateBirthNumberRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'birthdate': json['birthdate'] == null ? undefined : json['birthdate'],
        'birthNumber': json['birth_number'] == null ? undefined : json['birth_number'],
        'authenticationSource': AuthenticationSourceTypeFromJSON(json['authenticationSource']),
    };
}

export function ValidateBirthNumberRequestToJSON(value?: ValidateBirthNumberRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'birthdate': value['birthdate'],
        'birth_number': value['birthNumber'],
        'authenticationSource': AuthenticationSourceTypeToJSON(value['authenticationSource']),
    };
}

