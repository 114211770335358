import { StaticImage } from "gatsby-plugin-image";

export default function ContactInfoCheck() {
	return (
		<StaticImage
			src="../images/contact-info-check.png"
			alt="desk"
			style={{
				position: "absolute",
				zIndex: -1,
				right: "20px",
				top: "150px",
			}}
		/>
	);
}
