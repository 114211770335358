import SvgIcon from "@mui/material/SvgIcon";

const Insurance = (props): JSX.Element => {
	return (
		<SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
			<path d="M11.5 15.5H9.7v-1.3c0-.6-.5-1.1-1.1-1.1H5.4c-.7 0-1.2.5-1.2 1.1v1.3H2.4c-.9 0-1.6.7-1.6 1.6v5.3c0 .9.7 1.6 1.6 1.6h9.1c.9 0 1.6-.7 1.6-1.6v-5.3c0-.9-.7-1.6-1.6-1.6Zm-6.3-1.3.2-.1h3.2l.1.1v1.3H5.2v-1.3Zm7 8.2c0 .3-.3.6-.7.6H2.4a.6.6 0 0 1-.6-.6v-5.3c0-.4.3-.6.6-.6h9.1c.4 0 .6.2.6.6v5.3Z" />
			<path d="M8.7 19.2H7.6v-1a.5.5 0 1 0-1 0v1H5.5a.5.5 0 1 0 0 1h1v1.2a.5.5 0 1 0 1 0v-1.2h1.2a.5.5 0 1 0 0-1Zm9.5-3.5c-.3 0-.5.2-.5.5v1.1h-1.1a.5.5 0 1 0 0 1h1.1v1.1a.5.5 0 1 0 1 0v-1h1.1a.5.5 0 1 0 0-1h-1v-1.2c0-.3-.3-.5-.6-.5Zm6.3-8.1C22.5 3 17.8 0 12.5 0s-10 3-12 7.6a.5.5 0 0 0 .5.7h1.6a11.2 11.2 0 0 1 5 .8h.1c.9-.4 1.5-.6 2.6-.8.2 0 .4-.2.4-.5s-.3-.5-.6-.4c-1 .1-1.7.3-2.6.7a12.3 12.3 0 0 0-5.7-.9A12 12 0 0 1 12.5 1a12 12 0 0 1 10.7 6.2h-1c-1.7 0-3.3.3-4.9.8-.8-.3-1.5-.5-2.4-.6-.3 0-.6.1-.6.4 0 .3.1.5.4.6 1 0 1.5.3 2.4.6h.4c2-.8 4.3-1 6.4-.7.2 0 .4 0 .5-.2v-.5Z" />
			<path d="M13 10.9V8.8a.5.5 0 1 0-1 0v2a.5.5 0 1 0 1 0Zm9.7 2.7h-1.8v-1.3c0-.6-.5-1.1-1.2-1.1h-3.2c-.6 0-1.1.5-1.1 1.1v1.3h-1.9a.5.5 0 1 0 0 1h9.2c.3 0 .6.2.6.6v5.3c0 .3-.3.6-.6.6H16a.5.5 0 1 0 0 1h6.6c.8 0 1.6-.7 1.6-1.6v-5.3c0-1-.8-1.6-1.6-1.6Zm-2.8 0h-3.5v-1.3l.1-.1h3.2l.2.1v1.3Z" />
		</SvgIcon>
	);
};

export default Insurance;
