import { QueryCacheNotifyEvent, useQueryClient } from "@tanstack/react-query";
import { isNil } from "lodash";
import { useEffect, useState } from "react";

import {
	GetDPSDetailResponse,
	GetPPDetailResponse,
	ProductType,
	StarPensionClientTypeEnum,
} from "../../models";
import { ContractType } from "../types/contracts";

export const LastAMLCheckKey = "lastAMLCheck";
export const LastBankIdCheck = "lastBankIdCheck";
export const AMLCheckIntervalMs =
	process.env.GATSBY_FEATURE_USE_AML_PROMPT_PERIOD_FROM_LIBRARY === "true"
		? parseInt(process.env.GATSBY_AML_PERIOD_IN_MINUTES as string) * 60 * 1000
		: 1000 * 60 * 60 * 2;

export const getLastAmlChecks = (): {
	aml: number | null;
	bankId: number | null;
} => {
	const lastAMLCheckString = localStorage.getItem(LastAMLCheckKey);
	const lastBankIdCheckString = localStorage.getItem(LastBankIdCheck);
	return {
		aml: lastAMLCheckString ? parseInt(lastAMLCheckString) : null,
		bankId: lastBankIdCheckString ? parseInt(lastBankIdCheckString) : null,
	};
};

export const getIsTimeToPrompt = (prompt: AMLPromt) => {
	const lastChecks = getLastAmlChecks();
	const promptID =
		prompt.ID &&
		(lastChecks.bankId === null ||
			Date.now() - AMLCheckIntervalMs > lastChecks.bankId);
	const promptAML =
		prompt.AML &&
		(lastChecks.aml === null ||
			Date.now() - AMLCheckIntervalMs > lastChecks.aml);
	return { promptID, promptAML };
};

export interface AMLPromt {
	ID: boolean;
	AML: boolean;
	contractType: ContractType;
	contractId: string;
}

const filterQueryEvent = (event: QueryCacheNotifyEvent): boolean => {
	if (event.type !== "updated" || event.action.type !== "success") return false;
	return (
		(event.query.queryKey as string[]).includes("getContractDetail") &&
		[ProductType.Uf, ProductType.Pf].some((contractType) =>
			(event.query.queryKey as string[]).includes(contractType)
		)
	);
};

const findAMLPrompt = (data: AMLData) => {
	const contractKeys = Object.keys(data);
	const keyWithBankIdPrompt = contractKeys.find((key) => data[key].ID);
	if (keyWithBankIdPrompt) return data[keyWithBankIdPrompt];
	const keyWithAMLPrompt = contractKeys.find((key) => data[key].AML);
	if (keyWithAMLPrompt) return data[keyWithAMLPrompt];
	return null;
};

type AMLData = Record<string, AMLPromt>;

export const useAMLPrompt = (): AMLPromt | null => {
	const client = useQueryClient();
	const cache = client.getQueryCache();
	const [amlData, setAmlData] = useState<AMLData>({});

	useEffect(() => {
		const deleteListener = cache.subscribe((event) => {
			if (filterQueryEvent(event)) {
				const data = event.query.state.data as
					| GetDPSDetailResponse
					| GetPPDetailResponse;
				const contractType = (event.query.queryKey as string[]).find(
					(string) => string === ProductType.Uf || string === ProductType.Pf
				);
				const contractId = data.contract.contractNumber;
				const AML = !data.isAmlFulfilled;
				const ID = !data.isClientIdentified;
				const isChildrenContract =
					"isChildrenContract" in data && data.isChildrenContract === true;
				const isRetired = !isNil(data.contract.retirementDate);
				if (!isRetired && !isChildrenContract) {
					setAmlData((prev) => {
						return {
							...prev,
							[contractId]: {
								AML,
								ID: ID && data.clientType === StarPensionClientTypeEnum.Z,
								contractId,
								contractType: contractType as ContractType,
							},
						};
					});
				}
			}
		});
		return () => deleteListener();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return findAMLPrompt(amlData);
};
