/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CustomAllocation } from './CustomAllocation';
import {
    CustomAllocationFromJSON,
    CustomAllocationFromJSONTyped,
    CustomAllocationToJSON,
} from './CustomAllocation';

/**
 * 
 * @export
 * @interface ChangeResources
 */
export interface ChangeResources {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeResources
     */
    acceptRecommendedStrategy: boolean;
    /**
     * 
     * @type {Array<CustomAllocation>}
     * @memberof ChangeResources
     */
    customAllocation?: Array<CustomAllocation> | null;
}

/**
 * Check if a given object implements the ChangeResources interface.
 */
export function instanceOfChangeResources(value: object): value is ChangeResources {
    if (!('acceptRecommendedStrategy' in value) || value['acceptRecommendedStrategy'] === undefined) return false;
    return true;
}

export function ChangeResourcesFromJSON(json: any): ChangeResources {
    return ChangeResourcesFromJSONTyped(json, false);
}

export function ChangeResourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeResources {
    if (json == null) {
        return json;
    }
    return {
        
        'acceptRecommendedStrategy': json['acceptRecommendedStrategy'],
        'customAllocation': json['customAllocation'] == null ? undefined : ((json['customAllocation'] as Array<any>).map(CustomAllocationFromJSON)),
    };
}

export function ChangeResourcesToJSON(value?: ChangeResources | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'acceptRecommendedStrategy': value['acceptRecommendedStrategy'],
        'customAllocation': value['customAllocation'] == null ? undefined : ((value['customAllocation'] as Array<any>).map(CustomAllocationToJSON)),
    };
}

