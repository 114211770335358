/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PendingChangeType } from './PendingChangeType';
import {
    PendingChangeTypeFromJSON,
    PendingChangeTypeFromJSONTyped,
    PendingChangeTypeToJSON,
} from './PendingChangeType';
import type { PendingChangeDetail } from './PendingChangeDetail';
import {
    PendingChangeDetailFromJSON,
    PendingChangeDetailFromJSONTyped,
    PendingChangeDetailToJSON,
} from './PendingChangeDetail';

/**
 * 
 * @export
 * @interface PensionPendingChange
 */
export interface PensionPendingChange {
    /**
     * 
     * @type {Date}
     * @memberof PensionPendingChange
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PensionPendingChange
     */
    validFromDate: Date;
    /**
     * 
     * @type {PendingChangeType}
     * @memberof PensionPendingChange
     */
    changeType: PendingChangeType;
    /**
     * 
     * @type {PendingChangeDetail}
     * @memberof PensionPendingChange
     */
    detail: PendingChangeDetail;
}

/**
 * Check if a given object implements the PensionPendingChange interface.
 */
export function instanceOfPensionPendingChange(value: object): value is PensionPendingChange {
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('validFromDate' in value) || value['validFromDate'] === undefined) return false;
    if (!('changeType' in value) || value['changeType'] === undefined) return false;
    if (!('detail' in value) || value['detail'] === undefined) return false;
    return true;
}

export function PensionPendingChangeFromJSON(json: any): PensionPendingChange {
    return PensionPendingChangeFromJSONTyped(json, false);
}

export function PensionPendingChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PensionPendingChange {
    if (json == null) {
        return json;
    }
    return {
        
        'createdDate': (new Date(json['createdDate'])),
        'validFromDate': (new Date(json['validFromDate'])),
        'changeType': PendingChangeTypeFromJSON(json['changeType']),
        'detail': PendingChangeDetailFromJSON(json['detail']),
    };
}

export function PensionPendingChangeToJSON(value?: PensionPendingChange | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createdDate': ((value['createdDate']).toISOString()),
        'validFromDate': ((value['validFromDate']).toISOString()),
        'changeType': PendingChangeTypeToJSON(value['changeType']),
        'detail': PendingChangeDetailToJSON(value['detail']),
    };
}

