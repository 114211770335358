import { Skeleton } from "@mui/material";

import { GetLifeDetailResponse, InsuredPersonRole } from "../../../../models";
import { useLifeDetail } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import { BodyTextS } from "../../Typography";

interface ILifeCardDescriptionProps {
	contractId: string;
	frequency?: boolean;
}

const LifeCardDescription = ({
	contractId,
	frequency = false,
}: ILifeCardDescriptionProps): JSX.Element => {
	const {
		data: life,
		isLoading,
		isSuccess,
	} = useLifeDetail<GetLifeDetailResponse>({ id: contractId });

	if (isLoading)
		return <Skeleton width={50} sx={{ display: "inline-block" }} />;

	if (isSuccess) {
		const firstInsuredPerson = life.insuredPersons.find(
			(p) =>
				InsuredPersonRole.First === p.role ||
				InsuredPersonRole.PolicyHolderAndFirst === p.role
		);
		if (frequency && life.paymentInfo?.list) {
			return <>{cs.frequencies2[life.paymentInfo.list[0].client.frequency]}</>;
		} else if (firstInsuredPerson) {
			let text = "";
			if (firstInsuredPerson.firstName) text = firstInsuredPerson.firstName;
			if (firstInsuredPerson.lastName)
				text += ` ${firstInsuredPerson.lastName}`;
			if (firstInsuredPerson.dateOfBirth)
				text += ` (${new Date(firstInsuredPerson.dateOfBirth).getFullYear()})`;

			return <BodyTextS mb={0}>{text}</BodyTextS>;
		} else {
			console.warn("Na smlouvě chybí první pojištěná osoba");
			return <>Na smlouvě chybí první pojištěná osoba</>;
		}
	}
	return <></>;
};

export default LifeCardDescription;
