/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DeliveryType } from './DeliveryType';
import {
    DeliveryTypeFromJSON,
    DeliveryTypeFromJSONTyped,
    DeliveryTypeToJSON,
} from './DeliveryType';

/**
 * 
 * @export
 * @interface ContractParametersLife
 */
export interface ContractParametersLife {
    /**
     * 
     * @type {string}
     * @memberof ContractParametersLife
     */
    contractNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof ContractParametersLife
     */
    signatureDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContractParametersLife
     */
    discountGroup?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractParametersLife
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractParametersLife
     */
    doesEmployerContribute: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractParametersLife
     */
    hasTaxBenefit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractParametersLife
     */
    allowTaxBenefitChanges: boolean;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ContractParametersLife
     */
    taxStatementDelivery: DeliveryType;
}

/**
 * Check if a given object implements the ContractParametersLife interface.
 */
export function instanceOfContractParametersLife(value: object): value is ContractParametersLife {
    if (!('contractNumber' in value) || value['contractNumber'] === undefined) return false;
    if (!('doesEmployerContribute' in value) || value['doesEmployerContribute'] === undefined) return false;
    if (!('hasTaxBenefit' in value) || value['hasTaxBenefit'] === undefined) return false;
    if (!('allowTaxBenefitChanges' in value) || value['allowTaxBenefitChanges'] === undefined) return false;
    if (!('taxStatementDelivery' in value) || value['taxStatementDelivery'] === undefined) return false;
    return true;
}

export function ContractParametersLifeFromJSON(json: any): ContractParametersLife {
    return ContractParametersLifeFromJSONTyped(json, false);
}

export function ContractParametersLifeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractParametersLife {
    if (json == null) {
        return json;
    }
    return {
        
        'contractNumber': json['contractNumber'],
        'signatureDate': json['signatureDate'] == null ? undefined : (new Date(json['signatureDate'])),
        'discountGroup': json['discountGroup'] == null ? undefined : json['discountGroup'],
        'discountPercent': json['discountPercent'] == null ? undefined : json['discountPercent'],
        'doesEmployerContribute': json['doesEmployerContribute'],
        'hasTaxBenefit': json['hasTaxBenefit'],
        'allowTaxBenefitChanges': json['allowTaxBenefitChanges'],
        'taxStatementDelivery': DeliveryTypeFromJSON(json['taxStatementDelivery']),
    };
}

export function ContractParametersLifeToJSON(value?: ContractParametersLife | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractNumber': value['contractNumber'],
        'signatureDate': value['signatureDate'] == null ? undefined : ((value['signatureDate'] as any).toISOString()),
        'discountGroup': value['discountGroup'],
        'discountPercent': value['discountPercent'],
        'doesEmployerContribute': value['doesEmployerContribute'],
        'hasTaxBenefit': value['hasTaxBenefit'],
        'allowTaxBenefitChanges': value['allowTaxBenefitChanges'],
        'taxStatementDelivery': DeliveryTypeToJSON(value['taxStatementDelivery']),
    };
}

