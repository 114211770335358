import { Box } from "@mui/material";

import cs from "../../translations/cs.json";
import Attention from "../AttentionBlock";
import { HeadingL } from "../Typography";

interface Props {
	errorCode: number | undefined; // TODO: replace number by enum after swagger update
}

const DocumentsError = ({ errorCode }: Props): JSX.Element => (
	<Box mt={7} mx={4}>
		<HeadingL withoutScale>{cs.global.documentsList}</HeadingL>
		<Attention severity="error" title={cs.errors.somethingWentWrong}>
			{errorCode === 900 ? cs.errors.documentsNotAvailable : cs.errors.general}
		</Attention>
	</Box>
);

export default DocumentsError;
