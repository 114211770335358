import { Button, Stack } from "@mui/material";
import { useState } from "react";

import SimpleDrawer from "../Drawer/SimpleDrawer";
import ArrowIconRight from "../Icons/ArrowIconRight";
import TriggerButton from "../Link/TriggerButton";
import { BodyTextL, BodyTextM } from "../Typography";

function HowToUseQRCode(): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<Button
				isHorizontal
				sx={{
					alignSelf: { md: "flex-start" },
				}}
				onClick={() => {
					setIsOpen(true);
				}}
			>
				<BodyTextM color="primary.main">Jak platit pomocí QR kódu</BodyTextM>
				<ArrowIconRight sx={{ marginLeft: 3 }} />
			</Button>
			<SimpleDrawer
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				open={isOpen}
			>
				<Stack spacing={5}>
					<BodyTextM>
						<Stack spacing={5}>
							<BodyTextL strong={true}>Jak platit pomocí QR kódu</BodyTextL>
							<BodyTextM>
								Při příští platbě ve Vašem mobilním internetovém bankovnictví
								vyberete možnost platby pomocí QR kódu (možnost může být také v
								podobě ikonky). Většina bank nabízí kromě možnosti skenování QR
								kódu také jeho načtení přímo z galerie Vašeho zařízení. Všechny
								platební údaje tak předvyplní za vás.
							</BodyTextM>
						</Stack>
					</BodyTextM>
					<TriggerButton
						onClick={() => setIsOpen(false)}
						sx={{ alignSelf: "flex-end" }}
					>
						Rozumím
					</TriggerButton>
				</Stack>
			</SimpleDrawer>
		</>
	);
}

export default HowToUseQRCode;
