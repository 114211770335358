import { Stack } from "@mui/material";

import useIsDesktop from "../../../desktop/useIsDesktop";
import cs from "../../../translations/cs.json";
import { TriggerButton } from "../../Link";
import { BodyTextM, HeadingM } from "../../Typography";

interface ICookieDialog {
	initial: boolean;
	onExpand: () => void;
	onAllowAll: () => void;
	onAllowNecesary: () => void;
}

export const CookieDialog = ({
	initial,
	onExpand,
	onAllowAll,
	onAllowNecesary,
}: ICookieDialog): JSX.Element => {
	const isDesktop = useIsDesktop();

	return (
		<Stack>
			<HeadingM sx={{ mb: "20px" }} withoutScale>
				{cs.cookies.dialog.pageUsesCookies}
			</HeadingM>
			<BodyTextM sx={{ mb: 4 }}>
				{cs.cookies.dialog.cookiesServicesImprovement}
			</BodyTextM>
			{initial && !isDesktop && (
				<TriggerButton
					onClick={onExpand}
					variant="text-light"
					size="small"
					sx={{
						justifyContent: "start",
						mb: 5,
						p: 0,
						textDecoration: "underline",
					}}
				>
					{cs.cookies.dialog.manageOptions}
				</TriggerButton>
			)}
			<Stack direction="row" spacing={3}>
				<TriggerButton
					variant="contained"
					sx={{
						width: { md: "auto", xxs: "50%" },
						maxWidth: "180px",
						py: { md: 3, xxs: "12px" },
						px: 4,
					}}
					disableElevation={true}
					onClick={onAllowAll}
					size="small"
				>
					{cs.cookies.dialog.allowAll}
				</TriggerButton>
				{initial && (
					<TriggerButton
						variant="outlined"
						sx={{
							width: { md: "auto", xxs: "50%" },
							maxWidth: "180px",
							py: 3,
							px: 4,
						}}
						disableElevation={true}
						onClick={onAllowNecesary}
						size="small"
					>
						{cs.cookies.dialog.allowNeccessary}
					</TriggerButton>
				)}
				{initial && isDesktop && (
					<TriggerButton
						variant="text-light"
						sx={{
							alignSelf: "center",
							ml: "auto !important",
							pr: 4,
							"&:hover": {
								textDecoration: "underline",
							},
						}}
						size="small"
						onClick={onExpand}
					>
						{cs.cookies.dialog.manageOptions}
					</TriggerButton>
				)}
			</Stack>
		</Stack>
	);
};
