import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
	UseQueryResult,
} from "@tanstack/react-query";
import useIsDesktop from "desktop/useIsDesktop";
import { Formik } from "formik";
import React from "react";
import { useEffect, useState } from "react";

import {
	CustomAllocation,
	Fund,
	GetProfileResponse,
	InvestorProfileResult,
	PossibleAnswer,
	SfdrPreference,
	ZZJInformationRequest,
} from "../../../../../models";
import {
	FetchApiError,
	usePrimaryAPIClient,
} from "../../../../context/PrimaryAPIClient";
import {
	usePrecontractualDocs,
	useSMS,
	useStrategy,
} from "../../../../queryHooks";
import cs from "../../../../translations/cs.json";
import { CustomApiError } from "../../../../types/errors";
import { sortedFunds } from "../../../../utils";
import { AMLRedirect } from "../../../AML";
import { GetAMLQuestionsDrawerStep } from "../../../AML/Form/questions";
import {
	GetBankIdIndetificationDrawerErrorStep,
	GetBankIdIndetificationDrawerStep,
} from "../../../AML/identification";
import { useSmartAction } from "../../../Contract/SmartAction/context";
import { useDrawer } from "../../../Drawer/context";
import FormMultiStepDrawer from "../../../Drawer/FormMultiStepDrawer";
import { handleScrollToError } from "../../../Drawer/FormMultiStepDrawer";
import {
	mapFetchApiErrorToSubmitStatus,
	SubmitStatus,
	SubmitStatusStep,
} from "../../../Drawer/GlobalSteps/SubmitStatusStep";
import FillQuestionareDecision from "../../../InvestmentQuestionare/FillQuestionareDecision";
import InvestmentQuestionareForm from "../../../InvestmentQuestionare/InvestmentQuestionareForm";
import Questions from "../../../InvestmentQuestionare/Questions";
import ChangesOverview from "../ChangesOverview";
import EditInvestmentStrategy from "../EditInvestmentStrategy";
import Explanation from "../Explanation";
import InvestmentDefaultProfile from "../InvestmentDefaultProfile";
import InvestmentStrategyIntro from "../InvestmentStrategyIntro";
import InvestmentWhatToChange from "../InvestmentWhatToChange";
import { SfdrQuestion } from "../SdfrQuestion";
import SMS from "../SMS";
import TelephoneNumberVerification from "../TelephoneNumberVerification";
import ZZJ from "../ZZJ";
import ChangeRequestError800 from "./AfterSubmitSteps/ChangeRequestError800";
import {
	existingResourcesAllocation,
	newResourcesAllocation,
	setInitialData,
} from "./dataFormatting";
import { validationSchema } from "./validation";

type ChangeSavingsStrategyFormProps = {
	title: string;
	contractId: string;
	personalData: UseQueryResult<GetProfileResponse, unknown>;
	authPhoneNumbers: false | string[] | undefined;
	refetchPension: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<unknown, unknown>>;
	funds: Fund[];
	existingResourcesMissing: boolean;
	setFormWasSend: React.Dispatch<React.SetStateAction<boolean>>;
	includeID: boolean;
	includeAML: boolean;
};

const ChangeSavingsStrategyForm = ({
	existingResourcesMissing,
	personalData,
	title,
	funds,
	contractId,
	refetchPension,
	authPhoneNumbers,
	setFormWasSend,
	includeID,
	includeAML,
}: ChangeSavingsStrategyFormProps): JSX.Element => {
	const { action } = useSmartAction();
	const { setStep, step, nextStep, steps } = useDrawer();
	const [results, setResultsFunc] = useState<InvestorProfileResult>();
	const [submitInnerForm, setSubmitInnerForm] = useState();
	const [sfdrPreference, setSfdrPreference] = useState<SfdrPreference>();
	const [submitSfdrForm, setSubmitSfdrForm] = useState<() => void>();
	const [formikAnswersHolder, setFormikAnswersHolder] = useState();
	const [apiAnswersHolder, setApiAnswersHolder] = useState<
		Array<PossibleAnswer> | undefined
	>();
	const [wantFillQuestionare, setWantFillQuestionare] = useState<boolean>(true);
	const [initialValues, setInitialValues] = useState(
		setInitialData(
			results,
			personalData,
			existingResourcesMissing,
			authPhoneNumbers
		)
	);
	const [displayExplanation, setDisplayExplanation] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [zzjInformation, setZzjInformation] = useState<ZZJInformationRequest>();
	const [smsCode, setSmsCode] = useState("");
	const [zzjPageIsLoading, setZzjPageIsLoading] = useState(false);
	const [fullscreenZZJ, setFullscreenZZJ] = useState(false);
	const [submitError, setSubmitError] =
		useState<CustomApiError.SMSCodeNotValid | null>(null);
	const { data: smsData, refetch: smsRefetch } = useSMS({
		phoneNumber: phoneNumber,
	});

	const isDesktop = useIsDesktop();

	const sendSMS = smsRefetch;
	useEffect(() => {
		setInitialValues({
			...initialValues,
			existingResources: {
				...initialValues.existingResources,
				valuesChanged: undefined,
				allocation: sortedFunds(results?.investmentStrategy?.allocation),
				overrideFundsAllocationRecommendation: false,
			},
			newResources: {
				...initialValues.newResources,
				valuesChanged: undefined,
				allocation: sortedFunds(results?.investmentStrategy?.allocation),
				overrideFundsAllocationRecommendation: false,
			},
		});
	}, [results]);

	const { investorProfileApi } = usePrimaryAPIClient();

	const { data: docsData } = usePrecontractualDocs();

	const strategyEndpoint = useStrategy({
		contractId: contractId.toString(),
		changeFundsRequest: {
			transactionId: smsData?.transactionId || "",
			authenticationPhone: smsData?.phoneNumber || "",
			smsCode,
			dpsPrecontractualInfoName: docsData?.name || "",
			zzjInformation: zzjInformation
				? { ...zzjInformation }
				: ({} as ZZJInformationRequest),
			recommendedStrategy:
				results?.investmentStrategy?.allocation || ({} as CustomAllocation[]),
		},
	});

	const isInvestmentChanged = strategyEndpoint.isSuccess;

	useEffect(() => {
		setDisplayExplanation(false);
	}, [step]);

	useEffect(() => {
		if (isInvestmentChanged) {
			refetchPension(); // to immediately show banner that the fund change is in progress
		}
	}, [isInvestmentChanged]);
	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			validationSchema={validationSchema(
				step,
				steps,
				initialValues,
				results,
				funds
			)}
			initialTouched={{
				existingResources: {
					valuesChanged: true,
					overrideFundsAllocationRecommendation: true,
					dynBalMaxAlloc: true,
					dynConMaxAlloc: true,
					balDynMaxAlloc: true,
					balConMaxAlloc: true,
					conDynMaxAlloc: true,
					conBalMaxAlloc: true,
					prepensionBalMaxAlloc: true,
					prepensionDynMaxAlloc: true,
				},
				newResources: {
					valuesChanged: true,
					overrideFundsAllocationRecommendation: true,
					dynBalMaxAlloc: true,
					dynConMaxAlloc: true,
					balDynMaxAlloc: true,
					balConMaxAlloc: true,
					conDynMaxAlloc: true,
					conBalMaxAlloc: true,
					prepensionBalMaxAlloc: true,
					prepensionDynMaxAlloc: true,
				},
				overviewAccept: true,
				selectedResources: true,
			}}
			onSubmit={async (values, { setSubmitting, setStatus }) => {
				try {
					setStatus(SubmitStatus.Submitting);
					await strategyEndpoint.mutateAsync();
					setStatus(SubmitStatus.Success);
					setSubmitError(null);
				} catch (err) {
					setStatus(mapFetchApiErrorToSubmitStatus(err));
					if (
						err instanceof FetchApiError &&
						err.errorResponse.code === CustomApiError.SMSCodeNotValid
					)
						// 800 SMSCodeNotValid, 0 None, 399 InvalidQueryParameters, 400 ValidationFailed, 401 Unauthorized, 501 DBCallFailed, 502 UnhandledException, 600 PensionContributionAMLFailed, 700 StarPensionCallFailed, 900 LivelinkCallFailed
						setSubmitError(CustomApiError.SMSCodeNotValid);
				}
				setSubmitting(false);
				setFormWasSend(true);
			}}
		>
			{({
				validateForm,
				submitForm,
				values,
				setSubmitting,
				isSubmitting,
				setFieldValue,
				status,
			}) => {
				return (
					<FormMultiStepDrawer
						title={title}
						hasStepper={true}
						displayExplanation={displayExplanation}
						reactQueryStatus={strategyEndpoint.status}
						zzjPageIsLoading={zzjPageIsLoading}
						fullscreenZZJ={fullscreenZZJ}
						wideModal={isDesktop}
					>
						{includeID &&
							action?.type !== "InvestmentStrategyBankId" &&
							GetBankIdIndetificationDrawerStep({
								scenario: "default",
								actionInfo: {
									redirect: AMLRedirect.Strategy,
									contractId,
									contractType: "UF",
								},
								onSkip: () => {
									nextStep();
								},
							})}
						{action?.type === "InvestmentStrategyBankId" &&
							action.context &&
							"success" in action.context &&
							action.context.success === false &&
							GetBankIdIndetificationDrawerErrorStep({
								errorMessage: action.context?.errorMessage as
									| string
									| undefined,
								onSkip: () => {
									nextStep();
								},
							})}
						{includeAML &&
							GetAMLQuestionsDrawerStep({
								contractId,
								contractType: "UF",
								helpText: cs.AML.amlReasoning.investmentStrategy,
								saveText: cs.AML.amlSave.investmentStrategy,
								onSubmitCallback: (error) => {
									if (!error) {
										nextStep();
									} else {
										throw error;
									}
								},
							})}
						{{
							component: <InvestmentStrategyIntro />,
							stepName: "InvestmentStrategyIntro",
						}}
						{{
							component: (
								<SfdrQuestion
									setPreference={setSfdrPreference}
									setSubmitter={setSubmitSfdrForm}
									setNextStep={nextStep}
								/>
							),
							stepName: "SfdrQuestion",
							stepConfig: {
								customFunction() {
									submitSfdrForm();
								},
							},
						}}
						{{
							component: displayExplanation ? (
								<Explanation />
							) : (
								<FillQuestionareDecision
									isSubmitting={isSubmitting}
									wantFillQuestionare={wantFillQuestionare}
									setWantFillQuestionare={setWantFillQuestionare}
									displayExplanationFunc={() =>
										setDisplayExplanation(!displayExplanation)
									}
								/>
							),
							stepName: "FillQuestionareDecision",
							stepConfig: {
								customFunction: () => {
									if (displayExplanation) {
										setDisplayExplanation(!displayExplanation);
									} else {
										if (!wantFillQuestionare) {
											setSubmitting(true);
											void investorProfileApi
												.postInvestorProfilePost({
													userAnswersRequest: {
														rejected: true,
														answers: [],
													},
												})
												.then((result) => {
													setResultsFunc(result);
													setSubmitting(false);
													nextStep();
												});
										} else {
											nextStep();
										}
									}
								},
								customBackFunction: displayExplanation
									? () => {
											setDisplayExplanation(false);
											return false;
									  }
									: undefined,
							},
						}}
						{wantFillQuestionare && {
							component: (
								<InvestmentQuestionareForm
									setSubmitting={setSubmitting}
									setResultsFunc={setResultsFunc}
									wantFillQuestionare={wantFillQuestionare}
									setFormikAnswersHolder={setFormikAnswersHolder}
									formikAnswersHolder={formikAnswersHolder}
									setApiAnswersHolder={setApiAnswersHolder}
								>
									<Questions setSubmitInnerForm={setSubmitInnerForm} />
								</InvestmentQuestionareForm>
							),
							stepName: "InvestmentQuestionareForm",
							stepConfig: {
								label: cs.investmentStrategy.investmentQuestionare.confirm,
								customFunction: () => {
									submitInnerForm();
									handleScrollToError();
								},
							},
						}}
						{{
							component: (
								<InvestmentDefaultProfile
									profile={results?.investmentProfile}
									strategy={results?.investmentStrategy}
									allocation={results?.investmentStrategy?.allocation}
								/>
							),
							stepName: "InvestmentDefaultProfile",
						}}
						{{
							component: (
								<InvestmentWhatToChange
									existingResourcesMissing={existingResourcesMissing}
								/>
							),
							stepName: "InvestmentWhatToChange",
							stepConfig: {
								label: cs.investmentStrategy.continueToChange,
								customFunction: () => {
									validateForm()
										.then((errors) => {
											if (Object.keys(errors).length === 0) {
												setStep(step + 1);
											}
										})
										.catch((err) => {
											throw err;
										});
								},
							},
						}}
						{values.existingResourcesSelected && {
							component: (
								<EditInvestmentStrategy
									contractId={contractId}
									allocation={existingResourcesAllocation(funds, initialValues)}
									recommendedStrategy={results?.investmentStrategy}
									resources="existingResources"
								/>
							),
							stepName: "EditInvestmentStrategy1",
							stepConfig: {
								customFunction: () => {
									validateForm()
										.then((errors) => {
											if (Object.keys(errors).length === 0) {
												setStep(step + 1);
											}
										})
										.catch((err) => {
											throw err;
										});
								},
							},
						}}
						{values.newResourcesSelected && {
							component: (
								<EditInvestmentStrategy
									contractId={contractId}
									allocation={newResourcesAllocation(funds, initialValues)}
									recommendedStrategy={results?.investmentStrategy}
									resources="newResources"
								/>
							),
							stepName: "EditInvestmentStrategy2",
							stepConfig: {
								customFunction: () => {
									validateForm()
										.then((errors) => {
											if (Object.keys(errors).length === 0) {
												setStep(step + 1);
											}
										})
										.catch((err) => {
											throw err;
										});
								},
							},
						}}
						{{
							component: (
								<ChangesOverview
									existingResourcesMissing={existingResourcesMissing}
									defaultAllocation={{
										existingResourcesAllocation: existingResourcesAllocation(
											funds,
											initialValues
										),
										newResourcesAllocation: newResourcesAllocation(
											funds,
											initialValues
										),
									}}
									wantFillQuestionare={wantFillQuestionare}
								/>
							),
							stepName: "ChangesOverview",
							stepConfig: {
								customFunction: async () => {
									await validateForm()
										.then((errors) => {
											handleScrollToError();
											if (Object.keys(errors).length === 0) {
												setStep(step + 1);
											}
										})
										.catch((err) => {
											throw err;
										});
								},
							},
						}}
						{{
							component: (
								<ZZJ
									setSubmitting={setSubmitting}
									wantFillQuestionare={wantFillQuestionare}
									sfdrPreference={sfdrPreference}
									recommendedAllocation={
										results?.investmentStrategy?.allocation
									}
									answers={apiAnswersHolder}
									contractId={contractId}
									setZzjInformation={setZzjInformation}
									page={1}
									setZzjPageIsLoading={setZzjPageIsLoading}
									fullscreenZZJ={fullscreenZZJ}
									setFullscreenZZJ={setFullscreenZZJ}
								/>
							),
							stepName: "ZZJ1",
							drawerStepSx: { height: "100%" },
							stepConfig: {
								label: zzjPageIsLoading && cs.investmentStrategy.zzjLoading,
								forceFullView: true,
								customBackFunction: () => {
									setFullscreenZZJ(false);
									return true;
								},
							},
						}}
						{{
							component: (
								<ZZJ
									setSubmitting={setSubmitting}
									wantFillQuestionare={wantFillQuestionare}
									sfdrPreference={sfdrPreference}
									recommendedAllocation={
										results?.investmentStrategy?.allocation
									}
									answers={apiAnswersHolder}
									contractId={contractId}
									setZzjInformation={setZzjInformation}
									page={2}
									setZzjPageIsLoading={setZzjPageIsLoading}
									fullscreenZZJ={fullscreenZZJ}
									setFullscreenZZJ={setFullscreenZZJ}
								/>
							),
							stepName: "ZZJ2",
							drawerStepSx: { height: "100%" },
							stepConfig: {
								forceFullView: true,
							},
						}}
						{{
							component: (
								<ZZJ
									setSubmitting={setSubmitting}
									wantFillQuestionare={wantFillQuestionare}
									sfdrPreference={sfdrPreference}
									recommendedAllocation={
										results?.investmentStrategy?.allocation
									}
									answers={apiAnswersHolder}
									contractId={contractId}
									setZzjInformation={setZzjInformation}
									page={3}
									setZzjPageIsLoading={setZzjPageIsLoading}
									fullscreenZZJ={fullscreenZZJ}
									setFullscreenZZJ={setFullscreenZZJ}
								/>
							),
							stepName: "ZZJ3",
							drawerStepSx: { height: "100%" },
							stepConfig: {
								forceFullView: true,
								customFunction: () => {
									nextStep();
									setFullscreenZZJ(false);
								},
							},
						}}
						{{
							component: (
								<TelephoneNumberVerification
									personalData={personalData}
									setPhoneNumber={setPhoneNumber}
									authPhoneNumbers={authPhoneNumbers}
								/>
							),
							stepName: "TelephoneNumberVerification",
							stepConfig: {
								label: isSubmitting
									? cs.investmentStrategy.sendingSMS
									: cs.investmentStrategy.sendSMS,
								customFunction: () => {
									validateForm()
										.then(async (errors) => {
											if (Object.keys(errors).length === 0) {
												setSubmitting(true);
												await sendSMS();
												setSubmitting(false);
												setStep(step + 1);
											}
										})
										.catch((err) => {
											throw err;
										});
								},
							},
						}}
						{{
							component: (
								<SMS
									setFieldValue={setFieldValue}
									setSmsCode={setSmsCode}
									strategyEndpointStatus={strategyEndpoint.status}
								/>
							),
							stepName: "SMS",
							stepConfig: {
								label: cs.global.confirm,
								customFunction: () => {
									validateForm()
										.then(async (errors) => {
											if (Object.keys(errors).length === 0) {
												await submitForm();
												setStep(step + 1);
											}
										})
										.catch((err) => {
											throw err;
										});
								},
							},
						}}
						{{
							component: (
								<SubmitStatusStep
									status={status}
									errorProps={
										submitError
											? { customElement: <ChangeRequestError800 /> }
											: undefined
									}
								/>
							),
							stepName: "SubmitStatusStep",
							stepConfig: {
								label:
									strategyEndpoint.status === "error"
										? "Zavřít beze změny"
										: "Zavřít",
							},
						}}
					</FormMultiStepDrawer>
				);
			}}
		</Formik>
	);
};

export default ChangeSavingsStrategyForm;
