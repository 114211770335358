/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLoginPayloadRequest
 */
export interface CreateLoginPayloadRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLoginPayloadRequest
     */
    contractNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLoginPayloadRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLoginPayloadRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLoginPayloadRequest
     */
    bankerId?: string | null;
}

/**
 * Check if a given object implements the CreateLoginPayloadRequest interface.
 */
export function instanceOfCreateLoginPayloadRequest(value: object): value is CreateLoginPayloadRequest {
    if (!('contractNumber' in value) || value['contractNumber'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function CreateLoginPayloadRequestFromJSON(json: any): CreateLoginPayloadRequest {
    return CreateLoginPayloadRequestFromJSONTyped(json, false);
}

export function CreateLoginPayloadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLoginPayloadRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'contractNumber': json['contractNumber'],
        'phoneNumber': json['phoneNumber'],
        'email': json['email'],
        'bankerId': json['bankerId'] == null ? undefined : json['bankerId'],
    };
}

export function CreateLoginPayloadRequestToJSON(value?: CreateLoginPayloadRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractNumber': value['contractNumber'],
        'phoneNumber': value['phoneNumber'],
        'email': value['email'],
        'bankerId': value['bankerId'],
    };
}

