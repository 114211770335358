import { useField } from "formik";

import cs from "../../translations/cs.json";
import Attention from "../AttentionBlock";
import {
	yearlyStateContributionFormula,
	yearlyStateContributionFormulaAfterNovel,
} from "../DPS/formulas";
import HandMoneyIcon from "../Icons/HandMoney";
import PigIcon from "../Icons/Pig";
import { BodyTextM } from "../Typography";

const Attentions = () => {
	const [field] = useField("amount");
	const monthlyStateContribution =
		yearlyStateContributionFormula(field.value) / 12;
	const monthlyStateContributionAfterNovel =
		yearlyStateContributionFormulaAfterNovel(field.value) / 12;

	return (
		<>
			{monthlyStateContributionAfterNovel < monthlyStateContribution && (
				<Attention severity="warning" sx={{ backgroundColor: "#FFEDD9" }}>
					{cs.pensionCalculator.attentions.attention1}
					<BodyTextM strong>
						{cs.pensionCalculator.attentions.attention1b}
					</BodyTextM>
				</Attention>
			)}
			{monthlyStateContributionAfterNovel === monthlyStateContribution && (
				<Attention
					severity="warning"
					sx={{ backgroundColor: "#FFEDD9" }}
					icon={
						<PigIcon
							sx={{
								width: "2.4rem",
								height: "2.4rem",
								color: "#C16700",
							}}
						/>
					}
				>
					{cs.pensionCalculator.attentions.attention2}
					<BodyTextM strong>
						{cs.pensionCalculator.attentions.attention2b}
					</BodyTextM>
				</Attention>
			)}
			{monthlyStateContributionAfterNovel > monthlyStateContribution &&
				monthlyStateContributionAfterNovel < 340 && (
					<Attention
						severity="success"
						sx={{ backgroundColor: "#E9F2E7" }}
						icon={
							<HandMoneyIcon
								sx={{
									width: "2.4rem",
									height: "2.4rem",
									color: "#538B4C",
								}}
							/>
						}
					>
						{cs.pensionCalculator.attentions.attention3}
					</Attention>
				)}
			{monthlyStateContributionAfterNovel >= 340 && field.value < 5700 && (
				<Attention
					severity="success"
					sx={{ backgroundColor: "#E9F2E7" }}
					icon={
						<HandMoneyIcon
							sx={{
								width: "2.4rem",
								height: "2.4rem",
								color: "#538B4C",
							}}
						/>
					}
				>
					{cs.pensionCalculator.attentions.attention4}
					<BodyTextM strong>
						{cs.pensionCalculator.attentions.attention4b}
					</BodyTextM>
				</Attention>
			)}
			{field.value >= 5700 && (
				<Attention
					severity="success"
					sx={{ backgroundColor: "#E9F2E7" }}
					icon={
						<HandMoneyIcon
							sx={{
								width: "2.4rem",
								height: "2.4rem",
								color: "#538B4C",
							}}
						/>
					}
				>
					{cs.pensionCalculator.attentions.attention5}
					<BodyTextM strong>
						{cs.pensionCalculator.attentions.attention5b}
					</BodyTextM>
					{cs.pensionCalculator.attentions.attention5c}
					<BodyTextM strong>
						{cs.pensionCalculator.attentions.attention5d}
					</BodyTextM>
				</Attention>
			)}
		</>
	);
};

export default Attentions;
