import SvgIcon from "@mui/material/SvgIcon";

const Checkbox1 = (props): JSX.Element => {
	return (
		<SvgIcon width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
			<path d="M25 1H5a4 4 0 0 0-4 4v24a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V9 h-1 v+20 a3 3 0 0 1 -3 3 h-24 a3 3 0 0 1 -3 -3 v-24 a3 3 0 0 1 3 -3 h20 v-1 " />
			<path d="M26.437 7.39a.75.75 0 0 0-1.047.173L12.993 24.845 8.53 20.383a.75.75 0 1 0-1.06 1.06l5.087 5.087a.748.748 0 0 0 1.14-.092l12.912-18a.75.75 0 0 0-.172-1.047Z" />
		</SvgIcon>
	);
};

export default Checkbox1;
