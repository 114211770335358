/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAdUsersResponse
 */
export interface GetAdUsersResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    creationType: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    birthNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    pid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    createdDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAdUsersResponse
     */
    lastSignInDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAdUsersResponse
     */
    loginAttempts?: number | null;
}

/**
 * Check if a given object implements the GetAdUsersResponse interface.
 */
export function instanceOfGetAdUsersResponse(value: object): value is GetAdUsersResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    if (!('creationType' in value) || value['creationType'] === undefined) return false;
    if (!('reason' in value) || value['reason'] === undefined) return false;
    return true;
}

export function GetAdUsersResponseFromJSON(json: any): GetAdUsersResponse {
    return GetAdUsersResponseFromJSONTyped(json, false);
}

export function GetAdUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAdUsersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'creationType': json['creationType'],
        'reason': json['reason'],
        'birthNumber': json['birthNumber'] == null ? undefined : json['birthNumber'],
        'birthDate': json['birthDate'] == null ? undefined : json['birthDate'],
        'pid': json['pid'] == null ? undefined : json['pid'],
        'createdDateTime': json['createdDateTime'] == null ? undefined : json['createdDateTime'],
        'email': json['email'] == null ? undefined : json['email'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'lastSignInDate': json['lastSignInDate'] == null ? undefined : json['lastSignInDate'],
        'loginAttempts': json['loginAttempts'] == null ? undefined : json['loginAttempts'],
    };
}

export function GetAdUsersResponseToJSON(value?: GetAdUsersResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'displayName': value['displayName'],
        'creationType': value['creationType'],
        'reason': value['reason'],
        'birthNumber': value['birthNumber'],
        'birthDate': value['birthDate'],
        'pid': value['pid'],
        'createdDateTime': value['createdDateTime'],
        'email': value['email'],
        'phone': value['phone'],
        'lastSignInDate': value['lastSignInDate'],
        'loginAttempts': value['loginAttempts'],
    };
}

