import SvgIcon from "@mui/material/SvgIcon";

const PencilIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
			<path d="M13.35.649a2.224 2.224 0 0 0-3.141 0L1.959 8.89l-.004.005c-.014.015-.023.035-.035.053-.011.018-.026.032-.034.052L.025 13.54a.333.333 0 0 0 .436.434L4.99 12.1a.33.33 0 0 0 .108-.072l6.79-6.782.002-.002 1.459-1.457c.419-.42.65-.976.65-1.57 0-.592-.231-1.15-.65-1.568ZM.945 13.052l.525-1.28c.332.153.58.428.7.773l-1.225.507Zm1.837-.76a1.975 1.975 0 0 0-1.061-1.133l.66-1.61.032.011c.242.089.47.209.68.357.586.413 1.003 1.031 1.21 1.747l-1.52.628Zm10.096-8.977-1.224 1.223-1.322-1.32a.334.334 0 0 0-.471.472l1.321 1.32-6.299 6.29c-.265-.784-.745-1.463-1.406-1.928A3.571 3.571 0 0 0 2.791 9l7.89-7.88a1.557 1.557 0 0 1 2.197 0c.294.293.455.683.455 1.098 0 .414-.161.804-.455 1.097Z" />
		</SvgIcon>
	);
};

export default PencilIcon;
