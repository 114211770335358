import { Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { PageProps } from "gatsby";

import ContactInfoCheckImage from "../../images/ContactInfoCheck";

const Layout = ({ children }: PageProps): JSX.Element => {
	const isD = useIsDesktop();

	return (
		<Stack
			sx={{
				position: "relative",
				flexGrow: 1,
				alignItems: { md: "flex-start" },
			}}
		>
			{isD && <ContactInfoCheckImage />}
			<Stack
				spacing={5}
				sx={{
					flexGrow: 1,
					width: { md: "48%" },
					overflowY: "hidden",
					mx: { md: -3, xxs: 0 },
					pt: isD ? 8 : 0,
					pb: 3,
					px: { md: 3, xxs: 4 },
				}}
			>
				{children}
			</Stack>
		</Stack>
	);
};

export default Layout;
