/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizeRequest,
  AuthorizeResponse,
  CreateLoginPayloadRequest,
  CreateLoginPayloadResponse,
  ErrorResponse,
} from '../models/index';
import {
    AuthorizeRequestFromJSON,
    AuthorizeRequestToJSON,
    AuthorizeResponseFromJSON,
    AuthorizeResponseToJSON,
    CreateLoginPayloadRequestFromJSON,
    CreateLoginPayloadRequestToJSON,
    CreateLoginPayloadResponseFromJSON,
    CreateLoginPayloadResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models/index';

export interface AuthorizePostRequest {
    authorizeRequest?: AuthorizeRequest;
}

export interface CreateLoginPayloadPostRequest {
    createLoginPayloadRequest?: CreateLoginPayloadRequest;
}

/**
 * 
 */
export class PartnerLoginApi extends runtime.BaseAPI {

    /**
     * Authorize payload to allow continuation of AD B2C login process
     */
    async authorizePostRaw(requestParameters: AuthorizePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/partner-login/authorize-partner-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorizeRequestToJSON(requestParameters['authorizeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizeResponseFromJSON(jsonValue));
    }

    /**
     * Authorize payload to allow continuation of AD B2C login process
     */
    async authorizePost(requestParameters: AuthorizePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizeResponse> {
        const response = await this.authorizePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Try to create login payload for a trusted third party
     */
    async createLoginPayloadPostRaw(requestParameters: CreateLoginPayloadPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLoginPayloadResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/partner-login/create-login-payload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLoginPayloadRequestToJSON(requestParameters['createLoginPayloadRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLoginPayloadResponseFromJSON(jsonValue));
    }

    /**
     * Try to create login payload for a trusted third party
     */
    async createLoginPayloadPost(requestParameters: CreateLoginPayloadPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLoginPayloadResponse> {
        const response = await this.createLoginPayloadPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
