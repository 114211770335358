/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetAllowedChangesResponse,
  GetContractsResponse,
  IsContractPresentResponse,
} from '../models/index';
import {
    GetAllowedChangesResponseFromJSON,
    GetAllowedChangesResponseToJSON,
    GetContractsResponseFromJSON,
    GetContractsResponseToJSON,
    IsContractPresentResponseFromJSON,
    IsContractPresentResponseToJSON,
} from '../models/index';

export interface IsContractPresentGetRequest {
    contractId: string;
}

/**
 * 
 */
export class ContractsApi extends runtime.BaseAPI {

    /**
     * Get list of allowed changes for all contracts for a PID/MPK
     */
    async getAllowedChangesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllowedChangesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/allowed-changes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllowedChangesResponseFromJSON(jsonValue));
    }

    /**
     * Get list of allowed changes for all contracts for a PID/MPK
     */
    async getAllowedChangesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllowedChangesResponse> {
        const response = await this.getAllowedChangesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get list of contracts for the current user.
     */
    async getContractsJWTGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetContractsResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetContractsResponseFromJSON));
    }

    /**
     * Get list of contracts for the current user.
     */
    async getContractsJWTGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetContractsResponse>> {
        const response = await this.getContractsJWTGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Does contract belong to this portal
     */
    async isContractPresentGetRaw(requestParameters: IsContractPresentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsContractPresentResponse>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling isContractPresentGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contracts/present/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsContractPresentResponseFromJSON(jsonValue));
    }

    /**
     * Does contract belong to this portal
     */
    async isContractPresentGet(requestParameters: IsContractPresentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsContractPresentResponse> {
        const response = await this.isContractPresentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
