/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConsentType } from './ConsentType';
import {
    ConsentTypeFromJSON,
    ConsentTypeFromJSONTyped,
    ConsentTypeToJSON,
} from './ConsentType';

/**
 * 
 * @export
 * @interface SetConsentRequest
 */
export interface SetConsentRequest {
    /**
     * 
     * @type {ConsentType}
     * @memberof SetConsentRequest
     */
    consentType: ConsentType;
    /**
     * 
     * @type {boolean}
     * @memberof SetConsentRequest
     */
    consent: boolean;
}

/**
 * Check if a given object implements the SetConsentRequest interface.
 */
export function instanceOfSetConsentRequest(value: object): value is SetConsentRequest {
    if (!('consentType' in value) || value['consentType'] === undefined) return false;
    if (!('consent' in value) || value['consent'] === undefined) return false;
    return true;
}

export function SetConsentRequestFromJSON(json: any): SetConsentRequest {
    return SetConsentRequestFromJSONTyped(json, false);
}

export function SetConsentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetConsentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'consentType': ConsentTypeFromJSON(json['consentType']),
        'consent': json['consent'],
    };
}

export function SetConsentRequestToJSON(value?: SetConsentRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'consentType': ConsentTypeToJSON(value['consentType']),
        'consent': value['consent'],
    };
}

