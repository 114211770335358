import { Box, BoxProps, Stack, StackProps } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";

const parseSwipeableIndex = (element: HTMLElement) => {
	const indexAttribute = element.getAttribute("data-swipeable-id");
	return indexAttribute ? parseInt(indexAttribute) : undefined;
};

// This restricts mounted but invisible children from expanding div height
// Eliminates unnecessary whitespace
const updateChildrenHeights = (
	currentIndex: number,
	container: HTMLDivElement
) => {
	// Make sure children have ids
	const children = Array.from(
		container.children as HTMLCollectionOf<HTMLElement>
	).filter((child) => typeof parseSwipeableIndex(child) === "number");

	// Hide inactive children so they do not affect the active childs height
	// (active child automatically expands to the size of div)
	children.forEach((child) => {
		if (parseSwipeableIndex(child) !== currentIndex)
			child.style.display = "none";
	});

	const activeChild = children.find((child) => {
		const index = parseSwipeableIndex(child);
		return index === currentIndex;
	});

	if (activeChild) {
		// Reset active child to its natural height (may have been previously limited)
		activeChild.style.height = "auto";
		const activeChildHeight = activeChild.scrollHeight;

		// Set other childrens height to other active child height and make them render again
		children.forEach((child) => {
			if (parseSwipeableIndex(child) !== currentIndex) {
				child.style.height = `${activeChildHeight}px`;
				child.style.display = "block";
			}
		});
	}
};

export interface ScrollSnapperProps {
	index: number;
	dynamicHeight?: boolean;
	onIndexChange: (index: number, target: HTMLDivElement) => void;
	childContainerProps?: BoxProps;
}

export const ScrollSnapper = ({
	index,
	onIndexChange,
	onScroll,
	sx,
	children,
	dynamicHeight = false,
	childContainerProps,
	...rest
}: ScrollSnapperProps & StackProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const scrollTimeout = useRef<number>();
	const lastChildrenCount = useRef(0);
	// on every rerender
	useEffect(() => {
		if (!containerRef.current) return;

		// set aria-hidden and inert on all children that aren't current page
		const currentChild = containerRef.current.children[index];

		for (const child of containerRef.current.children) {
			if (!(child instanceof HTMLElement)) continue;
			const isCurrent = child === currentChild;
			child.ariaHidden = !isCurrent ? "true" : null;
			child.inert = !isCurrent;
		}

		// only if number of children changed
		const childrenCount = containerRef.current.children.length;
		if (childrenCount !== lastChildrenCount.current) {
			lastChildrenCount.current = childrenCount;

			// scroll container to the current page instantly
			const pageWidth =
				containerRef.current.scrollWidth / containerRef.current.children.length;
			containerRef.current.scrollTo({
				behavior: "instant",
				left: index * pageWidth,
				top: 0,
			});
		}
	});

	// on page index change
	useEffect(() => {
		if (!containerRef.current) return;
		// scroll container to the current page smoothly
		const pageWidth =
			containerRef.current.scrollWidth / containerRef.current.children.length;
		containerRef.current.scrollTo({
			behavior: "smooth",
			left: index * pageWidth,
			top: 0,
		});
		dynamicHeight && updateChildrenHeights(index, containerRef.current);
	}, [index]);

	// on user scroll
	const handleScroll = useCallback(
		(event: React.UIEvent<HTMLDivElement>) => {
			onScroll?.(event);
			const { currentTarget } = event;
			if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
			scrollTimeout.current = window.setTimeout(() => {
				// update current page index
				const pageWidth =
					currentTarget.scrollWidth / currentTarget.children.length;
				const currentIndex = Math.round(currentTarget.scrollLeft / pageWidth);
				onIndexChange(currentIndex, currentTarget);
			}, 100);
		},
		[onIndexChange, onScroll]
	);

	return (
		<Stack
			{...rest}
			sx={{
				flexDirection: "row",
				overflowX: "scroll",
				scrollBehavior: "smooth",
				"::-webkit-scrollbar": { display: "none" },
				msOverflowStyle: "none",
				overscrollBehaviorX: "none",
				scrollSnapType: "x mandatory",
				scrollbarWidth: "none",
				...sx,
			}}
			ref={containerRef}
			onScroll={handleScroll}
		>
			<>
				{(Array.isArray(children) ? children : [children]).map(
					(child, index) => (
						<Box
							data-swipeable-id={index}
							{...childContainerProps}
							sx={{
								flexShrink: 0,
								width: "100%",
								maxHeight: "100%",
								overflowY: "auto",
								"::-webkit-scrollbar": { display: "none" },
								msOverflowStyle: "none",
								scrollSnapAlign: "center",
								scrollSnapStop: "always",
								scrollbarWidth: "none",
								...childContainerProps?.sx,
							}}
							key={index}
						>
							{child}
						</Box>
					)
				)}
			</>
		</Stack>
	);
};
