import SvgIcon from "@mui/material/SvgIcon";

const DocumentsIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="18" height="21" viewBox="0 0 18 21" {...props}>
			<path d="M13.9 17.75V.5a.5.5 0 0 0-.5-.5H1.26a.5.5 0 0 0-.5.5v17.25c0 .28.22.5.5.5h12.16a.5.5 0 0 0 .5-.5Zm-1-.5H1.76V1h11.16v16.25Z" />
			<path d="M4.39 4.92h5.88a.5.5 0 1 0 0-1H4.39a.5.5 0 1 0 0 1ZM4.39 9.12h5.88a.5.5 0 1 0 0-1H4.39a.5.5 0 1 0 0 1ZM4.39 13.35h5.88a.5.5 0 1 0 0-1H4.39a.5.5 0 1 0 0 1Z" />
			<path d="M16.54 3.14a.5.5 0 0 0-.5.5V20H4.4a.5.5 0 1 0 0 1h12.15a.5.5 0 0 0 .5-.5V3.64a.5.5 0 0 0-.5-.5Z" />
		</SvgIcon>
	);
};

export default DocumentsIcon;
