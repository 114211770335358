import { Stack } from "@mui/material";
import { CardProps } from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import { ReactElement } from "react";

import { PensionPhaseStatus, ProductType } from "../../../models";
import { useContractId } from "../../context/ContractIDContext";
import { NumberToCZK } from "../../functions";
import {
	useContractById,
	useDpsAllowedChanges,
	usePensionDetail,
} from "../../queryHooks";
import cs from "../../translations/cs.json";
import { BodyTextM } from "../Typography";
import Overview from ".";

interface Props extends CardProps {
	image: string;
	title?: string;
	icon?: ReactElement;
	totalSavings?: number;
	claimedSavings?: number;
	isFundsChangeInProgress?: boolean;
}

function renderSavedAmount(
	isChangesNotAllowed: boolean,
	isDataLoaded: boolean,
	savedAmount: number
) {
	switch (true) {
		case isChangesNotAllowed:
			return cs.global.changeInProgress;
		case isDataLoaded:
			return NumberToCZK(savedAmount);
		default:
			return (
				<>
					<Skeleton width={70} sx={{ display: "inline-block" }} /> Kč
				</>
			);
	}
}

function ProductOverview({
	title,
	image,
	icon,
	totalSavings,
	claimedSavings,
	isFundsChangeInProgress,
	sx,
	...rest
}: Props): JSX.Element {
	const id = useContractId();
	const { data: contractData, isLoading: contractDataLoading } =
		useContractById({
			id: id,
			options: { refetchOnMount: false },
		});

	const { data: starpensionData, isLoading: starpensionDataLoading } =
		usePensionDetail({
			id,
		});

	const { data: allowedChangesData } = useDpsAllowedChanges({
		contractId: id,
		options: {
			enabled:
				contractData?.type !== undefined &&
				contractData.type === ProductType.Uf &&
				!!id,
		},
	});

	if (contractDataLoading || contractData === undefined)
		return (
			<Overview {...rest} image={image} sx={sx} alt={title}>
				<Stack
					direction="row"
					sx={{ flexGrow: { md: 1 }, alignItems: "center" }}
				>
					{icon}
					<Stack sx={{ flexGrow: 1 }}>
						<Stack direction="row" sx={{ justifyContent: "space-between" }}>
							<BodyTextM>Mám naspořeno</BodyTextM>
							<>
								<Skeleton width={70} sx={{ display: "inline-block" }} /> Kč
							</>
						</Stack>
					</Stack>
				</Stack>
			</Overview>
		);

	const isChangesNotAllowed =
		allowedChangesData !== undefined &&
		!allowedChangesData.canChangeInvestmentStrategy;
	const isDataLoaded = starpensionData !== undefined && !starpensionDataLoading;
	const savedAmount =
		starpensionData !== undefined ? starpensionData.savedAmount : 0;

	switch (contractData?.pensionPhaseStatus) {
		case PensionPhaseStatus.Saving: {
			return (
				<Overview {...rest} image={image} sx={sx} alt={title}>
					<Stack
						direction="row"
						sx={{ flexGrow: { md: 1 }, alignItems: "center" }}
					>
						{icon}
						<Stack sx={{ flexGrow: 1 }}>
							<Stack direction="row" sx={{ justifyContent: "space-between" }}>
								<BodyTextM>Mám naspořeno</BodyTextM>
								{renderSavedAmount(
									isChangesNotAllowed,
									isDataLoaded,
									savedAmount
								)}
							</Stack>
						</Stack>
					</Stack>
				</Overview>
			);
		}
		case PensionPhaseStatus.Payout: {
			return (
				<Overview {...rest} image={image} sx={sx} alt={title}>
					<Stack
						sx={{
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
						}}
					>
						<BodyTextM sx={{ textAlign: "center" }}>
							{cs.global.terminatedContract}
						</BodyTextM>
					</Stack>
				</Overview>
			);
		}
		default:
			throw new Error(
				"Invalid pension phase status: " + contractData?.pensionPhaseStatus
			);
	}
}
export default ProductOverview;
