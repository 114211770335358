/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Contributions
 */
export interface Contributions {
    /**
     * 
     * @type {number}
     * @memberof Contributions
     */
    owner: number;
    /**
     * 
     * @type {number}
     * @memberof Contributions
     */
    employer: number;
    /**
     * 
     * @type {number}
     * @memberof Contributions
     */
    others: number;
    /**
     * 
     * @type {number}
     * @memberof Contributions
     */
    state: number;
    /**
     * 
     * @type {number}
     * @memberof Contributions
     */
    total: number;
}

/**
 * Check if a given object implements the Contributions interface.
 */
export function instanceOfContributions(value: object): value is Contributions {
    if (!('owner' in value) || value['owner'] === undefined) return false;
    if (!('employer' in value) || value['employer'] === undefined) return false;
    if (!('others' in value) || value['others'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    return true;
}

export function ContributionsFromJSON(json: any): Contributions {
    return ContributionsFromJSONTyped(json, false);
}

export function ContributionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contributions {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'],
        'employer': json['employer'],
        'others': json['others'],
        'state': json['state'],
        'total': json['total'],
    };
}

export function ContributionsToJSON(value?: Contributions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'employer': value['employer'],
        'others': value['others'],
        'state': value['state'],
        'total': value['total'],
    };
}

