import {
	QueryKey,
	useMutation,
	useQuery,
	useQueryClient,
	UseQueryOptions,
} from "@tanstack/react-query";

import { ChangeDPSFundsPostRequest } from "../../apis";
import {
	GetDPSAllowedChangesResponse,
	GetDPSDetailResponse,
	GetZZJPreviewRequest,
	ProductType,
	SendAuthenticationSMSRequest,
	SetDPSTaxOptimumRequest,
} from "../../models";
import { usePrimaryAPIClient } from "../context/PrimaryAPIClient";
import { useQueryKeyFactory } from "../context/QueryKeyProvider";
import { ContractType } from "../types/contracts";

export const useQuestions = () => {
	const { investorProfileApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery(
		createQueryKey({ key: ["getQuestions"], includeUserIdentifier: false }),
		() => investorProfileApi.getInvestorProfileQuestionsGet()
	);
};

interface ZZJProps {
	key: QueryKey;
	contractId: string;
	getZZJPreviewRequest: GetZZJPreviewRequest;
}

export const useZZJ = ({ key, contractId, getZZJPreviewRequest }: ZZJProps) => {
	const { contractsDpsApi } = usePrimaryAPIClient();

	return useQuery(key, () =>
		contractsDpsApi.previewChangeDPSFundsZZJPost({
			contractId,
			getZZJPreviewRequest,
		})
	);
};

export const useSMS = ({ phoneNumber }: SendAuthenticationSMSRequest) => {
	const { authenticateApi } = usePrimaryAPIClient();

	const { data, isInitialLoading, refetch } = useQuery(
		["SendAuthenticationSMS.Post", phoneNumber],
		() =>
			authenticateApi.sendAuthenticationSMSPost({
				sendAuthenticationSMSRequest: { phoneNumber },
			}),
		{ enabled: false } // don't trigger on component mount
	);
	return { data, isInitialLoading, refetch };
};

export const useStrategy = ({
	contractId,
	changeFundsRequest,
}: ChangeDPSFundsPostRequest) => {
	const { contractsDpsApi } = usePrimaryAPIClient();

	return useMutation({
		mutationFn: () => {
			return contractsDpsApi.changeDPSFundsPost({
				contractId,
				changeFundsRequest,
			});
		},
	});
};

interface IUseAllowedChanges {
	contractId: string;
	options?: UseQueryOptions<
		GetDPSAllowedChangesResponse,
		unknown,
		GetDPSAllowedChangesResponse
	>;
}

/**
 * @deprecated The method should not be used, use useAllowedChangesForContract or useAllowedChanges
 */
export const useDpsAllowedChanges = ({
	contractId,
	options,
}: IUseAllowedChanges) => {
	const { contractsDpsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();
	return useQuery<
		GetDPSAllowedChangesResponse,
		unknown,
		GetDPSAllowedChangesResponse
	>(
		createQueryKey({
			key: ["getDPSAllowedChanges", contractId],
			includeUserIdentifier: false,
		}),
		() =>
			contractsDpsApi.getDPSAllowedChangesGet({
				contractId,
			}),
		options
	);
};

interface IUsePutTaxOptimum {
	contractId: string;
	contractType: ContractType;
}

export const usePutTaxOptimum = ({
	contractId,
	contractType,
}: IUsePutTaxOptimum) => {
	const { createQueryKey } = useQueryKeyFactory();
	const { contractsDpsApi, contractsPpApi } = usePrimaryAPIClient();
	const client = useQueryClient();
	const contractDetailQueryKey = createQueryKey({
		key: ["getContractDetail", contractId],
	});

	const mutationFn = async (taxOptimumRequest: SetDPSTaxOptimumRequest) => {
		if (contractType === ProductType.Uf) {
			return await contractsDpsApi.setDPSTaxOptimumPut({
				contractId,
				setDPSTaxOptimumRequest: taxOptimumRequest,
			});
		}

		return await contractsPpApi.setPPTaxOptimumPut({
			contractId,
			setPPTaxOptimumRequest: taxOptimumRequest,
		});
	};

	return useMutation({
		mutationFn,
		onSuccess: (_, variables) => {
			client.setQueryData<GetDPSDetailResponse>(
				contractDetailQueryKey,
				(data) => {
					if (data === undefined) {
						return;
					}
					return {
						...data,
						contract: { ...data.contract, isTaxOptimum: variables.isActive },
					};
				}
			);
		},
	});
};
