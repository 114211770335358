/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  GetFundsPerformanceResponse,
  GetLifeDetailResponse,
  GetLifeDetailV2Response,
  GetLifeTransactionsResponse,
  LifeTransactionEnum,
  SetBeneficiariesRequest,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetFundsPerformanceResponseFromJSON,
    GetFundsPerformanceResponseToJSON,
    GetLifeDetailResponseFromJSON,
    GetLifeDetailResponseToJSON,
    GetLifeDetailV2ResponseFromJSON,
    GetLifeDetailV2ResponseToJSON,
    GetLifeTransactionsResponseFromJSON,
    GetLifeTransactionsResponseToJSON,
    LifeTransactionEnumFromJSON,
    LifeTransactionEnumToJSON,
    SetBeneficiariesRequestFromJSON,
    SetBeneficiariesRequestToJSON,
} from '../models/index';

export interface GetLifeDetailGetRequest {
    contractId: string;
}

export interface GetLifeDetailV2NdpGetRequest {
    contractId: string;
}

export interface GetLifeFundsGetRequest {
    contractId: string;
}

export interface GetLifeTransactionsGetRequest {
    contractId: string;
    count?: number;
    from?: Date;
    to?: Date;
    typeFilter?: Array<LifeTransactionEnum>;
}

export interface SetLifeBeneficiariesPutRequest {
    contractId: string;
    setBeneficiariesRequest: SetBeneficiariesRequest;
}

/**
 * 
 */
export class ContractsLifeApi extends runtime.BaseAPI {

    /**
     * Get Life contract detail for the current user
     */
    async getLifeDetailGetRaw(requestParameters: GetLifeDetailGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLifeDetailResponse>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getLifeDetailGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/life/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLifeDetailResponseFromJSON(jsonValue));
    }

    /**
     * Get Life contract detail for the current user
     */
    async getLifeDetailGet(requestParameters: GetLifeDetailGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLifeDetailResponse> {
        const response = await this.getLifeDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Life contract detail for the current user v2 using NDP data
     */
    async getLifeDetailV2NdpGetRaw(requestParameters: GetLifeDetailV2NdpGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLifeDetailV2Response>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getLifeDetailV2NdpGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/v2/life/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLifeDetailV2ResponseFromJSON(jsonValue));
    }

    /**
     * Get Life contract detail for the current user v2 using NDP data
     */
    async getLifeDetailV2NdpGet(requestParameters: GetLifeDetailV2NdpGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLifeDetailV2Response> {
        const response = await this.getLifeDetailV2NdpGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get historical values of fund units for a life contract
     */
    async getLifeFundsGetRaw(requestParameters: GetLifeFundsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetFundsPerformanceResponse>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getLifeFundsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/life/{contractId}/funds`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetFundsPerformanceResponseFromJSON));
    }

    /**
     * Get historical values of fund units for a life contract
     */
    async getLifeFundsGet(requestParameters: GetLifeFundsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetFundsPerformanceResponse>> {
        const response = await this.getLifeFundsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Life transaction history.
     */
    async getLifeTransactionsGetRaw(requestParameters: GetLifeTransactionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetLifeTransactionsResponse>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getLifeTransactionsGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['count'] != null) {
            queryParameters['Count'] = requestParameters['count'];
        }

        if (requestParameters['from'] != null) {
            queryParameters['From'] = (requestParameters['from'] as any).toISOString();
        }

        if (requestParameters['to'] != null) {
            queryParameters['To'] = (requestParameters['to'] as any).toISOString();
        }

        if (requestParameters['typeFilter'] != null) {
            queryParameters['TypeFilter'] = requestParameters['typeFilter']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/life/{contractId}/transactions`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetLifeTransactionsResponseFromJSON));
    }

    /**
     * Get Life transaction history.
     */
    async getLifeTransactionsGet(requestParameters: GetLifeTransactionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetLifeTransactionsResponse>> {
        const response = await this.getLifeTransactionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set beneficiaries for life
     */
    async setLifeBeneficiariesPutRaw(requestParameters: SetLifeBeneficiariesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setLifeBeneficiariesPut().'
            );
        }

        if (requestParameters['setBeneficiariesRequest'] == null) {
            throw new runtime.RequiredError(
                'setBeneficiariesRequest',
                'Required parameter "setBeneficiariesRequest" was null or undefined when calling setLifeBeneficiariesPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/life/{contractId}/beneficiaries`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetBeneficiariesRequestToJSON(requestParameters['setBeneficiariesRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set beneficiaries for life
     */
    async setLifeBeneficiariesPut(requestParameters: SetLifeBeneficiariesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setLifeBeneficiariesPutRaw(requestParameters, initOverrides);
    }

}
