import { blue, green, grey, orange, purple } from "./designColors";

const palette = {
	primary: {
		ultraLight: orange["100"],
		light: orange["300"],
		main: orange["500"],
		dark: orange["700"],
		contrastText: "#ffffff",
	},
	secondary: {
		ultraLight: purple["000"],
		light: purple["100"],
		main: purple["300"],
		dark: purple["500"],
		ultraDark: purple["700"],
		contrastText: "#ffffff",
	},
	grey: {
		ultraLight: grey["000"],
		light: grey["100"],
		main: grey["200"],
		softDark: grey["400"],
		dark: grey["600"],
		ultraDark: grey["800"],
		contrastText: "#ffffff",
	},
	info: {
		light: blue["000"],
		main: blue["500"],
		contrastText: "#ffffff",
	},
	success: {
		light: green["000"],
		main: green["500"],
		contrastText: "#ffffff",
	},
	error: {
		light: orange["000"],
		main: orange["700"],
	},
	warning: {
		light: orange["000"],
		main: orange["300"],
	},
	white: {
		main: "#FFFFFF",
	},
	black: {
		main: "#000000",
	},
	// https://www.figma.com/file/05LuetjX7IjYLLOOGNIUYs/NN-DS---Style?node-id=4757%3A2945
	dataVisualisation: {
		quantitative: {
			1: {
				main: orange["500"],
				contrastText: "#ffffff",
			},
			2: {
				main: blue["500"],
				contrastText: "#ffffff",
			},
			3: {
				main: green["500"],
				contrastText: "#ffffff",
			},
			4: {
				main: purple["500"],
				contrastText: "#ffffff",
			},
			5: {
				main: orange["300"],
				contrastText: grey["800"],
			},
			6: {
				main: blue["300"],
				contrastText: grey["800"],
			},
			7: {
				main: green["300"],
				contrastText: grey["800"],
			},
			8: {
				main: purple["300"],
				contrastText: grey["800"],
			},
			9: {
				main: orange["100"],
				contrastText: grey["800"],
			},
			10: {
				main: blue["100"],
				contrastText: grey["800"],
			},
			11: {
				main: green["100"],
				contrastText: grey["800"],
			},
			12: {
				main: purple["100"],
				contrastText: grey["800"],
			},
		},
		sequence: {},
	},
	conservativeChart: {
		main: blue["700"],
		label: blue["700"],
	},
	myContributionsChart: {
		main: orange["500"],
		label: orange["500"],
	},
	balancedChart: {
		main: green["100"],
		label: green["100"],
	},
	countryEvaluationsChart: {
		main: orange["100"],
		label: orange["100"],
	},
	growthChart: {
		main: orange["500"],
		label: orange["500"],
	},
	othersChart: {
		main: grey["100"],
		label: grey["100"],
	},
	countryContributionsChart: {
		main: green["500"],
		label: green["500"],
	},
	myAndEmployerEvaluationsChart: {
		main: purple["500"],
		label: purple["500"],
	},
	employerContributionsChart: {
		main: blue["700"],
		label: blue["700"],
	},
	text: {
		main: grey["800"],
		primary: grey["800"],
		secondary: grey["800"],
	},
};

export default palette;
