import SvgIcon from "@mui/material/SvgIcon";

const Inflation = (props): JSX.Element => {
	return (
		<SvgIcon width="29" height="29" viewBox="0 0 29 29" fill="none" {...props}>
			<path d="m28.27 22.68-5.65-3.99a.95.95 0 0 0-1-.07.92.92 0 0 0-.5.82v1.07H9.88a.67.67 0 0 0-.67.66.66.66 0 0 0 .59.65l.08.02h11.63c.52 0 .95-.42.95-.92v-.71l4.56 3.21-4.56 3.22v-.7a.94.94 0 0 0-.95-.93H9.93a.67.67 0 1 0 0 1.33h11.2v1.07c0 .34.19.66.5.82a.95.95 0 0 0 .99-.07l5.66-3.99a.92.92 0 0 0 .39-.75.92.92 0 0 0-.4-.75Zm-3.3-15.56a15.14 15.14 0 0 1-8.5 7.53c-1.7.62-3.5.95-5.34.99H9.8a.67.67 0 1 0 0 1.33H11.27l.05-.01a17.8 17.8 0 0 0 5.6-1.05 16.52 16.52 0 0 0 9.1-7.9l1.2.4c.36.12.73.03 1-.23a.9.9 0 0 0 .21-.93L26.33.97a.93.93 0 0 0-.57-.58.96.96 0 0 0-.83.08l-5.97 3.56a.91.91 0 0 0-.45.88.92.92 0 0 0 .63.78l1 .33a10.19 10.19 0 0 1-4.04 3.82 11.24 11.24 0 0 1-5 1.3H9.7a.67.67 0 1 0 0 1.33h1.52l.06-.02c1.91-.06 3.8-.56 5.45-1.44a11.46 11.46 0 0 0 4.83-4.8.9.9 0 0 0 .03-.76.94.94 0 0 0-.57-.53l-.6-.2 4.8-2.87L26.9 6.9l-.78-.26a.95.95 0 0 0-1.16.48ZM7.29 25.02H5.31a.67.67 0 0 0-.67.66c0 .36.3.67.67.67h1.98c.36 0 .67-.3.67-.67a.67.67 0 0 0-.67-.67ZM2.67 25H1.33a.67.67 0 1 0 0 1.33h1.34a.67.67 0 1 0 0-1.33Zm0-4.5H1.33a.67.67 0 1 0 0 1.33h1.34a.67.67 0 1 0 0-1.33Zm2.62 1.33h1.96a.67.67 0 0 0 .67-.67.66.66 0 0 0-.67-.66H5.3a.66.66 0 0 0-.66.66c0 .36.29.67.66.67Zm2-6.2H5.31a.67.67 0 0 0-.67.67c0 .36.3.66.67.66h1.98c.36 0 .67-.3.67-.66a.67.67 0 0 0-.67-.67Zm-4.62 0H1.33a.67.67 0 1 0 0 1.33h1.34a.67.67 0 1 0 0-1.33Zm0-4.5H1.33a.67.67 0 1 0 0 1.33h1.34a.67.67 0 1 0 0-1.34Zm2.62 1.33h1.96a.66.66 0 0 0 .67-.67.67.67 0 0 0-.67-.67H5.3a.67.67 0 0 0-.66.67.66.66 0 0 0 .66.67Z" />
		</SvgIcon>
	);
};

export default Inflation;
