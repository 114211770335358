import { Alert, AlertProps } from "@mui/material";
import Box from "@mui/material/Box";

import { BodyTextM } from "../Typography";

const Attention = ({
	title,
	action,
	children,
	...rest
}: AlertProps): JSX.Element => {
	return (
		<Alert {...rest}>
			{title && (
				<BodyTextM strong sx={{ display: "inline-block", mb: 2 }}>
					{title}
				</BodyTextM>
			)}
			<Box>
				<BodyTextM>{children}</BodyTextM>
			</Box>
			{action && <Box sx={{ mt: 2 }}>{action}</Box>}
		</Alert>
	);
};

export default Attention;
