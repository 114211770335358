import { Stack } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React from "react";

import { AllowedChangesItem } from "../../../../models";
import Attention from "../../AttentionBlock";

export const SmartActionWarnings: React.FC<{
	allowedChanges: AllowedChangesItem | undefined;
}> = ({ allowedChanges }) => {
	if (isNil(allowedChanges)) return null;

	const allowedChangesWarnings = [
		allowedChanges?.taxOptimumDescription,
		allowedChanges?.contributionDescription,
		allowedChanges?.contactAddressDescription,
		allowedChanges?.amlIdentificationDescription,
		allowedChanges?.electronicContactsDescription,
		allowedChanges?.isEmployerContributingDescription,
		allowedChanges?.beneficiariesDescription,
	];

	return (
		<Stack direction={"column"}>
			{allowedChangesWarnings
				.filter((v) => !isEmpty(v))
				.map((warning) => (
					<Attention key={warning} severity={"info"} style={{ marginTop: 24 }}>
						{warning}
					</Attention>
				))}
		</Stack>
	);
};
