import Stack from "@mui/material/Stack";
import { ReactNode, useState } from "react";

import SimpleDrawer from "../../Drawer/SimpleDrawer";
import TriggerButton, {
	TriggerButtonWithAttention,
} from "../../Link/TriggerButton";

interface ICtaPay {
	children: ReactNode;
	message: ReactNode;
}

const CtaPay = ({ children, message }: ICtaPay): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<TriggerButtonWithAttention
				sx={{ width: "100%" }}
				severity="error"
				onClick={() => setIsOpen(true)}
			>
				{children}
			</TriggerButtonWithAttention>
			<SimpleDrawer onClose={() => setIsOpen(false)} open={isOpen}>
				<Stack spacing={5}>
					{message}
					<TriggerButton
						onClick={() => setIsOpen(false)}
						sx={{ alignSelf: "flex-end" }}
					>
						Rozumím
					</TriggerButton>
				</Stack>
			</SimpleDrawer>
		</>
	);
};

export default CtaPay;
