import { Stack } from "@mui/material";
import { useFormikContext } from "formik";

import { ProductType } from "../../../models";
import { useContractTypeContext } from "../../context/ContractTypeContext";
import cs from "../../translations/cs.json";
import SingleStepDrawer from "../Drawer/SingleStepDrawer";
import Form from "../Form/Form";
import FormErrorMessage from "../Form/FormErrorMessage";
import DatePicker from "../Form/Input/DatePicker";
import Switch from "../Form/Input/Switch";
import TriggerButton from "../Link/TriggerButton";

function ResetFilter({ handleHideDrawer }: Props) {
	const { isSubmitting } = useFormikContext();

	return (
		<TriggerButton
			variant="text"
			type="reset"
			disabled={isSubmitting}
			onClick={handleHideDrawer}
		>
			Zrušit&nbsp;filtr
		</TriggerButton>
	);
}

interface Props {
	handleHideDrawer: () => void;
}

const FilterTransactionsDrawer = ({ handleHideDrawer }: Props): JSX.Element => {
	const { contractType } = useContractTypeContext();
	const { errors } = useFormikContext();
	return (
		<SingleStepDrawer>
			<Form
				spacing={6}
				actions={<ResetFilter handleHideDrawer={handleHideDrawer} />}
			>
				<Stack spacing={4}>
					<Switch name="employer" label="Příspěvky zaměstnavatele" />
					<Switch name="client" label="Příspěvky klienta" />
					<Switch name="state" label="Státní příspěvky" />
					{contractType === ProductType.Pf && (
						<Switch name="other" label="Ostatní" />
					)}
					{errors.isAnySelected && (
						<FormErrorMessage>{cs.formErrors.isAnySelected}</FormErrorMessage>
					)}
				</Stack>
				<DatePicker
					name="dateFrom"
					placeholder="Datum od"
					options={{ maxDate: new Date(), minDate: new Date(1990, 1, 1) }}
				/>
				<DatePicker
					name="dateTo"
					placeholder="Datum do"
					options={{ maxDate: new Date(), minDate: new Date(1990, 1, 1) }}
				/>
			</Form>
		</SingleStepDrawer>
	);
};

export default FilterTransactionsDrawer;
