/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EncryptUserIdResponse,
  ErrorResponse,
  SendAuthenticationSMSRequest,
  SendAuthenticationSMSResponse,
} from '../models/index';
import {
    EncryptUserIdResponseFromJSON,
    EncryptUserIdResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SendAuthenticationSMSRequestFromJSON,
    SendAuthenticationSMSRequestToJSON,
    SendAuthenticationSMSResponseFromJSON,
    SendAuthenticationSMSResponseToJSON,
} from '../models/index';

export interface SendAuthenticationSMSPostRequest {
    sendAuthenticationSMSRequest: SendAuthenticationSMSRequest;
}

/**
 * 
 */
export class AuthenticateApi extends runtime.BaseAPI {

    /**
     * Get encrypted user id
     */
    async encryptUserIdGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EncryptUserIdResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/authenticate/encrypt-user-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EncryptUserIdResponseFromJSON(jsonValue));
    }

    /**
     * Get encrypted user id
     */
    async encryptUserIdGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EncryptUserIdResponse> {
        const response = await this.encryptUserIdGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all phone numbers that can be used for authentication
     */
    async getAuthenticationPhoneNumbersGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/authenticate/phone-numbers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all phone numbers that can be used for authentication
     */
    async getAuthenticationPhoneNumbersGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAuthenticationPhoneNumbersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sends sms to picked phone number type
     */
    async sendAuthenticationSMSPostRaw(requestParameters: SendAuthenticationSMSPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendAuthenticationSMSResponse>> {
        if (requestParameters['sendAuthenticationSMSRequest'] == null) {
            throw new runtime.RequiredError(
                'sendAuthenticationSMSRequest',
                'Required parameter "sendAuthenticationSMSRequest" was null or undefined when calling sendAuthenticationSMSPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/authenticate/send-sms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendAuthenticationSMSRequestToJSON(requestParameters['sendAuthenticationSMSRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendAuthenticationSMSResponseFromJSON(jsonValue));
    }

    /**
     * Sends sms to picked phone number type
     */
    async sendAuthenticationSMSPost(requestParameters: SendAuthenticationSMSPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendAuthenticationSMSResponse> {
        const response = await this.sendAuthenticationSMSPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
