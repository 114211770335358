import SvgIcon from "@mui/material/SvgIcon";

const Close = (props): JSX.Element => {
	return (
		<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path d="m11.06 10 8.47-8.47A.75.75 0 1 0 18.47.47L10 8.94 1.53.47a.748.748 0 0 0-1.06 0 .75.75 0 0 0 0 1.06L8.94 10 .47 18.47a.75.75 0 1 0 1.06 1.06L10 11.06l8.47 8.47a.746.746 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L11.06 10Z" />
		</SvgIcon>
	);
};

export default Close;
