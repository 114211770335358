import { createContext, ReactNode, useContext, useState } from "react";

export type SelectedPersonContext = {
	selectedPersonIndex: number;
	setSelectedPerson: (index: number) => void;
};

const SelectedPersonContext = createContext<SelectedPersonContext>({
	selectedPersonIndex: 0,
} as SelectedPersonContext);

interface ISelectedPersonContextProvider {
	children: ReactNode;
}

export const useSelectedPerson = () => useContext(SelectedPersonContext);

export const SelectedPersonContextProvider = ({
	children,
}: ISelectedPersonContextProvider) => {
	const [index, setIndex] = useState(0);
	return (
		<SelectedPersonContext.Provider
			value={{ selectedPersonIndex: index, setSelectedPerson: setIndex }}
		>
			{children}
		</SelectedPersonContext.Provider>
	);
};
