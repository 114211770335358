import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import cs from "../../translations/cs.json";
import {
	DistributionProps,
	fundsDistributionDefault,
} from "../DPS/ChangeSavingsStrategy";
import LabelledProgress from "../LinearProgress/LabelledProgress";

const FundsDistribution = ({
	isFixed,
	allocation = fundsDistributionDefault,
}: DistributionProps): JSX.Element => {
	return (
		<Paper
			sx={{
				p: { md: 5, xxs: 4 },
			}}
		>
			<Stack spacing={3}>
				{allocation.map((fund, i) =>
					fund.fundCode !== undefined ? (
						<LabelledProgress
							key={fund.fundCode}
							title={cs.investmentStrategy.fundsNames[fund.fundCode]}
							formattedValue={
								isFixed && Number.isInteger(fund.allocation) === false
									? `${fund.allocation.toFixed(2)} %`
									: `${fund.allocation} %`
							}
							value={fund.allocation}
						/>
					) : (
						<Stack spacing={3} key={i}>
							<Stack spacing={3} direction="row" justifyContent="space-between">
								<Skeleton variant="rectangular" width={200} />
								<Skeleton variant="rectangular" width={30} />
							</Stack>
							<Skeleton variant="rectangular" />
						</Stack>
					)
				)}
			</Stack>
		</Paper>
	);
};

export default FundsDistribution;
