/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';

/**
 * 
 * @export
 * @interface BeneficiaryParty
 */
export interface BeneficiaryParty {
    /**
     * 
     * @type {Participant}
     * @memberof BeneficiaryParty
     */
    beneficiary: Participant;
    /**
     * 
     * @type {string}
     * @memberof BeneficiaryParty
     */
    relationship?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeneficiaryParty
     */
    beneficiaryShare: number;
    /**
     * 
     * @type {boolean}
     * @memberof BeneficiaryParty
     */
    isBank: boolean;
}

/**
 * Check if a given object implements the BeneficiaryParty interface.
 */
export function instanceOfBeneficiaryParty(value: object): value is BeneficiaryParty {
    if (!('beneficiary' in value) || value['beneficiary'] === undefined) return false;
    if (!('beneficiaryShare' in value) || value['beneficiaryShare'] === undefined) return false;
    if (!('isBank' in value) || value['isBank'] === undefined) return false;
    return true;
}

export function BeneficiaryPartyFromJSON(json: any): BeneficiaryParty {
    return BeneficiaryPartyFromJSONTyped(json, false);
}

export function BeneficiaryPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BeneficiaryParty {
    if (json == null) {
        return json;
    }
    return {
        
        'beneficiary': ParticipantFromJSON(json['beneficiary']),
        'relationship': json['relationship'] == null ? undefined : json['relationship'],
        'beneficiaryShare': json['beneficiaryShare'],
        'isBank': json['isBank'],
    };
}

export function BeneficiaryPartyToJSON(value?: BeneficiaryParty | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'beneficiary': ParticipantToJSON(value['beneficiary']),
        'relationship': value['relationship'],
        'beneficiaryShare': value['beneficiaryShare'],
        'isBank': value['isBank'],
    };
}

