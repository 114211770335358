import { DialogProps } from "@mui/material";
import { Formik } from "formik";

import { ProductType, SetAmlIdentificationRequest } from "../../../../models";
import { usePrimaryAPIClient } from "../../../context/PrimaryAPIClient";
import cs from "../../../translations/cs.json";
import { useDrawer } from "../../Drawer/context";
import FormMultiStepDrawer from "../../Drawer/FormMultiStepDrawer/index";
import {
	mapFetchApiErrorToSubmitStatus,
	SubmitStatus,
	SubmitStatusStep,
} from "../../Drawer/GlobalSteps/SubmitStatusStep";
import Modal from "../../Drawer/Modal";
import { TriggerButton } from "../../Link";
import { AMLIncomeSource } from "../index";
import { AMLQuestions } from "./questions";
import { AMLQuestionsSchema } from "./schema";

interface IAMLDrawer {
	contractId: string;
	contractType: string;
	onCloseCallback?: () => void;
}

export type IAMLFormValues = {
	employmentType:
		| AMLIncomeSource.Employment
		| AMLIncomeSource.Business
		| AMLIncomeSource.Other
		| undefined;
	businessIncomeId: string | undefined;
	otherIncomeId: string | undefined;
	averageIncome: string | undefined;
	incomeSourceSpecification: string | undefined;
	propertySourceSpecification: string | undefined;
	usaResident: boolean;
	greenCardHolder: boolean;
	politicallyExposed: boolean;
	taxResidence: string;
	taxNumber: string | undefined;
	propertySources: Record<string, boolean>;
};

export const AMLFormDefaultValues: IAMLFormValues = {
	employmentType: undefined,
	businessIncomeId: undefined,
	otherIncomeId: undefined,
	incomeSourceSpecification: undefined,
	propertySourceSpecification: undefined,
	averageIncome: undefined,
	greenCardHolder: false,
	politicallyExposed: false,
	usaResident: false,
	taxResidence: "CZE",
	taxNumber: undefined,
	propertySources: {},
};

export const mapAMLRequest = (
	values: IAMLFormValues,
	token?: string
): SetAmlIdentificationRequest => {
	const {
		employmentType,
		businessIncomeId,
		otherIncomeId,
		incomeSourceSpecification,
		politicallyExposed: pep,
		propertySources,
		propertySourceSpecification,
		taxNumber: taxNumberFieldValue,
		taxResidence: domicil,
		greenCardHolder: greenCard,
		averageIncome: income,
		usaResident: usTaxResident,
	} = values;
	const sourceOfIncome = (() => {
		if (
			employmentType === AMLIncomeSource.Business ||
			employmentType === AMLIncomeSource.Employment
		)
			return employmentType;
		return otherIncomeId;
	})();
	const companySourceOfIncome = (() => {
		if (employmentType === AMLIncomeSource.Business) {
			return businessIncomeId;
		}
		if (employmentType === AMLIncomeSource.Other && otherIncomeId === "Jiné") {
			return incomeSourceSpecification;
		}
	})();

	const sourceOfProperty = (() => {
		const sources: string[] = [];

		for (const source in propertySources) {
			if (propertySources[source] && source !== "Jiné") {
				sources.push(source);
			}
		}

		const sortedSources = sources.sort();

		if (propertySources["Jiné"] && propertySourceSpecification)
			sortedSources.push(`Jiné:${propertySourceSpecification}`);

		return sources.join("|");
	})();

	const taxNumber = domicil !== "CZE" ? taxNumberFieldValue : undefined;
	return {
		sourceOfIncome,
		companySourceOfIncome,
		sourceOfProperty,
		domicil,
		greenCard,
		pep,
		income,
		taxNumber,
		token,
		usTaxResident,
	};
};

export const AMLForm = ({
	contractId,
	contractType,
	onCloseCallback,
}: IAMLDrawer) => {
	const { nextStep } = useDrawer();
	const { contractsDpsApi, contractsPpApi } = usePrimaryAPIClient();
	return (
		<Formik
			validationSchema={AMLQuestionsSchema}
			initialValues={AMLFormDefaultValues}
			onSubmit={async (values, { setStatus, setSubmitting }) => {
				try {
					setSubmitting(true);
					setStatus(SubmitStatus.Submitting);
					if (contractType === ProductType.Uf) {
						await contractsDpsApi.setDPSAmlIdentificationPut({
							contractId,
							setAmlIdentificationRequest: mapAMLRequest(values),
						});
					} else {
						await contractsPpApi.setPPAmlIdentificationPut({
							contractId,
							setAmlIdentificationRequest: mapAMLRequest(values),
						});
					}
					setStatus(SubmitStatus.Success);
					setSubmitting(false);
					nextStep();
				} catch (error) {
					setStatus(mapFetchApiErrorToSubmitStatus(error));
				}
				onCloseCallback && onCloseCallback();
			}}
		>
			{({ submitForm, status }) => {
				return (
					<FormMultiStepDrawer
						title={cs.AML.amlQuestionaire.title}
						onCloseCallback={onCloseCallback}
					>
						{[
							{
								component: (
									<AMLQuestions
										key="aml-questions"
										helpText={cs.AML.amlReasoning.insurancePayout}
									/>
								),
								stepName: "aml-questions",
								stepConfig: {
									customNextButton: (
										<TriggerButton onClick={submitForm}>Uložit</TriggerButton>
									),
								},
							},
							{
								component: (
									<SubmitStatusStep
										status={status}
										successProps={{
											description: cs.AML.amlQuestionaire.success,
										}}
									/>
								),
								stepName: "after-submit",
							},
						]}
					</FormMultiStepDrawer>
				);
			}}
		</Formik>
	);
};

interface IAMLFormStandaloneDrawer {
	amlProps: IAMLDrawer;
	dialogProps?: DialogProps;
	open: boolean;
}

export const AMLFormStandaloneDrawer = ({
	amlProps,
	dialogProps,
	open,
}: IAMLFormStandaloneDrawer) => {
	const { onCloseCallback, ...amlRest } = amlProps;
	return (
		<Modal open={open} onClose={onCloseCallback} {...dialogProps}>
			<AMLForm
				onCloseCallback={() => {
					onCloseCallback && onCloseCallback();
				}}
				{...amlRest}
			/>
		</Modal>
	);
};
