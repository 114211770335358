// heavily inspired by https://hackernoon.com/improving-formik-performance-when-its-slow-material-ui

import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { ErrorMessage, useField, useFormikContext } from "formik";
import { ChangeEvent, FC, FocusEvent, memo, useEffect, useState } from "react";

import { BodyTextM } from "../../../Typography";
import FormErrorMessage from "../../FormErrorMessage";
import { usePropagateRef } from "./usePropagateRef";
export type PerformantTextFieldProps = Omit<TextFieldProps, "name"> & {
	name: string;
	disabled?: boolean;
	label: string;
	isRequired?: boolean;
	sublabel?: string;
	/**
	 * IF true, it will use the traditional method for disabling performance
	 */
	disablePerformance?: boolean;
};
/**
 * This is kind of hacky solution, but it mostly works. Your mileage may vary
 */
// eslint-disable-next-line
const TextInput: FC<PerformantTextFieldProps> = memo(
	({
		name,
		disabled = false,
		label,
		sublabel,
		isRequired = false,
		disablePerformance,
		...rest
	}) => {
		const [field, meta] = useField(name);
		const { isSubmitting } = useFormikContext();
		const error = !!meta.error && meta.touched;
		/**
		 * For performance reasons (possible due to CSS in JS issues), heavy views
		 * affect re-renders (Formik changes state in every re-render), bringing keyboard
		 * input to its knees. To control this, we create a setState that handles the field's inner
		 * (otherwise you wouldn't be able to type) and then propagate the change to Formik onBlur and
		 * onFocus.
		 */
		const [fieldValue, setFieldValue] = useState<string | number>(field.value);
		usePropagateRef({
			setFieldValue,
			name,
			value: field.value,
		});
		/**
		 * Using this useEffect guarantees us that pre-filled forms
		 * such as passwords work.
		 */
		useEffect(() => {
			if (meta.touched) {
				return;
			}
			if (field.value !== fieldValue) {
				setFieldValue(field.value);
			}
		}, [field.value]);
		const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
			setFieldValue(evt.target.value);
		};
		const onBlur = (evt: FocusEvent<HTMLInputElement>) => {
			const val = evt.target.value || "";
			window.setTimeout(() => {
				field.onChange({
					target: {
						name,
						value: val,
					},
				});
			}, 0);
		};
		// Will set depending on the performance props
		const performanceProps = disablePerformance
			? {
					...field,
					value: fieldValue,
			  }
			: {
					...field,
					value: fieldValue,
					onChange,
					onBlur,
					onFocus: onBlur,
			  };
		return (
			<Stack>
				{label && (
					<InputLabel shrink htmlFor={`${name}-name`} required={isRequired}>
						{label}
					</InputLabel>
				)}
				{sublabel && (
					<BodyTextM required={isRequired} sx={{ color: "grey.dark" }}>
						{sublabel}
					</BodyTextM>
				)}
				<TextField
					className={`input ${error ? "invalid" : ""}`}
					type="text"
					required={isRequired}
					id={`${name}-name`}
					disabled={isSubmitting || disabled}
					variant="outlined"
					{...rest}
					{...performanceProps}
				/>
				<ErrorMessage
					name={name}
					render={(msg) => (
						<FormErrorMessage name={name}>{msg}</FormErrorMessage>
					)}
				/>
			</Stack>
		);
	}
);

export default TextInput;
