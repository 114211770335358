import InputAdornment from "@mui/material/InputAdornment";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers";
import { useField } from "formik";
import { useState } from "react";

import Calendar from "../../../Icons/Calendar";
import CaretLeft from "../../../Icons/CaretLeft";
import CaretRight from "../../../Icons/CaretRight";
import Contracts from "../../../Icons/Contracts";
import TextInput, { TextInputProps } from "../Text/nonPerformant";

export interface DatePickerProps extends TextInputProps {
	name: string;
	label?: string;
	placeholder: string;
	hideError?: boolean;
}

const makeTimezoneIndependantTime = (date: Date) => {
	// MUI datepicker sets the date as a datetime with time set to 00:00 in user's timezone
	// problem is that API functions generated by openapi generator use .toISOString() function to convert the date into string, this function converts time into UTC+0 time format no matter what which is a problem because for example 2.1.2020 00:00 in GMT+1 is equal to 1.1.2020 23:00 in UTC+0 and when you select 2.1.2022 in the datepicker the date it is sent to API as 1.1.2022
	// the above is the reason why there must be this function which converts any datetime affected by timezone to 00:00 in UTC+0
	const timezoneIndependantTime = new Date(
		date.getTime() - date.getTimezoneOffset() * 60 * 1000
	);
	return timezoneIndependantTime;
};

const DatePicker = ({
	name,
	label,
	placeholder,
	options,
	hideError,
	...rest
}: DatePickerProps): JSX.Element => {
	const [field, _, { setValue }] = useField(name);
	const [pickerInputValue, setPickerInputValue] = useState<Date>(field.value);
	return (
		<MUIDatePicker
			components={{
				LeftArrowIcon: () => (
					<CaretLeft sx={{ width: "2rem", height: "2rem" }} />
				),
				RightArrowIcon: () => (
					<CaretRight sx={{ width: "2rem", height: "2rem" }} />
				),
				SwitchViewIcon: () => (
					<Contracts sx={{ width: "2rem", height: "2rem" }} />
				),
				OpenPickerIcon: () => (
					<Calendar
						color="primary"
						sx={{ width: "2.4rem", height: "2.4rem" }}
					/>
				),
			}}
			okText="Hotovo"
			cancelText="Zrušit"
			clearText="Smazat"
			ToolbarComponent={() => <></>}
			name={name}
			label={label}
			value={pickerInputValue} // datepicker expects the value in non-manipulated date format, so there can´t be used the date changed by makeTimezoneIndependantTime()
			mask="__.__.____"
			inputFormat="dd.MM.yyyy"
			onChange={(newValue) => {
				if (newValue instanceof Date) {
					setPickerInputValue(newValue);
					setValue(makeTimezoneIndependantTime(newValue));
				} else if (newValue === null) setValue("");
			}}
			inputProps={{
				placeholder: placeholder, // for desktop
			}}
			{...options}
			renderInput={(params) => {
				return (
					<TextInput // This is old non-performant version of TextInput. With performant input, the datepicker behaves strange in Chrome when typing (switching month and day onBlur), even passing of disablePerformance prop doesn´t help
						{...rest}
						{...params}
						name={name}
						onChange={() => null}
						hideError={hideError}
						placeholder={placeholder} // for phone
						endAdornment={
							<InputAdornment position="end">
								<Calendar
									color="primary"
									sx={{ width: "2.4rem", height: "2.4rem" }}
								/>
							</InputAdornment>
						}
					/>
				);
			}}
		/>
	);
};

export default DatePicker;
