export const inactivityTime = 1000 * 60 * 30;
export const warningTime = 1000 * 60 * 2;
export const lastActivityKey = "lastActivity";

export enum inactivityStatus {
	active = "active",
	warning = "warning",
	inactive = "inactive",
}

export enum channelMessages {
	logout = "LOGOUT",
	closeWarning = "CLOSE_WARNING",
	openWarning = "OPEN_WARNING",
}

export const getActivity = (): number | undefined => {
	const activity = localStorage.getItem(lastActivityKey);
	if (activity) return parseInt(activity);
	else return undefined;
};

export const checkActivity = (): inactivityStatus | null => {
	const current = new Date().getTime();
	const last = getActivity();
	if (last && current - last < inactivityTime) {
		return inactivityStatus.active;
	} else if (last && current - last < inactivityTime + warningTime) {
		return inactivityStatus.warning;
	} else if (last) {
		return inactivityStatus.inactive;
	}
	return null;
};

export const setActivity = () => {
	const date = new Date().getTime();
	localStorage.setItem("lastActivity", date.toString());
};
