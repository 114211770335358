import Skeleton from "@mui/material/Skeleton";

import { GetPPDetailResponse } from "../../../models";
import { useContractId } from "../../context/ContractIDContext";
import { NumberToCZK } from "../../functions";
import { usePensionDetail } from "../../queryHooks";
import PieChartCard from "../Charts/PieChartCard";

const EvaluationsChart = (): JSX.Element => {
	const id = useContractId();
	const result = usePensionDetail<GetPPDetailResponse>({ id: id });

	const evaluations = result.isSuccess && result.data.evaluationsBreakdown;
	const contributions = result.isSuccess && result.data.contributionsBreakdown;

	const chartData = [
		{
			name: "myContributions",
			label: "Moje příspěvky",
			data: contributions.owner,
		},
		{
			name: "employerContributions",
			label: "Příspěvky zaměstnavatele",
			data: contributions.employer,
		},
		{
			name: "countryContributions",
			label: "Státní příspěvky",
			data: contributions.state,
		},
		{
			name: "myAndEmployerEvaluations",
			label: "Zhodnocení mých příspěvků a příspěvků zaměstnavatele",
			data: evaluations.owner,
		},
		{
			name: "countryEvaluations",
			label: "Zhodnocení státního příspěvku",
			data: evaluations.state,
		},
	];

	// Zobraz ctvrtou hodnoty Ostatní, pouze když přišly nějaké nespecifikované
	// peníze
	if (contributions.others !== 0)
		chartData.push({
			name: "othersContributions",
			label: "Ostatní",
			data: contributions.others,
		});

	return (
		<PieChartCard
			title="Přehled příspěvků a zhodnocení"
			subTitle={
				<>
					Vaše celkové příspěvky jsou{" "}
					<strong>
						{!result.isSuccess ? (
							<>
								<Skeleton sx={{ display: "inline-block" }} width={50} /> Kč
							</>
						) : (
							NumberToCZK(result.data.contributionsBreakdown.total, {
								maximumFractionDigits: 0,
							})
						)}
					</strong>{" "}
					s celkovým zhodnocením{" "}
					<strong>
						{!result.isSuccess ? (
							<>
								<Skeleton sx={{ display: "inline-block" }} width={50} /> Kč
							</>
						) : (
							NumberToCZK(result.data.evaluationsBreakdown.total, {
								maximumFractionDigits: 0,
							})
						)}
					</strong>
					.
				</>
			}
			chartData={result.isSuccess && chartData}
			total={result.isSuccess && contributions.total + evaluations.total}
		/>
	);
};

export default EvaluationsChart;
