export const yearlyStateContributionFormula = (
	monthlyContribution: number
): number => {
	// formula acquired from business department
	if (monthlyContribution < 300) {
		return 0;
	} else if (monthlyContribution > 299 && monthlyContribution < 1000) {
		const monthlyContributionAbove300 = monthlyContribution - 300;
		return Math.round(90 + 0.2 * monthlyContributionAbove300) * 12;
	} else return 230 * 12;
};

export const yearlyStateContributionFormulaAfterNovel = (
	monthlyContribution: number
): number => {
	// formula acquired from business department
	if (monthlyContribution < 500) {
		return 0;
	} else if (monthlyContribution > 499 && monthlyContribution < 1700) {
		const monthlyContributionAbove500 = monthlyContribution - 500;
		return Math.round(100 + 0.2 * monthlyContributionAbove500) * 12;
	} else return 340 * 12;
};

export const possibleTaxSavingFormula = (
	monthlyContribution: number
): number => {
	return Math.round(
		Math.max(Math.min(monthlyContribution - 1000, 2000), 0) * 12 * 0.15
	); // formula acquired from business departnemt
};

export const possibleTaxSavingFormulaH12024 = (
	monthlyContribution: number
): number => {
	return Math.round(
		Math.max(Math.min(monthlyContribution - 1000, 4000), 0) * 12 * 0.15
	); // formula acquired from business departnemt
};

export const possibleTaxSavingFormulaAfterNovel = (
	monthlyContribution: number
): number => {
	return Math.round(
		Math.max(Math.min(monthlyContribution - 1700, 4000), 0) * 12 * 0.15
	); // formula acquired from business departnemt
};
