import { Box, Paper, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { useState } from "react";

import { ProductType } from "../../../models";
import { LoggedInBar } from "../../components/AppBar";
import Contract, {
	ContractById,
	FufiContractById,
} from "../../components/Contract";
import ContractCard, {
	ContractCardSkeleton,
} from "../../components/Contract/ContractCard";
import {
	ContractHeaderMobileCarrets,
	ContractHeaderWithFufiData,
} from "../../components/Contract/ContractCard/ContractCardHeader";
import ContractCardDesktopMenu, {
	ContractCardDesktopMenuWithFufiData,
} from "../../components/Contract/ContractCardDesktopMenu";
import BottomLeftBanner from "../../components/InfoBox/BottomLeftBanner";
import HelpBox from "../../components/InfoBox/HelpBox";
import { ScrollSnapper } from "../../components/ScrollSnapper";
import { SEO } from "../../components/SEO";
import { TransactionSkeleton } from "../../components/Transactions/SingleTransactionRow";
import { HeadingL, HeadingXL } from "../../components/Typography";
import Version from "../../components/Version";
import { ContractTypeProvider } from "../../context/ContractTypeContext";
import withProtection from "../../highOrderComponents/withProtection";
import { useContracts } from "../../queryHooks";
import { ContractType } from "../../types/contracts";

export const HeadingMainLengthWithData = () => {
	const { data: contracts, isSuccess } = useContracts();

	if (!isSuccess) return <>Načítáme Vaše smlouvy...</>;

	if (contracts.length === 1)
		return <>Vaše {contracts.length} aktivní smlouva</>;
	else if (contracts.length < 5)
		return <>Vaše {contracts.length} aktivní smlouvy</>;
	else return <>Vašich {contracts.length} aktivních smluv</>;
};

interface IContractCardsDesktopMenu {
	setContractIndex: (i: number) => void;
	contractIndex: number;
}

const ContractDesktopSelectorListWithData = ({
	contractIndex,
	setContractIndex,
}: IContractCardsDesktopMenu): JSX.Element => {
	const { data: contracts, isLoading } = useContracts();

	if (isLoading)
		return (
			<>
				<TransactionSkeleton />
				<TransactionSkeleton />
			</>
		);

	return (
		<>
			{contracts?.map((contract, i) => {
				return (
					<ContractTypeProvider key={contract.id} contractType={contract.type}>
						{contract.type === ProductType.Clf ? (
							<ContractCardDesktopMenuWithFufiData
								contractId={contract.id}
								isActive={contractIndex === i}
								setContractIndex={() => setContractIndex(i)}
							/>
						) : (
							<ContractCardDesktopMenu
								data={contract}
								setContractIndex={() => setContractIndex(i)}
								contractIndex={contractIndex}
								selectedProduct={i}
							/>
						)}
					</ContractTypeProvider>
				);
			})}
		</>
	);
};

interface ICustomWidthSwipeableViews {
	setContractIndex: (i: number) => void;
	contractIndex: number;
}

const ContractMobileSwipableSelectorWithData = ({
	contractIndex,
	setContractIndex,
}: ICustomWidthSwipeableViews): JSX.Element => {
	const { data: contracts, isLoading } = useContracts();

	if (isLoading)
		return (
			<Stack direction="row" spacing={6} paddingLeft={4}>
				<ContractCardSkeleton width={0.7} />
				<ContractCardSkeleton width={0.3} />
			</Stack>
		);

	// TODO refactor carret usage into a singular wrapper for all types of cards and
	// fix carret discrepancy
	return (
		<ScrollSnapper
			index={contractIndex}
			onIndexChange={setContractIndex}
			sx={{ alignItems: "stretch", pb: 4 }}
			childContainerProps={{
				sx: {
					p: 1,
					display: "flex",
					alignItems: "stretch",
				},
			}}
		>
			{contracts?.map(({ id, type }, i) => (
				<ContractTypeProvider key={id} contractType={type as ContractType}>
					<ContractHeaderMobileCarrets
						isFirst={i === 0}
						isLast={i === contracts.length - 1}
						onShift={(modifier) => {
							setContractIndex(i + modifier);
						}}
					>
						{type === ProductType.Clf ? (
							<Paper
								elevation={4}
								sx={{ flexGrow: 1, alignSelf: "stretch", p: 4 }}
							>
								<ContractHeaderWithFufiData contractId={id} />
							</Paper>
						) : (
							<ContractCard contractId={id} type={type} />
						)}
					</ContractHeaderMobileCarrets>
				</ContractTypeProvider>
			))}
		</ScrollSnapper>
	);
};

function ContractsList() {
	const [contractIndex, setContractIndex] = useState(0);
	const isDesktop = useIsDesktop();
	const { data } = useContracts();

	return (
		<Stack
			direction={{ xxs: "column", md: "row" }}
			sx={{ justifyContent: { md: "space-between" } }}
		>
			<Stack
				spacing={6}
				sx={{
					flexShrink: { md: 0 },
					width: { md: "38%" },
					pt: { md: 2, xxs: 0 },
				}}
			>
				{isDesktop ? ( // don't swipe navigation cards on desktop
					<ContractDesktopSelectorListWithData
						setContractIndex={setContractIndex}
						contractIndex={contractIndex}
					/>
				) : (
					<ContractMobileSwipableSelectorWithData
						setContractIndex={setContractIndex}
						contractIndex={contractIndex}
					/>
				)}
				{isDesktop && (
					<>
						<HelpBox sx={{ display: "flex" }} noImage />
						<BottomLeftBanner />
					</>
				)}
			</Stack>
			<Box sx={{ width: { md: "48.3%" } }}>
				<ScrollSnapper
					index={contractIndex}
					onIndexChange={setContractIndex}
					sx={{
						my: 0,
						mx: 3,
					}}
					childContainerProps={{ sx: { py: 1, px: 3 } }}
				>
					{data ? (
						data.map(({ type, id }) => (
							<ContractTypeProvider key={id} contractType={type}>
								{type === ProductType.Clf ? (
									<FufiContractById key={id} id={id} />
								) : (
									<ContractById key={id} type={type} id={id} />
								)}
							</ContractTypeProvider>
						))
					) : (
						<Contract isLoading />
					)}
				</ScrollSnapper>
			</Box>
		</Stack>
	);
}

export const AppBar = (): JSX.Element => <LoggedInBar />;

const DashboardPage = (): JSX.Element => {
	const isD = useIsDesktop();
	if (isD)
		return (
			<Stack gap={5}>
				<HeadingXL sx={{ mb: 0 }}>
					<HeadingMainLengthWithData />
				</HeadingXL>
				<ContractsList />
				<Version />
			</Stack>
		);

	return (
		<Stack sx={{ justifyContent: "space-between" }}>
			<Stack spacing={{ xxs: 5, md: 7 }}>
				<HeadingL sx={{ mb: 0, pl: { md: 0, xxs: 4 } }}>
					<HeadingMainLengthWithData />
				</HeadingL>
				<ContractsList />
			</Stack>
			<Version />
		</Stack>
	);
};

export default withProtection(DashboardPage);

export const Head = (): JSX.Element => (
	<SEO title="Smlouvy">
		<meta name="robots" content="noindex" />
	</SEO>
);
