/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Evaluations
 */
export interface Evaluations {
    /**
     * 
     * @type {number}
     * @memberof Evaluations
     */
    owner: number;
    /**
     * 
     * @type {number}
     * @memberof Evaluations
     */
    state: number;
    /**
     * 
     * @type {number}
     * @memberof Evaluations
     */
    total: number;
}

/**
 * Check if a given object implements the Evaluations interface.
 */
export function instanceOfEvaluations(value: object): value is Evaluations {
    if (!('owner' in value) || value['owner'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    return true;
}

export function EvaluationsFromJSON(json: any): Evaluations {
    return EvaluationsFromJSONTyped(json, false);
}

export function EvaluationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Evaluations {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'],
        'state': json['state'],
        'total': json['total'],
    };
}

export function EvaluationsToJSON(value?: Evaluations | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'state': value['state'],
        'total': value['total'],
    };
}

