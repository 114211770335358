import useIsDesktop from "desktop/useIsDesktop";
import { PageProps } from "gatsby";

import { ProductType } from "../../../models";
import { ContractIDProvider } from "../../context/ContractIDContext";
import { ContractTypeProvider } from "../../context/ContractTypeContext";
import { BackNavigationBar, LoggedInBar } from "../AppBar";
import { FufiSubtitle, LifeSubtitle } from "./Heading/Subtitle";
import { FufiTitle, LifeTitle } from "./Heading/Title";

export const LifeAppBar = (pageProps: PageProps): JSX.Element => {
	const isDesktop = useIsDesktop();
	return isDesktop ? (
		<LoggedInBar />
	) : (
		<ContractIDProvider pageProps={pageProps}>
			<ContractTypeProvider contractType={ProductType.Cl}>
				<BackNavigationBar title={<LifeTitle />} subtitle={<LifeSubtitle />} />
			</ContractTypeProvider>
		</ContractIDProvider>
	);
};

export const FufiAppBar = (pageProps: PageProps): JSX.Element => {
	const isDesktop = useIsDesktop();
	return isDesktop ? (
		<LoggedInBar />
	) : (
		<ContractIDProvider pageProps={pageProps}>
			<ContractTypeProvider contractType={ProductType.Clf}>
				<BackNavigationBar title={<FufiTitle />} subtitle={<FufiSubtitle />} />
			</ContractTypeProvider>
		</ContractIDProvider>
	);
};
