import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useIsDesktop from "desktop/useIsDesktop";
import { useEffect, useState } from "react";

import {
	Allocation,
	Fund,
	StarPensionClientTypeEnum,
} from "../../../../models";
import { useContractId } from "../../../context/ContractIDContext";
import {
	usePensionDetail,
	usePhoneNumbers,
	useProfile,
} from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import Attention from "../../AttentionBlock";
import { useSmartAction } from "../../Contract/SmartAction/context";
import { useDrawer } from "../../Drawer/context";
import ChangeSavingsStrategyForm from "./Form";
export interface DistributionProps {
	isFixed?: boolean;
	allocation?:
		| Allocation[]
		| {
				fundCode: undefined;
		  }[];
}

export const fundsDistributionDefault = [
	{
		fundCode: undefined,
	},
	{
		fundCode: undefined,
	},
	{
		fundCode: undefined,
	},
];

const ChangeSavingsStrategy = ({
	title,
	refetch,
	funds,
	isFundsChangeInProgress,
	isLoading,
	...rest
}: {
	title: string;
	refetch;
	funds: Fund[];
	isFundsChangeInProgress: boolean;
	isLoading: boolean;
}): JSX.Element => {
	const { showDrawer, setDrawerContent } = useDrawer();
	const isDesktop = useIsDesktop();
	const ai = useAppInsightsContext();
	const personalData = useProfile();
	const phoneNumbers = usePhoneNumbers();
	const id = useContractId();
	const pension = usePensionDetail({ id });
	const refetchPension = pension.refetch;
	const existingResourcesMissing = pension?.data?.savedAmount === 0;
	const [formWasSend, setFormWasSend] = useState<boolean>(false); //basic "optimistic update" , waiting on refetch can be sometimes slow and the button should be disabled immediately after form submit
	const authPhoneNumbers = !phoneNumbers.isLoading && phoneNumbers?.data;
	const openInvestStr = () => {
		ai.trackEvent({
			name: `${title} [dialog]`,
		});
		showDrawer();
		setDrawerContent(
			<ChangeSavingsStrategyForm
				refetchPension={refetchPension}
				title={title}
				personalData={personalData}
				authPhoneNumbers={authPhoneNumbers}
				funds={funds}
				contractId={id}
				existingResourcesMissing={existingResourcesMissing}
				setFormWasSend={setFormWasSend}
				includeAML={
					process.env.GATSBY_FEATURE_IS_AML_AND_BANK_ID_ENABLED === "true" &&
					pension.data !== undefined &&
					!pension.data.isAmlFulfilled &&
					(!("isChildrenContract" in pension.data) ||
						!pension.data.isChildrenContract)
				}
				includeID={
					process.env.GATSBY_FEATURE_IS_AML_AND_BANK_ID_ENABLED === "true" &&
					pension.data !== undefined &&
					!pension.data.isClientIdentified &&
					pension.data.clientType === StarPensionClientTypeEnum.Z &&
					(!("isChildrenContract" in pension.data) ||
						!pension.data.isChildrenContract)
				}
			/>
		);
	};
	const { action } = useSmartAction();
	useEffect(() => {
		// show dialog after data is loaded
		if (
			(action?.type === "InvestmentStrategy" ||
				action?.type === "InvestmentStrategyBankId") &&
			!isLoading
		) {
			openInvestStr();
		}
	}, [isLoading]);

	return (
		<>
			{authPhoneNumbers && authPhoneNumbers.length === 0 && (
				<Attention
					severity="info"
					title={cs.investmentStrategy.alerts.noPhone.title}
					sx={{ mb: 4 }}
				>
					<span
						dangerouslySetInnerHTML={{
							__html: cs.investmentStrategy.alerts.noPhone.description,
						}}
					/>
				</Attention>
			)}
			<Box mt={isDesktop ? 4 : 0}>
				{(isFundsChangeInProgress || formWasSend) && (
					<Attention severity="info" sx={{ mb: 4 }}>
						{cs.investmentStrategy.alerts.fundChangeInProgress.description}
					</Attention>
				)}
				<Button
					{...rest}
					disabled={
						!authPhoneNumbers ||
						isFundsChangeInProgress ||
						isLoading ||
						formWasSend
					}
					variant="contained"
					sx={{
						alignSelf: { md: "flex-start" },
						width: { md: "auto", xxs: "100%" },
					}}
					onClick={() => {
						openInvestStr();
					}}
				>
					{title}
				</Button>
			</Box>
		</>
	);
};

export default ChangeSavingsStrategy;
