import SvgIcon from "@mui/material/SvgIcon";

const Shield = (props): JSX.Element => {
	return (
		<SvgIcon width="25" height="29" viewBox="0 0 25 29" fill="none" {...props}>
			<path d="M24.498 7.398c-.022-1.315-.046-2.674.015-3.99a.666.666 0 0 0-.617-.695 62.017 62.017 0 0 0-1.941-.104c-1.343-.057-2.612-.111-3.809-.367-1.116-.24-2.189-.654-3.325-1.092a40.234 40.234 0 0 0-2.047-.747c-.634-.204-1.369.093-1.653.23C8.678 1.808 6.3 2.425 3.856 2.518c-.708.026-1.415.02-2.14.012L.672 2.525a.666.666 0 0 0-.667.666l.005 2.394c.006 1.668.01 3.281-.01 4.894-.066 5.448 1.779 10.014 5.484 13.574a21.568 21.568 0 0 0 1.963 1.667 25.697 25.697 0 0 0 2.21 1.492c.513.31 1.044.608 1.592.897.284.149.64.224.992.224.324 0 .645-.063.902-.191 4.353-2.146 7.472-5.136 9.535-9.14 1.953-3.787 1.884-7.761 1.819-11.604Zm-3.004 10.994c-1.928 3.74-4.852 6.539-8.942 8.554-.156.078-.52.067-.68-.017a29.51 29.51 0 0 1-1.524-.858 24.51 24.51 0 0 1-2.096-1.415 20.488 20.488 0 0 1-1.843-1.564c-3.478-3.341-5.137-7.461-5.074-12.596.02-1.62.015-3.24.01-4.916L1.34 3.862l.361.002c.748.008 1.476.016 2.205-.013 2.632-.1 5.181-.76 7.793-2.018.331-.159.597-.184.663-.162.665.216 1.322.47 1.977.723 1.134.438 2.308.89 3.528 1.151 1.306.28 2.63.336 4.03.396.412.018.833.036 1.26.06-.031 1.145-.012 2.298.006 3.42.067 3.847.128 7.481-1.67 10.97Z" />
			<path d="M18.48 9.685h-1.547a.666.666 0 1 0 0 1.333l1.618.063-.07 3.307h-3.192c-.677 0-1.233.398-1.382.99a.63.63 0 0 0-.021.164l-.07 3.467-3.262-.062v-3.412a1.15 1.15 0 0 0-1.151-1.147H5.97v-3.37h3.432a1.15 1.15 0 0 0 1.15-1.147l.071-3.474 3.262.062v1.438a.666.666 0 1 0 1.333 0V6.46c0-.769-.63-1.395-1.403-1.395h-3.192c-.774 0-1.404.626-1.404 1.395v3.226H5.874c-.681 0-1.236.552-1.236 1.23v3.49c0 .784.467 1.295 1.195 1.315a.402.402 0 0 0 .041.001h3.347v3.225c0 .77.63 1.396 1.403 1.396h3.192c.773 0 1.403-.626 1.403-1.396v-3.242l.07.017 3.192-.001c.814 0 1.404-.587 1.404-1.396V11.08c0-.809-.59-1.396-1.404-1.396Z" />
		</SvgIcon>
	);
};

export default Shield;
