/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActiveDirectoryUserIdRequest,
  ActiveDirectoryUserIdResponse,
  ErrorResponse,
  RegisterRequest,
  RegisterResponse,
  ValidateBirthNumberRequest,
  ValidateBirthNumberResponse,
} from '../models/index';
import {
    ActiveDirectoryUserIdRequestFromJSON,
    ActiveDirectoryUserIdRequestToJSON,
    ActiveDirectoryUserIdResponseFromJSON,
    ActiveDirectoryUserIdResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    RegisterRequestFromJSON,
    RegisterRequestToJSON,
    RegisterResponseFromJSON,
    RegisterResponseToJSON,
    ValidateBirthNumberRequestFromJSON,
    ValidateBirthNumberRequestToJSON,
    ValidateBirthNumberResponseFromJSON,
    ValidateBirthNumberResponseToJSON,
} from '../models/index';

export interface ActiveDirectoryUserIdPostRequest {
    activeDirectoryUserIdRequest: ActiveDirectoryUserIdRequest;
}

export interface RegisterPostRequest {
    registerRequest: RegisterRequest;
}

export interface ValidateBirthNumberPostRequest {
    validateBirthNumberRequest: ValidateBirthNumberRequest;
}

/**
 * 
 */
export class RegistrationApi extends runtime.BaseAPI {

    /**
     * Decipher user (object) id from a symmetric cipher
     */
    async activeDirectoryUserIdPostRaw(requestParameters: ActiveDirectoryUserIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveDirectoryUserIdResponse>> {
        if (requestParameters['activeDirectoryUserIdRequest'] == null) {
            throw new runtime.RequiredError(
                'activeDirectoryUserIdRequest',
                'Required parameter "activeDirectoryUserIdRequest" was null or undefined when calling activeDirectoryUserIdPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/active-directory-user-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActiveDirectoryUserIdRequestToJSON(requestParameters['activeDirectoryUserIdRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveDirectoryUserIdResponseFromJSON(jsonValue));
    }

    /**
     * Decipher user (object) id from a symmetric cipher
     */
    async activeDirectoryUserIdPost(requestParameters: ActiveDirectoryUserIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveDirectoryUserIdResponse> {
        const response = await this.activeDirectoryUserIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Try resolve information from registration into a PID
     */
    async registerPostRaw(requestParameters: RegisterPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterResponse>> {
        if (requestParameters['registerRequest'] == null) {
            throw new runtime.RequiredError(
                'registerRequest',
                'Required parameter "registerRequest" was null or undefined when calling registerPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterRequestToJSON(requestParameters['registerRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterResponseFromJSON(jsonValue));
    }

    /**
     * Try resolve information from registration into a PID
     */
    async registerPost(requestParameters: RegisterPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterResponse> {
        const response = await this.registerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate provided birth number againts AD B2C
     */
    async validateBirthNumberPostRaw(requestParameters: ValidateBirthNumberPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['validateBirthNumberRequest'] == null) {
            throw new runtime.RequiredError(
                'validateBirthNumberRequest',
                'Required parameter "validateBirthNumberRequest" was null or undefined when calling validateBirthNumberPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/validate-birth-number`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateBirthNumberRequestToJSON(requestParameters['validateBirthNumberRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate provided birth number againts AD B2C
     */
    async validateBirthNumberPost(requestParameters: ValidateBirthNumberPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateBirthNumberPostRaw(requestParameters, initOverrides);
    }

}
