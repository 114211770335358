import { EventMessage, EventType, InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { navigate } from "gatsby";
import { useEffect, useState } from "react";

import { LoggedOutBar } from "../../components/AppBar";
import Attention from "../../components/AttentionBlock";
import NewPortalAttention from "../../components/AttentionBlock/NewPortalAttention";
import BankIcons from "../../components/BankIcons";
import DarkBox from "../../components/InfoBox/DarkBox";
import { BackLink } from "../../components/Link/InternalLink";
import LoginButton from "../../components/LoginButton";
import { setActivity } from "../../components/Logout/utils";
import Overview from "../../components/Overview";
import { SEO } from "../../components/SEO";
import {
	BodyTextM,
	BodyTextS,
	HeadingL,
	HeadingS,
} from "../../components/Typography";

const errorToText: Record<string, string> = {
	server_error: "Chyba serveru",
};

const defaultRequest = {
	scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE],
};

export const AppBar = (): JSX.Element => <LoggedOutBar />;

function LoginPage({ pageContext }): JSX.Element {
	const [loginError, setLoginError] = useState();
	const [authority, setAuthority] = useState();

	const queryClient = useQueryClient();

	useEffect(() => {
		const authority = localStorage.getItem("authority");
		if (authority) setAuthority(authority);
	}, []);
	const { instance } = useMsal();
	useEffect(() => {
		const callbackId = instance.addEventCallback((event: EventMessage) => {
			if (
				[
					EventType.LOGIN_FAILURE,
					EventType.LOGOUT_FAILURE,
					EventType.SSO_SILENT_FAILURE,
					EventType.ACQUIRE_TOKEN_FAILURE,
					EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE,
				].includes(event.eventType)
			) {
				if (
					event.error &&
					event.error.errorMessage.indexOf("AADB2C90118") > -1
				) {
					if (event.interactionType === InteractionType.Redirect) {
						const request = {
							authority: `https://${process.env.GATSBY_B2C_DOMAIN}/${process.env.GATSBY_B2C_TENANT_ID}/${process.env.GATSBY_B2C_SIGNUP_RESET_PASSWORD_POLICY}`,
						};
						instance.loginRedirect({ ...defaultRequest, ...request });
					}
					return;
				}

				if (event.error && event.error.errorMessage.indexOf("AADB2C90091") > -1)
					return;

				setLoginError(event.error);
			}

			if (
				[
					EventType.LOGIN_SUCCESS,
					EventType.ACQUIRE_TOKEN_SUCCESS,
					EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS,
					EventType.SSO_SILENT_SUCCESS,
				].includes(event.eventType)
			) {
				localStorage.removeItem("REACT_QUERY_OFFLINE_CACHE");
				queryClient.removeQueries();
				setActivity();
				instance.setActiveAccount(event.payload.account);
				if (
					!event.payload.account.idTokenClaims.extension_PID &&
					!event.payload.account.idTokenClaims.extension_MPK
				) {
					void instance.logout({
						postLogoutRedirectUri: "/uzivatel-nenalezen/",
					});
				} else if (
					event.payload.account.idTokenClaims.partner_code === "AirBank"
				) {
					void navigate("/uvitaci-obrazovka-airbank/");
				} else {
					if (event.payload.account.idTokenClaims.extension_MPK === undefined) {
						void instance.logout({
							postLogoutRedirectUri: "/prihlaseni/",
						});
					} else {
						void navigate("/smlouvy/");
					}
				}
			}
		});

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, [instance]);

	return (
		<>
			<Overview
				alt="Přihlášení"
				sx={{
					overflow: { md: "visible", xxs: "hidden" },
					mb: { md: 0, xxs: -4 },
					mx: { md: 0, xxs: -4 },
					p: { md: 0 },
					pt: { md: "72px" },
					".MuiCardContent-root": {
						boxShadow: (theme) => theme.customShadows.close,
					},
					".MuiCardMedia-root": {
						display: { md: "none" },
						pb: (theme) => theme.spacing(3),
					},
				}}
			>
				<Stack sx={{ px: 3 }}>
					<BackLink mb={4} to="/prihlaseni-nebo-registrace" />
					<HeadingL>Přihlášení</HeadingL>
					{process.env.GATSBY_FEATURE_IS_REGISTRATION_ENABLED === "true" && (
						<NewPortalAttention />
					)}
				</Stack>
			</Overview>
			<Stack
				spacing={5}
				sx={{
					p: { md: 5 },
					boxShadow: (theme) => ({
						md: theme.customShadows.close,
					}),
					backgroundColor: "white.main",
				}}
			>
				{authority === undefined && loginError && (
					<Attention
						severity="error"
						title={errorToText[loginError.errorCode] ?? undefined}
					>
						{loginError.errorMessage}
					</Attention>
				)}
				<BodyTextM>Vyberte si jednu z možností</BodyTextM>
				<DarkBox>
					<HeadingS withoutScale>BankID</HeadingS>
					<BodyTextS>Doporučujeme použít tuto možnost.</BodyTextS>
					<LoginButton journey="bank-id" />
					<BankIcons />
					{authority === "B2C_1A_BANKIDPOLICY" && loginError && (
						<Attention
							severity="error"
							title={errorToText[loginError.errorCode] ?? undefined}
						>
							{loginError.errorMessage}
						</Attention>
					)}
				</DarkBox>
				<DarkBox>
					<HeadingS withoutScale>E-mail a heslo</HeadingS>
					<BodyTextS>Přihlášení přes e-mail</BodyTextS>
					<LoginButton
						journey="local-login"
						sx={{ alignSelf: { md: "flex-start" } }}
						data-test="login-button"
					/>
					{authority &&
						["B2C_1A_LOGINPOLICY", "B2C_1_PrototypeLogin"].includes(
							authority
						) &&
						loginError && (
							<Attention
								severity="error"
								title={errorToText[loginError.errorCode] ?? undefined}
							>
								{loginError.errorMessage}
							</Attention>
						)}
				</DarkBox>
			</Stack>
		</>
	);
}

export default LoginPage;

export const Head = (): JSX.Element => <SEO title="Přihlášení" />;
