/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConsentType } from './ConsentType';
import {
    ConsentTypeFromJSON,
    ConsentTypeFromJSONTyped,
    ConsentTypeToJSON,
} from './ConsentType';

/**
 * 
 * @export
 * @interface GetConsentResponse
 */
export interface GetConsentResponse {
    /**
     * 
     * @type {ConsentType}
     * @memberof GetConsentResponse
     */
    type: ConsentType;
    /**
     * 
     * @type {boolean}
     * @memberof GetConsentResponse
     */
    consent: boolean;
}

/**
 * Check if a given object implements the GetConsentResponse interface.
 */
export function instanceOfGetConsentResponse(value: object): value is GetConsentResponse {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('consent' in value) || value['consent'] === undefined) return false;
    return true;
}

export function GetConsentResponseFromJSON(json: any): GetConsentResponse {
    return GetConsentResponseFromJSONTyped(json, false);
}

export function GetConsentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetConsentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ConsentTypeFromJSON(json['type']),
        'consent': json['consent'],
    };
}

export function GetConsentResponseToJSON(value?: GetConsentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ConsentTypeToJSON(value['type']),
        'consent': value['consent'],
    };
}

