import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";

import MinusIcon from "../Icons/Minus";
import PlusIcon from "../Icons/Plus";

export interface SingleRowProps {
	dueDate: string;
	description: string;
	content: JSX.Element;
}

interface RowProps {
	row: SingleRowProps;
	titles: Array<string>;
}

function Row({ row, titles }: RowProps): JSX.Element {
	const [open, setOpen] = useState(false);

	return (
		<>
			<TableRow>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? (
							<MinusIcon sx={{ width: "2.1rem", height: "2.1rem" }} />
						) : (
							<PlusIcon sx={{ width: "2.1rem", height: "2.1rem" }} />
						)}
					</IconButton>
				</TableCell>
				{titles.map((_, i) => (
					<TableCell
						sx={{
							color: (theme) =>
								open
									? theme.palette.primary.dark
									: theme.palette.grey.ultraDark,
						}}
						key={i}
					>
						{Object.values(row)[i]}
					</TableCell>
				))}
			</TableRow>
			<TableRow>
				<TableCell sx={{ pt: 0, pb: 0 }} colSpan={6}>
					<Collapse in={open} timeout={0}>
						<Stack sx={{ pl: 8, py: 3 }} spacing={4}>
							{row.content}
						</Stack>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

export default Row;
