import { TypographyProps } from "@mui/material/Typography";
import { ReactNode } from "react";

import { BodyTextS } from "../Typography";

export interface LabelProps extends TypographyProps {
	children: ReactNode;
}

export const Label = ({ children, sx, ...rest }: LabelProps): JSX.Element => (
	<BodyTextS
		component="p"
		mb={0}
		{...rest}
		sx={{
			...sx,
			py: 2,
		}}
	>
		{children}
	</BodyTextS>
);

export interface ChartLabelProps {
	children: ReactNode;
	color: string;
}

const ChartLabel = ({
	children,
	color,
	...rest
}: ChartLabelProps): JSX.Element => (
	<Label
		{...rest}
		sx={{
			position: "relative",
			pl: 5,
			"&:before": {
				position: "absolute",
				top: "6px",
				left: 0,
				display: "block",
				width: 16,
				height: 16,
				borderRadius: 1,
				background: color,
				content: "''",
			},
		}}
	>
		{children}
	</Label>
);

export default ChartLabel;
