import SvgIcon from "@mui/material/SvgIcon";

const PlusButton = (props): JSX.Element => {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				id="Box"
				d="M20 24L4 24C1.79086 24 0 22.2091 0 20L0 4C0 1.79086 1.79086 0 4 0L20 0C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24Z"
				fill="#EA650D"
			/>
			<path
				id="+"
				d="M11.0131 16.7019H13.0081V12.8829H16.7131V10.9449H13.0081V7.29688H11.0131V10.9449H7.28906V12.8829H11.0131V16.7019Z"
				fill="white"
			/>
		</SvgIcon>
	);
};

export default PlusButton;
