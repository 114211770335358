import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Formik } from "formik";
import React, { useState } from "react";
import { array, object, string } from "yup";

import {
	Beneficiary,
	PensionBeneficiary,
	ProductType,
} from "../../../../models";
import { usePrimaryAPIClient } from "../../../context/PrimaryAPIClient";
import cs from "../../../translations/cs.json";
import { useDrawer } from "../../Drawer/context";
import FormMultiStepDrawer from "../../Drawer/FormMultiStepDrawer";
import {
	mapFetchApiErrorToSubmitStatus,
	SubmitStatus,
	SubmitStatusStep,
} from "../../Drawer/GlobalSteps/SubmitStatusStep";
import { beneficiariesSchema, ratiosSchema } from "../../Form/rules";
import BeneficiariesList from "../steps/BeneficiariesList";
import BeneficiarInfo from "../steps/BeneficiarInfo";
import ChangesOverview from "../steps/ChangesOverview";
import ShareDistribution from "../steps/ShareDistribution";
import {
	beneficiariesInitialValues,
	cleanAddress,
	ratiosInitialValues,
} from "./cleaners";

type BeneficiariesChangeFormProps = {
	id: string;
	contractType: string;
	beneficiaries: Beneficiary[] | PensionBeneficiary[];
	refetchOnSuccess: () => Promise<void>;
	refetchOnError: () => Promise<void>;
	setFormWasSend: React.Dispatch<React.SetStateAction<boolean>>;
};

const BeneficiariesChangeForm = ({
	id,
	contractType,
	beneficiaries,
	refetchOnSuccess,
	refetchOnError,
	setFormWasSend,
}: BeneficiariesChangeFormProps): JSX.Element => {
	const { setStep, step } = useDrawer();
	const { contractsPpApi, contractsDpsApi, contractsLifeApi } =
		usePrimaryAPIClient();
	const ai = useAppInsightsContext();

	const beneficiariesChange = (newSetup: unknown) => {
		ai.trackEvent({
			name: "Changed beneficiaries [submit]",
			properties: {
				previous: beneficiaries,
				new: newSetup,
				contractId: id,
			},
		});
	};

	const initialValues = {
		beneficiaries: beneficiariesInitialValues(beneficiaries),
		editedBeneficiar: beneficiaries.length,
		ratios: ratiosInitialValues(beneficiaries),
	};

	const validationSchema = object().shape({
		beneficiaries: beneficiariesSchema,
		ratios: step === 2 ? ratiosSchema : array(),
		valuesUnchanged:
			step === 2 || beneficiaries.length === 0 // when the initial state is 0 beneficiaries, show error message instantly on the beneficiarList step because there is no ratio distribution step in this situation
				? string().parentHasChanges({ ...initialValues })
				: string(),
	});

	const [removedBeneficiaries, setRemovedBeneficiaries] = useState([]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values, { setStatus }) => {
				setStatus(SubmitStatus.Submitting);
				const beneficiaries = values.beneficiaries.map((item, index) => {
					const address = {
						// don't check if country is empty, there doesn't exist an input to control this value anymore, country code is posted programatically after submit and not taken from formik initial values
						...item.address,
						countryName: undefined,
						country: undefined,
						type: undefined,
					};
					const cleanedAddress = cleanAddress(address);
					const completedInfo = {
						...item,
						...values.ratios[index],
						address:
							Object.keys(cleanedAddress).length === 0
								? null
								: { ...cleanedAddress, type: "Delivery", country: "CZ" },
					};
					return completedInfo;
				});
				try {
					if (contractType === ProductType.Pf) {
						await contractsPpApi.setPPBeneficiariesPut({
							contractId: id,
							setPensionBeneficiariesRequest: {
								beneficiaries: [...beneficiaries, ...removedBeneficiaries],
							},
						});
						beneficiariesChange(values.beneficiaries);
					}
					if (contractType === ProductType.Uf) {
						await contractsDpsApi.setDPSBeneficiariesPut({
							contractId: id,
							setPensionBeneficiariesRequest: {
								beneficiaries: [...beneficiaries, ...removedBeneficiaries],
							},
						});
						beneficiariesChange(values.beneficiaries);
					}
					if (contractType === ProductType.Cl) {
						await contractsLifeApi.setLifeBeneficiariesPut({
							contractId: id,
							setBeneficiariesRequest: {
								beneficiaries,
							},
						});
						beneficiariesChange(values.beneficiaries);
					}
					setStatus(SubmitStatus.Success);
					setFormWasSend(true);
					await refetchOnSuccess();
				} catch (error) {
					setStatus(mapFetchApiErrorToSubmitStatus(error));
					await refetchOnError();
				}
			}}
		>
			{({
				setTouched,
				setFieldTouched,
				validateForm,
				values,
				submitForm,
				status,
			}) => {
				const touchEditedBeneficiar = (prefix, beneficiaries) => {
					Object.keys(beneficiaries).forEach((key) => {
						const name = `${prefix}${prefix !== "" ? "." : ""}${key}`;
						if (
							typeof beneficiaries[key] === "object" &&
							beneficiaries[key] !== null
						) {
							touchEditedBeneficiar(name, beneficiaries[key]);
						} else {
							setFieldTouched(name);
						}
					});
				};
				return (
					<FormMultiStepDrawer
						title={
							contractType
								? cs.product.beneficiaries.changeBeneficiaries[contractType]
								: cs.product.beneficiaries.changeBeneficiaries.default
						}
					>
						{{
							component: (
								<BeneficiariesList
									contractType={contractType}
									removedBeneficiaries={removedBeneficiaries}
									setRemovedBeneficiaries={setRemovedBeneficiaries}
								/>
							),
							stepConfig: {
								label:
									values.beneficiaries.length === 0
										? cs.global.confirmChange
										: cs.beneficiariesChange.beneficiariesListContinue,
								customFunction: () => {
									validateForm()
										.then((errors) => {
											setTouched({ ...errors }); // touch all fields with error
											if (Object.keys(errors).length === 0) {
												if (values.beneficiaries.length === 0) {
													setStep(3);
												} else {
													setStep(2);
												}
											}
										})
										.catch((err) => {
											throw err;
										});
								},
							},
							stepName: "BeneficiariesList",
						}}
						{{
							component: <BeneficiarInfo />,
							stepConfig: {
								title: contractType
									? cs.product.beneficiaries.changeBeneficiarTitle[contractType]
									: cs.product.beneficiaries.changeBeneficiarTitle.default,
								label: contractType
									? cs.product.beneficiaries.backBeneficiaries[contractType]
									: cs.product.beneficiaries.backBeneficiaries.default,
								customFunction: () => {
									validateForm()
										.then((errors) => {
											if (errors?.beneficiaries?.[values.editedBeneficiar]) {
												{
													touchEditedBeneficiar(
														`beneficiaries.${values.editedBeneficiar}`,
														errors.beneficiaries[values.editedBeneficiar]
													);
												}
											}
											const stepErrors =
												errors.beneficiaries?.[values.editedBeneficiar] || {};
											if (Object.keys(stepErrors).length === 0) {
												setStep(0);
											}
										})
										.catch((err) => {
											throw err;
										});
								},
								customBackFunction: () => {
									validateForm()
										.then((errors) => {
											if (errors?.beneficiaries?.[values.editedBeneficiar]) {
												touchEditedBeneficiar(
													`beneficiaries.${values.editedBeneficiar}`,
													errors.beneficiaries[values.editedBeneficiar]
												);
											}
										})
										.catch((err) => {
											throw err;
										});
									return true;
								},
							},
							stepName: "BeneficiarInfo",
						}}
						{{
							component: <ShareDistribution />,
							stepConfig: {
								label: cs.beneficiariesChange.shareDistributionContinue,
								customFunction: () => {
									validateForm()
										.then((errors) => {
											setTouched({ ...errors }); // touch all fields with error
											if (Object.keys(errors).length === 0) {
												setStep(3);
											}
										})
										.catch((err) => {
											throw err;
										});
								},
							},
							stepName: "ShareDistribution",
						}}
						{{
							component: <ChangesOverview />,
							stepConfig: {
								label: cs.global.confirmChange,
								customFunction: () => {
									void submitForm();
									setStep(4);
								},
							},
							stepName: "ChangesOverview",
						}}

						{{
							component: <SubmitStatusStep status={status} />,
							stepName: "SubmitStatusStep",
						}}
					</FormMultiStepDrawer>
				);
			}}
		</Formik>
	);
};

export default BeneficiariesChangeForm;
