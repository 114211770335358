import { navigate } from "gatsby";

import { useDrawer } from "../components/Drawer/context";
import { useContractId } from "../context/ContractIDContext";
import { useContractTypeContext } from "../context/ContractTypeContext";
import { useHasSPError } from "../context/SPErrorContext";
import { contractLink } from "../functions";

interface IUseTabsRedirect {
	allowedTabs: string[];
	redirectTab?: string;
}

export const useSPErrorTabsRedirect = (
	{ allowedTabs, redirectTab }: IUseTabsRedirect = {
		allowedTabs: ["dokumenty"],
	}
) => {
	const id = useContractId();
	const { contractType } = useContractTypeContext();
	const { hasError: isError } = useHasSPError();
	const { hideDrawer } = useDrawer();
	const currentTab = new URL(window.location.href).searchParams.get("main-tab");
	if (isError && !allowedTabs.includes(currentTab ?? "")) {
		hideDrawer();
		void navigate(
			contractLink(id, contractType, redirectTab ?? allowedTabs[0])
		);
	}
};
