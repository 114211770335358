/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProfileCode = {
    Rejected: 'REJECTED',
    Con: 'CON',
    Bal: 'BAL',
    Dyn: 'DYN'
} as const;
export type ProfileCode = typeof ProfileCode[keyof typeof ProfileCode];


export function instanceOfProfileCode(value: any): boolean {
    for (const key in ProfileCode) {
        if (Object.prototype.hasOwnProperty.call(ProfileCode, key)) {
            if ((ProfileCode as Record<string, ProfileCode>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ProfileCodeFromJSON(json: any): ProfileCode {
    return ProfileCodeFromJSONTyped(json, false);
}

export function ProfileCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileCode {
    return json as ProfileCode;
}

export function ProfileCodeToJSON(value?: ProfileCode | null): any {
    return value as any;
}

