import { useEffect, useState } from "react";

import {
	getIsTimeToPrompt,
	LastAMLCheckKey,
	LastBankIdCheck,
	useAMLPrompt,
} from "../../hooks/useAMLPrompt";
import { usePostLogin } from "../../queryHooks";
import { AMLRedirect } from "../AML";
import { AMLFormStandaloneDrawer } from "../AML/Form";
import { BankIdIdentification } from "../AML/identification";
import { AgreementDrawer } from "../Drawer/AgreementDrawer/AgreementDrawer";

export enum Drawers {
	Agreement = "Agreement",
	Id = "Id",
	AML = "AML",
}

interface IDrawerManager {
	disableAgreementDrawer?: boolean;
	disableAmlAndBankIdDrawers?: boolean;
}

export const DrawerManager = ({
	disableAgreementDrawer = false,
	disableAmlAndBankIdDrawers = false,
}: IDrawerManager) => {
	const { data, isSuccess } = usePostLogin({
		options: { enabled: !disableAgreementDrawer },
	});
	const amlPrompt = useAMLPrompt();

	const [drawerQueue, setDrawerQueue] = useState<Drawers[]>([]);
	const [currentDrawerIndex, setCurrentDrawerIndex] = useState(0);

	useEffect(() => {
		const newPrompts: Drawers[] = [];
		const timeToPrompt = amlPrompt ? getIsTimeToPrompt(amlPrompt) : false;
		if (
			!disableAgreementDrawer &&
			isSuccess &&
			data.startConsentGathering &&
			!drawerQueue.includes(Drawers.Agreement)
		) {
			newPrompts.push(Drawers.Agreement);
		}
		if (
			!disableAmlAndBankIdDrawers &&
			amlPrompt &&
			amlPrompt.ID &&
			!drawerQueue.includes(Drawers.Id) &&
			timeToPrompt &&
			timeToPrompt.promptID
		) {
			newPrompts.push(Drawers.Id);
		}
		if (
			!disableAmlAndBankIdDrawers &&
			amlPrompt &&
			amlPrompt.AML &&
			!drawerQueue.includes(Drawers.AML) &&
			timeToPrompt &&
			timeToPrompt.promptAML
		) {
			newPrompts.push(Drawers.AML);
		}
		if (newPrompts.length > 0) {
			setDrawerQueue((prev) => [...prev, ...newPrompts]);
		}
	}, [isSuccess, data, drawerQueue, amlPrompt, disableAgreementDrawer]);

	const bankIdCloseCallback = () => {
		localStorage.setItem(LastBankIdCheck, Date.now().toString());
		setCurrentDrawerIndex((prev) => prev + 1);
	};

	const amlCloseCallback = () => {
		localStorage.setItem(LastAMLCheckKey, Date.now().toString());
		setCurrentDrawerIndex((prev) => prev + 1);
	};

	const currentDrawer = ((): Drawers | null => {
		if (currentDrawerIndex >= drawerQueue.length) {
			return null;
		}
		return drawerQueue[currentDrawerIndex];
	})();
	return (
		<>
			<AgreementDrawer
				open={currentDrawer === Drawers.Agreement}
				onClose={() => {
					setCurrentDrawerIndex((prev) => prev + 1);
				}}
			/>
			<BankIdIdentification
				action={{
					redirect: AMLRedirect.Default,
					contractId: amlPrompt?.contractId,
					contractType: amlPrompt?.contractType,
				}}
				onClick={bankIdCloseCallback}
				onCloseCallback={bankIdCloseCallback}
				scenario={"default"}
				open={currentDrawer === Drawers.Id}
			/>
			<AMLFormStandaloneDrawer
				amlProps={{
					contractId: amlPrompt?.contractId,
					contractType: amlPrompt?.contractType,
					onCloseCallback: amlCloseCallback,
				}}
				open={currentDrawer === Drawers.AML}
			/>
		</>
	);
};
