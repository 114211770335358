/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    phone1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    phone2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContactInfo
     */
    isChangeInProgress: boolean;
}

/**
 * Check if a given object implements the ContactInfo interface.
 */
export function instanceOfContactInfo(value: object): value is ContactInfo {
    if (!('isChangeInProgress' in value) || value['isChangeInProgress'] === undefined) return false;
    return true;
}

export function ContactInfoFromJSON(json: any): ContactInfo {
    return ContactInfoFromJSONTyped(json, false);
}

export function ContactInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'phone1': json['phone1'] == null ? undefined : json['phone1'],
        'phone2': json['phone2'] == null ? undefined : json['phone2'],
        'mobile': json['mobile'] == null ? undefined : json['mobile'],
        'email': json['email'] == null ? undefined : json['email'],
        'isChangeInProgress': json['isChangeInProgress'],
    };
}

export function ContactInfoToJSON(value?: ContactInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'phone1': value['phone1'],
        'phone2': value['phone2'],
        'mobile': value['mobile'],
        'email': value['email'],
        'isChangeInProgress': value['isChangeInProgress'],
    };
}

