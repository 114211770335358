import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import useIsDesktop from "desktop/useIsDesktop";

import { ProductType } from "../../../models";
import { useContractTypeContext } from "../../context/ContractTypeContext";
import { dateTimeFormat } from "../../functions/dates";
import cs from "../../translations/cs.json";
import AddressTemplate from "../AddressBox/AddressTemplate";
import InfoIconDrawer from "../Drawer/InfoIconDrawer";
import InformationList from "../InformationList";
import { CommonBeneficiaryType as CommonBeneficiaryType } from "../ZivotniPojisteni/types";

const mapBeneficiariesToInformationLists = (
	beneficiaries: CommonBeneficiaryType[],
	contractType: ProductType
): JSX.Element[] => {
	return beneficiaries.map((beneficiary, index) => {
		const displayRelationShip = !!(
			beneficiary.firstName &&
			!beneficiary.lastName &&
			beneficiary.relationship
		);
		return (
			<InformationList
				data-test="beneficient-information-table"
				title={`${index + 1}. ${cs.global.person}`}
				key={index}
				sx={{ width: { md: "50%" }, mb: { md: 6 } }}
				infoIconDrawer={
					<InfoIconDrawer
						title={
							cs.product.beneficiaries.singlePerson[
								contractType as keyof typeof cs.product.beneficiaries.singlePerson
							]
						}
						description={cs.product.beneficiaries.description}
						dataTest="beneficiary-info-modal"
					/>
				}
				information={[
					{
						title: cs.global.ratio,
						description: `${beneficiary.share} %`,
					},
					{
						title: cs.global.name,
						description: beneficiary.firstName ?? "-",
					},
					{
						title: cs.global.surname,
						description: beneficiary.lastName ?? "-",
					},
					displayRelationShip && {
						title: cs.global.relationship,
						description: beneficiary.relationship as string,
					},
					!beneficiary.isBank && {
						title: cs.global.birthDate,
						description: beneficiary.birthDate
							? dateTimeFormat.format(new Date(beneficiary.birthDate))
							: "-",
					},
					{
						title: cs.global.contactAddressInCZ,
						description: (
							<AddressTemplate address={beneficiary.contactAdress} />
						),
					},
				]}
			/>
		);
	});
};

interface IBeneficieariesInformationList {
	beneficiaries?: CommonBeneficiaryType[];
	isLoading: boolean;
}

const BeneficieariesInformationList = ({
	beneficiaries,
	isLoading,
}: IBeneficieariesInformationList): JSX.Element => {
	const isDesktop = useIsDesktop();
	const { contractType } = useContractTypeContext();
	const beneficiaryViews =
		isLoading || beneficiaries ? (
			mapBeneficiariesToInformationLists(beneficiaries ?? [], contractType)
		) : (
			<Box
				data-test="no-beneficiaries-message"
				sx={{ width: { md: "50%" }, mb: { md: 6 } }}
			>
				{contractType
					? cs.product.beneficiaries.noBeneficiariesListed[contractType]
					: cs.product.beneficiaries.noBeneficiariesListed.default}
			</Box>
		);

	return (
		<Stack
			sx={{
				flexDirection: { md: "row" },
				flexWrap: { md: "wrap" },
				mb: { md: -4 },
				"& > div:nth-of-type(even)": { pl: { md: 4 } },
				"& > div:nth-of-type(odd)": { pr: { md: 4 } },
			}}
			gap={isDesktop ? 0 : 5}
		>
			{beneficiaryViews}
		</Stack>
	);
};
export default BeneficieariesInformationList;
