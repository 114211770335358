/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeRequestType,
  GetChangesResponse,
} from '../models/index';
import {
    ChangeRequestTypeFromJSON,
    ChangeRequestTypeToJSON,
    GetChangesResponseFromJSON,
    GetChangesResponseToJSON,
} from '../models/index';

export interface GetChangeRequestsGetRequest {
    type?: ChangeRequestType;
}

/**
 * 
 */
export class ChangeRequestsApi extends runtime.BaseAPI {

    /**
     * Get change requests for the current user
     */
    async getChangeRequestsGetRaw(requestParameters: GetChangeRequestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetChangesResponse>>> {
        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/changes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetChangesResponseFromJSON));
    }

    /**
     * Get change requests for the current user
     */
    async getChangeRequestsGet(requestParameters: GetChangeRequestsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetChangesResponse>> {
        const response = await this.getChangeRequestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
