import { createContext, useContext, useState } from "react";

export type ActionType =
	| "Changes"
	| "NextPayment"
	| "Payments"
	| "Report"
	| "Fond"
	| "FondBankId"
	| "TaxCertificate"
	| "EmployerContribution"
	| "Coverage"
	| "ChangeAdress"
	| "InvestmentStrategy"
	| "InvestmentStrategyBankId"
	| undefined;

export type ActionWithContextType = {
	type: ActionType;
	context?: Record<string, unknown>;
};

export interface ActionContext {
	action: ActionWithContextType | undefined;
	setAction: (action: ActionWithContextType) => void;
	clearAction: () => void;
}

const SmartActionContext = createContext<ActionContext>(undefined);

export default SmartActionContext;

export const SmartActionProvider = ({ children }): JSX.Element => {
	const [action, setAction] = useState<ActionWithContextType>(undefined);
	const clearAction = (): void => setAction(undefined);

	return (
		<SmartActionContext.Provider
			value={{
				action,
				setAction,
				clearAction,
			}}
		>
			{children}
		</SmartActionContext.Provider>
	);
};

export function useSmartAction(): ActionContext {
	return useContext(SmartActionContext);
}
