import { Paper, Stack } from "@mui/material";
import { Formik } from "formik";
import { useEffect } from "react";
import { object, string } from "yup";

import { SfdrPreference } from "../../../../models";
import cs from "../../../translations/cs.json";
import RadioInput from "../../Form/Input/Radio";
import RadioGroup from "../../Form/Input/Radio/RadioGroup";
import { HeadingL, HeadingS } from "../../Typography";

interface ISfdrQuestion {
	setSubmitter: (submitter: any) => void;
	setPreference: (preference: SfdrPreference) => void;
	setNextStep: () => void;
}

export const SfdrQuestion = ({
	setSubmitter,
	setPreference,
	setNextStep,
}: ISfdrQuestion): JSX.Element => {
	return (
		<Formik
			initialValues={{ sfdrPreference: "" as SfdrPreference }}
			initialTouched={{ sfdrPreference: true }}
			validationSchema={object({
				sfdrPreference: string()
					.test({
						name: "notYes",
						message: cs.investmentStrategy.sfdr.noSustainableFundsAvailable,
						test: (value) => {
							return value !== SfdrPreference.Yes;
						},
					})
					.required(),
			})}
			onSubmit={(values) => {
				setPreference(values.sfdrPreference);
				setNextStep();
			}}
		>
			{({ submitForm }) => {
				useEffect(() => {
					setSubmitter(() => submitForm);
				}, []);
				return (
					<Stack
						sx={{
							width: { md: "75%" },
							margin: { md: "auto" },
						}}
					>
						<HeadingL sx={{ textAlign: { md: "center" } }}>
							{cs.investmentStrategy.sfdr.sustainability}
						</HeadingL>
						<HeadingS sx={{ mb: 4 }}>
							{cs.investmentStrategy.sfdr.question}
						</HeadingS>
						<Paper sx={{ p: 4 }}>
							<Stack gap={4}>
								<RadioGroup name="sfdrPreference">
									<RadioInput
										id={"sfdrYes"}
										name="sfdrPreference"
										label={cs.investmentStrategy.sfdr.yes}
										value={SfdrPreference.Yes}
									/>
									<RadioInput
										id={"sfdrPartial"}
										name="sfdrPreference"
										label={cs.investmentStrategy.sfdr.partialYes}
										value={SfdrPreference.PartialYes}
									/>
									<RadioInput
										id={"sfdrNo"}
										name="sfdrPreference"
										label={cs.investmentStrategy.sfdr.no}
										value={SfdrPreference.No}
									/>
								</RadioGroup>
							</Stack>
						</Paper>
					</Stack>
				);
			}}
		</Formik>
	);
};
