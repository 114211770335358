import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {
	ErrorMessage,
	FieldAttributes,
	useField,
	useFormikContext,
} from "formik";
import { ChangeEvent, FC } from "react";
import NumberFormat from "react-number-format";

import FormErrorMessage from "../../FormErrorMessage";

export type onTextInputChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface NumberInputProps extends FieldAttributes<any> {
	disabled?: boolean;
	name: string;
	isRequired?: boolean;
	inputProps?: {
		inputMode?: string;
	};
	min?: number;
	max?: number;
	inputIgnoresMaxInterval?: boolean;
	hideError?: boolean;
	customOnChange?: onTextInputChange;
}

const NumberInput: FC<NumberInputProps> = ({
	disabled = false,
	name,
	label,
	isRequired = false,
	inputProps,
	min,
	max,
	hideError = false,
	inputIgnoresMaxInterval = false,
	customOnChange,
	...rest
}) => {
	const [field, { error }, { setValue }] = useField(name);
	const { isSubmitting, setFieldTouched } = useFormikContext();

	const handleBlur = () => {
		setFieldTouched(name, true);
		if (typeof min !== "undefined" && typeof max !== "undefined") {
			if (field.value < min) {
				setValue(min);
			} else if (field.value > max && !inputIgnoresMaxInterval) {
				setValue(max);
			}
		}
	};

	return (
		<Stack>
			{label && (
				<InputLabel shrink htmlFor={`${name}-name`} required={isRequired}>
					{label}
				</InputLabel>
			)}
			{/* allow only numbers in "numeric" inputs
			this can be done in the simplier way by defining type="number"
			this method however adds unwanted ugly plus/minus arrows into the input */}
			<NumberFormat
				customInput={TextField}
				className={`input ${error ? "invalid" : ""}`}
				type="text"
				inputProps={{
					inputMode: "numeric",
					...inputProps,
				}}
				required={isRequired}
				id={`${name}-name`}
				disabled={isSubmitting || disabled}
				{...rest}
				{...field}
				onBlur={handleBlur}
				onChange={(e) => {
					setValue(parseFloat(e.target.value));
					if (!e.target.value) {
						setValue(min);
					}
					if (customOnChange) {
						customOnChange(e);
					}
				}}
			/>
			{!hideError && (
				<ErrorMessage
					name={name}
					render={(msg) => (
						<FormErrorMessage name={name}>{msg}</FormErrorMessage>
					)}
				/>
			)}
		</Stack>
	);
};

export default NumberInput;
