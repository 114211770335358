const currencyFormatter = (options?: any) => {
	const context = {
		style: "currency",
		currency: "CZK",
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	};

	return new Intl.NumberFormat(
		"cs-CZ",
		options ? { ...context, ...options } : context
	);
};

const floatFormatter = new Intl.NumberFormat("cs-CZ");

export function NumberToCZK(number: number, options?: any): string {
	return currencyFormatter(options).format(number);
}

export function NumberToFloat(number: number): string {
	return floatFormatter.format(number);
}

export function NumberToCZKExtraDigits(
	number: number,
	digits?: number
): string {
	return new Intl.NumberFormat("cs-CZ", {
		style: "currency",
		currency: "CZK",
		minimumFractionDigits: digits ?? 4,
		maximumFractionDigits: digits ?? 4,
	}).format(number);
}
export function NumberExtraDigits(number: number, digits?: number): string {
	return new Intl.NumberFormat("cs-CZ", {
		minimumFractionDigits: digits ?? 4,
		maximumFractionDigits: digits ?? 4,
	}).format(number);
}

// TODO modifikovat typy podle toho, jak se rozhodne byznys
export const contractTypeToDetailURL: { [name: string]: string } = {
	UF: "doplnkove-penzijni-sporeni",
	CL: "zivotni-pojisteni",
	PF: "penzijni-pripojisteni",
	CLF: "fufi-pojisteni",
};

export function contractLink(
	id: string,
	type: string | undefined,
	tab?: string
): string {
	if (!type) return ""; //Check if type is not undefined https://github.com/gatsbyjs/gatsby/issues/16097
	const searchParams = new URLSearchParams({
		cislo: id,
	});
	if (tab) searchParams.set("main-tab", tab);
	return `/${contractTypeToDetailURL[type]}/?${searchParams.toString()}`;
}

export const blobToBase64 = (blob) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
};
