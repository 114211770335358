import { ContactAddress } from "../../../../models";
import cs from "../../../translations/cs.json";
import { Trigger } from "../../Link";
import { useDrawer } from "../context";
import ChangeAddressDrawerForm from "./form";

const ChangeAddressDrawer = ({
	contractNumber,
	title,
	disabled,
	setFormWasSend,
	refetch,
}: ContactAddress & {
	disabled: boolean;
	setFormWasSend: (val: boolean) => void;
	refetch: boolean;
}): JSX.Element => {
	const { showDrawer, setDrawerContent } = useDrawer();

	return (
		<>
			<Trigger
				sx={{
					ml: { md: "40%", sm: "0" },
				}}
				disabled={disabled}
				onClick={() => {
					showDrawer();
					setDrawerContent(
						<ChangeAddressDrawerForm
							setFormWasSend={setFormWasSend}
							refetch={refetch}
							title={title}
							contractNumber={contractNumber}
						/>
					);
				}}
			>
				{cs.changeAddress.changeContactAddress}
			</Trigger>
		</>
	);
};
export default ChangeAddressDrawer;
