import SvgIcon from "@mui/material/SvgIcon";

const Adress = (props): JSX.Element => {
	return (
		<SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
			<path d="M24.5 17.2c-.7-1.6-1.8-3.1-3.2-4.4L20 12a.5.5 0 1 0-.5.8l1 .8c1 1 2 2 2.6 3.2h-1c-1.7 0-3.3.3-4.9.9-1.5-.6-3.2-.9-4.8-.9h-.1c-1.7 0-3.4.3-4.9 1a12.3 12.3 0 0 0-5.7-1 12.2 12.2 0 0 1 10.7-6.2.5.5 0 1 0 0-1c-1.7 0-3.3.3-4.9.9a12.6 12.6 0 0 0-7 6.7v.5c.1.2.3.2.4.2h1.6a11.1 11.1 0 0 1 5 .9h.1a12 12 0 0 1 4.2-1v5.7a.5.5 0 1 0 1 0v-5.7c1.5 0 2.9.4 4.2.9h.4c2-.8 4.3-1 6.4-.8l.5-.1v-.6Z" />
			<path d="M14.7 7.9h.3l1.2-.3-1.9 5.1c0 .4 0 .7.3.9l.5.1c.2 0 .3 0 .5-.2L22 6.8l1.8-2c.3-.2.4-.6.2-1a1 1 0 0 0-.9-.6h-2.3A116.8 116.8 0 0 0 22.2.8a.5.5 0 0 0-.8-.6L20 2.3l-.7 1a.5.5 0 0 0 .4.9H23l-1.8 2-5.6 5.9a533.1 533.1 0 0 1 1.8-5.3c0-.2-.2-.3-.4-.3l-2.1.3H14v-.4l.5-1L17 .8a.5.5 0 0 0-1-.4L13.8 5l-.5 1c-.2.4-.2 1 .1 1.3.3.5.8.6 1.4.6Z" />
		</SvgIcon>
	);
};

export default Adress;
