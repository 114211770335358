import { useEffect, useState } from "react";

import { ChangeRequestType, ChangeType, RequirementStatus } from "../../models";
import { usePrimaryAPIClient } from "../context/PrimaryAPIClient";

type useHasCurrentContractsChange =
	| { data: undefined; isSuccess: false }
	| { data: boolean; isSuccess: true };

// Returns has current contract change without caching
export const useHasCurrentContactsChange = (): useHasCurrentContractsChange => {
	const [hasChange, setHasChange] = useState<boolean>();
	const { changeRequestsApi } = usePrimaryAPIClient();
	useEffect(() => {
		void (async () => {
			const data = await changeRequestsApi.getChangeRequestsGet({
				type: ChangeRequestType.Contract,
			});
			setHasChange(
				data.some(
					(change) =>
						change.changeType === ChangeType.ChangeElectronicContacts &&
						(change.status === RequirementStatus.New ||
							change.status === RequirementStatus.Waiting)
				)
			);
		})();
	}, []);

	if (hasChange === undefined) {
		return { data: undefined, isSuccess: false };
	}

	return { data: hasChange, isSuccess: true };
};
