/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DailyValue } from './DailyValue';
import {
    DailyValueFromJSON,
    DailyValueFromJSONTyped,
    DailyValueToJSON,
} from './DailyValue';

/**
 * 
 * @export
 * @interface GetFundsPerformanceResponse
 */
export interface GetFundsPerformanceResponse {
    /**
     * 
     * @type {string}
     * @memberof GetFundsPerformanceResponse
     */
    name: string;
    /**
     * 
     * @type {Array<DailyValue>}
     * @memberof GetFundsPerformanceResponse
     */
    values: Array<DailyValue>;
}

/**
 * Check if a given object implements the GetFundsPerformanceResponse interface.
 */
export function instanceOfGetFundsPerformanceResponse(value: object): value is GetFundsPerformanceResponse {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function GetFundsPerformanceResponseFromJSON(json: any): GetFundsPerformanceResponse {
    return GetFundsPerformanceResponseFromJSONTyped(json, false);
}

export function GetFundsPerformanceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFundsPerformanceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'values': ((json['values'] as Array<any>).map(DailyValueFromJSON)),
    };
}

export function GetFundsPerformanceResponseToJSON(value?: GetFundsPerformanceResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'values': ((value['values'] as Array<any>).map(DailyValueToJSON)),
    };
}

