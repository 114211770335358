import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useField } from "formik";
import { useEffect, useRef } from "react";

import cs from "../../../translations/cs.json";
import Attention from "../../AttentionBlock";
import countries from "../../Form/countries.json";
import DatePicker from "../../Form/Input/DatePicker";
import TextInput from "../../Form/Input/Text";
import { BodyTextS, HeadingL, HeadingM } from "../../Typography";

const BeneficiarInfo = (): JSX.Element => {
	const [editedBeneficiarField] = useField("editedBeneficiar");
	const editedBeneficiar = editedBeneficiarField.value;
	const [selectedCountry] = useField(
		`beneficiaries.${editedBeneficiar}.address.country`
	);

	const [
		editedAdress,
		_,
		{
			setValue: setAdressValue,
			setTouched: setAdressTouched,
			setError: setAdressError,
		},
	] = useField(`beneficiaries.${editedBeneficiar}.address`);

	const isRiskCountry = () => {
		const selectedCountryObject = countries.find(
			(country) => country.country_code === selectedCountry.value
		);
		return selectedCountryObject && selectedCountryObject.risk_country;
	};

	const riskCountryMessageRef = useRef();

	const resetAdress = () => {
		setAdressValue({});
		setAdressTouched(false);
		setAdressError(undefined);
	};

	useEffect(() => {
		if (isRiskCountry && riskCountryMessageRef.current) {
			riskCountryMessageRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [riskCountryMessageRef.current]);

	useEffect(() => {
		if (selectedCountry.value === "") {
			resetAdress();
		}
	}, [selectedCountry.value]);

	return (
		<>
			<HeadingL withoutScale data-test="beneficient-information-add-header">
				{editedBeneficiar + 1}. {cs.global.person}
			</HeadingL>
			<HeadingM withoutScale>{cs.global.personalData}</HeadingM>
			<Stack spacing={5}>
				<Paper sx={{ p: 4 }}>
					<Stack spacing={5}>
						<TextInput
							data-test="name-input-field"
							label={cs.global.name}
							name={`beneficiaries.${editedBeneficiar}.firstName`}
							isRequired={true}
						/>
						<TextInput
							data-test="surname-input-field"
							label={cs.global.surname}
							name={`beneficiaries.${editedBeneficiar}.lastName`}
							isRequired={true}
						/>
						<DatePicker
							data-test="date-input-field"
							name={`beneficiaries.${editedBeneficiar}.dateOfBirth`}
							label={cs.global.birthDate}
							isRequired={true}
							options={{ disableFuture: true }}
							minDate={new Date("1900-01-01")}
						/>
						<Stack spacing={3}>
							<TextInput
								data-test="birthdate-input-field"
								label={cs.global.birthNumber}
								name={`beneficiaries.${editedBeneficiar}.birthNumber`}
								isRequired={true}
							/>
							<BodyTextS>{cs.global.birthNumberSubLabel}</BodyTextS>
						</Stack>
					</Stack>
				</Paper>
				<Stack spacing={4}>
					<Stack>
						<HeadingM sx={{ mb: 0 }} withoutScale>
							{`${cs.global.contactAddressInCZ} (nepovinné)`}
						</HeadingM>
						<BodyTextS sx={{ mb: 0 }}>
							{cs.global.contactAddressSubLabel}
						</BodyTextS>
					</Stack>
					<Paper sx={{ p: 4 }}>
						<Stack spacing={5}>
							<TextInput
								label={cs.global.street}
								name={`beneficiaries.${editedBeneficiar}.address.street`}
								autoComplete={
									process.env.GATSBY_FEATURE_IS_FORM_AUTOCOMPLETE_ENABLED ===
									"true"
										? "off"
										: "nope" // https://stackoverflow.com/a/38961567/1377775
								}
							/>
							<TextInput
								label={cs.global.houseNumber}
								name={`beneficiaries.${editedBeneficiar}.address.houseNumber`}
								inputProps={{
									inputMode: "numeric",
								}}
								autoComplete={
									process.env.GATSBY_FEATURE_IS_FORM_AUTOCOMPLETE_ENABLED ===
									"true"
										? "off"
										: "nope"
								}
							/>
							<TextInput
								label={cs.global.city}
								name={`beneficiaries.${editedBeneficiar}.address.town`}
								autoComplete={
									process.env.GATSBY_FEATURE_IS_FORM_AUTOCOMPLETE_ENABLED ===
									"true"
										? "off"
										: "nope"
								}
							/>
							<TextInput
								label={cs.global.zip}
								name={`beneficiaries.${editedBeneficiar}.address.zip`}
								inputProps={{
									inputMode: "numeric",
								}}
								autoComplete={
									process.env.GATSBY_FEATURE_IS_FORM_AUTOCOMPLETE_ENABLED ===
									"true"
										? "off"
										: "nope"
								}
							/>

							{isRiskCountry() && (
								<Box ref={riskCountryMessageRef}>
									<Attention severity="info">
										Vámi doplněná kontaktní adresa je v tzv.{" "}
										<a
											href="https://www.nn.cz/files/archiv/cz-nn/seznam_rizikovych_zemi.pdf"
											target="_blank"
											rel="noreferrer"
										>
											rizikové zemi
										</a>
										. Na základě platných předpisů vás musíme upozornit, že
										pokud by došlo k plnění smlouvy, nemůžeme Vám peníze
										vyplatit. Důvodem je, že rizikové země nedostatečně (nebo
										vůbec) neuplatňují opatření proti legalizaci výnosů z
										trestné činnosti a financování terorismu.
									</Attention>
								</Box>
							)}
						</Stack>
					</Paper>
				</Stack>
			</Stack>
		</>
	);
};

export default BeneficiarInfo;
