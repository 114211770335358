/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendAuthenticationSMSRequest
 */
export interface SendAuthenticationSMSRequest {
    /**
     * 
     * @type {string}
     * @memberof SendAuthenticationSMSRequest
     */
    phoneNumber: string;
}

/**
 * Check if a given object implements the SendAuthenticationSMSRequest interface.
 */
export function instanceOfSendAuthenticationSMSRequest(value: object): value is SendAuthenticationSMSRequest {
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    return true;
}

export function SendAuthenticationSMSRequestFromJSON(json: any): SendAuthenticationSMSRequest {
    return SendAuthenticationSMSRequestFromJSONTyped(json, false);
}

export function SendAuthenticationSMSRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendAuthenticationSMSRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'phoneNumber': json['phoneNumber'],
    };
}

export function SendAuthenticationSMSRequestToJSON(value?: SendAuthenticationSMSRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'phoneNumber': value['phoneNumber'],
    };
}

