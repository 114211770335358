import { useSmartAction } from "../Contract/SmartAction/context";
import { useDrawer } from "./context";
import Modal from "./Modal";

const Drawer = (): JSX.Element => {
	const { drawerContent, openDrawer } = useDrawer();

	const { clearAction } = useSmartAction();

	return (
		<Modal
			open={openDrawer}
			onClose={() => {
				clearAction();
			}}
		>
			{drawerContent}
		</Modal>
	);
};

export default Drawer;
