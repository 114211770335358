import { createContext, useContext, useMemo } from "react";

import { useUserIdentifier } from "../hooks/useUserIdentifier";
import {
	getQueryKeyFactory,
	QueryKeyFactory,
} from "../queryHooks/query-key-factory";

export interface IQueryKeyFactoryContext {
	createQueryKey: QueryKeyFactory;
}

const QueryKeyFactoryContext = createContext({} as IQueryKeyFactoryContext);

interface IProps {
	children: JSX.Element;
}

export const QueryKeyFactoryProvider = ({ children }: IProps) => {
	const userIdentifier = useUserIdentifier();
	const contextValue: IQueryKeyFactoryContext = useMemo(() => {
		return { createQueryKey: getQueryKeyFactory(userIdentifier) };
	}, [userIdentifier]);

	return (
		<QueryKeyFactoryContext.Provider value={contextValue}>
			{children}
		</QueryKeyFactoryContext.Provider>
	);
};

export const useQueryKeyFactory = () => {
	return useContext(QueryKeyFactoryContext);
};
