/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EncryptUserIdResponse
 */
export interface EncryptUserIdResponse {
    /**
     * 
     * @type {string}
     * @memberof EncryptUserIdResponse
     */
    cipher: string;
    /**
     * 
     * @type {string}
     * @memberof EncryptUserIdResponse
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof EncryptUserIdResponse
     */
    cryptographicNonce: string;
}

/**
 * Check if a given object implements the EncryptUserIdResponse interface.
 */
export function instanceOfEncryptUserIdResponse(value: object): value is EncryptUserIdResponse {
    if (!('cipher' in value) || value['cipher'] === undefined) return false;
    if (!('tag' in value) || value['tag'] === undefined) return false;
    if (!('cryptographicNonce' in value) || value['cryptographicNonce'] === undefined) return false;
    return true;
}

export function EncryptUserIdResponseFromJSON(json: any): EncryptUserIdResponse {
    return EncryptUserIdResponseFromJSONTyped(json, false);
}

export function EncryptUserIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EncryptUserIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'cipher': json['cipher'],
        'tag': json['tag'],
        'cryptographicNonce': json['cryptographicNonce'],
    };
}

export function EncryptUserIdResponseToJSON(value?: EncryptUserIdResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cipher': value['cipher'],
        'tag': value['tag'],
        'cryptographicNonce': value['cryptographicNonce'],
    };
}

