import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { Formik } from "formik";

import { ConsentType } from "../../../../models";
import Form from "../../../components/Form/Form";
import RadioInput from "../../../components/Form/Input/Radio";
import RadioGroup from "../../../components/Form/Input/Radio/RadioGroup";
import { agreement } from "../../../components/Form/rules";
import { SEO } from "../../../components/SEO";
import { BodyTextM } from "../../../components/Typography";
import withProtection from "../../../highOrderComponents/withProtection";
import { useConsents, usePutConsents } from "../../../queryHooks/consents";
import cs from "../../../translations/cs.json";

export const Title = () => cs.profile.marketing.header;

const Info = (): JSX.Element => {
	const { data: marketingEnabled, isSuccess } = useConsents<
		boolean | undefined
	>({
		options: {
			select: (data) => {
				return data.find((consent) => consent.type === ConsentType.Marketing)
					?.consent;
			},
		},
	});
	const { mutateAsync } = usePutConsents();

	return (
		<>
			<BodyTextM>{cs.profile.marketing.content}</BodyTextM>
			<Paper sx={{ p: 4 }}>
				{isSuccess ? (
					<Formik
						initialValues={{ agreement: marketingEnabled?.toString() }}
						validationSchema={agreement}
						onSubmit={async ({ agreement }) => {
							await mutateAsync({
								setConsentRequest: {
									consentType: ConsentType.Marketing,
									consent: agreement === "true",
								},
							});
						}}
					>
						<Form>
							<RadioGroup
								defaultValue={marketingEnabled?.toString()}
								name="agreement"
							>
								<RadioInput
									name="agreement"
									value="true"
									label="Souhlasím"
									id="agree"
								/>
								<RadioInput
									name="agreement"
									value="false"
									label="Nesouhlasím"
									id="disagree"
								/>
							</RadioGroup>
						</Form>
					</Formik>
				) : (
					<Skeleton variant="rounded" height={162} />
				)}
			</Paper>
			<BodyTextM>{cs.profile.marketing.content2}</BodyTextM>
		</>
	);
};

export default withProtection(Info);

export const Head = (): JSX.Element => (
	<SEO title="Souhlas">
		<meta name="robots" content="noindex" />
	</SEO>
);
