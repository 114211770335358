import { ReactNode, useEffect } from "react";

import { useContractId } from "../../context/ContractIDContext";
import useIsDesktop from "../../desktop/useIsDesktop";
import { BackNavigationBar } from "../AppBar";
import { useLayout } from "./context";

export interface LayoutProps {
	children: ReactNode;
	title: ReactNode;
	subtitle?: ReactNode;
}

const BackButtonLayout = ({
	children,
	title,
	subtitle,
}: LayoutProps): JSX.Element => {
	const { setSecondaryAppBar } = useLayout();
	const id = useContractId();
	const isD = useIsDesktop();

	useEffect(() => {
		setSecondaryAppBar(
			<BackNavigationBar id={id} title={title} subtitle={subtitle} />
		);
	}, [isD]);

	return <>{children}</>;
};
export default BackButtonLayout;
