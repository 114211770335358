import Stack from "@mui/material/Stack";
import { ReactNode } from "react";

export interface ModalFooterProps {
	children: ReactNode;
}

const ModalFooter = ({ children }: ModalFooterProps): JSX.Element => {
	return (
		<Stack
			sx={{
				zIndex: 1300,
				bottom: 0,
				width: "100%",
				p: 4,
				boxShadow: "extraFar",
				backgroundColor: "white.main",
			}}
		>
			{children}
		</Stack>
	);
};

export default ModalFooter;
