import { sub } from "date-fns";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import { object, ref } from "yup";
import * as Yup from "yup";

import { GetFundsPerformanceResponse } from "../../../../models";
import theme from "../../../gatsby-theme-material-ui-top-layout/theme";
import cs from "../../../translations/cs.json";

export type fundsType = GetFundsPerformanceResponse[];

export type fundFormattedType = {
	name: string;
	label: string;
	palette: { main: string; contrastText: string };
	data: { date: Date; value: number }[];
};

export type fundsFormattedType = fundFormattedType[];

export type fundsChecks = { [key: string]: boolean };

export type fundsFilterType = {
	dateTo: Date;
	dateFrom: Date;
	funds: fundsChecks;
};

export const initialValues = (funds: fundsFormattedType): fundsFilterType => ({
	dateTo: new Date(),
	dateFrom: sub(new Date(), { years: 1 }),
	funds: funds.reduce((obj, item: { name: string }) => {
		return {
			...obj,
			[item.name]: true,
		};
	}, {}),
});

const today = new Date();
today.setHours(23, 59, 59);

export const validationSchema = object().shape({
	dateFrom: Yup.date()
		.typeError(cs.errorMessages.invalidDateFormat)
		.min("1993-01-01", cs.errorMessages.cantFilterTooDeepIntoPast)
		.max(today, cs.errorMessages.cantFilterFuture),
	dateTo: Yup.date()
		.min(ref("dateFrom"), cs.errorMessages.toDateLaterThanFromDate)
		.typeError(cs.errorMessages.invalidDateFormat)
		.max(today, cs.errorMessages.fondFuture),
	validRange: Yup.date().test(
		"is-dates-range-valid",
		cs.errorMessages.dateRange,
		function () {
			const startDate = this.parent.dateFrom;
			const endDate = this.parent.dateTo;
			const differenceInMonths = differenceInCalendarMonths(endDate, startDate);
			return differenceInMonths >= 3;
		}
	),
	funds: object().test(
		"isAnySwitchOn",
		cs.errorMessages.chooseSomeFund,
		(funds) => {
			return !Object.values(funds).every((item) => item === false); // chech if at least one switch is on
		}
	),
});

export const fundsSort = (
	funds: fundsType = [{ name: "", values: [] }]
): fundsType => {
	return funds
		.sort(function (a, b) {
			// move "Vyvážený" to the top
			return +!b.name.localeCompare("Vyvážený");
		})
		.sort(function (a, b) {
			// move "Růstový" to the top
			return +!b.name.localeCompare("Růstový");
		});
};

export const fundsFormat = (
	funds: fundsType = [{ name: "", values: [] }]
): fundsFormattedType => {
	return funds.map(
		({ name = "Neznámý fond", values = [], ...rest }, index) => ({
			name,
			label: name,
			palette: theme.palette.dataVisualisation.quantitative[index + 1],
			data: values
				.filter(({ day, value }) => {
					if (day && value) {
						return true;
					} else {
						console.warn(
							`Fund [${name}] with index [${index}] has some empty values ${{
								day,
								value,
							}}`
						);
						return false;
					}
				})
				.map(({ day, value }) => ({
					value,
					date: day,
				})),
			...rest,
		})
	);
};

export const fundsFilter = (
	completeFunds: fundsFormattedType = [
		{ name: "", data: [], label: "", palette: { main: "", contrastText: "" } },
	],
	formValues: fundsFilterType
): fundsFormattedType => {
	const { funds, dateFrom, dateTo } = formValues;
	const checkedFunds = Object.entries(funds)
		.filter(([_, value]) => value === true)
		.map(([key]) => key);
	return (
		completeFunds
			// Filtruji ty, ktere jsou checknute jako ze chci videt (by default vse)
			.filter((fund) => checkedFunds.includes(fund.name))
			.map((fund) => ({
				...fund,
				// vyfiltruji data mimo vybrany casovy rozsah (by default rok zpet od ted)
				data: fund.data.filter(
					(row) => row.date > dateFrom && row.date < dateTo
				),
			}))
	);
};
