import { Stack } from "@mui/material";

import { GetPensionTransactionsResponse } from "../../../../models";
import { TransactionSkeleton } from "../../Transactions/SingleTransactionRow";
import TransactionsContainer from "../../Transactions/TransactionsContainer";

type TransactionsListProps = {
	data: GetPensionTransactionsResponse[];
	loading: boolean;
	skeletonsCount: number;
};

interface TransactionsListSkeletonProps {
	skeletonsCount: number;
}

export function TransactionsListSkeleton({
	skeletonsCount,
}: TransactionsListSkeletonProps): JSX.Element {
	return (
		<Stack spacing={4} py={3}>
			{[...Array(skeletonsCount).keys()].map((_, index) => (
				<TransactionSkeleton key={index} />
			))}
		</Stack>
	);
}

const TransactionsList = ({
	fetching,
	isLoading,
	data,
}: TransactionsListProps): JSX.Element => {
	return (
		<>
			{!isLoading && (
				<TransactionsContainer isIcon={true} transactions={data} />
			)}
			{fetching === "fetching" && (
				<TransactionsListSkeleton skeletonsCount={6} />
			)}
		</>
	);
};

export default TransactionsList;
