import { Box, Card, CardContent, Stack } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";

import { LoggedOutBar } from "../../components/AppBar";
import { InternalButtonLink } from "../../components/Link";
import Overview from "../../components/Overview";
import { SEO } from "../../components/SEO";
import { BodyTextM, HeadingL } from "../../components/Typography";
interface imageProps {
	intro_image: {
		publicURL: string;
	};
}

const introImage = (): imageProps => {
	return useStaticQuery<Queries.RozcestnikImagesQuery>(
		graphql`
			query RozcestnikImages {
				intro_image: file(relativePath: { eq: "rozcestnik.png" }) {
					publicURL
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		`
	);
};

export const AppBar = (): JSX.Element => <LoggedOutBar />;

function RozcestnikPage({ pageContext }): JSX.Element {
	return (
		<>
			<Overview
				image={introImage().intro_image.publicURL}
				alt="Přihlášení"
				sx={{
					overflow: { md: "visible", xxs: "hidden" },
					mb: { md: 0, xxs: -4 },
					mx: { md: 0, xxs: -4 },
					p: { md: 0 },
					pt: { md: "72px" },
					".MuiCardContent-root": {
						boxShadow: (theme) => theme.customShadows.close,
						backgroundColor: "white.main",
					},
					".MuiCardMedia-root": {
						display: { md: "none" },
						backgroundPositionY: "2px",
					},
				}}
			>
				<Box sx={{ px: 3 }}>
					<HeadingL>Přihlaste se ke svému účtu z pohodlí domova</HeadingL>
					<BodyTextM component="p" marginY={6}>
						Pro správné směřování do klientského portálu si vyberte produkt, na
						který se chcete podívat.
					</BodyTextM>
					<Stack direction="row" justifyContent="space-between" spacing={5}>
						<InternalButtonLink
							to="/prihlaseni-nebo-registrace"
							variant="outlined"
							sx={{ width: "50%", py: "11px" }}
						>
							<BodyTextM>Penzijko</BodyTextM>
						</InternalButtonLink>
						<InternalButtonLink
							to="/rozcestnik-zivotko/"
							variant="outlined"
							sx={{ width: "50%", py: "11px" }}
						>
							<BodyTextM>Životko</BodyTextM>
						</InternalButtonLink>
					</Stack>
				</Box>
			</Overview>
			<Card>
				<CardContent>
					<BodyTextM>
						Pokud se Vám přihlášení nepovede, prostě nám zavolejte na číslo 244
						090 800 a naši operátoři Vám pomůžou. Jsme tu pro vás!
					</BodyTextM>
				</CardContent>
			</Card>
		</>
	);
}

export default RozcestnikPage;

export const Head = (): JSX.Element => (
	<SEO
		description="Vyberte si svůj produkt, na který se chcete podívat."
		title="Rozcestník"
	/>
);
