import SvgIcon from "@mui/material/SvgIcon";

const Zoom = (props): JSX.Element => {
	return (
		<SvgIcon width="18" height="22" viewBox="0 0 17 22" fill="none" {...props}>
			<path
				fill="#414141"
				d="M11.144 3.986a5.633 5.633 0 0 0-2.363-1.21.5.5 0 0 0-.233.973c.72.172 1.375.508 1.949.999a4.854 4.854 0 0 1 1.386 1.992.5.5 0 1 0 .937-.347 5.852 5.852 0 0 0-1.676-2.407Z"
			/>
			<path
				fill="#414141"
				d="m17.353 18.135-3.77-4.973.01-.01a7.842 7.842 0 0 0 1.277-7.677 7.719 7.719 0 0 0-2.212-3.176l-.003-.002A7.409 7.409 0 0 0 7.157.53a7.457 7.457 0 0 0-5.144 2.725C.262 5.384-.227 8.326.736 10.93a7.698 7.698 0 0 0 2.207 3.171 7.41 7.41 0 0 0 5.52 1.777 7.414 7.414 0 0 0 4.421-1.983l3.668 4.838c.178.24.251.537.207.836a1.1 1.1 0 0 1-.435.728 1.044 1.044 0 0 1-1.468-.229L12.292 16.6a.5.5 0 1 0-.805.594l2.564 3.47a2.062 2.062 0 0 0 1.659.834c.42 0 .843-.128 1.206-.396.452-.332.747-.823.83-1.383a2.113 2.113 0 0 0-.393-1.583Zm-4.533-5.619a6.46 6.46 0 0 1-4.446 2.366 6.422 6.422 0 0 1-4.78-1.54 6.705 6.705 0 0 1-1.92-2.759 6.841 6.841 0 0 1 1.111-6.695A6.464 6.464 0 0 1 7.816 1.5c1.535 0 3.007.544 4.195 1.561l.004.004a6.718 6.718 0 0 1 1.917 2.756 6.837 6.837 0 0 1-1.112 6.695Z"
			/>
		</SvgIcon>
	);
};

export default Zoom;
