import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { createContext, ReactNode, useContext, useState } from "react";

import { useSmartAction } from "../Contract/SmartAction/context";
import { MultiStepChildren } from "./MultiStepDrawer";

export type stepFunc = (element: number) => void;

export interface IDrawerContext {
	drawerContent: ReactNode;
	setDrawerContent: (element: ReactNode) => void;
	openDrawer: boolean;
	step: number;
	setStep: stepFunc;
	previousStep: () => void;
	nextStep: () => void;
	hideDrawer: () => void;
	hideMultistepDrawer: () => void;
	showDrawer: () => void;
	navigateByName: () => void;
	setSteps: (steps: number[] | MultiStepChildren[]) => void;
	steps: ReactNode;
	prevStep: number;
	stepNames: string[];
	drawerIsOpen: boolean;
}

const DrawerContext = createContext<IDrawerContext>(undefined);

export default DrawerContext;

export interface DrawerProps {
	children: ReactNode;
}

export const DrawerProvider = ({ children }: DrawerProps): JSX.Element => {
	const [drawerContent, setDrawerContent] = useState<ReactNode>();
	const [openDrawer, setOpenDrawer] = useState(false);
	const [steps, setSteps] = useState([]);
	const [prevSteps, setPrevSteps] = useState([0]);
	const { clearAction } = useSmartAction();
	const [step, setCurrentStep] = useState(0);

	const stepNames = steps.map((step) => step.stepName);

	const prevStep = prevSteps[prevSteps.length - 1];

	const location = useLocation();
	function findObjectIndexByStepName(array, stepName) {
		const stepNames = array.map((obj) => obj.stepName);
		return stepNames.indexOf(stepName);
	}

	const navigateByName = (steps, stepName: string) => {
		const index = findObjectIndexByStepName(steps, stepName);
		const prevStepsArrayCopy = prevSteps;
		prevStepsArrayCopy.push(index);
		setPrevSteps(prevStepsArrayCopy);
		setCurrentStep(index);
		navigate(location.pathname + location.search + `#${stepNames[index]}`);
	};

	return (
		<DrawerContext.Provider
			value={{
				drawerContent,
				setDrawerContent,
				openDrawer,
				step,
				stepNames,
				setStep(nextStep) {
					const prevStepsArrayCopy = prevSteps;
					prevStepsArrayCopy.push(nextStep);
					setPrevSteps(prevStepsArrayCopy);
					setCurrentStep(nextStep);
					navigate(
						location.pathname + location.search + `#${stepNames[nextStep]}`
					);
				},
				previousStep() {
					let prevStep;
					if (prevSteps.length !== 1) {
						prevSteps.pop();
						prevStep = prevSteps[prevSteps.length - 1];
					} else {
						prevStep = 0;
					}
					setCurrentStep(prevStep);
					navigate(
						location.pathname + location.search + `#${stepNames[prevStep]}`
					);
				},
				prevStep,
				nextStep() {
					const newPrevStepsArray = prevSteps;
					newPrevStepsArray.push(step + 1);
					setPrevSteps(newPrevStepsArray);
					setCurrentStep((step) => step + 1);
					navigate(
						location.pathname + location.search + `#${stepNames[step + 1]}`
					);
				},
				hideDrawer() {
					setOpenDrawer(false);
				},
				hideMultistepDrawer() {
					const previousScrollY = window.scrollY; // Get current scroll position
					setOpenDrawer(false);
					window.location.hash = "";
					clearAction();
					window.scrollTo(0, previousScrollY);
				},
				showDrawer() {
					setCurrentStep(0); // make sure to open every modal on the first step
					setPrevSteps([0]);
					setOpenDrawer(true);
				},
				setSteps,
				steps,
				navigateByName,
				drawerIsOpen: openDrawer,
			}}
		>
			{children}
		</DrawerContext.Provider>
	);
};

export function useDrawer(): IDrawerContext {
	return useContext(DrawerContext);
}
