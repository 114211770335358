import Box from "@mui/material/Box";

import image from "../../../images/server.jpg";
import cs from "../../../translations/cs.json";
import { BodyTextM, HeadingL } from "../../Typography";

export interface IChangeRequestError {
	heading?: string;
	description?: string;
}

function ChangeRequestError({
	heading = cs.global.failedChangeRequest,
	description = cs.global.failedChangeRequestDescription,
}: IChangeRequestError): JSX.Element {
	return (
		<>
			<Box mb={5} sx={{ textAlign: "center" }}>
				<img src={image} alt="error" width={211} />
			</Box>
			<HeadingL>{heading}</HeadingL>
			<BodyTextM component="p">{description}</BodyTextM>
		</>
	);
}

export default ChangeRequestError;
