import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useField } from "formik";
import { ErrorMessage } from "formik";
import { forwardRef, useImperativeHandle, useRef } from "react";

import { usePensionDetail } from "../../../../queryHooks";
import cs from "../../../../translations/cs.json";
import { ContractType } from "../../../../types/contracts";
import Attention from "../../../AttentionBlock";
import FormErrorMessage from "../../../Form/FormErrorMessage";
import Switch from "../../../Form/Input/Switch";
import Slider from "../../../Form/Slider";
import { BodyTextM, HeadingL } from "../../../Typography";
import {
	possibleTaxSavingFormulaAfterNovel,
	yearlyStateContributionFormulaAfterNovel,
} from "../../formulas";

interface ISetNewAmount {
	currentAmount?: number;
	id: string;
	contractType: ContractType;
}

export type SetNewAmmountHandle = {
	scrollToEmployerContribution: () => void;
};

const SetNewAmount = forwardRef<SetNewAmmountHandle, ISetNewAmount>(
	function SetNewAmount(
		{ currentAmount, id, contractType },
		setNewAmmountHandleRef
	): JSX.Element {
		const employerContributionRef = useRef<HTMLDivElement>(null);
		const [field] = useField("monthlyContribution");
		const monthlyContribution = field.value;
		const possibleTaxSaving =
			possibleTaxSavingFormulaAfterNovel(monthlyContribution);
		const yearlyStateContribution =
			yearlyStateContributionFormulaAfterNovel(monthlyContribution);

		const { data } = usePensionDetail({ id, type: contractType });

		useImperativeHandle(
			setNewAmmountHandleRef,
			() => ({
				scrollToEmployerContribution() {
					employerContributionRef.current?.scrollIntoView();
				},
			}),
			[]
		);

		return (
			<Stack sx={{ gap: 5 }}>
				<HeadingL withoutScale mb={0}>
					{cs.employerContributionDialog.monthlyContributionChange}
				</HeadingL>
				<Stack gap={3}>
					<Stack direction="row" justifyContent="space-between">
						<BodyTextM>{cs.employerContributionDialog.currentAmount}</BodyTextM>
						<BodyTextM strong>{currentAmount} Kč</BodyTextM>
					</Stack>
					<Paper sx={{ p: 4 }}>
						<Slider
							name="monthlyContribution"
							minLabel="100 Kč"
							maxLabel="10 000 Kč"
							min={100}
							max={10000}
							step={100}
							defaultValue={100}
							label={cs.employerContributionDialog.newAmount}
							decimalScale={0}
							showSwitch={false}
							inputIgnoresMaxInterval={true}
							allowNegative={false}
						/>
					</Paper>
				</Stack>
				<Paper sx={{ py: 5, px: 4 }}>
					<Stack justifyContent="space-between" direction="row">
						<BodyTextM>
							{cs.employerContributionDialog.governmentContribution}
						</BodyTextM>
						<HeadingL data-test="state-contribution-ammount">
							{yearlyStateContribution} Kč
						</HeadingL>
					</Stack>
					<Stack justifyContent="space-between" direction="row">
						<BodyTextM>
							{cs.employerContributionDialog.possibleTaxSaving}
						</BodyTextM>
						<HeadingL sx={{ mb: 0 }} data-test="tax-saving-ammount">
							{possibleTaxSaving} Kč
						</HeadingL>
					</Stack>
				</Paper>
				<HeadingL withoutScale mb={0}>
					{cs.employerContributionDialog.employerChange}
				</HeadingL>
				<Stack ref={employerContributionRef} id="employer-contribution" gap={3}>
					<Stack direction="row" justifyContent="space-between">
						<BodyTextM>
							{cs.employerContributeDialog.currentValue}{" "}
							<BodyTextM strong>
								{data?.contract.doesEmployerContribute
									? cs.employerContributeDialog.contribute
									: cs.employerContributeDialog.dontContribute}
								.
							</BodyTextM>
						</BodyTextM>
					</Stack>
					<Paper sx={{ py: 5, px: 4 }}>
						<Switch
							name="doesEmployerContribute"
							label="Přispívá mi i zaměstnavatel"
							data-test="pension-employer-contribution-switch"
						/>
					</Paper>
				</Stack>
				<Attention
					severity="warning"
					data-test="pension-employer-contribution-info"
				>
					{cs.employerContributionDialog.legalInfo}
				</Attention>
				<ErrorMessage
					name="valuesChanged"
					render={(msg) => (
						<FormErrorMessage name="valuesChanged">{msg}</FormErrorMessage>
					)}
				/>
			</Stack>
		);
	}
);

export default SetNewAmount;
