import { indicateStarPensionEmptyAddress } from "../../AddressBox/AddressTemplate";

export const cleanAddress = (address) => {
	const cleanedAddress = Object.keys(address)
		.filter((item) => address[item])
		.reduce((obj, key) => {
			obj[key] = address[key];
			return obj;
		}, {});
	return cleanedAddress;
};

export const beneficiariesInitialValues = (beneficiaries) =>
	beneficiaries.map(
		({
			firstName,
			lastName,
			dateOfBirth,
			birthNumber,
			id,
			relationship,
			address,
		}) => {
			return {
				firstName,
				lastName,
				dateOfBirth,
				birthNumber,
				id,
				relationship,
				address: {
					...(address && address),
					zip:
						address?.zip && !indicateStarPensionEmptyAddress(address)
							? address.zip
							: "",
				},
				existsOnBE: true,
			};
		}
	);

export const ratiosInitialValues = (beneficiaries) =>
	beneficiaries.map(({ ratio }) => {
		return {
			ratio,
		};
	});
