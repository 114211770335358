/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SfdrPreference = {
    Yes: 'Yes',
    PartialYes: 'PartialYes',
    No: 'No'
} as const;
export type SfdrPreference = typeof SfdrPreference[keyof typeof SfdrPreference];


export function instanceOfSfdrPreference(value: any): boolean {
    for (const key in SfdrPreference) {
        if (Object.prototype.hasOwnProperty.call(SfdrPreference, key)) {
            if ((SfdrPreference as Record<string, SfdrPreference>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SfdrPreferenceFromJSON(json: any): SfdrPreference {
    return SfdrPreferenceFromJSONTyped(json, false);
}

export function SfdrPreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SfdrPreference {
    return json as SfdrPreference;
}

export function SfdrPreferenceToJSON(value?: SfdrPreference | null): any {
    return value as any;
}

