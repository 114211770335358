/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NdpAddress
 */
export interface NdpAddress {
    /**
     * 
     * @type {number}
     * @memberof NdpAddress
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    countryA3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    countryA2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    addressTypeCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    addressTypeDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NdpAddress
     */
    sourceSystem?: string | null;
}

/**
 * Check if a given object implements the NdpAddress interface.
 */
export function instanceOfNdpAddress(value: object): value is NdpAddress {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function NdpAddressFromJSON(json: any): NdpAddress {
    return NdpAddressFromJSONTyped(json, false);
}

export function NdpAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): NdpAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'street': json['street'] == null ? undefined : json['street'],
        'houseNumber': json['houseNumber'] == null ? undefined : json['houseNumber'],
        'city': json['city'] == null ? undefined : json['city'],
        'countryA3': json['countryA3'] == null ? undefined : json['countryA3'],
        'countryA2': json['countryA2'] == null ? undefined : json['countryA2'],
        'country': json['country'] == null ? undefined : json['country'],
        'zipCode': json['zipCode'] == null ? undefined : json['zipCode'],
        'addressTypeCode': json['addressTypeCode'] == null ? undefined : json['addressTypeCode'],
        'addressTypeDescription': json['addressTypeDescription'] == null ? undefined : json['addressTypeDescription'],
        'sourceSystem': json['sourceSystem'] == null ? undefined : json['sourceSystem'],
    };
}

export function NdpAddressToJSON(value?: NdpAddress | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'street': value['street'],
        'houseNumber': value['houseNumber'],
        'city': value['city'],
        'countryA3': value['countryA3'],
        'countryA2': value['countryA2'],
        'country': value['country'],
        'zipCode': value['zipCode'],
        'addressTypeCode': value['addressTypeCode'],
        'addressTypeDescription': value['addressTypeDescription'],
        'sourceSystem': value['sourceSystem'],
    };
}

