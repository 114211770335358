import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import { useState } from "react";
import { bool, object } from "yup";

import {
	GetDPSTransactionsGetRequest,
	GetPensionTransactionsResponse,
	GetPPTransactionsGetRequest,
} from "../../../../apis";
import { PensionTransactionEnum } from "../../../../models";
import { useContractId } from "../../../context/ContractIDContext";
import { useContractTypeContext } from "../../../context/ContractTypeContext";
import { useTransactions } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import Attention from "../../AttentionBlock";
import FilterTransactionsDrawer from "../../Drawer/FilterTransactionsDrawer";
import Modal from "../../Drawer/Modal";
import { dateRangeFrom1990 } from "../../Form/rules";
import Trigger from "../../Link/Trigger";
import { HeadingL } from "../../Typography";
import LoadMoreButton from "./LoadMoreButton";
import TransactionsList from "./TransactionsList";

const initialValues = {
	dateFrom: "",
	dateTo: "",
	employer: true,
	client: true,
	state: true,
	other: false,
};

const validationSchema = object().shape({
	...dateRangeFrom1990,
	employer: bool(),
	client: bool(),
	state: bool(),
	other: bool(),
	isAnySelected: bool().test("isAnySelected", "error", function () {
		return (
			this.parent.employer ||
			this.parent.client ||
			this.parent.state ||
			this.parent.other
		);
	}),
});

interface TransactionsSubComponentProps {
	isDrawerOpen: boolean;
	setIsDrawerOpen: (isDrawerOpen: boolean) => void;
}

function TransactionsSubComponent({
	isDrawerOpen,
	setIsDrawerOpen,
}: TransactionsSubComponentProps): JSX.Element {
	const id = useContractId();

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const { contractType } = useContractTypeContext();
	const filterEnum = PensionTransactionEnum;
	const initialParams:
		| GetDPSTransactionsGetRequest
		| GetPPTransactionsGetRequest = {
		contractId: id,
		count: 6,
		typeFilter: [
			filterEnum.ContribEmployer,
			filterEnum.ContribState,
			filterEnum.ContribClient,
		],
	};

	const [params, setParams] = useState<
		GetDPSTransactionsGetRequest | GetPPTransactionsGetRequest
	>(initialParams);

	const [keepPreviousData, setKeepPreviousData] = useState(true);

	const result = useTransactions({
		type: contractType,
		params: params,
		options: {
			refetchOnWindowFocus: false,
			keepPreviousData,
			enabled: !!id,
		},
	});

	const { isLoading, fetchStatus } = result;

	const getMoreTransactions = () => {
		const newCount = (params.count as number) + 6;
		const newParams = { ...params, count: newCount };
		setKeepPreviousData(true);
		setParams(newParams);
	};

	const setFilter = (
		options: Omit<
			GetDPSTransactionsGetRequest | GetPPTransactionsGetRequest,
			"contractId"
		>
	) => {
		setParams({
			contractId: params.contractId,
			count: 6,
			...options,
		});
	};

	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<HeadingL withoutScale>Pohyby na účtu</HeadingL>
				<Trigger
					onClick={() => setIsDrawerOpen((isDrawerOpen) => !isDrawerOpen)}
					disabled={isLoading || fetchStatus === "fetching"}
				>
					Filtrovat
				</Trigger>
			</Stack>
			{result.isSuccess && (
				<Attention severity="info" variant="transparent">
					{cs.global.paymentProcessingInformation}
				</Attention>
			)}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onReset={() => setParams(initialParams)}
				onSubmit={(values) => {
					setIsDrawerOpen(false);
					setKeepPreviousData(false);
					const typeFilter: PensionTransactionEnum[] = [];
					if (values.employer)
						typeFilter.push(PensionTransactionEnum.ContribEmployer);
					if (values.state)
						typeFilter.push(PensionTransactionEnum.ContribState);
					if (values.client)
						typeFilter.push(PensionTransactionEnum.ContribClient);
					if (values.other) typeFilter.push(PensionTransactionEnum.Other);
					const options = {
						typeFilter: typeFilter.length > 0 ? typeFilter : null,
					};

					if (values.dateFrom) options["from"] = values.dateFrom;
					if (values.dateTo) options["to"] = values.dateTo;

					setFilter(options);
				}}
			>
				{({ setSubmitting, isSubmitting }) => {
					if (result.isLoading && !isSubmitting) {
						setSubmitting(true);
					}

					if (result.isSuccess && isSubmitting) {
						setSubmitting(false);
					}

					return (
						<>
							<Modal
								open={isDrawerOpen}
								onClose={() => {
									setIsDrawerOpen(false);
								}}
								onOpen={() => {
									setIsDrawerOpen(true);
								}}
							>
								<FilterTransactionsDrawer
									handleHideDrawer={() => setIsDrawerOpen(false)}
								/>
							</Modal>
							<Paper elevation={isDesktop ? 4 : 0} sx={{ p: { md: 5 } }}>
								<TransactionsList
									isLoading={result.isLoading}
									fetching={result.fetchStatus}
									data={result.data as GetPensionTransactionsResponse[]}
								/>
								{!result.isLoading && result.fetchStatus !== "fetching" && (
									<Box mt={3}>
										<LoadMoreButton
											loading={result.isLoading}
											fetching={result.fetchStatus}
											getMoreTransactions={getMoreTransactions}
										/>
									</Box>
								)}
							</Paper>
						</>
					);
				}}
			</Formik>
		</>
	);
}

export default TransactionsSubComponent;
