import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Paper, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { Button } from "gatsby-theme-material-ui";

import { PensionPhaseStatus, ProductType } from "../../../models";
import { contractLink } from "../../functions";
import { useContractById } from "../../queryHooks";
import cs from "../../translations/cs.json";
import { ContractType } from "../../types/contracts";
import Attention from "../AttentionBlock";
import { TransactionsListSkeleton } from "../DPS/Transactions/TransactionsList";
import { DebtCtaPayById } from "../Link/CTA/PayById";
import ContractCard, { ContractCardSkeletonHeader } from "./ContractCard";
import { ContractHeaderWithFufiData } from "./ContractCard/ContractCardHeader";
import SmartActionsById, { IAttention, SmartAction } from "./SmartAction";
import TransactionsById, { ITransaction } from "./Transaction";
export interface ContractDTO {
	title: string;
	id: string;
	isLoading: boolean;
	type: ContractType;
	description: string;
	smartActions: [SmartAction];
	attentions: [IAttention];
	transactions: [ITransaction];
}

export interface IContract {
	contractId: string;
	type: ProductType;
	isLodaing: boolean;
}

function Contract({
	id,
	type,
	isLoading,
}:
	| Pick<ContractDTO, "id" | "type" | "isLoading">
	| Record<string, never>): JSX.Element {
	const isLife = type === ProductType.Cl;
	const isFufi = type === ProductType.Clf;

	const isDesktop = useIsDesktop();
	const { isLoading: isLoadingContract, data: contract } = useContractById({
		id: id,
	});

	const getTransactions = () => {
		if (!type) return <TransactionsListSkeleton skeletonsCount={4} />;
		if (!isFufi) return <TransactionsById id={id} type={type} />;
		return null;
	};
	return (
		<Stack spacing={5}>
			<Paper elevation={isDesktop ? 4 : 0} sx={{ p: { md: 5 } }}>
				<Stack spacing={5} sx={{ px: { md: 0, xxs: 4 } }}>
					{!isLoadingContract && !isLoading && isDesktop ? (
						<ContractCard contractId={id} position="preview" type={type} />
					) : (
						isDesktop && <ContractCardSkeletonHeader />
					)}
					{contract?.pensionPhaseStatus === PensionPhaseStatus.Payout ||
					isFufi ? null : (
						<SmartActionsById id={id} type={type} />
					)}
					{isLife && !isFufi && <DebtCtaPayById contractId={id} />}
					{getTransactions()}
					{!isFufi && (
						<Button
							sx={{ width: "fit-content", pl: 0 }}
							disabled={!id}
							to={
								id
									? contractLink(
											id,
											type,
											type === ProductType.Cl ? "pohyby" : "pohyby-platby"
									  )
									: undefined
							}
							variant="text"
							size="small"
							endIcon={<ArrowForwardIcon />}
						>
							Zobrazit všechny platby
						</Button>
					)}
				</Stack>
			</Paper>
		</Stack>
	);
}

interface ContractByIdProps {
	id: string;
}

export function ContractById({ id }: ContractByIdProps): JSX.Element {
	const { data, isLoading } = useContractById({
		id: id,
		options: { refetchOnMount: false },
	});

	return <Contract type={data?.type} id={id} isLoading={isLoading} />;
}

const FufiNoTransactionsAttention = () => {
	return (
		<Attention severity={"warning"}>
			{cs.life.global.transactionsNotAvailableOnFufi}
		</Attention>
	);
};

export const FufiContractById = ({ id }: ContractByIdProps) => {
	const isDesktop = useIsDesktop();
	return (
		<Stack sx={{ py: 0, px: { md: 0, xxs: 4 } }}>
			{isDesktop ? (
				<Paper elevation={4} sx={{ p: 5 }}>
					<Stack gap={5}>
						<ContractHeaderWithFufiData contractId={id} />
						<SmartActionsById type={ProductType.Clf} id={id} />
						<FufiNoTransactionsAttention />
					</Stack>
				</Paper>
			) : (
				<Stack gap={5}>
					<SmartActionsById type={ProductType.Clf} id={id} />
					<FufiNoTransactionsAttention />
				</Stack>
			)}
		</Stack>
	);
};

export default Contract;
