import SvgIcon from "@mui/material/SvgIcon";

const ArrowIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path d="M15.072 7.55a1.12 1.12 0 0 0-.271-.376L7.895.961a.5.5 0 0 0-.668.744l6.44 5.795H1.333a.5.5 0 0 0 0 1h12.335l-6.441 5.795a.5.5 0 0 0 .668.743l6.908-6.213A1.102 1.102 0 0 0 15.167 8c0-.156-.032-.308-.095-.451Z" />
		</SvgIcon>
	);
};

export default ArrowIcon;
