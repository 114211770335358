import { useFormikContext } from "formik";

import { ContactAddress } from "../../../models";
import cs from "../../translations/cs.json";
import AddressTemplate from "../AddressBox/AddressTemplate";
import InformationList from "../InformationList";
import { HeadingL } from "../Typography";

function OverviewAddress({
	contractNumber,
	title,
}: ContactAddress): JSX.Element {
	const { values } = useFormikContext();
	return (
		<>
			<HeadingL>{cs.global.changesOverview}</HeadingL>

			<InformationList
				information={[
					{
						title: cs.global.product,
						description: `${title} (${contractNumber})`,
					},
					{
						title: cs.global.address,
						description: <AddressTemplate address={values} />,
					},
				]}
			/>
		</>
	);
}

export default OverviewAddress;
