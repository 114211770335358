import { CardContent, CardMedia } from "@mui/material";
import Card, { CardProps } from "@mui/material/Card";
import useIsDesktop from "desktop/useIsDesktop";
import { graphql, useStaticQuery } from "gatsby";
import { ReactNode } from "react";

interface Props extends CardProps {
	image?: string;
	alt?: string;
	opacity?: boolean;
}

export const OverviewBase = ({
	alt,
	image,
	children,
	sx,
	opacity,
	...rest
}: Props): JSX.Element => (
	<Card
		{...rest}
		sx={{
			overflow: "visible",
			px: 4,
			boxShadow: "none",
			...sx,
		}}
	>
		<CardMedia
			alt={alt}
			sx={{
				paddingBottom: { md: 0, xxs: "50%" },
				backgroundPosition: "50% 0",
				backgroundSize: "cover",
				opacity: opacity ? 0.5 : 1,
			}}
			image={image}
		/>
		{children}
	</Card>
);

function Overview({ alt, image, children, sx, ...rest }: Props): JSX.Element {
	const isD = useIsDesktop();
	return (
		<OverviewBase alt={alt} image={image} sx={sx} {...rest}>
			{children && (
				<CardContent
					sx={{
						mb: isD ? 0 : 3,
						py: 5,
						px: 4,
						boxShadow: "far",
					}}
				>
					{children}
				</CardContent>
			)}
		</OverviewBase>
	);
}

export function OverviewLoginType({
	children,
	alt,
	opacity,
}: {
	children: ReactNode;
	alt: string;
	opacity?: boolean;
}): JSX.Element {
	interface imageProps {
		intro_image: {
			publicURL: string;
		};
	}

	const introImage = (): imageProps => {
		return useStaticQuery(
			graphql`
				query PrihlasitNeboRegistrovatImages {
					intro_image: file(relativePath: { eq: "login.png" }) {
						publicURL
					}
				}
			`
		);
	};

	const image = introImage();

	return (
		<Overview
			image={image.intro_image.publicURL}
			alt={alt}
			sx={{
				overflow: { md: "visible", xxs: "hidden" },
				mb: { md: 0, xxs: -4 },
				mx: { md: 0, xxs: -4 },
				p: { md: 0 },
				pt: { md: "72px" },
				".MuiCardContent-root": {
					boxShadow: "close",
				},
				".MuiCardMedia-root": {
					display: { md: "none" },
				},
			}}
			opacity={opacity}
		>
			{children}
		</Overview>
	);
}

export default Overview;
