import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ITelemetryItem } from "@microsoft/applicationinsights-web";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MUITab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { navigate } from "gatsby";
import { ReactNode, useEffect, useState } from "react";

import { ProductType } from "../../../models";
import { useContractTypeContext } from "../../context/ContractTypeContext";
import { ScrollSnapper } from "../ScrollSnapper";
import PensionOverview from "./pension";
import TabItems from "./TabItems";
export { default as Tab } from "./Tab";

interface ITab {
	label: string;
	slug: string;
	disabled?: boolean;
}

interface TabsProps {
	children: ReactNode;
	tabs: ITab[];
	defaultTab?: number;
	isHashPreferBeforeQueryParam?: boolean;
	queryParamKey?: string;
}

/**
 * Pro kazdy tab vytvori jednu polozku v menu.
 *
 * @param renderBody
 * @param tabs
 * @param defaultTab
 * @constructor
 */
function Tabs({
	children,
	tabs,
	defaultTab = 0,
	isHashPreferBeforeQueryParam = true,
	queryParamKey = "tab", // muze byt randomizovan
}: TabsProps): JSX.Element {
	const theme = useTheme();
	const [tabIndex, setTabIndex] = useState(defaultTab);
	const ai = useAppInsightsContext();
	const filteredTabs = tabs.filter((tab) => tab !== null);

	function handleTabIndexChange(newIndex: number) {
		const url = new URL(window.location.href);
		if (isHashPreferBeforeQueryParam) {
			url.hash = `#${filteredTabs[newIndex].slug}`;
		} else {
			url.searchParams.set(queryParamKey, filteredTabs[newIndex].slug);
		}
		if (tabIndex !== newIndex) {
			setTabIndex(newIndex);
			window.scrollTo(0, 0);
			return navigate(url.pathname + url.search + url.hash, { replace: true });
		}
	}

	const { contractType } = useContractTypeContext();

	useEffect(() => {
		const customTelemetry = ai.core?.addTelemetryInitializer(
			(item: ITelemetryItem) => {
				if (
					item.baseType === "PageviewData" ||
					item.baseType === "PageviewPerformanceData"
				) {
					if (item.baseData) {
						item.baseData.name =
							document.title + " " + filteredTabs[tabIndex].label;
						const newUrl =
							window.location.pathname +
							window.location.search.replace(/[0-9]/g, "x");
						item.baseData.uri = newUrl;
					}
				}
			}
		);

		return () => customTelemetry?.remove();
	}, [tabIndex]);

	/**
	 * Kouknu do URL, jestli nahodou nema nastaven tab na kterem mam byt zpocatku
	 */
	useEffect(() => {
		const url = new URL(window.location.href);
		// zjistim index toho, co se nachazi v URL
		const indexOfDefaultTab = filteredTabs.findIndex(
			({ slug }) =>
				slug ===
				(isHashPreferBeforeQueryParam
					? url.hash
					: url.searchParams.get(queryParamKey))
		);
		// pokud se v URL nachazi nejaky z tabuuu, nastavim ho jako default
		// jinak ZNOVU na prvni pohled zbytecne nastavuje defaultTab
		// to kvuli optimalizaci
		handleTabIndexChange(
			indexOfDefaultTab > -1 ? indexOfDefaultTab : defaultTab
		);
	}, [tabs]);

	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<Stack id="tabs">
			<Stack direction="row" sx={{ m: { md: 0, xxs: -4 } }}>
				<TabItems tabIndex={tabIndex} setTabIndex={handleTabIndexChange}>
					{filteredTabs.map(({ label, slug, disabled = false }, i) => (
						<MUITab
							key={slug}
							label={label}
							disabled={disabled}
							sx={{
								...(isDesktop
									? theme.typography.bodyTextM
									: theme.typography.bodyTextS),
								minWidth: 0,
								minHeight: 0,
								mr: 3,
								ml: i === 0 ? 4 : 0,
								p: 3,
								textTransform: "none",
							}}
						/>
					))}
				</TabItems>
				{[ProductType.Uf, ProductType.Pf].includes(contractType) && (
					<PensionOverview />
				)}
			</Stack>
			<ScrollSnapper
				sx={{ pt: { md: 0, xxs: 8 } }}
				dynamicHeight
				index={tabIndex}
				onIndexChange={handleTabIndexChange}
			>
				{Array.isArray(children)
					? children.filter((child) => !!child)
					: children}
			</ScrollSnapper>
		</Stack>
	);
}

export default Tabs;

export function TabsWithHash(
	props: Omit<TabsProps, "isHashPreferBeforeQueryParam">
) {
	return <Tabs {...props} isHashPreferBeforeQueryParam={true} />;
}

export function TabsWithQueryParameter(
	props: Omit<TabsProps, "isHashPreferBeforeQueryParam">
) {
	return <Tabs {...props} isHashPreferBeforeQueryParam={false} />;
}
