import cs from "../../../../translations/cs.json";
import Attention from "../../../AttentionBlock";
import InformationList from "../../../InformationList";

const PersonError = ({ firstName, lastName }) => (
	<Attention
		severity="error"
		sx={{
			flexDirection: "row-reverse",
			p: 0,
			".MuiAlert-icon": {
				alignItems: "center",
			},
			".MuiAlert-message": {
				p: 0,
			},
		}}
	>
		<InformationList
			sx={{
				".MuiPaper-root": {
					boxShadow: "none",
					backgroundColor: "transparent",
				},
			}}
			information={[
				{
					title: `${cs.global.fullName}:`,
					description: `${firstName} ${lastName}`,
				},
			]}
		/>
	</Attention>
);

export default PersonError;
