/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DailyValue
 */
export interface DailyValue {
    /**
     * 
     * @type {Date}
     * @memberof DailyValue
     */
    day: Date;
    /**
     * 
     * @type {number}
     * @memberof DailyValue
     */
    value: number;
}

/**
 * Check if a given object implements the DailyValue interface.
 */
export function instanceOfDailyValue(value: object): value is DailyValue {
    if (!('day' in value) || value['day'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function DailyValueFromJSON(json: any): DailyValue {
    return DailyValueFromJSONTyped(json, false);
}

export function DailyValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyValue {
    if (json == null) {
        return json;
    }
    return {
        
        'day': (new Date(json['day'])),
        'value': json['value'],
    };
}

export function DailyValueToJSON(value?: DailyValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'day': ((value['day']).toISOString()),
        'value': value['value'],
    };
}

