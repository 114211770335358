import { Stack } from "@mui/material";
import { useFormikContext } from "formik";

import { dateTimeFormat } from "../../../functions/dates";
import AddressTemplate from "../../AddressBox/AddressTemplate";
import InformationList from "../../InformationList";
import { BodyTextM, HeadingL } from "../../Typography";

const ChangesOverview = (): JSX.Element => {
	const { values } = useFormikContext();
	const hasBeneficiaries = values.beneficiaries.length > 0;

	if (hasBeneficiaries) {
		return (
			<>
				<HeadingL withoutScale data-test="changes-overview-header">
					Přehled změn
				</HeadingL>
				<Stack spacing={5}>
					{values.beneficiaries.map((item, index) => {
						return (
							<InformationList
								data-test="changes-information-list"
								title={`${index + 1}. člověk`}
								key={`${index + 1}. člověk`}
								information={[
									{
										title: "Jméno",
										description: item.firstName,
									},
									{
										title: "Příjmení",
										description: item.lastName,
									},
									{
										title: "Datum narození",
										description: dateTimeFormat.format(item.dateOfBirth),
									},
									{
										title: "Podíl",
										description: `${values.ratios[index].ratio} %`,
									},
									{
										title: "Kontaktní adresa",
										description: <AddressTemplate address={item.address} />,
									},
								]}
							/>
						);
					})}
				</Stack>
			</>
		);
	} else {
		return (
			<>
				<HeadingL withoutScale>Přehled změn</HeadingL>
				<BodyTextM>Všichni lidé byli odstraněni.</BodyTextM>
			</>
		);
	}
};

export default ChangesOverview;
