import { Box, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useState } from "react";

import cs from "../../translations/cs.json";
import Trigger from "../Link/Trigger";
import TriggerButton from "../Link/TriggerButton";
import { BodyTextM, HeadingS } from "../Typography";
import SimpleDrawer from "./SimpleDrawer";

interface Props {
	handleConfirmDelete: () => void;
}

const ConfirmDelete = ({ handleConfirmDelete }: Props): JSX.Element => {
	const { isSubmitting } = useFormikContext();
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<Trigger
				onClick={() => {
					setIsOpen(true);
				}}
			>
				{cs.global.delete}
			</Trigger>
			<SimpleDrawer
				onClose={() => setIsOpen(false)}
				onOpen={() => setIsOpen(true)}
				open={isOpen}
			>
				<Stack spacing={6}>
					<Box>
						<HeadingS>Upozornění</HeadingS>
						<BodyTextM>
							Tímto krokem trvale vymažete zvolenou osobu ze seznamu. Přejete-li
							si pokračovat, klikněte na tlačítko Odstranit.
						</BodyTextM>
					</Box>
					<Stack
						direction={{ xxs: "column", xs: "row" }}
						spacing={{ xxs: 0, xs: 4 }}
						justifyContent="space-between"
					>
						<TriggerButton
							variant="text"
							disabled={isSubmitting}
							onClick={() => {
								setIsOpen(false);
							}}
						>
							{cs.global.cancel}
						</TriggerButton>
						<TriggerButton
							disabled={isSubmitting}
							onClick={() => {
								handleConfirmDelete();
								setIsOpen(false);
							}}
						>
							{cs.global.delete}
						</TriggerButton>
					</Stack>
				</Stack>
			</SimpleDrawer>
		</>
	);
};

export default ConfirmDelete;
