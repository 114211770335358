/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LifePaymentInfo } from './LifePaymentInfo';
import {
    LifePaymentInfoFromJSON,
    LifePaymentInfoFromJSONTyped,
    LifePaymentInfoToJSON,
} from './LifePaymentInfo';
import type { PaymentInfoType } from './PaymentInfoType';
import {
    PaymentInfoTypeFromJSON,
    PaymentInfoTypeFromJSONTyped,
    PaymentInfoTypeToJSON,
} from './PaymentInfoType';

/**
 * 
 * @export
 * @interface LifePaymentInfoGroup
 */
export interface LifePaymentInfoGroup {
    /**
     * 
     * @type {PaymentInfoType}
     * @memberof LifePaymentInfoGroup
     */
    type: PaymentInfoType;
    /**
     * 
     * @type {LifePaymentInfo}
     * @memberof LifePaymentInfoGroup
     */
    client: LifePaymentInfo;
    /**
     * 
     * @type {LifePaymentInfo}
     * @memberof LifePaymentInfoGroup
     */
    employer: LifePaymentInfo;
}

/**
 * Check if a given object implements the LifePaymentInfoGroup interface.
 */
export function instanceOfLifePaymentInfoGroup(value: object): value is LifePaymentInfoGroup {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('client' in value) || value['client'] === undefined) return false;
    if (!('employer' in value) || value['employer'] === undefined) return false;
    return true;
}

export function LifePaymentInfoGroupFromJSON(json: any): LifePaymentInfoGroup {
    return LifePaymentInfoGroupFromJSONTyped(json, false);
}

export function LifePaymentInfoGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): LifePaymentInfoGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'type': PaymentInfoTypeFromJSON(json['type']),
        'client': LifePaymentInfoFromJSON(json['client']),
        'employer': LifePaymentInfoFromJSON(json['employer']),
    };
}

export function LifePaymentInfoGroupToJSON(value?: LifePaymentInfoGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': PaymentInfoTypeToJSON(value['type']),
        'client': LifePaymentInfoToJSON(value['client']),
        'employer': LifePaymentInfoToJSON(value['employer']),
    };
}

