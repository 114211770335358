import SvgIcon from "@mui/material/SvgIcon";

const DownloadIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="16" height="20" viewBox="0 0 16 20" {...props}>
			<path d="M7.8 15.2c.1.1.3.2.6.2h.4c.2 0 .3-.1.4-.1.1 0 .1-.1.2-.1l4.7-5.2c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0L9 14.1V1c0-.3-.2-.5-.5-.5S8 .7 8 1v12.8L3.9 9.2c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l4.6 5.3ZM15.5 16.5c-.3 0-.5.2-.5.5v1.5H1V17c0-.3-.2-.5-.5-.5s-.5.2-.5.5v2c0 .3.2.5.5.5h15c.3 0 .5-.2.5-.5v-2c0-.3-.2-.5-.5-.5Z" />
		</SvgIcon>
	);
};

export default DownloadIcon;
