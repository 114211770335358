import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

//TODO fix importing .png files
import image from "../../../images/change-request-confirmation.png";
import cs from "../../../translations/cs.json";
import { HeadingL } from "../../Typography";

export interface IChangeRequestLoading {
	heading?: string;
}

function ChangeRequestLoading({
	heading = cs.global.changeRequestProcessing,
}: IChangeRequestLoading): JSX.Element {
	return (
		<Stack sx={{ position: "absolute", width: "100%", height: "100%" }}>
			<Box mb={5} sx={{ textAlign: "center" }}>
				<img src={image} alt="Potvrzení o odeslání změny" width={211} />
			</Box>
			<HeadingL>{heading}</HeadingL>
			<Stack
				sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
			>
				<CircularProgress size={60} />
			</Stack>
		</Stack>
	);
}

export default ChangeRequestLoading;
