/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignPidRequest
 */
export interface AssignPidRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignPidRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof AssignPidRequest
     */
    pid: string;
    /**
     * 
     * @type {string}
     * @memberof AssignPidRequest
     */
    employeeId: string;
}

/**
 * Check if a given object implements the AssignPidRequest interface.
 */
export function instanceOfAssignPidRequest(value: object): value is AssignPidRequest {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('pid' in value) || value['pid'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    return true;
}

export function AssignPidRequestFromJSON(json: any): AssignPidRequest {
    return AssignPidRequestFromJSONTyped(json, false);
}

export function AssignPidRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignPidRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'pid': json['pid'],
        'employeeId': json['employeeId'],
    };
}

export function AssignPidRequestToJSON(value?: AssignPidRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'pid': value['pid'],
        'employeeId': value['employeeId'],
    };
}

