import Stack from "@mui/material/Stack";

import cs from "../../../translations/cs.json";
import { BodyTextM, HeadingL } from "../../Typography";

const Explanation = (): JSX.Element => {
	return (
		<>
			<HeadingL>
				{cs.investmentStrategy.investmentQuestionare.explain.title}
			</HeadingL>
			<Stack spacing={5}>
				{cs.investmentStrategy.investmentQuestionare.explain.content.map(
					(paragraph, i) => (
						<BodyTextM component="p" key={i}>
							{paragraph}
						</BodyTextM>
					)
				)}
			</Stack>
		</>
	);
};

export default Explanation;
