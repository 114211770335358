import LinearProgress, {
	linearProgressClasses,
	LinearProgressProps,
} from "@mui/material/LinearProgress";

function RoundedLinearProgress({
	value,
	...props
}: LinearProgressProps): JSX.Element {
	return (
		<LinearProgress
			variant="determinate"
			value={value}
			{...props}
			sx={{
				height: 8,
				my: 3,
				borderRadius: 8,
				[`&.${linearProgressClasses.colorPrimary}`]: {
					backgroundColor: "grey.ultraLight",
				},
				[`& .${linearProgressClasses.bar}`]: {
					borderRadius: 8,
					backgroundColor: "primary.main",
				},
			}}
		/>
	);
}

export default RoundedLinearProgress;
