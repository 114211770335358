import SvgIcon from "@mui/material/SvgIcon";

const LogOutIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="14" height="19" viewBox="0 0 14 19" {...props}>
			<path d="M6.91 15.788c.23 0 .416-.186.416-.417v-2.246c.278-.073.536-.212.745-.42.31-.31.48-.723.48-1.162 0-.438-.17-.85-.48-1.162-.622-.619-1.701-.62-2.323.001-.31.312-.48.723-.48 1.161a1.63 1.63 0 0 0 1.225 1.582v2.246c0 .23.186.417.416.417Zm-.573-3.673a.804.804 0 0 1-.236-.572.807.807 0 0 1 .809-.808.806.806 0 0 1 .808.808.805.805 0 0 1-.236.572.838.838 0 0 1-1.145 0Z" />
			<path d="M11.98 7.355h-.669v-2.12a4.373 4.373 0 0 0-1.289-3.112A4.371 4.371 0 0 0 6.91.833a4.372 4.372 0 0 0-3.112 1.29 4.372 4.372 0 0 0-1.29 3.113v2.12H1.84c-.83 0-1.506.675-1.506 1.505v7.64a.416.416 0 1 0 .834 0V8.86c0-.37.301-.672.672-.672h10.14c.372 0 .673.302.673.672v7.967c0 .37-.301.672-.673.672H2.185a.416.416 0 1 0 0 .834h9.794c.831 0 1.507-.676 1.507-1.506V8.861c0-.83-.676-1.506-1.507-1.506Zm-1.502 0H3.34v-2.12c0-.953.37-1.849 1.045-2.523a3.545 3.545 0 0 1 2.523-1.045c.954 0 1.85.37 2.524 1.045a3.547 3.547 0 0 1 1.045 2.524v2.12Z" />
		</SvgIcon>
	);
};

export default LogOutIcon;
