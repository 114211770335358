import "./style.css";

import Stack from "@mui/material/Stack";
import { useFormikContext } from "formik";
import {
	default as VerificationInputNpm,
	VerificationInputProps,
} from "react-verification-input";

import FormErrorMessage from "../../FormErrorMessage";

interface VerificationInputProps extends VerificationInputProps {
	length: number;
	placeholder: string;
	name: string;
	autoFocus?: boolean;
}

const VerificationInput = ({
	length,
	placeholder,
	name,
	autoFocus = false,
}: VerificationInputProps): JSX.Element => {
	const { setFieldValue, errors, values } = useFormikContext();
	return (
		<Stack spacing={4}>
			<VerificationInputNpm
				value={values[name]}
				placeholder={placeholder}
				length={length}
				validChars="0-9"
				classNames={{
					// We couldn´t find any npm module which would accept customInput prop, that's why we can´t pass our MUI based <SingleDigit /> component as a prop and we were forced to style the inputs via CSS file with CSS classes.
					container: "container",
					character: "character",
					characterInactive: "character--inactive",
					characterSelected: "character--selected",
				}}
				onChange={(value) => {
					setFieldValue(name, value, value.length === length ? true : false);
				}}
				/* eslint-disable */
				autoFocus={autoFocus}
			/>
			{errors[name] && (
				<FormErrorMessage name={name}>{errors[name]}</FormErrorMessage>
			)}
		</Stack>
	);
};

export default VerificationInput;
