const HasNoBeneficiaries = (): JSX.Element => {
	return (
		<p>
			Je nám líto, ale tuto změnu zatím nejde udělat přes Portál. Pokud
			potřebujete změnu provést, kontaktujte svého poradce nebo nám pošlete{" "}
			<a
				href="https://www.nn.cz/files/archiv/cz-penze/formulare/514538d.pdf"
				target="_blank"
				rel="noreferrer"
			>
				vyplněný formulář
			</a>{" "}
			s Vaším ověřeným podpisem. Veškeré informace najdete na formuláři.
			Děkujeme, Vaše NN.
		</p>
	);
};

export default HasNoBeneficiaries;
