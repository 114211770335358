import Stack from "@mui/material/Stack";
import { ReactNode } from "react";

import Stepper from "./Stepper";

export interface ModalHeaderProps {
	children: ReactNode;
	hasStepper?: boolean;
	numberOfSteps?: number;
	wideModal?: boolean;
}

const ModalHeader = ({
	children,
	hasStepper,
	numberOfSteps,
	wideModal,
}: ModalHeaderProps): JSX.Element => {
	return (
		<Stack
			sx={{
				zIndex: 1,
				boxShadow: "far",
				borderTopLeftRadius: 16,
				borderTopRightRadius: 16,
				backgroundColor: "white.main",
			}}
		>
			<Stack
				direction="row"
				sx={{
					position: "realative",
					justifyContent: "space-between",
					alignItems: "center",
					width: "100%",
					minHeight: wideModal ? "86px" : "64px",
					px: 4,
				}}
			>
				{children}
			</Stack>
			{hasStepper && <Stepper numberOfSteps={numberOfSteps} />}
		</Stack>
	);
};

export default ModalHeader;
