import SvgIcon from "@mui/material/SvgIcon";

const Adress = (props): JSX.Element => {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
			<g clipPath="url(#a)">
				<path d="M17 13.1h3.2c.5 0 .8-.3.8-.8V9c0-.4-.3-.8-.8-.8H17c-.4 0-.8.4-.8.8v3.3c0 .5.4.8.8.8Zm.1-3.9h3v3h-3v-3ZM15.9 2H8.2v1H16V2Zm1.9 2.8c.3 0 .5-.2.5-.5V.5a.5.5 0 1 0-1 0v3.8c0 .3.3.5.5.5Z" />
				<path d="M19.8 2v1C21.6 3 23 4.2 23 6v13.7c0 1.8-1.4 3.2-3.2 3.2H4.2A3.2 3.2 0 0 1 1 19.8V6.1A3 3 0 0 1 4.2 3V2A4.2 4.2 0 0 0 0 6.1v13.7C0 22.1 1.9 24 4.2 24h15.6c2.3 0 4.2-1.9 4.2-4.2V6.1C24 3.8 22.1 2 19.8 2Z" />
				<path d="M3 9v3.3c0 .5.3.8.8.8H7c.4 0 .8-.3.8-.8V9c0-.4-.4-.8-.8-.8H3.8c-.5 0-.8.4-.8.8Zm1 .2h2.9v3h-3v-3Zm9.7 5.2h-3.4c-.4 0-.8.3-.8.8v3.3c0 .4.4.8.8.8h3.4c.4 0 .8-.4.8-.8v-3.3c0-.5-.4-.8-.8-.8Zm-.2 3.9h-3v-3h3v3Zm-6.4-3.9H3.8c-.5 0-.8.3-.8.8v3.3c0 .4.3.8.8.8H7c.4 0 .8-.4.8-.8v-3.3c0-.5-.4-.8-.8-.8Zm-.2 3.9h-3v-3h3v3ZM14.5 9c0-.4-.4-.8-.8-.8h-3.4c-.4 0-.8.4-.8.8v3.3c0 .5.4.8.8.8h3.4c.4 0 .8-.3.8-.8V9Zm-1 3.1h-3V9.2h3v3ZM6.3 4.8c.3 0 .5-.2.5-.5V.5a.5.5 0 1 0-1 0v3.8c0 .3.2.5.5.5Z" />
			</g>
		</SvgIcon>
	);
};

export default Adress;
