import { useLocation } from "@reach/router";

import { EnvironmentHelper } from "../functions/env";

type Param = { name: string; required?: boolean };

export const useParams = (...params: Param[]) => {
	if (!EnvironmentHelper.isBrowser()) return params.map((param) => param.name);
	const { href } = useLocation();
	const searchParams = new URL(href).searchParams;

	const paramValues = params.map((param) => {
		if (param.required === true && !searchParams.has(param.name))
			throw new Error(`Missing required param ${param.name}`);
		return searchParams.get(param.name);
	});

	return paramValues;
};
