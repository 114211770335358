import InfoIconDrawer from "../Drawer/InfoIconDrawer";

const BeneficiariesNotEditable = ({ title }): JSX.Element => (
	<InfoIconDrawer
		description={
			<>
				{title} můžete jen na smlouvě, kde jste pojistníkem a zároveň i
				pojištěným. Tedy osobou, která si smlouvu sjednala a která je na smlouvě
				pojištěna. Jste-li pouze pojištěný, své obmyšlené osoby si můžete měnit
				přes formulář, který najdete{" "}
				<a
					href="https://www.nn.cz/pro-klienty/pojisteni/"
					target="_blank"
					rel="noreferrer"
				>
					zde
				</a>
				.
			</>
		}
		title={title}
	/>
);

export default BeneficiariesNotEditable;
