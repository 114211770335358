import { Stack } from "@mui/material";

import { GetContactsResponse } from "../../../models";
import cs from "../../translations/cs.json";
import InformationList from "../InformationList";
import { EmailLink } from "../Link/ContactLink";
import { HeadingL } from "../Typography";

interface Props {
	consultants: GetContactsResponse[];
}

const Consultants = ({ consultants }: Props): JSX.Element => {
	return (
		<>
			{consultants.length > 0 && (
				<>
					<HeadingL>Moji poradci</HeadingL>
					<Stack spacing={5} mb={6}>
						{consultants.map((contact, i) => (
							<InformationList
								key={i}
								title={contact.productName}
								information={[
									contact.name !== null && {
										title: cs.global.consultant,
										description: contact.name,
									},
									contact.email !== null && {
										title: cs.global.email,
										description: (
											<EmailLink email={contact.email} key="email" />
										),
									},
								]}
							/>
						))}
					</Stack>
				</>
			)}
		</>
	);
};
export default Consultants;
