/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetODSClientsResponse
 */
export interface GetODSClientsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetODSClientsResponse
     */
    pid: string;
    /**
     * 
     * @type {string}
     * @memberof GetODSClientsResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof GetODSClientsResponse
     */
    birthNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetODSClientsResponse
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof GetODSClientsResponse
     */
    emails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetODSClientsResponse
     */
    phones?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetODSClientsResponse
     */
    contractNumbers?: string | null;
}

/**
 * Check if a given object implements the GetODSClientsResponse interface.
 */
export function instanceOfGetODSClientsResponse(value: object): value is GetODSClientsResponse {
    if (!('pid' in value) || value['pid'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    if (!('birthNumber' in value) || value['birthNumber'] === undefined) return false;
    if (!('birthDate' in value) || value['birthDate'] === undefined) return false;
    return true;
}

export function GetODSClientsResponseFromJSON(json: any): GetODSClientsResponse {
    return GetODSClientsResponseFromJSONTyped(json, false);
}

export function GetODSClientsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetODSClientsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'pid': json['pid'],
        'displayName': json['displayName'],
        'birthNumber': json['birthNumber'],
        'birthDate': json['birthDate'],
        'emails': json['emails'] == null ? undefined : json['emails'],
        'phones': json['phones'] == null ? undefined : json['phones'],
        'contractNumbers': json['contractNumbers'] == null ? undefined : json['contractNumbers'],
    };
}

export function GetODSClientsResponseToJSON(value?: GetODSClientsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pid': value['pid'],
        'displayName': value['displayName'],
        'birthNumber': value['birthNumber'],
        'birthDate': value['birthDate'],
        'emails': value['emails'],
        'phones': value['phones'],
        'contractNumbers': value['contractNumbers'],
    };
}

