import { Paper } from "@mui/material";
import { ErrorMessage, useFormikContext } from "formik";

import cs from "../../../translations/cs.json";
import FormErrorMessage from "../../Form/FormErrorMessage";
import Switch from "../../Form/Input/Switch";
import InformationList from "../../InformationList";
import { BodyTextM, HeadingL, HeadingM } from "../../Typography";
import { ITaxOptimumFormValues } from "./TaxOptimumDialog";

export const TaxOptimumInfo = () => {
	return (
		<>
			<HeadingL>{cs.global.fillNewInfo}</HeadingL>
			<Paper sx={{ py: 5, px: 4 }}>
				<Switch
					name="isTaxOptimum"
					label={cs.taxOptimumDialog.wantTaxOptimum}
				/>
			</Paper>
			<ErrorMessage
				name="valuesChanged"
				render={(msg) => (
					<FormErrorMessage name="valuesChanged">{msg}</FormErrorMessage>
				)}
			/>
			<Paper sx={{ mt: 5, py: 5, px: 4 }}>
				<HeadingM>{cs.taxOptimumDialog.taxOptimum}</HeadingM>
				<BodyTextM>{cs.taxOptimumDialog.description}</BodyTextM>
				<HeadingM sx={{ mt: 5 }}>
					{cs.taxOptimumDialog.howItWorksTitle}
				</HeadingM>
				<BodyTextM>{cs.taxOptimumDialog.howItWorks}</BodyTextM>
			</Paper>
		</>
	);
};

export const TaxOptimumOverview = (): JSX.Element => {
	const { values } = useFormikContext<ITaxOptimumFormValues>();

	return (
		<>
			<HeadingL>{cs.global.changesOverview}</HeadingL>
			<InformationList
				information={[
					{
						title: cs.taxOptimumDialog.wantTaxOptimum,
						description: values.isTaxOptimum ? cs.global.yes : cs.global.no,
						valueSelector: "pension-employer-contribution-change-overview",
					},
				]}
			/>
		</>
	);
};
