import SvgIcon from "@mui/material/SvgIcon";

const Adress = (props): JSX.Element => {
	return (
		<SvgIcon width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
			<path d="M20 5H1a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5v-14A.5.5 0 0 0 20 5Zm-.5 14h-18V6h3v10.5a.5.5 0 0 0 1 0V6h10v10.5a.5.5 0 1 0 1 0V6h3v13ZM6 3.5a.5.5 0 0 0 .5-.5V1.7a.7.7 0 0 1 .8-.7h6.5a.7.7 0 0 1 .7.7V3a.5.5 0 1 0 1 0V1.7A1.8 1.8 0 0 0 13.8 0H7.3a1.8 1.8 0 0 0-1.8 1.7V3a.5.5 0 0 0 .5.5Z" />
		</SvgIcon>
	);
};

export default Adress;
