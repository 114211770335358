import { ReactNode } from "react";

import InternalLink from "./InternalLink";

enum Type {
	PHONE,
	EMAIL,
}

const type2Protocol = {
	[Type.PHONE]: "tel",
	[Type.EMAIL]: "mailto",
};

interface Props {
	contact: string;
	type: Type;
	children?: ReactNode;
}

function ContactLink({ type, contact, children }: Props): JSX.Element {
	return (
		<InternalLink to={`${type2Protocol[type]}:${contact}`} variant="light">
			{children ?? contact}
		</InternalLink>
	);
}

interface PhoneLinkProps {
	phone: string;
	children: ReactNode;
}

interface EmailLinkProps {
	email: string;
}

export function PhoneLink({ phone, children }: PhoneLinkProps): JSX.Element {
	return (
		<ContactLink contact={phone} type={Type.PHONE}>
			{children}
		</ContactLink>
	);
}

export function EmailLink({ email }: EmailLinkProps): JSX.Element {
	return <ContactLink contact={email} type={Type.EMAIL} />;
}
