import { useFormikContext } from "formik";
import { FC } from "react";

import TriggerButton from "../Link/TriggerButton";
export interface FormSubmitProps {
	text: {
		submitting: string;
		label: string;
	};
	disabled?: boolean;
	className?: string;
	onClick?: () => void;
}

const FormSubmit: FC<FormSubmitProps> = ({
	text,
	disabled = false,
	className = "",
	onClick = () => null,
	...rest
}) => {
	const { isSubmitting } = useFormikContext();

	return (
		<TriggerButton
			disabled={isSubmitting || disabled}
			className={`form-submit ${className}`}
			type="submit"
			onClick={onClick}
			{...rest}
		>
			{isSubmitting ? text.submitting : text.label}
		</TriggerButton>
	);
};

export default FormSubmit;
