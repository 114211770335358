import SvgIcon from "@mui/material/SvgIcon";

const GraphIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="70" height="50" viewBox="0 0 70 50" fill="none" {...props}>
			<path d="M62.4762 46.9154H60.0326V10.8757C60.0326 10.0231 59.3512 9.33334 58.5088 9.33334C57.6664 9.33334 56.985 10.0231 56.985 10.8757V46.9154H47.5563V7.08464H53.1813C54.0236 7.08464 54.7051 6.39491 54.7051 5.54232C54.7051 4.68973 54.0236 4 53.1813 4H46.0325C45.1901 4 44.5087 4.68973 44.5087 5.54232V46.9154H39.1366V25.8803C39.1366 25.0277 38.4552 24.338 37.6128 24.338H25.1355C24.2932 24.338 23.6117 25.0277 23.6117 25.8803V46.9154H19.5106V38.5652C19.5106 37.7126 18.8291 37.0229 17.9867 37.0229H10.5597C9.71733 37.0229 9.03589 37.7126 9.03589 38.5652C9.03589 39.4178 9.71733 40.1076 10.5597 40.1076H16.4629V46.9154H7.03269V41.5021C7.03269 40.6495 6.35124 39.9598 5.50888 39.9598C4.66651 39.9598 3.98507 40.6495 3.98507 41.5021V46.9154H1.52381C0.681448 46.9154 0 47.6051 0 48.4577C0 49.3103 0.681448 50 1.52381 50H5.50888H17.9864H25.1352H37.6128H46.0325H58.5088H62.4762C63.3186 50 64 49.3103 64 48.4577C64 47.6051 63.3186 46.9154 62.4762 46.9154ZM36.0893 46.9154H26.6594V27.4226H36.0893V46.9154Z" />
		</SvgIcon>
	);
};

export default GraphIcon;
