import Box from "@mui/material/Box";
import { globalHistory } from "@reach/router";
import { ReactNode, useEffect } from "react";

export interface SingleStepDrawerProps {
	children: ReactNode;
	hideDrawer: () => void;
	dataTest?: string;
}

function SingleStepDrawer({
	hideDrawer,
	children,
	dataTest,
}: SingleStepDrawerProps): JSX.Element {
	useEffect(() => {
		return globalHistory.listen(({ action }) => {
			if (action === "POP") {
				hideDrawer();
			}
		});
	}, []);

	return (
		<Box
			data-test={dataTest}
			sx={{
				width: { md: "480px" },
				overflowY: "auto",
				py: 7,
				px: 4,
			}}
		>
			{children}
		</Box>
	);
}

export default SingleStepDrawer;
