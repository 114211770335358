import { Box } from "@mui/material";
import TopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";

import theme from "../../gatsby-theme-material-ui-top-layout/theme";
import cs from "../../translations/cs.json";
import Attention from "../AttentionBlock";
import ModalContent from "../Drawer/ModalContent";
import ModalFooter from "../Drawer/ModalFooter";
import ModalHeader from "../Drawer/ModalHeader";
import ErrorContent from "../ErrorPage/ErrorContent";
import GeneralMessage, { IErrorMessage } from "../ErrorPage/GeneralMessage";
import CloseIcon from "../Icons/Close";
import { Trigger, TriggerButton } from "../Link";
import { FallbackUI } from ".";
import { DisplayableError } from "./DisplayableError";

export const GetDefaultErrorUI: (uiProps?: IErrorMessage) => FallbackUI = (
	uiProps
) =>
	function DefaultErrorUI({ error }) {
		const displayElement = (() => {
			if (error instanceof DisplayableError) {
				if ("customJSX" in error.display) {
					return error.display.customJSX;
				}
				return (
					<GeneralMessage
						title={error.display.title ?? uiProps?.title}
						message={error.display.message ?? uiProps?.message}
						buttonText={error.display.buttonText ?? uiProps?.buttonText}
						customButton={
							error.display.customButton !== undefined
								? error.display.customButton
								: uiProps?.customButton
						}
					/>
				);
			}
			return <GeneralMessage {...uiProps} />;
		})();

		return <ErrorContent>{displayElement}</ErrorContent>;
	};

export const DefaultErrorUI: FallbackUI = GetDefaultErrorUI();

export const DefaultErrorUIWithTheme: FallbackUI = ({ error }) => {
	return (
		<TopLayout theme={theme}>
			<DefaultErrorUI error={error} />
		</TopLayout>
	);
};

interface IDefaultModalUIErrorWithProps extends IErrorMessage {
	onClose: () => void;
}

export const GetDefaultModalErrorUI: (
	modalProps: IDefaultModalUIErrorWithProps
) => FallbackUI = ({
	onClose,
	buttonText = cs.global.close,
	message = cs.errors.general,
	title = cs.errors.somethingWentWrong,
}) =>
	function DefaultModalError({ error }) {
		const isDisplayError = error instanceof DisplayableError;
		const modalContent = (() => {
			if (isDisplayError && "customJSX" in error.display)
				return error.display.customJSX;
			return (
				<ErrorContent sx={{ height: "100%", minHeight: "auto" }}>
					<Attention
						severity="error"
						title={
							isDisplayError &&
							"title" in error.display &&
							error.display.title !== undefined
								? error.display.title
								: title
						}
					>
						{isDisplayError &&
						"message" in error.display &&
						error.display.message !== undefined
							? error.display.message
							: message}
					</Attention>
				</ErrorContent>
			);
		})();
		const button = (() => {
			if (error instanceof DisplayableError) {
				if (!("customJSX" in error.display)) {
					if (
						error.display.customButton ||
						error.display.customButton === null
					) {
						return error.display.customButton;
					}
					return (
						<TriggerButton onClick={onClose}>
							{error.display.buttonText ?? buttonText}
						</TriggerButton>
					);
				}
			}
			return buttonText;
		})();

		return (
			<>
				<ModalHeader>
					<Box sx={{ visibility: "hidden" }}></Box>
					<Trigger onClick={onClose}>
						<CloseIcon sx={{ width: "2rem", height: "2rem" }} />
					</Trigger>
				</ModalHeader>
				<ModalContent>{modalContent}</ModalContent>
				<ModalFooter>{button}</ModalFooter>
			</>
		);
	};
