import SvgIcon from "@mui/material/SvgIcon";

const Plus = (props): JSX.Element => {
	return (
		<SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
			<path d="M15.791 1.6c-.2-.1-.499 0-.699.2-.1.2 0 .5.2.7 2.997 1.6 4.995 4.6 5.195 8 .1 2.5-.8 5-2.498 6.8a9.356 9.356 0 0 1-6.593 3.1c-5.195.2-9.69-3.8-9.89-9.1-.2-5.1 3.796-9.6 9.09-9.8.3 0 .5-.2.5-.5-.1-.3-.3-.5-.6-.5C4.703.8.307 5.7.507 11.5c.3 5.6 4.896 10 10.49 10h.5c5.794-.3 10.29-5.2 9.99-11-.2-3.7-2.398-7.1-5.695-8.9Z" />
			<path d="M10.497 5.3v5.2H5.302c-.3 0-.5.2-.5.5s.2.5.5.5h5.195v5.2c0 .3.2.5.5.5.299 0 .499-.2.499-.5v-5.2h5.195c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-5.195V5.3c0-.3-.2-.5-.5-.5s-.5.2-.5.5Z" />
		</SvgIcon>
	);
};

export default Plus;
