import { Box, IconButton, Paper } from "@mui/material";
import { DrawerProps } from "@mui/material/Drawer";
import { ReactNode } from "react";

import CloseIcon from "../Icons/Close";
import { TriggerButton } from "../Link";
import { HeadingS } from "../Typography";
import Modal from "./Modal";
import ModalHeader from "./ModalHeader";

export interface SimpleDrawerProps extends DrawerProps {
	title?: string;
	isCloseIcon?: boolean;
	width?: number;
	customButton?: ReactNode;
}

function SimpleDrawer({
	isCloseIcon = false,
	title,
	children,
	onClose,
	customButton,
	width = 480,
	...rest
}: SimpleDrawerProps): JSX.Element {
	return (
		<Modal onClose={onClose} {...rest}>
			<Box sx={{ width: { md: `${width}px` }, overflow: "auto" }}>
				{(isCloseIcon || title) && (
					<ModalHeader>
						<Box
							sx={{
								visibility: "hidden",
								width: "2.1rem",
								height: "2.1rem",
							}}
						></Box>
						{title && <HeadingS sx={{ mb: 0 }}>{title}</HeadingS>}
						{isCloseIcon && (
							<IconButton
								aria-label="close"
								onClick={onClose}
								color={"primary"}
							>
								<CloseIcon fontSize="large" />
							</IconButton>
						)}
					</ModalHeader>
				)}
				<Box sx={{ pt: 7, pb: isCloseIcon || title ? "126px" : 7, px: 4 }}>
					{children}
				</Box>
				{(isCloseIcon || title) && (
					<Paper
						sx={{
							position: "sticky",
							bottom: 0,
							display: "flex",
							width: "100%",
							p: 4,
							backgroundColor: "white.main",
						}}
						elevation={24}
					>
						{customButton ? (
							customButton
						) : (
							<TriggerButton onClick={onClose} sx={{ flexGrow: 1 }}>
								Rozumím
							</TriggerButton>
						)}
					</Paper>
				)}
			</Box>
		</Modal>
	);
}

export default SimpleDrawer;
