import SvgIcon from "@mui/material/SvgIcon";

const ArrowIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="19" height="24" viewBox="0 0 19 24" fill="none" {...props}>
			<path d="M15 14.3a3 3 0 0 0-3.4 0 4 4 0 0 1-2 .5 4 4 0 0 1-2.2-.6 2.9 2.9 0 0 0-2.3-.5c-3.3.7-5 4-5.1 9.8a.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5c0-5.4 1.5-8.3 4.3-8.8a1.9 1.9 0 0 1 1.5.3c.8.6 1.7.8 2.7.8a4.9 4.9 0 0 0 2.7-.7 2 2 0 0 1 2.2 0c2.1 1.5 2.8 5.4 3 8.4a.5.5 0 0 0 .6.4.5.5 0 0 0 .4-.5c-.2-3.2-1-7.4-3.5-9.1Zm-5.8-2.8c1.3 0 2.6-.8 3.6-2.4a8.2 8.2 0 0 0 1.5-4.2A5 5 0 0 0 4 4.9c0 2.5 2.3 6.6 5 6.6ZM9.2 1a4 4 0 0 1 4 3.9c0 1.3-.5 2.6-1.2 3.6a3.8 3.8 0 0 1-2.8 2c-2.1 0-4-3.5-4-5.6a4 4 0 0 1 4-3.9Z" />
		</SvgIcon>
	);
};

export default ArrowIcon;
