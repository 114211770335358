import "./customOptionsVariants";

import { createTheme } from "@mui/material";

import Check from "../components/Icons/Check";
import Error from "../components/Icons/Error";
import Info from "../components/Icons/Info";
import bankidImage from "../images/bankid.jpg";
import { grey, orange } from "./designColors";
import palette from "./palette";
import spacing from "./spacing";
import typographyComponents from "./typography";
import { EnvironmentHelper } from "../functions/env";

const MuiDrawer = {
	defaultProps: {
		anchor: "bottom",
	},
	styleOverrides: {
		paperAnchorBottom: {
			borderTopRightRadius: 16,
			borderTopLeftRadius: 16,
		},
	},
};

const theme = createTheme({
	spacing,
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					overflowY: "scroll",
					fontSize: "62.5%;", // preferred this to typography.pxToRem(), because typography.pxToRem() is poorly documented and therefore useless
				},
				strong: {
					fontFamily: "NNNittiGroteskTextBold",
					fontWeight: 600,
				},
				".Mui-disabled + label": { color: grey["400"] },
				"ul:not([style]):not([class])": {
					li: {
						marginTop: "8px",
					},
					"li::before": {
						content: "'•'",
						color: orange["300"],
						display: "inline-block",
						marginLeft: "-22px",
						width: "22px",
						fontSize: "22px",
						lineHeight: "0",
						position: "relative",
						top: "3px",
					},
					"ul li::before": {
						color: grey["600"],
					},
					listStyleType: "none",
					padding: "0 0 0 22px",
				},
				"a:not([style]):not([class])": {
					color: orange["500"],
				},
				"ul[data-clear]": { margin: 0 },
			},
		},
		MuiAlert: {
			defaultProps: {
				iconMapping: {
					// defaultne color=inherit, coz ale odvozuje trosku jinak a dava stejnou
					// barvu jako je background
					info: <Info color="info" fontSize="inherit" />,
					warning: <Info color="warning" fontSize="inherit" />,
					error: <Error color="error" fontSize="inherit" />,
					success: <Check color="success" fontSize="inherit" />,
				},
			},
			styleOverrides: {
				icon({ theme }) {
					return { marginRight: theme.spacing(3) };
				},
				root({ ownerState }) {
					const palette = theme.palette[ownerState.severity];
					return {
						padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
						color: theme.palette.text.main,
						backgroundColor: palette.light,
						borderLeft: `${palette.main} 4px solid`,
					};
				},
				message: {
					flexGrow: 1,
				},
			},
			variants: [
				{
				  props: { variant: "transparent" },
				  style: {
					backgroundColor: "transparent",
					borderLeft: "none"
				  },
				},
			  ],
		},
		MuiSwipeableDrawer: MuiDrawer,
		MuiDrawer,
	},
	palette,
	customShadows: {
		close:
			"0px 0px 4px rgba(65, 65, 65, 0.03), 0px 1px 2px rgba(102, 102, 102, 0.08), 0px 0px 6px rgba(128, 128, 128, 0.15), 0px 4px 8px rgba(114, 114, 114, 0.1);",
		far: "0px 4px 12px rgba(65, 65, 65, 0.15)",
		extraFar: "0px -4px 12px rgba(65, 65, 65, 0.15);",
	},
	typography: {
		fontFamily: `"NNNittiGroteskTextRegular", sans-serif`,
		body1: typographyComponents.bodyTextM,
		body2: typographyComponents.bodyTextM,
		caption: typographyComponents.bodyTextM,
		overline: typographyComponents.bodyTextM,
		subtitle1: typographyComponents.bodyTextM,
		subtitle2: typographyComponents.bodyTextM,
		string: typographyComponents.bodyTextM,
		...typographyComponents,
	},
	breakpoints: {
		values: {
			xxs: 0,
			xs: 360,
			sm: 600,
			md: EnvironmentHelper.isBrowser() ? window.location.pathname === "/penzijni-kalkulacka/" ? 930 : 962 : 962, // 930px content, 32px horizontal spacing
		},
	},
	zIndex: {
		swipeableViews: 0,
	},
});

theme.shadows[4] = theme.shadows["close"] = theme.customShadows.close;
theme.shadows[16] = theme.shadows["far"] = theme.customShadows.far;
theme.shadows[24] = theme.shadows["extraFar"] = theme.customShadows.extraFar;

theme.typography.h1 = {
	...typographyComponents.headingL,
	":not(.withoutScale)": {
		[theme.breakpoints.up("md")]: {
			fontSize: theme.typography.headingXL.fontSize,
		},
	},
	"&.scaleDown": {
		[theme.breakpoints.up("md")]: {
			fontSize: theme.typography.headingM.fontSize,
		},
	},
};

theme.typography.h2 = {
	...typographyComponents.headingM,
	":not(.withoutScale)": {
		[theme.breakpoints.up("md")]: {
			fontSize: theme.typography.headingL.fontSize,
		},
	},
	"&.scaleDown": {
		[theme.breakpoints.up("md")]: {
			fontSize: theme.typography.headingS.fontSize,
		},
	},
};

theme.typography.h3 = {
	...typographyComponents.headingS,
	":not(.withoutScale)": {
		[theme.breakpoints.up("md")]: {
			fontSize: theme.typography.headingM.fontSize,
		},
	},
	"&.scaleDown": {
		[theme.breakpoints.up("md")]: {
			fontSize: theme.typography.headingXS.fontSize,
		},
	},
};

theme.typography.h4 = {
	...typographyComponents.headingXS,
	":not(.withoutScale)": {
		[theme.breakpoints.up("md")]: {
			fontSize: theme.typography.headingS.fontSize,
		},
	},
};

theme.typography.bodyTextM = {
	...typographyComponents.bodyTextM,
	"&.scaleDown": {
		[theme.breakpoints.up("md")]: {
			fontSize: theme.typography.bodyTextS.fontSize,
		},
	},
};

theme.components.MuiButtonBase = {
	styleOverrides: {
		root: {
			"&.MuiTab-root": {
				fontFamily: "NNNittiGroteskTextRegular",
			},
		},
	},
};

theme.components.MuiButton = {
	defaultProps: { disableRipple: true, size: "large" },
	styleOverrides: {
		sizeSmall: { fontSize: "1.6rem" },
		sizeMedium: { fontSize: "1.8rem" }, // should be unused in NN design system
		sizeLarge: { fontSize: "2.0rem" },
		root: {
			...theme.components.MuiCssBaseline.styleOverrides.strong,
			...theme.typography.bodyTextL,
			minWidth: 0,
			textTransform: "none",
			"&:focus": {
				outlineColor: theme.palette.black.main,
				outlineStyle: "solid",
				outlineWidth: "3px",
			},
		},
	},
	variants: [
		{
			props: { variant: "appBarLink" },
			style: {
				marginBottom: 0,
				border: "none",
				padding: "5px 8px",
				fontWeight: 400,
				color: theme.palette.grey.ultraDark,
				backgroundColor: "transparent",
				fontFamily: "NNNittiGroteskTextRegular",
				"&:hover": {
					background: "none",
				},
				"& .MuiButton-startIcon": {
					marginLeft: 0,
					marginRight: theme.spacing(4),
				},
			},
		},
		{
			props: { variant: "contained" },
			style: {
				...theme.components.MuiCssBaseline.styleOverrides.strong,
				padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
			},
		},
		{
			props: { variant: "smartAction" },
			style: {
				textTransform: "none",
				textAlign: "center",
				minHeight: 74,
				flexDirection: "column",
				justifyContent: "flex-start",
				width: "33.333%",
				padding: `${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(
					3
				)}`,
				[theme.breakpoints.up("md")]: {
					maxWidth: "unset",
					padding: `${theme.spacing(4)} ${theme.spacing(3)} 0 ${theme.spacing(
						3
					)} `,
					minHeight: 94,
				},
				color: theme.palette.white.main,
				background: `linear-gradient(127.06deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
				margin: 0,
				".MuiButton-startIcon": {
					margin: 0,
					marginTop: 0,
					height: 24,
					alignItems: "center",
				},
				"&.Mui-disabled": {
					background: theme.palette.grey.softDark,
				},
			},
		},
		{
			props: { variant: "text" },
			style: {
				...theme.components.MuiCssBaseline.styleOverrides.strong,
			},
		},
		{
			props: { variant: "text-light" },
			style: {
				fontWeight: 400,
				color: theme.palette.primary.main,
				fontFamily: "NNNittiGroteskTextRegular",
				padding: 0,
				justifyContent: "start",
			},
		},
		{
			props: { variant: "light" },
			style: {
				fontWeight: 400,
				textDecoration: "underline",
			},
		},
		{
			props: { variant: "bank-id" },
			style: {
				color: theme.palette.white.main,
				background: `${theme.palette.black.main} url("${bankidImage}") no-repeat
				16px center`,
				paddingLeft: "140px",
				paddingRight: "20px",
				minHeight: "51px",
				"&:hover": {
					backgroundColor: theme.palette.black.main,
				},
				"&:focus": {
					outlineColor: theme.palette.primary.light,
				},
			},
		},
		{
			props: { variant: "clear" },
			style: {
				padding: 0,
			},
		},
		{
			props: { variant: "collapsible" },
			style: {
				padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(
					3
				)} 0`,
				width: "100%",
				display: "flex",
				justifyContent: "space-between",
				borderRadius: 0,
			},
		},
		{
			props: { variant: "cookie-collapsible" },
			style: {
				width: "100%",
				display: "flex",
				justifyContent: "start",
				minHeight: 32,
				fontWeight: 400,
				fontFamily: "NNNittiGroteskTextRegular",
				padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(
					4
				)} ${theme.spacing(4)}`,
			},
		},
		{
			props: { isHorizontal: true },
			style: {
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
	],
};

theme.components.MuiIconButton = {
	variants: [
		{
			props: { variant: "contained" },
			style: {
				background: theme.palette.grey.light,
				"&:hover": {
					background: theme.palette.grey.light,
					svg: {
						fill: theme.palette.white.main,
					},
				},
			},
		},
	],
};

theme.components.MuiLink = {
	styleOverrides: {
		root: {
			...theme.typography.bodyTextM,
			...theme.components.MuiCssBaseline.styleOverrides.strong,
		},
	},
	variants: [
		{
			props: { variant: "light" },
			style: {
				fontFamily: "NNNittiGroteskTextRegular",
				fontWeight: 400,
				textDecoration: "underline",
			},
		},
	],
};

theme.components.MuiFormControlLabel = {
	styleOverrides: {
		root: {
			marginLeft: 0,
		},
		label: {
			...theme.typography.bodyTextM,
			...theme.components.MuiCssBaseline.styleOverrides.strong,
			marginLeft: theme.spacing(3),
		},
	},
};

theme.components.MuiNativeSelect = {
	styleOverrides: {
		select: {
			textOverflow: "ellipsis",
			"&&&": {
				// https://github.com/mui/material-ui/issues/24453
				paddingRight: 40,
			},
		},
		icon: {
			right: theme.spacing(4),
			width: "2rem",
			height: "1.2rem",
			color: theme.palette.primary.dark,
		},
	},
};

theme.components.MuiSlider = {
	styleOverrides: {
		thumb: {
			height: 32,
			width: 32,
			backgroundColor: theme.palette.white.main,
			border: "1px solid currentColor",
			"&.Mui-active": {
				boxShadow: "none",
			},
			"&:hover": {
				boxShadow: "none",
			},
			"&.Mui-focusVisible": {
				boxShadow: "none",
			},
			"&::before": {
				boxShadow: theme.shadows[4],
			},
		},
		rail: {
			backgroundColor: theme.palette.grey.main,
			height: 8,
			width: "calc(100% + 32px)",
			marginLeft: -16,
		},
		track: {
			width: "calc(100% + 32px)",
			marginLeft: -16,
		},
	},
};

theme.components.MuiCheckbox = {
	styleOverrides: {
		root: {
			paddingLeft: 0,
			"&.Mui-disabled": {
				color: theme.palette.grey.softDark,
			},
		},
	},
};

theme.components.MuiRadio = {
	styleOverrides: {
		root: {
			"&.Mui-checked span": {
				boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
			},
			"&.Mui-disabled span": {
				boxShadow: `0 0 0 1px ${theme.palette.grey.softDark}`,
			},
			"&.Mui-disabled span::before": {
				backgroundImage: `radial-gradient(${theme.palette.grey.softDark},${theme.palette.grey.softDark} 34%,transparent 38%)`,
			},
		},
	},
};

theme.components.MuiSwitch = {
	styleOverrides: {
		root: {
			width: 56,
			height: 32,
			padding: 0,
			marginRight: 9,
		},
		switchBase: {
			padding: 0,
			margin: 1,
			transitionDuration: "300ms",
			"&.Mui-checked": {
				transform: "translateX(24px)",
				color: "#fff",
				"& + .MuiSwitch-track": {
					opacity: 1,
					border: 0,
				},
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				backgroundColor: theme.palette.grey.softDark,
				opacity: 1,
			},
			"&.Mui-disabled .MuiSwitch-thumb": {
				color: theme.palette.white.main,
			},
		},
		thumb: {
			boxSizing: "border-box",
			width: 30,
			height: 30,
		},
		track: {
			borderRadius: 16,
			backgroundColor: theme.palette.grey.ultraLight,
			opacity: 1,
			transition: theme.transitions.create(["background-color"], {
				duration: 500,
			}),
		},
	},
};

theme.components.MuiInputAdornment = {
	styleOverrides: {
		root: {
			// fix the collision of adornment button with MuiOutlinedInput.styleOverrides.notchedOutline:before (element which creates "hole" in input border)
			zIndex: 1,
		},
	},
};

theme.components.MuiOutlinedInput = {
	styleOverrides: {
		root: {
			border: `1px solid ${theme.palette.grey.softDark}`,
			borderRadius: 4,
			width: "100%",
			position: "relative",
			"&::before": {
				content: `""`,
				display: "block",
				height: 12,
				width: 16,
				position: "absolute",
				background: theme.palette.white.main,
				right: -1,
				top: -1,
			},
			"&.Mui-focused": {
				borderColor: theme.palette.grey.ultraDark,
			},
			"&.Mui-disabled": {
				borderColor: theme.palette.grey.softDark,
			},
			"&.Mui-disabled input": {
				color: theme.palette.grey.softDark,
			},
		},
		notchedOutline: {
			border: 0,
		},
	},
};

theme.components.MuiInput = {
	styleOverrides: {
		root: {
			"&.MuiNativeSelect-root": {
				"&::before": {
					content: `""`,
					display: "block",
					height: 12,
					width: 16,
					position: "absolute",
					background: theme.palette.white.main,
					right: -1,
					top: -1,
					transform: "none",
					left: "unset",
					border: 0,
					zIndex: 1,
				},
				"&::after": {
					display: "none",
				},
			},
			"&.MuiNativeSelect-root:hover": {
				"&::before": {
					border: 0,
				},
			},
			".MuiNativeSelect-select": {
				position: "relative",
				border: `1px solid ${theme.palette.grey.softDark}`,
				borderRadius: 4,
				width: "100%",
			},
			"&.Mui-focused .MuiNativeSelect-select": {
				borderColor: theme.palette.grey.ultraDark,
			},
			"&.Mui-disabled .MuiNativeSelect-select": {
				borderColor: theme.palette.grey.softDark,
				color: theme.palette.grey.softDark,
			},
		},
		input: {
			...theme.typography.bodyTextM,
			padding: theme.spacing(4),
			"&:focus": {
				backgroundColor: "transparent",
			},
		},
	},
};

theme.components.MuiInputLabel = {
	styleOverrides: {
		root: {
			...theme.typography.bodyTextM,
			...theme.components.MuiCssBaseline.styleOverrides.strong,
			transform: "none",
			marginBottom: theme.spacing(2),
			color: theme.palette.grey.ultraDark,
			"&.Mui-focused": {
				color: theme.palette.grey.dark
			},
		},
	},
	variants: [
		{
			props: { variant: "subLabel" },
			style: {
				fontFamily: "NNNittiGroteskTextRegular",
				fontWeight: 400,
				whiteSpace: "normal",
				paddingLeft: "73px",
				cursor: "pointer",
			},
		},
	],
};

theme.components.MuiSvgIcon = {
	styleOverrides: {
		root: {
			verticalAlign: "middle",
		},
	},
};

theme.components.MuiTab = {
	styleOverrides: {
		root: {
			"& > .MuiTab-iconWrapper": {
				justifyContent: "center",
				height: "24px",
			},
		},
	},
};

theme.components.MuiPaper = {
	defaultProps: {
		elevation: 4,
	},
};

theme.components.MuiChip = {
	defaultProps: {
		color: "primary",
	},
	styleOverrides: {
		root: {
			...theme.typography.bodyTextXS,
			borderRadius: 4,
			height: 16,
		},
		label: {
			padding: `0 ${theme.spacing(1)}`,
		},
	},
};

theme.components.MuiTableCell = {
	styleOverrides: {
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingTop: 12,
			paddingBottom: 12,
		},
		head: {
			...theme.typography.headingS,
			borderBottom: `3px solid ${theme.palette.primary.main}`,
		},
	},
};

theme.components.MuiContainer = {
	styleOverrides: {
		root: {
			[theme.breakpoints.up("md")]: {
				paddingLeft: theme.spacing(4),
				paddingRight: theme.spacing(4),
			},
		},
	},
};

theme.components.MuiDialog = {
	styleOverrides: {
		paper: {
			height: "auto",
			width: "auto",
			maxWidth: "90%",
			maxHeight: "90%",
			overflowY: "clip",
			borderRadius: theme.shape.borderRadius * 4,
		},
		paperFullScreen: {
			width: "100%",
			maxWidth: "100%",
			alignSelf: "flex-end"
		}
	},
};

theme.components.MuiFormLabel = {
	styleOverrides: {
		asterisk: { color: theme.palette.primary.main },
	},
};

theme.components.MuiStepLabel = {
	styleOverrides: {
		iconContainer: {
			paddingRight: 0,
			width: 12,
			height: 12,
			borderRadius: "100%",
			border: `2px solid ${theme.palette.grey.light}`,
			"&.Mui-active, &.Mui-completed": {
				backgroundColor: theme.palette.primary.main,
				border: `2px solid ${theme.palette.primary.main}`,
			},
		},
	},
};

theme.components.MuiStepConnector = {
	styleOverrides: {
		line: {
			borderColor: theme.palette.primary.main,
			borderTopWidth: 2,
		},
		root: {
			"&.Mui-disabled .MuiStepConnector-line": {
				borderColor: theme.palette.grey.light,
			},
		},
	},
};

theme.components.MuiStep = {
	styleOverrides: {
		root: {
			padding: 0,
		},
	},
};

theme.components.MuiStepper = {
	styleOverrides: {
		root: {
			"&::before": {
				content: `""`,
				display: "flex",
				flex: "1 1 auto",
				height: 2,
				backgroundColor: theme.palette.primary.main,
			},
			"&::after": {
				content: `""`,
				display: "flex",
				flex: "1 1 auto",
				height: 2,
				backgroundColor: theme.palette.grey.light,
			},
			"&.last-step::after": {
				backgroundColor: theme.palette.primary.main,
			},
		},
	},
};

theme.components.MuiListItemIcon = {
	styleOverrides: {
		root: {
			minWidth: 0,
			marginRight: "12px"
		},
	},
};

export default theme;
