import { Skeleton, Stack } from "@mui/material";
import { useFormikContext } from "formik";

import cs from "../../translations/cs.json";
import SingleStepDrawer from "../Drawer/SingleStepDrawer";
import Form from "../Form/Form";
import FormErrorMessage from "../Form/FormErrorMessage";
import DatePicker from "../Form/Input/DatePicker";
import Switch from "../Form/Input/Switch";
import TriggerButton from "../Link/TriggerButton";
import { HeadingM } from "../Typography";
function ResetFilter({ handleHideDrawer }: Props) {
	const { isSubmitting, resetForm, submitForm } = useFormikContext();

	return (
		<TriggerButton
			variant="text"
			type="reset"
			disabled={isSubmitting}
			onClick={async () => {
				resetForm();
				await submitForm();
				handleHideDrawer();
			}}
		>
			Zrušit&nbsp;filtr
		</TriggerButton>
	);
}

interface Props {
	fundNames?: string[];
	handleHideDrawer: () => void;
}

const FilterChartsDrawer = ({
	fundNames,
	handleHideDrawer,
}: Props): JSX.Element => {
	const { errors, touched } = useFormikContext();

	return (
		<SingleStepDrawer hideDrawer={handleHideDrawer}>
			<Form
				spacing={6}
				actions={<ResetFilter handleHideDrawer={handleHideDrawer} />}
				isSubmitDisabled={
					fundNames === undefined || Object.keys(errors).length > 0
				}
			>
				<HeadingM sx={{ m: 0 }}>Filtrovat vývoj fondů</HeadingM>
				<Stack spacing={4}>
					{fundNames ? (
						fundNames.map((name) => (
							<Switch key={name} name={`funds.${name}`} label={name} />
						))
					) : (
						<Skeleton height={100} />
					)}
				</Stack>
				{errors.funds && (
					<FormErrorMessage name="funds">{errors.funds}</FormErrorMessage>
				)}
				{errors.validRange && (touched.dateFrom || touched.dateTo) && (
					<FormErrorMessage name="funds">
						{cs.errorMessages.dateRange}
					</FormErrorMessage>
				)}
				<DatePicker
					name="dateFrom"
					placeholder="Datum od"
					options={{ maxDate: new Date(), minDate: new Date(1993, 1, 1) }}
				/>
				<DatePicker
					name="dateTo"
					placeholder="Datum do"
					options={{ maxDate: new Date(), minDate: new Date(1993, 1, 1) }}
				/>
			</Form>
		</SingleStepDrawer>
	);
};

export default FilterChartsDrawer;
